<template>
  <div class="map-view-container">
    <div v-show="isUsingBaiduMap" id="baidu_map">
      <baidu-map
        id="baidu-container"
        :ak="baiduMapKey"
        :center="centerPoint"
        :zoom="zoom"
        :scroll-wheel-zoom="true"
        @ready="handler"
        @moveend="syncCenterAndZoom"
        @zoomend="syncCenterAndZoom"
      >
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" />
        <img class="locate-icon" src="/img/locate.png" @click="getCurrentLocation">
        <!-- <bm-marker v-if="targetPoint" :position="targetPoint" :dragging="false" />
        <bm-marker v-if="currentPoint" :position="currentPoint" :dragging="false" /> -->
      </baidu-map>
    </div>
    <div v-show="!isUsingBaiduMap" id="google_map">
      <gmap-map
        ref="googleMapRef"
        :center="{ lng: 114.169361,lat: 22.319304 }"
        :zoom="17"
        :options="{
          zoomControl: true,
          zoomControlOptions: {
            position: 'RIGHT_TOP',
          },
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: false
        }"
      >
        <img ref="locateIconRef" class="locate-icon" src="/img/locate.png" @click="getCurrentLocation">
        <gmap-marker
          v-if="targetPoint"
          :position="targetPoint"
          :clickable="false"
          :draggable="false"
          :icon="'/img/location.2x.png'"
        />
        <gmap-marker
          v-if="currentPoint"
          :position="currentPoint"
          :clickable="false"
          :draggable="false"
          :icon="'/img/location.2x.png'"
        />
      </gmap-map>
    </div>
  </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import { BmNavigation } from 'vue-baidu-map'
import { gmapApi } from 'vue2-google-maps'
import axios from 'axios'
import utilsMixin from '@/mixins/utilsMixin'
import backHandlerMixin from '@/mixins/backHandlerMixin'
import { sendPageViewAnalytics } from '@/utils/tracking'

export default {
  name: 'MissionLocationMap',
  components: {
    BaiduMap,
    BmNavigation
  },
  mixins: [utilsMixin, backHandlerMixin],
  data () {
    return {
      storeId: null,
      data: {},
      targetPoint: null,
      currentPoint: null,
      centerPoint: null,
      zoom: 17,
      baiduBMap: null,
      baiduMap: null,
      baiduBounds: null,
      googleMap: null,
      firstLoad: false,
      googleCluster: null
    }
  },
  computed: {
    isUsingBaiduMap () {
      const lang = this.$i18n.locale
      return lang === 'zh-s'
    },
    baiduMapKey () {
      return process.env.VUE_APP_BAIDU_MAP_KEY
    },
    googleMapKey () {
      return process.env.VUE_APP_GOOGLE_API_KEY
    },
    google: gmapApi
  },
  async mounted () {
    this.$store.commit('app/setNavType', 'back_language_menu')
    this.$store.commit('app/hideFooter')

    const { storeId } = this.$route.query
    this.storeId = storeId
    this.getMissionDetail()
    await this.getMissionLocationDetail()

    if (this.isUsingBaiduMap) {
      this.setBaiduMapPoint(this.targetPoint, true)
    } else {
      this.centerPoint = this.targetPoint

      this.firstLoad = true
      this.initGoogleMap()
    }
  },
  beforeDestroy () {
    this.$store.commit('app/setNavType', 'logo_language_menu')
    this.$store.commit('app/showFooter')
  },
  methods: {
    async getMissionLocationDetail () {
      const data = this.$store.getters['campaigns/missionLocationDetail'](this.storeId)
      if (data && data.id) {
        this.data = this.formatApplicableStore(data)
        if (data.longitude && data.latitude) {
          this.targetPoint = { lng: parseFloat(data.longitude), lat: parseFloat(data.latitude) }
        }
        return
      }

      await axios.get(`/checkin_locations/${this.storeId}`).then(response => {
        const data = response.data
        this.$store.commit('campaigns/setMissionLocationDetail', data)
        this.data = this.formatApplicableStore(data)
        if (data.longitude && data.latitude) {
          this.targetPoint = { lng: parseFloat(data.longitude), lat: parseFloat(data.latitude) }
        }
      }).catch(error => {
        console.log('371--- errro', error)
        const { data } = error.response || {}
        const { detail } = data || {}
        this.detailNotFound = detail === 'Not found.'
      })
    },
    async getMissionDetail () {
      const { missionId, gamificationId } = this.$route.query
      await axios
        .get(`/gamification_missions/${missionId}`)
        .then(response => {
          // console.log('response---', response)
          // this.missionData = response.data
          // this.$store.commit('campaigns/setMissionDetail', this.missionData)
          this.$store.commit('gamification/updateMissionList', {
            gamificationCampaignId: gamificationId,
            missionId: missionId,
            detail: response.data
          })
          sendPageViewAnalytics(this.$route, this.$i18n.locale, this.$store)
        })
        .catch(error => {
          sendPageViewAnalytics(this.$route, this.$i18n.locale, this.$store)
          console.log('371--- errro', error)
        })
    },
    setBaiduMapPoint (point, isTargetPoint = false) {
      console.log('setBaiduMapPoint', point, isTargetPoint)
      if (point && point.lat) {
        const _this = this
        var translateCallback = function (data) {
          if (data.status === 0) {
            const translatePoint = data.points[0]
            if (isTargetPoint) {
              _this.targetPoint = translatePoint
              _this.centerPoint = translatePoint
              _this.baiduMap.centerAndZoom(translatePoint, _this.zoom)
            }
            console.log('translatePoint', translatePoint, data)
            var markerbm = new _this.baiduBMap.Marker(translatePoint, { icon: new _this.baiduBMap.Icon('/img/location.2x.png', new _this.baiduBMap.Size(100, 100)) })
            _this.baiduMap.addOverlay(markerbm)
          }
        }
        var convertor = new _this.baiduBMap.Convertor()
        var pointArr = [point]
        const COORDINATES_WGS84 = 1
        const COORDINATES_GCJ02 = 3
        const COORDINATES_BD09 = 5
        convertor.translate(pointArr, isTargetPoint ? COORDINATES_GCJ02 : COORDINATES_WGS84, COORDINATES_BD09, translateCallback)
        /**
         * 坐標常量說明：
         * COORDINATES_WGS84 = 1, WGS84坐標
         * COORDINATES_WGS84_MC = 2, WGS84的平面墨卡托坐標
         * COORDINATES_GCJ02 = 3，GCJ02坐標
         * COORDINATES_GCJ02_MC = 4, GCJ02的平面墨卡托坐標
         * COORDINATES_BD09 = 5, 百度bd09經緯度坐標
         * COORDINATES_BD09_MC = 6，百度bd09墨卡托坐標
         * COORDINATES_MAPBAR = 7，mapbar地圖坐標
         * COORDINATES_51 = 8，51地圖坐標
        */
      }
    },
    handler ({ BMap, map }) {
      console.log(BMap, map)
      this.baiduBMap = BMap
      this.baiduMap = map
      this.baiduBounds = map.getBounds()
    },
    syncCenterAndZoom (e) {
      this.zoom = e.target.getZoom()
      this.center = e.target.getCenter()
    },
    grantGPSSuccess (lat, lng) {
      this.currentPoint = { lng, lat }
      if (this.isUsingBaiduMap) {
        this.setBaiduMapPoint(this.currentPoint, false)
      }

      if (this.targetPoint && this.targetPoint.lat && this.currentPoint && this.currentPoint.lat) {
        if (this.isUsingBaiduMap) {
          var view = this.baiduMap.getViewport([this.targetPoint, this.currentPoint])
          this.zoom = view.zoom
          this.centerPoint = view.center
          this.baiduMap.centerAndZoom(view.center, view.zoom)
        } else {
          var markerBounds = new this.google.maps.LatLngBounds()
          markerBounds.extend(this.targetPoint)
          markerBounds.extend(this.currentPoint)
          this.googleMap.fitBounds(markerBounds)
        }
      }
    },
    grantGPSError (err) {
      console.log('getGPSerror', err)
    },
    getCurrentLocation () {
      this.grantGPS(this.grantGPSSuccess, this.grantGPSError)
    },
    initGoogleMap () {
      const _this = this
      this.$refs.googleMapRef.$mapPromise && this.$refs.googleMapRef.$mapPromise.then((map) => {
        _this.googleMap = map
        map.panTo(_this.targetPoint)
        map.controls[_this.google.maps.ControlPosition.RIGHT_BOTTOM].push(_this.$refs.locateIconRef)
      })
    }
  }
}
</script>

<style scoped lang='scss'>
#baidu_map, #google_map {
  // margin: 20px;
  // border: 1px solid;
}

#baidu-container {
  width: 100%;
  height: calc(100vh - 72px);
  // border: 1px solid;
  position: relative;

  .locate-icon {
    width: 32px;
    height: 32px;
    position: absolute;
    bottom: 30px;
    right: 10px;
  }
}
.vue-map-container {
  width: 100%;
  height: calc(100vh - 72px);
  // border: 1px solid red;

  .locate-icon {
    width: 50px;
    height: 50px;
  }
}

</style>
<style lang='scss'>
</style>
