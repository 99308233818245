<template>
  <div
    :class="['custom-tooltip-container', {'revert': revert}]"
    :style="{
      'visibility': show ? 'visible' : 'hidden',
      'left': `${pos.left || 0}px`,
      'marginTop': `${pos.marginTop || 0}px`,
      'flex-direction': revert ? 'column-reverse' : 'column',
      'width': revert ? `calc(100% + 100px)` : isMobile ? 'auto' : '100%'
    }">
    <!-- <div v-if="showHover" class="tooltip-hover-container">
      <slot></slot>
      <img
        class="tooltip-icon"
        src="/img/tooltip_arrow.png"
        :style="{
          'display': visible ? 'block' : 'none',
          // 'display': visible ? 'block' : 'block',
        }"
      >
    </div> -->
    <img
      v-if="showHover"
      class="tooltip-icon"
      src="/img/tooltip_arrow.png"
      :style="{
        //'display': visible ? 'block' : 'none',
        // 'display': visible ? 'block' : 'block',
        //'visibility': forceHide ? 'hidden' : 'unset',
        //'left': iconPos.left,
        //'top': iconPos.top,
        'margin-left': iconPos.marginLeft ? `${iconPos.marginLeft}px` : '0px'
      }"
    >
    <div
      v-if="content"
      :class="['tooltip-content-container', {'close': showClose}, langClass]"
      :style="{
        //'display': visible ? showClose ? 'flex' : 'block' : 'none',
        // 'display': visible ? 'block' : 'block',
        //'visibility': forceHide ? 'hidden' : 'unset',
        //'left': contentPos.left,
        //'right': contentPos.right,
        //'top': contentPos.top,
      }"
    >
      {{ content }}
      <img
        v-if="showClose"
        class="close"
        srcset="/img/close_white.png, /img/close_white.2x.png 2x, /img/close_white.3x.png 3x"
        src="/img/close_white.2x.png"
        @click.stop="$emit('closeTips')"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomTooltip',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    showHover: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: false
    },
    revert: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    },
    customTooltipSlotElement: {
      type: Object,
      default: null
    },
    customTooltipReferenceElement: {
      type: Object,
      default: null
    },
    tooltipIndex: {
      type: Number,
      default: 0
    },
    spaceBetween: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      forceHide: true,
      iconPos: {},
      contentPos: {},
      pos: {},
      show: false
    }
  },
  computed: {
    isMobile () {
      return this.$store.getters['app/isMobile']
    },
    langClass () {
      if (this.$i18n.locale === 'zh-s') {
        return 'zhs'
      } else if (this.$i18n.locale === 'zh-t') {
        return 'zht'
      }
      return 'en'
    },
    positions () {
      return [this.customTooltipSlotElement, this.customTooltipReferenceElement]
    }
  },
  watch: {
    // 'visible': function (newVal, oldVal) {
    //   if (newVal) {
    //     this.$nextTick(() => {
    //       this.calculatePos(this.positions)
    //     })
    //   }
    // },
    'positions': function (newVal, oldVal) {
      if (newVal) {
        this.$nextTick(() => {
          console.log('154-00', newVal)
          setTimeout(() => {
            this.calculatePos(newVal)
          }, 50)
        })
      }
    },
    '$store.state.app.windowWidth': function (newVal, oldVal) {
      if (this.positions && this.positions[0]) {
        this.calculatePos(this.positions)
      }
    },
    'visible': function (newVal, oldVal) {
      if (!newVal) {
        this.show = false
      }
    }
  },
  async mounted () {
    this.calculatePos(this.positions)
  },
  methods: {
    calculatePos (positions) {
      this.forceHide = false
      const element0 = positions && positions[0]?.queryName ? window.$(positions[0].queryName)[positions[0].index] : null
      const element1 = positions && positions[1]?.queryName ? window.$(positions[1].queryName)[positions[1].index] : null

      const customTooltipSlotPos = element0 && element0.getBoundingClientRect()
      const customTooltipReferencePos = element1 && element1.getBoundingClientRect()
      const contentElement = window.$('.tooltip-content-container')[this.tooltipIndex]
      const contentPos = contentElement && contentElement.getBoundingClientRect()

      const element = window.$('.custom-tooltip-container')[this.tooltipIndex]
      console.log('154-0', positions, this.tooltipIndex)
      console.log('154', customTooltipSlotPos, customTooltipReferencePos)
      if (customTooltipSlotPos && customTooltipReferencePos) {
        // this.pos.left = customTooltipSlotPos.x - customTooltipReferencePos.x
        if (element) {
          this.pos.left = customTooltipSlotPos.x - ((element.getBoundingClientRect()).width / 2 - customTooltipSlotPos.width / 2) - customTooltipReferencePos.x

          if (this.isMobile) {
            this.pos.left = 0
            this.iconPos.marginLeft = customTooltipSlotPos.x - customTooltipReferencePos.x - customTooltipSlotPos.width / 2
            if (customTooltipSlotPos.x < (element.getBoundingClientRect()).width / 2) {
              this.pos.left = 0 - customTooltipSlotPos.x / 2
              this.iconPos.marginLeft = customTooltipSlotPos.x + customTooltipSlotPos.width - (element.getBoundingClientRect()).width
            }
          }

          if (this.revert) {
            this.pos.left = 16 - 100
            let referenceHeight = customTooltipReferencePos.height
            if (!referenceHeight) {
              console.log('154-11: ', element1.firstElementChild, element1.firstElementChild?.height)
              referenceHeight = element1.firstElementChild?.height
            }
            // console.log('154-192: ', customTooltipReferencePos.firstElementChild, customTooltipReferencePos.firstElementChild.getBoundingClientRect())
            this.pos.marginTop = -((element.getBoundingClientRect()).height + referenceHeight)
            this.iconPos.marginLeft = customTooltipSlotPos.x - customTooltipReferencePos.x + 100 - 32 - 5
          }

          if (this.visible) {
            this.show = true
          }
          // this.pos.left -= ((element.getBoundingClientRect()).width / 2 - customTooltipSlotPos.width / 2)
        }
        // let extraPos = 0
        // if (customTooltipSlotPos.width !== 16) {
        //   extraPos = (customTooltipSlotPos.width - 16) / 2
        // }
        // this.iconPos = {
        //   top: this.revert ? `${-(8 + customTooltipSlotPos.height)}px` : `${-(8 + this.spaceBetween - 8 - 2)}px`,
        //   left: `${customTooltipSlotPos.left - customTooltipReferencePos.left + extraPos}px`
        // }
        // this.contentPos = {
        //   top: this.revert ? `${-(8 + customTooltipSlotPos.height + (contentPos && contentPos.height))}px` : `${-(this.spaceBetween - 8 - 2)}px`,
        //   left: this.isMobile ? '0px' : '',
        //   right: this.isMobile ? '0px' : ''
        // }
      } else {
        // setTimeout(() => {
        //   this.calculatePos(this.positions)
        // }, 500)
        this.forceHide = true
        this.iconPos = {
          top: this.revert ? `${-(8)}px` : `${-(8 + this.spaceBetween - 8 - 2)}px`
        }
        this.contentPos = {
          top: this.revert ? `${-(8 + (contentPos && contentPos.height))}px` : `${-(this.spaceBetween - 8 - 2)}px`,
          left: this.isMobile ? '0px' : '',
          right: this.isMobile ? '0px' : ''
        }
      }
      console.log('???1 calculate', positions, this.iconPos, this.contentPos, contentPos)
    }
  }
}
</script>

<style scoped lang='scss'>
@import '@/assets/common.scss';
.custom-tooltip-container {
  // position: relative;
  position: absolute;
  flex-direction: column;
  align-items: center;
  display: flex;
  z-index: 500;
  // justify-content: center
}
// .tooltip-hover-container {
//   position: relative;
// }
.tooltip-icon {
  // position: absolute;
  width: 16px;
  height: 8px;
  // top: -8px;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, 100%);
}
.tooltip-content-container {
  background: #303133;
  color: #FFFFFF;

  // position: absolute;
  // top: 5px;

  border-radius: 8px;
  padding: 10px;
  // z-index: 2000;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  min-width: 10px;
  word-wrap: break-word;
}
.tooltip-content-container.close {
  display: flex;
  justify-content: space-between;
  .close {
    width: 20px;
    height: 20px;
    margin-left: 12px;
  }
}

.custom-tooltip-container.revert {
  .tooltip-icon {
    transform: rotateX(180deg);
  }
}

.is-vue-mobile .tooltip-content-container {
}

.is-vue-desktop {
  .custom-tooltip-container {
    max-width: 624px;
  }
}
</style>
<style lang='scss'>
.is-vue-desktop {
  .custom-tooltip-container {
    max-width: 624px;
  }
}
</style>
