<template>
  <div class="mix-item">
    <goodie-bag-list-item
      v-if="isGoodieBagItem"
      :key="`${item.recordType}-${item.id}`"
      :item="item"
      :card-orientation="cardOrientation"
      @showTnc="toggleTncDialog"
    />
    <gamification-list-item
      v-if="isGamificationCampaignItem"
      :key="`${item.recordType}-${item.id}`"
      :item="item"
      :card-orientation="cardOrientation"
    />
  </div>
</template>

<script>
import GoodieBagListItem from '@/components/GoodieBagListItem'
import GamificationListItem from '@/components/GamificationListItem'

export default {
  name: 'GoodieBagAndGamificationListItem',
  components: {
    GoodieBagListItem,
    GamificationListItem
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    cardOrientation: {
      type: String,
      default: 'HORIZONTAL'
    }
  },
  data () {
    return {}
  },
  computed: {
    isGoodieBagItem () {
      return this.item.recordType === 'goodiebag'
    },
    isGamificationCampaignItem () {
      return this.item.recordType === 'gamification'
    }
  },
  mounted () {},
  methods: {
    toggleTncDialog () {
      this.$emit('showTnc', this.item.id)
    }
  }
}
</script>
