import Vue from 'vue'
import Vuex from 'vuex'
import { app } from './app.module'
import { local } from './local.module'
import { auth } from './auth.module'
import { coupons } from './coupons.module'
import { goodiebagparticipationrecords, getGoodieBagList } from './goodiebagparticipationrecords.module'
import { goodiebags } from './goodiebags.module'
import { campaigns } from './campaigns.module'
import { gamification, getGamificationCampaignRecordList } from './gamification.module'
import { interestpreferences } from './interestpreferences.module'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const authState = createPersistedState({
  paths: ['auth', 'local']
})

export const store = new Vuex.Store({
  modules: {
    app,
    local,
    auth,
    campaigns,
    coupons,
    interestpreferences,
    goodiebagparticipationrecords,
    goodiebags,
    gamification
  },
  plugins: [authState],
  state: {
    mixRecordType: 'all'
  },
  getters: {
    mixRecordType: (state) => {
      return state.mixRecordType
    },
    goodieBagAndGamificationRecords: (state) => (isTestUser, countryCode, recordType) => {
      const goodieBagRecordList = getGoodieBagList(state.goodiebagparticipationrecords.records, isTestUser) || []
      const gamificationList = getGamificationCampaignRecordList(state.gamification.gamificatonDetailList, isTestUser, countryCode) || []

      const mixRecords = goodieBagRecordList.concat(gamificationList).sort((a, b) => {
        if (isTestUser) {
          // id big first, then goodiebag first
          if (a.orderValue1 === b.orderValue1) {
            return a.recordType < b.recordType ? 1 : -1
          }
          return b.orderValue1 - a.orderValue1
        } else {
          // orderValue1 big first, then orderValue2 big first, then orderValue3 a-z, then orderValue4(id) big first, then goodiebag first
          if (a.orderValue1 === b.orderValue1) {
            if (a.orderValue2 === b.orderValue2) {
              if (a.orderValue3 === b.orderValue3) {
                if (a.orderValue4 === b.orderValue4) {
                  return a.recordType < b.recordType ? 1 : -1
                }
                return a.orderValue4 < b.orderValue4 ? 1 : -1
              }
              return a.orderValue3 < b.orderValue3 ? -1 : 1
            } else if (!a.orderValue2 && b.orderValue2) {
              return 1
            } else if (a.orderValue2 && !b.orderValue2) {
              return -1
            }
            return a.orderValue2 < b.orderValue2 ? 1 : -1
          } else if (!a.orderValue1 && b.orderValue1) {
            return a.orderValue2 < b.orderValue1 ? 1 : -1
          } else if (a.orderValue1 && !b.orderValue1) {
            return a.orderValue1 < b.orderValue2 ? 1 : -1
          }
          return a.orderValue1 < b.orderValue1 ? 1 : -1
        }
      })
      const activeItems = mixRecords.filter((item) => !item.is_expired && !item.is_invalid)
      const expiredItems = mixRecords.filter((item) => item.is_expired && !item.is_invalid)
      // console.log('goodieBagAndGamificationRecords', isTestUser, mixRecords.length, activeItems.length, expiredItems.length, mixRecords.filter((item) => item.is_invalid))
      const totalMixRecords = [...activeItems, ...expiredItems]
      if (recordType === 'both') {
        const gamificationRecords = totalMixRecords.filter((item) => item.recordType === 'gamification')
        const goodieBagRecords = totalMixRecords.filter((item) => item.recordType === 'goodiebag')
        if (gamificationRecords.length && goodieBagRecords.length) {
          return [goodieBagRecords[0], gamificationRecords[0]]
        } else if (gamificationRecords.length) {
          return gamificationRecords.slice(0, 1)
        }
        return goodieBagRecords.slice(0, 1)
      }
      return totalMixRecords
    }
  },
  mutations: {
    setMixRecordType (state, mixRecordType) {
      state.mixRecordType = mixRecordType || 'all'
    }
  }
})

export default store
