import axios from 'axios'

export const getGamificationCampaignRecordList = (items, isTestUser, countryCode) => {
  const newItems = items.filter((item) => {
    const isEligableCouryCode = countryCode && item.country_codes && item.country_codes.length ? (item.country_codes || []).filter((itemCountryCode) => itemCountryCode.id === countryCode).length : true
    return (item.is_public || item.gamification_campaign_participation_record_id) && isEligableCouryCode
  }).map((item) => {
    // let orderValue1 = ''
    // let orderValue2 = ''
    // let orderValue3 = ''
    // let orderValue4 = ''
    let orderValue = ''
    if (isTestUser) {
      // orderValue1 = item.id
      orderValue = item.id
    } else {
      // orderValue1 = item.gamification_campaign_participation_date
      // orderValue2 = item.display_start_date
      // orderValue3 = item.name
      // orderValue4 = item.id

      if (item.gamification_campaign_participation_record_id) {
        orderValue = item.gamification_campaign_participation_date
      } else {
        orderValue = new Date(Math.max(new Date(item.display_start_date), item.publication_date ? new Date(item.publication_date) : new Date('0000-01-01')))
      }
    }

    const validStartDate = item.display_start_date ? new Date(item.display_start_date) : new Date('0000-01-01')
    const validEndDate = item.display_end_date ? new Date(item.display_end_date) : new Date('9999-12-31')
    const is_expired = item.end_date && new Date() > new Date(item.end_date)
    const is_invalid = new Date() > validEndDate || new Date() < validStartDate
    return {
      ...item,
      is_expired,
      is_invalid,
      recordType: 'gamification',
      // orderValue1,
      // orderValue2,
      // orderValue3,
      // orderValue4,
      orderValue
    }
  }).sort((a, b) => {
    return a.orderValue < b.orderValue ? 1 : -1
  })
  // return newItems
  const activeItems = newItems.filter((item) => !item.is_expired && !item.is_invalid)
  const expiredItems = newItems.filter((item) => item.is_expired && !item.is_invalid)

  return [...activeItems, ...expiredItems]
}

export const gamification = {
  namespaced: true,
  state: {
    campaigncategories: [],
    activeCategory: 'all',
    // campaigns: [],
    // campaignDetailList: [],
    categories: {},
    brands: {},
    offerTypeLabels: {},
    // goodieBagCampaignCountRefs: {},
    // campaignLimits: {},
    locations: {},
    gamificatonDetailList: [],
    initFinished: false,
    stampRewards: {},
    missionList: {},
    missionCheckinList: {},
    missionCampaignList: {},
    gamificationFrom: ''
  },
  actions: {
    refresh: async ({ commit, rootState }) => {
      const url = `/gamification_campaigns?page[size]=100&sort=-id`
      const response = await axios.get(url)
      const records = response.data || []

      commit('initGamificationList', records)
    }
  },
  mutations: {
    setGamificationFrom (state, from) {
      state.gamificationFrom = from
    },
    initCampaignCategories (state, campaigncategories) {
      state.campaigncategories = campaigncategories
    },
    setActiveCategory (state, categoryId) {
      state.activeCategory = categoryId
    },
    initCategories (state, categories) {
      state.categories = {
        ...state.categories,
        ...categories
      }
    },
    initBrands (state, brands) {
      state.brands = {
        ...state.brands,
        ...brands
      }
    },
    initOfferTypeLabels (state, offerTypeLabels) {
      state.offerTypeLabels = {
        ...state.offerTypeLabels,
        ...offerTypeLabels
      }
    },
    initLocations (state, locations) {
      state.locations = {
        ...state.locations,
        ...locations
      }
    },
    initGamificationList (state, list) {
      const newList = state.gamificatonDetailList

      list.forEach(element => {
        console.log('@@122: ', element)
        const index = state.gamificatonDetailList.findIndex(item => parseInt(item.id) === parseInt(element.id))
        if (index > -1) {
          const newGamification = {
            ...(state.gamificatonDetailList[index]),
            ...element
          }
          newList.splice(index, 1, newGamification)
        } else {
          newList.push(element)
        }
      })
      state.initFinished = true
    },
    updateGamificationList (state, data) {
      const { id, detail } = data
      const index = state.gamificatonDetailList.findIndex(item => parseInt(item.id) === parseInt(id))
      console.log('@@19: ', detail)

      const stateDetail = index > -1 ? state.gamificatonDetailList[index] : {}
      const newDetail = {
        ...stateDetail,
        ...detail,
        coverPhotoes: [detail.cover_photo_url ? detail.cover_photo_url : stateDetail.cover_photo_url]
      }
      if (index > -1) {
        const newGamification = newDetail
        state.gamificatonDetailList.splice(index, 1, newGamification)
      } else {
        const gamification = newDetail
        state.gamificatonDetailList.push(gamification)
      }
    },
    updateGamificationStampRewards (state, result) {
      const { id, data } = result
      state.stampRewards = {
        ...state.stampRewards,
        [id]: data
      }
    },
    updateGamificationMissionList (state, data) {
      const now = new Date()
      const formatedList = data.list.map((misison) => {
        let iconImage = '/img/checkInIcon.png'
        let redeemButtonName = 'gamificationMissionSectionCheckInBtn'

        if (misison.mission_type === 'SPECIAL_CAMPAIGN') {
          iconImage = '/img/couponRedeemIcon.png'
          redeemButtonName = 'gamificationMissionSectionRedeemCouponBtn'
        } else if (misison.mission_type === 'SPECIAL_GOODIE_BAG') {
          iconImage = '/img/acquireGoodiebagIcon.png'
          redeemButtonName = 'gamificationMissionSectionRedeemBtn'
        }

        const isNotReachStartTime = new Date(misison.start_date) > now
        const isExpired = misison.end_date && new Date(misison.end_date) < now
        if (isNotReachStartTime) {
          iconImage = '/img/info_gm.png'
          redeemButtonName = 'gamificationMissionAvailableOn'
        } else if (isExpired) {
          iconImage = '/img/info_gm.png'
          redeemButtonName = 'gamificationMissionCardExpiredOn'
        } else if ((misison.per_head_limit || misison.per_head_limit === 0) && misison.collected_stamp_rewards_count === misison.per_head_limit) {
          iconImage = '/img/info_gm.png'
          redeemButtonName = 'gamificationMissionSectionReachLimitBtn'
        }

        return {
          ...misison,
          iconImage,
          redeemButtonName,
          isNotReachStartTime,
          isExpired
        }
      })

      state.missionList = {
        ...state.missionList,
        [data.gamificationCampaignId]: formatedList
      }
    },
    updateMissionList (state, data) {
      const now = new Date()
      const { gamificationCampaignId, detail } = data
      let key = '-1'
      if (gamificationCampaignId) {
        key = gamificationCampaignId
      }
      const index = state.missionList?.[key]?.findIndex(item => parseInt(item.id) === parseInt(detail.id))

      const newList = state.missionList?.[key] || []
      let iconImage = ''
      let redeemButtonName = ''
      if (detail.mission_type === 'SPECIAL_CAMPAIGN') {
        iconImage = '/img/couponRedeemIcon.png'
        redeemButtonName = 'gamificationMissionSectionRedeemCouponBtn'
      } else if (detail.mission_type === 'SPECIAL_GOODIE_BAG') {
        iconImage = '/img/acquireGoodiebagIcon.png'
        redeemButtonName = 'gamificationMissionSectionRedeemBtn'
      } else if (detail.misison_type === 'CHECK_IN') {
        iconImage = '/img/checkInIcon.png'
        redeemButtonName = 'gamificationMissionSectionCheckInBtn'
      }
      const isNotReachStartTime = new Date(detail.start_date) > now
      const isExpired = detail.end_date && new Date(detail.end_date) < now
      if (isNotReachStartTime) {
        iconImage = '/img/info_gm.png'
        redeemButtonName = 'gamificationMissionAvailableOn'
      } else if (isExpired) {
        iconImage = '/img/info_gm.png'
        redeemButtonName = 'gamificationMissionCardExpiredOn'
      } else if ((detail.per_head_limit || detail.per_head_limit === 0) && detail.collected_stamp_rewards_count === detail.per_head_limit) {
        iconImage = '/img/info_gm.png'
        redeemButtonName = 'gamificationMissionSectionReachLimitBtn'
      }

      const newDetail = {
        ...detail,
        iconImage,
        redeemButtonName,
        isNotReachStartTime,
        isExpired
      }
      if (index < 0) {
        newList.push(newDetail)
      } else {
        newList.splice(index, 1, newDetail)
      }

      state.missionList = {
        ...state.missionList,
        [key]: newList
      }
    },
    updateMissionCheckinList (state, data) {
      const { missionId, checkInData } = data
      const index = state.missionCheckinList?.[missionId]?.findIndex(item => parseInt(item.id) === parseInt(checkInData.id))
      if (index > -1) {
        const newCheckInData = {
          ...(state.missionCheckinList?.[missionId]?.[index] || {}),
          ...checkInData
        }
        state.missionCheckinList?.[missionId]?.splice(index, 1, newCheckInData)
      } else {
        state.missionCheckinList?.[missionId]?.push(checkInData)
      }
    },
    clearMisisonCheckinList (state, missionId) {
      state.missionCheckinList = {
        ...state.missionCheckinList,
        [missionId]: []
      }
    },
    initMissionCampaignList (state, data) {
      const { missionId, list } = data
      state.missionCampaignList = {
        ...state.missionCampaignList,
        [missionId]: list
      }
    },
    updateMissionCampaignList (state, data) {
      const { missionId, campaign } = data
      const index = state.missionCampaignList?.[missionId]?.findIndex(item => parseInt(item.id) === parseInt(campaign.id))
      if (index > -1) {
        const newCampaign = {
          ...(state.missionCampaignList?.[missionId]?.[index] || {}),
          ...campaign
        }
        state.missionCampaignList?.[missionId]?.splice(index, 1, newCampaign)
      } else {
        state.missionCampaignList?.[missionId]?.push(campaign)
      }
    },
    clearMisisonCampaignList (state, missionId) {
      state.missionCampaignList = {
        ...state.missionCampaignList,
        [missionId]: []
      }
    }
  },
  getters: {
    getGamificationFrom: (state) => {
      return state.gamificationFrom
    },
    campaignCategoryList: (state) => {
      return state.campaigncategories.filter(item => item.is_active).sort((a, b) => a.display_priority - b.display_priority)
    },
    activeCategory: (state) => {
      return state.activeCategory
    },
    categories: (state) => (missionId) => {
      return state.categories[missionId] || []
    },
    brands: (state) => (missionId) => {
      return state.brands[missionId] || []
    },
    offerTypeLabels: (state) => (missionId) => {
      return state.offerTypeLabels[missionId] || []
    },
    locations: (state) => (missionId) => {
      return state.locations[missionId] || []
    },
    initFinished: (state) => { return state.initFinished },
    gamificationDetailList: (state) => (isTestUser, countryCode) => {
      // const list = state.gamificatonDetailList

      // //   return state.gamificationDetailList
      // return list.filter(item => item?.is_public || item?.gamification_campaign_participation_record_id).sort((a, b) => {
      //   const dateTimeA = new Date(a.display_start_date)
      //   const dateTimeB = new Date(b.display_start_date)
      //   if (dateTimeB !== dateTimeA) {
      //     return dateTimeB - dateTimeA
      //   }

      //   return b.name - a.name
      // })
      return getGamificationCampaignRecordList(state.gamificatonDetailList, isTestUser, countryCode)
    },
    gamificationDetail: (state) => (gamificationId) => {
      return state.gamificatonDetailList.filter((item) => parseInt(item.id) === parseInt(gamificationId))?.[0]
    },
    getGamificationByMission: (state) => (missionId) => {
      let gamificationId = null

      Object.keys(state.missionList || {}).forEach((key) => {
        if (key !== '-1') {
          const array = state.missionList[key]
          if (array.any((item) => parseInt(item.id) === parseInt(missionId))) {
            console.log('@@152: ', key)
            gamificationId = key
          }
        }
      })

      if (gamificationId) {
        return state.gamificatonDetailList.filter((item) => parseInt(item.id) === parseInt(gamificationId))?.[0]
      }

      return null
    },
    stampRewards: (state) => (gamificationId) => {
      console.log('@@251--------0: ', state.stampRewards[gamificationId])
      return state.stampRewards[gamificationId]
    },
    missionList: (state) => (gamificationId) => {
      console.log('@@252--------0: ', state.missionList[gamificationId])
      return state.missionList[gamificationId]
    },
    missionDetail: (state) => (gamificationId, missionId) => {
      console.log('@@252-0: ', gamificationId, missionId, state.missionList, Object.values(state.missionList))
      if (gamificationId) {
        return state.missionList?.[gamificationId]?.filter((item) => parseInt(item.id) === parseInt(missionId))?.[0]
      }
      return Object.values(state.missionList || {}).flat(Infinity).filter((item) => parseInt(item.id) === parseInt(missionId))?.[0]
    },
    missionCheckinList: (state) => (missionId) => {
      return state.missionCheckinList[missionId]
    },
    getMissionCampaignList: (state) => (missionId) => {
      return state.missionCampaignList[missionId]
    },
    getMissionCampaignDetail: (state) => (missionId, campaignId) => {
      const campaignList = state.missionCampaignList[missionId]
      return campaignList.filter((item) => parseInt(item.id) === parseInt(campaignId))?.[0]
    }
  }
}

export default gamification
