import axios from 'axios'

export const goodiebags = {
  namespaced: true,
  state: {
    goodieBags: [],
    slotsCampaigns: {}
  },
  actions: {
    updateGoodieBag: async (payload, { goodieBagId, queryString }) => {
      const response = await axios.get(`/goodie_bags/${goodieBagId}?${queryString}`)
      const goodieBag = response.data || {}
      payload.commit('updateGoodieBag', goodieBag)
    }
  },
  mutations: {
    updateGoodieBag (state, goodieBag) {
      state.goodieBags = [
        ...state.goodieBags.filter(item => item.id !== goodieBag.id),
        goodieBag
      ]
    },
    updateSlotsCampaigns (state, data) {
      state.slotsCampaigns = {
        ...state.slotsCampaigns,
        [data.goodieBagId]: data.slotsCampaigns
      }
    }
  },
  getters: {
    goodieBagDetail: (state) => (id) => {
      return state.goodieBags.find(goodieBag => parseInt(goodieBag.id) === parseInt(id))
    },
    slotsCampaigns: (state) => (id) => {
      return state.slotsCampaigns[id] || {}
    }
  }
}

export default goodiebags
