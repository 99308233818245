<template>
  <div v-if="!isLoading">
    <not-find :title="title" :description="description" :from="from" :imgIcon="imgIcon" />
  </div>
</template>
<script>
import NotFind from '@/components/NotFind'
import { sendManualAnalytics } from '@/utils/tracking'
import axios from 'axios'
import { getLangName } from '@/utils/lang'
import {
  // sendPageViewAnalytics,
  // sendManualAnalytics,
  sendAdobeManualAnalytics
} from '@/utils/tracking'
export default {
  name: 'PageNotFind',
  components: {
    NotFind
  },
  data () {
    return {
      title: null,
      description: null,
      from: null,
      isLoading: false,
      imgIcon: null
    }
  },
  async mounted () {
    console.log('@@26: ', this.$router.history.current)
    if (this.$router.history.current.path.indexOf('verify-email-result') >= 0) {
      this.isLoading = true
      try {
        const response = await axios.get(`/customers/email_address_verification?customer_code=${this.$router.history.current.query?.customer_code}&token=${this.$router.history.current.query?.token}&lang=${getLangName(this.$i18n.locale)}`)

        this.title = response.data.title
        this.description = response.data.content || ' '

        if (response.data.verify_success) {
          this.imgIcon = '/img/email_address_verification_sucess.png'
          sendManualAnalytics(
            this.$route,
            this.$i18n.locale,
            this.$store,
            'account_info_email_verified_success',
            'event',
            {
              language: this.$i18n.locale,
              verifiedstatus: 'success'
            }
          )
        }

        // sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'Verified success - click the verify email link', { campaignId: this.campaignId })
      } catch (error) {
        this.title = error.response.data.detail
        this.description = error.response.data.display_error_content
      }

      this.isLoading = false
    } else {
      console.log('@@14: ', this.$router.history.current.query)
      const { title, description, from } = this.$route.query
      this.title = title
      this.description = description
      this.from = from
    }

    if (this.from === 'GAMIFICATION') {
      const { gamificationCampaignId } = this.$route.query
      sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'page not found', { gamificationId: gamificationCampaignId, errorMessage: this.$t(this.title) })
    }
  }
}
</script>
