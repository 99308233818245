<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="isShowRecaptchaFail"
      class="recaptcha-fail"
      :width="$store.getters['app/isMobile']?'316px':'600px'"
      center
      custom-class="coupon-alert-bar-dialog"
      :lock-scroll="true"
      :show-close="$store.getters['app/isMobile']?false:true"
      @close="closeRecaptchaError()"
    >
      <div slot="title" class="goodie-bag-sold-out-title">{{ recaptchaFailTitle }}</div>
      <div class="goodie-bag-sold-out-content" v-html="recaptchaFailContent" />
      <div class="goodie-bag-sold-out-bottom">
        <div class="button primary-button" @click="closeRecaptchaError()">{{ $t('goodiebagOk') }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'RecaptchaVerifyFailDialog',
  props: {
    isShowRecaptchaFail: {
      type: Boolean,
      default: false
    },
    recaptchaFailTitle: {
      type: String,
      default: null
    },
    recaptchaFailContent: {
      type: String,
      default: null
    }
  },
  methods: {
    closeRecaptchaError () {
      this.$emit('setIsShowRecaptchaFail', false)
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/assets/carousel.scss';
@import "@/assets/coupon-item.scss";
.goodie-bag-sold-out-title{
  font-weight: 700;
  font-size: 18px;
  padding-top: 43px;
  padding-left: 33px;
  padding-right: 33px;
  color: #000000;
}
.goodie-bag-sold-out-content{
  font-size: 14px;
  color: #000000;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 16px;
  text-align: center;
  word-break: break-word;
  font-weight: 400;
}
.goodie-bag-sold-out-bottom{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  margin-bottom: 28px;
  .button{
    border-radius: 50px;
    border:none;
    font-weight: 700;
    font-size: 18px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.is-vue-desktop {
  .goodie-bag-sold-out-title{
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: left;
    color: #4A4A4A;
    font-weight: 700;
    font-size: 30px;
  }
  .goodie-bag-sold-out-content{
    color: #404040;
    text-align: left;
    margin-left: 30px;
    margin-right: 30px;
    font-weight: 400;
    font-size: 15px;
  }
  .goodie-bag-sold-out-bottom{
    justify-content: right;
    padding-right: 37px;
    .button{
      font-weight: 700;
      font-size: 20px;
      padding-left: 36px;
      padding-right: 36px;
    }
  }
}

.is-vue-mobile {

}
</style>

<style lang="scss">
.el-dialog__wrapper.recaptcha-fail {
  .goodie-bag-sold-out-title {
    text-align: center;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
}

.el-dialog__wrapper.recaptcha-fail .el-dialog__close.el-icon.el-icon-close{
  font-size: 30px;
}

.document-campaign-detail.has-one-trust .coupon-alert-bar-dialog,
.document-prize-campaign-detail.has-one-trust .coupon-alert-bar-dialog,
.my-account.has-one-trust .coupon-alert-bar-dialog{
  // height:60%;
  // overflow-y: scroll;
  margin-top: calc(20vh - 100px);
}

.coupon-alert-bar-dialog.el-dialog--center{
  border-radius: 10px;
  .el-dialog__body {
    padding: 0px;
  }
}

.wide-code .coupon-alert-bar-dialog{
  width: calc(100% - 6px) !important;
  .scan-code-success-bar-footer{
    width: 100%;
    text-align: center;
  }
}

.el-dialog.coupon-alert-bar-dialog .el-dialog__header{
  padding: 0px;
}

.el-dialog.coupon-alert-bar-dialog{
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
}

.el-dialog.desktop-coupon-alert-bar-dialog{
  margin-top: auto !important;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.40);
  .el-dialog__body{
    padding-top: 0px;
  }
  .view-barcode-text{
    color: #4A4A4A;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .barcode-full-screen-content, .full-barcode-content{
    text-align: center;
  }
  .el-icon-close:before{
    transform: scale(2);
    display: inline-block;
  }
}

</style>
