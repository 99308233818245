<template>
    <div id="scan-qr-code">
      <div class="qr-code-full-window">
        <div class="qr-contain full-width">
          <div class="full-width close-contain">
            <img class="close" src="/img/scan_close.png" width="40px" height="40px" @click="close()">
          </div>
          <div class="title-contain">
            {{ $t('myWalletScanQRcodeScanning') }}
          </div>
          <div class="title-contain">
            {{ $t(isCheckIn ? 'LocationScanQRcode' : 'myWalletScanQRcode') }}
          </div>
          <div class="description-contain">
            {{ $t('myWalletScanQRcodeAlign') }}
          </div>
        </div>
        <div id="qr-code-full-region" />
      </div>
      <el-dialog
        title=""
        :visible.sync="isQrcodeErrorDialog"
        width="316px"
        top="33vh"
        center
        @close="closeInvalidDialog"
      >
        <div class="scan-code-success-dialog-content">
          <div class="qrcode-error-message">{{ $t(isCheckIn ? 'missionTaskCheckInInvalidQRcodeLineOne' : 'myWalletInvalidQRcodeLineOne') }}</div>
          <div class="qrcode-error-message">{{ $t(isCheckIn ? 'missionTaskCheckInInvalidQRcodeLineTwo' : 'myWalletInvalidQRcodeLineTwo') }}</div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" class="scan-code-success-done" @click="closeInvalidDialog">{{ $t(isCheckIn ? 'goodiebagOk' : 'myAccountDeleteAccountconfirm2PromptOK') }}</el-button>
        </span>
      </el-dialog>
      <div
        v-loading.fullscreen.lock="isLoading"
        element-loading-text=""
        element-loading-spinner="get-coupe-icon-loading"
        element-loading-background="#F7F7F7"
      />
    </div>
</template>
<script>
import utilsMixin from '@/mixins/utilsMixin'
import { isWechatMiniProgram } from '@/utils/utils'

export default {
  name: 'ScanQrcode',
  components: {
  },
  mixins: [utilsMixin],
  props: {
    isCheckIn: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    from: {
      type: String,
      default: null
    },
    imgIcon: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      html5Qrcode: '',
      isQrcodeErrorDialog: false,
      isLoading: false,
      lockScan: false,

      latitude: null,
      longitude: null,

      isUsingCoupon: false,
      isOpeningCamera: false
    }
  },
  mounted () {
    // this.initQrcode()
  },
  methods: {
    isUseWechatCamera () {
      return isWechatMiniProgram() && process.env.VUE_APP_WECHAT_OFFICIAL_ACCOUNT_APP_ID
    },
    async onScanSuccess (qrCodeMessage) {
      console.log('@2680x qr code message', qrCodeMessage, this.couponData, this.lockScan)

      if (this.lockScan) {
        this.isUsingCoupon = false
        return
      }

      console.log('@2680a')

      //   const response = null
      this.lockScan = true

      this.$emit('scanSuccess', qrCodeMessage)

      //   this.lockScan = false

      //   if (this.html5Qrcode) { this.html5Qrcode.stop() }

      //   this.enableOverflow()
      //   //   sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon_use_coupon_success', 'event', { coupon_qty: 1 })
      //   //   sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon_detail_use_coupon_success', 'event', { method: this.couponData.template.redemption_method, coupon_qty: 1 })

      //   this.adjustSuccessPopupForOneTrust()
    },
    onScanError (errorMessage) {
      console.log('5470----- error Message', errorMessage)
      // this.isQrcodeErrorDialog = true
      // this.error = errorMessage
    },
    sendCameraErrorMessage (error, errorWechat) {
      let campaignId = this.campaignId
      if (!campaignId && this.couponData && this.couponData.campaign) {
        campaignId = this.couponData.campaign.id
      }
      console.log('@1324b', campaignId)
      let cameraErrorMassage = JSON.stringify(error)
      if (errorWechat) {
        cameraErrorMassage += '; ' + JSON.stringify(errorWechat)
      }
      console.log('cameraErrorMassage: ', cameraErrorMassage)
      // TODO
    //   sendManualAnalytics(this.$route, this.$i18n.locale, this.$store,
    //     'coupon_camera_fail', 'event',
    //     { id: campaignId, camera_errormsg: cameraErrorMassage })
    //   if (cameraErrorMassage && cameraErrorMassage.includes('NotAllowedError')) {
    //     this.logCustomerActivityLog('COUPON_USE_FAIL_WITH_CAMERA_PERMISSION_DECLINED')
    //   }
    },
    async _initQrcode () {
      if (this.html5Qrcode) {
        this.html5Qrcode.stop()
      }
      const config = {
        fps: 10,
        qrbox: 250,
        aspectRatio: 1.777778
      }
      console.log('487------ uri init qrcode')
      this.html5Qrcode = new window.Html5Qrcode('qr-code-full-region')
      console.log(this.html5Qrcode)
      this.lockScan = false

      // if (true || this.getMobileOperatingSystem() === 'Android') {
      /*
      try {
        // https://stackoverflow.com/questions/11642926/stop-close-webcam-stream-which-is-opened-by-navigator-mediadevices-getusermedia
        window.navigator.mediaDevices.getUserMedia({ video: true })
          .then(stream => {
            stream.getTracks().forEach((track) => {
              track.stop()
            })
          })
          .catch((err) => {
            console.log('@2311', err)
          })
      } catch (error) {
        console.log('@2315,, failed to close media', error)
      }
      */
      // }

      if (this.isUseWechatCamera()) {
        this.isOpeningCamera = true
        await this.wechatOpenCamera((result) => {
          console.log('@2384', result)
          // window.alert('@2383: ' + JSON.stringify(result).substring(0, 255))
          this.onScanSuccess(result.resultStr)
        }, (errWechat) => {
          this.isUsingCoupon = false
          console.log('failed to open wechat camera', errWechat)
          this.sendCameraErrorMessage(errWechat)
        })
        this.close()
        this.isOpeningCamera = false
        this.isUsingCoupon = false
      } else {
        this.disableOverflow()

        // this.showCodeType = 'qr-code'

        this.html5Qrcode.start(
          { facingMode: 'environment' }, config, this.onScanSuccess, this.onScanError
        ).catch((err) => {
          console.log('@1324 failed to open webview camera', err)
          console.log('@1324b trying to start wechat camera now')
          alert('failed to open webview camera')
          this.isUsingCoupon = false
          this.sendCameraErrorMessage(err)

          this.close()
          this.isOpeningCamera = false
        })
        this.isUsingCoupon = false
        this.isOpeningCamera = false
      }
    },
    initQrcode (latitude, longitude) {
      this.latitude = latitude
      this.longitude = longitude
      clearTimeout(window.initQrcodeTimer)
      window.initQrcodeTimer = setTimeout(() => {
        this._initQrcode()
      }, 500)
    },
    close () {
      this.lockScan = false

      setTimeout(() => {
        if (this.html5Qrcode) {
          this.html5Qrcode.stop()
        }
      }, 0)
      this.$emit('closeScanQrcode')
      this.$store.commit('app/setNeedShowBackToTop', true)
      this.enableOverflow()
    },
    closeInvalidDialog () {
      this.isQrcodeErrorDialog = false
      this.lockScan = false

      if (isWechatMiniProgram()) {
        // for mini program, close error dialog also close the error message
        // this.showCodeType = 'coupon-detail'
        this.$emit('closeScanQrcode')
      }
    },
    showQrcodeError () {
      this.isQrcodeErrorDialog = true
    }
  }
}
</script>
<style lang='scss'>
.scan-code-success-dialog-content{
  display: flex;
  align-items: center;
  flex-flow: column;
  .rotation-reminder{
    padding-left: 16px;
    padding-right: 16px;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row;
    color: #999;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 8px;
    align-items: center;
    img {
      height: 30px;
      margin-right: 5px;
    }
  }
}

.get-coupe-icon-loading{
  background-image: url('/img/loading.gif');
  background-size: contain;
  width: 120px;
  height: 120px;
  left: calc(50% - 60px);
  top: calc(50% - 60px);
  position: absolute;
}

.el-dialog__body .scan-code-success-dialog-content{
  .reference-id-contain{
    color: #999999;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 8px;
    margin-left: 0px;
  }

  .scan-code-success-description{
    font-weight: 600;
    font-size: 16px;
    color: #999999;
    margin-bottom: 11px;
  }

  .scan-code-success-name{
    text-align: center;
    font-weight: bold;
    font-size: 21px;
    color: #000000;
    word-wrap: break-word;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 8px;
  }

  .scan-code-success-message{
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    // word-break: keep-all;
    word-wrap: break-word;
    padding-left: 24px;
    padding-right: 24px;
  }

  .scan-code-success-message.scan-code-store{
    margin-bottom: 8px;
  }

  .dialog-footer .scan-code-success-done{
    background: #E2002C;
    border-radius: 41px;
    width: 150px;
  }

  .coupon-content .coupon-type{
    font-size: 16px;
    padding: 4px 8px;
    // margin: 0;
  }

  .scan-code-success-buttons {
    width: 100%;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 16px;
    padding-bottom: 6px;
    position: fixed;
    bottom: 0;
    z-index: 50;
    background-color: #fff;
    box-shadow: 0px 1px 0px 0px #D1D1D1 inset;

    .scan-code-success-done-button, .scan-code-success-take-survey {
      border-radius: 50px;
      background: #E2002C;
      text-align: center;
      cursor: pointer;
      display: flex;
      width: 100%;
      height: 48px;
      padding: 8px 40px;
      margin-bottom: 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      color: #FFF;
      text-align: center;

      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 116%; /* 20.88px */
    }
  }
  .scan-code-success-buttons.has-survey .scan-code-success-done-button {
    color: #E2002C;
    background: #fff;
  }

  .scan-code-success-take-survey {
    width: 190px;
    height: 48px;
    margin-bottom: 16px;
    background: #E2002C;
    border-radius: 41px;
    color: #fff;
    text-align: center;
    line-height: 48px;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
  }

  .scan-code-success-bar-code {
    // padding: 27px 46px;
    width: 100%;
    padding-bottom: 8px;
    margin-top: 20px;
    padding-top: 20px;
    text-align: center;

    .partner-qr-bar-code-code {
      color: #000000;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      word-break: break-all;
      padding: 0 20px 10px;
    }
  }

  .scan-code-success-bar-footer {
    word-break: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000;
    background: #EEE;
    padding: 12px 16px;
    text-align: left;
  }

}

.scan-code-success-dialog .el-dialog__body .scan-code-success-dialog-content {
  .scan-code-success-bar-code {
    .partner-qr-bar-code-code {
      padding: 0 40px 10px;
    }
  }
}

.el-dialog.full-dialog .el-dialog__body .scan-code-success-dialog-content{
 .scan-code-success-bar-footer {
    margin-bottom: 150px;
  }

  .scan-code-success-bar-code {
      border-top: 1px dashed #979797;
  }
}
</style>
<style scoped lang='scss'>
#scan-qr-code{
  z-index: 999;

  .qr-code-full-window {
    position: fixed !important;
    margin: 0;
    left: 0;
    top: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #7B7B7B;
    display: flex;
    align-items: center;
    flex-flow: column;
  }
  #qr-code-full-region {
    width: 100%;
    position: fixed !important;
    bottom: -15px;
  }
  .full-width{
    width: 100%;
  }
  .close-contain{
    justify-content: flex-end;
    display: flex;
    margin-bottom: -18px;
  }
  .close-contain img{
    height: 40px;
  }
  .close{
    float: right;
    margin-right: 21px;
    margin-top: 19px;
  }
  .title-contain{
    font-weight: bold;
    font-size: 22px;
    color: #FFFFFF;
    padding-left: 28px;
  }
  .description-contain{
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    padding-left: 28px;
    padding-right: 28px;
    margin-top: 12px;
  }
  .qr-contain{
    z-index: 40;
  }
}
.qrcode-error-message{
  font-size: 14px;
  color: #2D2D2D;
  // word-break: keep-all;
  word-wrap: break-word;
}

</style>
