var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['custom-tooltip-container', {'revert': _vm.revert}],style:({
    'visibility': _vm.show ? 'visible' : 'hidden',
    'left': ((_vm.pos.left || 0) + "px"),
    'marginTop': ((_vm.pos.marginTop || 0) + "px"),
    'flex-direction': _vm.revert ? 'column-reverse' : 'column',
    'width': _vm.revert ? "calc(100% + 100px)" : _vm.isMobile ? 'auto' : '100%'
  })},[(_vm.showHover)?_c('img',{staticClass:"tooltip-icon",style:({
      //'display': visible ? 'block' : 'none',
      // 'display': visible ? 'block' : 'block',
      //'visibility': forceHide ? 'hidden' : 'unset',
      //'left': iconPos.left,
      //'top': iconPos.top,
      'margin-left': _vm.iconPos.marginLeft ? ((_vm.iconPos.marginLeft) + "px") : '0px'
    }),attrs:{"src":"/img/tooltip_arrow.png"}}):_vm._e(),(_vm.content)?_c('div',{class:['tooltip-content-container', {'close': _vm.showClose}, _vm.langClass],style:({
      //'display': visible ? showClose ? 'flex' : 'block' : 'none',
      // 'display': visible ? 'block' : 'block',
      //'visibility': forceHide ? 'hidden' : 'unset',
      //'left': contentPos.left,
      //'right': contentPos.right,
      //'top': contentPos.top,
    })},[_vm._v(" "+_vm._s(_vm.content)+" "),(_vm.showClose)?_c('img',{staticClass:"close",attrs:{"srcset":"/img/close_white.png" + ", " + "/img/close_white.2x.png" + " 2x, " + "/img/close_white.3x.png" + " 3x","src":"/img/close_white.2x.png"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('closeTips')}}}):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }