<script>
import { doLogout } from '@/helpers/http'
import {
  isWechatMiniProgram,
  replaceRouterWithQuery,
  pushRouterWithQuery
} from '@/utils/utils'

// import { isWechatMiniProgram, LOCATION_AREA_ID } from '@/utils/utils'
import axios from 'axios'
import Cookies from 'js-cookie'
import { sendManualAnalytics } from '@/utils/tracking'
// import { removeClass } from 'element-ui/src/utils/dom'

export default {
  data: function () {
    return {
      wechatToken: null
    }
  },
  computed: {
    userName () {
      const {
        nickname,
        first_name: firstName,
        last_name: lastName,
        mobile_phone_number_subscriber_number: phone,
        email_address: email
      } = this.$store.getters['auth/userProfile']

      console.log('@448', nickname, firstName, lastName)
      if (nickname) {
        return nickname
      }

      if (firstName || lastName) {
        return `${firstName} ${lastName}`
      }

      if (phone || email) {
        return this.$t('headerDearMember')
      }
      return ''
    },
    oneTrustHeight () {
      return this.$store.getters['app/getOneTrustHeight']
    },
    isOneTrustDisplay () {
      return this.$store.getters['app/getIsOneTrustDisplay']
    },
    langClass () {
      if (this.$i18n.locale === 'zh-s') {
        return 'zhs'
      } else if (this.$i18n.locale === 'zh-t') {
        return 'zht'
      }
      return 'en'
    },
    isMobile () {
      return this.$store.getters['app/isMobile']
    },
    isVerifyRecaptcha () {
      const { recaptchaOpen } = this.$store.getters['app/getSystemSettings']
      return recaptchaOpen
    },
    isPreviewGamificationPrize () {
      return this.$route.name.indexOf('prize-') >= 0
    },
    isGamificationPrize () {
      return this.$route.name.indexOf('prize-') >= 0 || this.gamificationId || this.gamificationCampaignId || this.missionId
    }
  },
  methods: {
    pushRouterWithQuery (router, pathInfo) {
      // this are used for '@click=pushRouter'
      pushRouterWithQuery(router, pathInfo) // called imported
    },
    replaceRouterWithQuery (router, pathInfo) {
      replaceRouterWithQuery(router, pathInfo)
    },
    getWechatTokenURL () {
      let pageUrl = this.$store.getters['app/getWechatTokenURL']
      const ua = window.navigator.userAgent.toLowerCase()
      if (/iphone|ipad|ipod|ios/.test(ua) && this.$store.getters['app/getFirstLoadWechatTokenURL']) {
        pageUrl = this.$store.getters['app/getFirstLoadWechatTokenURL']
      }
      console.log('@getWechatTokenURL', ua, pageUrl)
      return pageUrl
    },
    async wechatConfigAPI () {
      const token = this.wechatToken
      const vconsoleExists = window.$('#__vconsole').length > 0
      console.log('@41', window.$('#__vconsole').length, vconsoleExists)
      window.wx.config({
        debug: vconsoleExists, // 调试时可开启
        appId: process.env.VUE_APP_WECHAT_OFFICIAL_ACCOUNT_APP_ID, // <!-- replace -->
        timestamp: token.timestamp, // 必填，填任意数字即可
        nonceStr: token.noncestr, // 必填，填任意非空字符串即可
        // https://mp.weixin.qq.com/debug/cgi-bin/sandbox?t=jsapisign
        signature: token.signature, // 必填，填任意非空字符串即可
        jsApiList: ['chooseImage', 'scanQRCode', 'getLocation'] // 必填，随意一个接口即可
      })
    },
    async wechatCollectToken () {
      console.log('@51, start to collect wechat token')
      // const url = '/customers/wechat-config?url=' + encodeURIComponent(window.location.href)
      // const pageUrl = this.$store.getters['app/getWechatTokenURL']
      const pageUrl = this.getWechatTokenURL()

      const url = '/customers/wechat-config?url=' + encodeURIComponent(pageUrl)
      const response = await axios.get(url)

      // window.alert('@55: ' + JSON.stringify(response.data))

      if (response && response.data) {
        const result = response.data
        console.log('@765', result)
        this.wechatToken = result
        this.wechatToken.clientTimestamp = Date.now()
      }
    },
    async wechatUpdateTokenIfNeeded () {
      if (!this.wechatToken) {
        await this.wechatCollectToken()
      }

      if (
        this.wechatToken &&
        this.wechatToken.clientTimestamp &&
        Date.now() - this.wechatToken.clientTimestamp > 5000
      ) {
        await this.wechatCollectToken() // update token and retry once
        //        this.wechatConfigAPI()
      }

      await this.wechatConfigAPI()
    },
    checkWxIsReady () {
      return new Promise((resolve, reject) => {
        window.wx.ready(() => resolve())
        window.wx.error((err) => reject(err))
      })
    },
    async wechatOpenCamera (success, fail) {
      if (!isWechatMiniProgram()) {
        console.log('not inside mini program, quit now')
        return
      }

      await this.wechatUpdateTokenIfNeeded()

      console.log('@767b')
      try {
        await this.checkWxIsReady()
        console.log(
          '@767bb, @115, wx ready',
          window.wx.scanCode,
          window.wx.scanQRCode
        )
        window.wx.scanQRCode({
          onlyFromCamera: true,
          needResult: 1,
          success,
          fail
        })
      } catch (error) {
        console.log('@80, wx error', error)
        if (error && error.realAuthUrl) {
          // const pageUrl = this.$store.getters['app/getWechatTokenURL']
          const pageUrl = this.getWechatTokenURL()

          let realAuthUrl = error.realAuthUrl
          try {
            if (typeof error.realAuthUrl === 'string' || error.realAuthUrl instanceof String) {
              if (error.realAuthUrl.startsWith('[') && error.realAuthUrl.endsWith(']')) {
                realAuthUrl = error.realAuthUrl.substring(1, error.realAuthUrl.length - 1).split(',')[0]
              }
            }
            if (Array.isArray(error.realAuthUrl) && error.realAuthUrl.length > 1) {
              realAuthUrl = error.realAuthUrl[0]
            }
          } catch (error) {
            console.log('@80a, parse realAuthUrl fail', error)
          }
          if (realAuthUrl !== pageUrl) {
            console.log('@80b, will update wechat token url', error.realAuthUrl, realAuthUrl)

            try {
              this.$store.commit('app/setWechatTokenURL', realAuthUrl)
              // worst case handling, should work for the next time
            } catch (error) {
              console.log('@80c, setWechatTokenURL fail', error)
            }
          }
        }
        fail(error)
      }

      console.log('@767c')
    },
    async wechatGetLocation (success, fail) {
      if (!isWechatMiniProgram()) {
        console.log('not inside mini program, quit now')
        return
      }

      await this.wechatUpdateTokenIfNeeded()

      console.log('@767b')
      try {
        console.log('@767b-')
        await this.checkWxIsReady()
        console.log('@767b----')
        console.log('@767bb, @115, wx ready', window.wx.getLocation)
        window.wx.getLocation({
          type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
          success: function (res) {
            var latitude = res.latitude // 纬度，浮点数，范围为90 ~ -90
            var longitude = res.longitude // 经度，浮点数，范围为180 ~ -180。
            // var speed = res.speed; // 速度，以米/每秒计
            // var accuracy = res.accuracy; // 位置精度
            success(latitude, longitude)
          },
          fail: function (err) {
            // 获取定位信息失败（譬如：网页位置信息已授权，但操作系统定位已关闭）
            fail(err)
          },
          cancel: function (err) {
            // 网页位置信息授权点击“拒绝”按钮
            fail(err)
          }
        })
      } catch (error) {
        console.log('@80, wx error', error)
        if (error && error.realAuthUrl) {
          const pageUrl = this.$store.getters['app/getWechatTokenURL']

          if (error.realAuthUrl !== pageUrl) {
            console.log(
              '@80b, will update wechat token url',
              error.realAuthUrl
            )

            this.$store.commit('app/setWechatTokenURL', error.realAuthUrl)
            // worst case handling, should work for the next time
          }
        }
        fail(error)
      }

      console.log('@767c')
    },
    canGoBack () {
      const backTitle = this.$store.getters['app/getMiniProgramBackTitle']
      if (backTitle !== null) {
        // null means no back, note it can be empty string
        return true
      }
      console.log('@1937a', this.$root.initialHistoryCount)
      return window.history.length > this.$root.initialHistoryCount
    },
    isWechatMiniProgramPage () {
      // to be used inside template
      return isWechatMiniProgram()
    },
    showCopySuccessWithMessage (message) {
      const imageUrl = require('@/assets/images/notification-success-icon.png')
      let offset = 77
      if (window.$('.is-vue-mobile').length) {
        offset = 73
      }
      if (window.$('.nav').height() === 0) {
        offset = 10
      }
      this.$message({
        dangerouslyUseHTMLString: true,
        message:
          `<strong><img class='icon' src='${imageUrl}'>` +
          message +
          `</strong>`,
        customClass: 'account-success',
        type: '',
        duration: 5000,
        offset: offset
      })
    },
    showCopySuccess () {
      const message = this.$t('campaignTooltips')
      this.showCopySuccessWithMessage(message)
    },
    showLinkCopySuccess () {
      const message = this.$t('linkCopied')
      this.showCopySuccessWithMessage(message)
    },
    showShortLinkCopySuccess () {
      const message = this.$t('linkCopiedShort')
      this.showCopySuccessWithMessage(message)
    },
    showCodeCopySuccess () {
      const message = this.$t('codeCopiedShort')
      this.showCopySuccessWithMessage(message)
    },
    changeLanguageIfNeeded (value) {
      this.isShowMobileLanguage = false
      const prevLang = this.$i18n.locale
      window.$('html').removeClass('no-scroll')
      let lang = null
      if (value) {
        if (value === prevLang) {
          return
        }
        lang = value
        this.lang = value
      } else {
        lang = prevLang
      }
      const { name } = this.$route

      const to = this.$i18nRoute(this.$route, name, lang)

      this.$i18n.locale = lang

      replaceRouterWithQuery(this.$router, to)
      window.location.reload()
    },
    showGetSuccessfullMessage (needShowButton, couponType) {
      console.log('@30', couponType)
      let buttonText = ''
      const couponText =
        couponType === 'VOUCHER_SECTION'
          ? this.$t('myWalletAcquiredVouchar')
          : couponType === 'COUPON_SECTION'
            ? this.$t('myWalletAcquiredCoupons')
            : this.$t('myWalletAcquiredTickets')
      if (needShowButton) {
        if (couponType === 'VOUCHER_SECTION') {
          buttonText = this.$t('myWalletAcquiredCouponViewVouchar')
        } else if (couponType === 'COUPON_SECTION') {
          buttonText = this.$t('myWalletAcquiredCouponViewCoupons')
        } else if (couponType === 'TICKET_SECTION') {
          buttonText = this.$t('myWalletAcquiredCouponViewTickets')
        }
      }

      if (!window.$('.get-coupon-success').length) {
        const h = this.$createElement
        let offset = 77
        if (window.$('.is-vue-mobile').length) {
          offset = 73
        }
        if (window.$('.nav').height() === 0) {
          offset = 10
        }
        this.$message({
          message: h('strong', null, [
            h('img', { class: 'icon' }),
            h('div', null, [
              h('a', { class: 'text' }, couponText),
              h(
                'a',
                {
                  class: 'view',
                  href: '#',
                  on: {
                    click: () => {
                      console.log('@24@success', couponType)
                      this.$store.commit(
                        'coupons/setFilterCouponType',
                        couponType || 'COUPON_SECTION'
                      )
                      pushRouterWithQuery(this.$router, { name: 'my-wallet' })
                    }
                  }
                },
                buttonText
              )
            ])
          ]),
          // message: "<strong><img class='icon' src='/img/message_icon_success.png'><div>Congratulations! You’ve acquired coupon(s) successfully! <a href='" + viewUri + "'>View Coupons</a></div></strong>",
          customClass: 'get-coupon-success',
          type: '',
          duration: 5000,
          offset: offset
        })
      }
      this.$message.close()
    },
    showSentVerificationEmailMessage (
      checkApi = false,
      skipCheckDisplaying = false
    ) {
      const ssoUid = this.$store.getters['auth/ssoUid']
      let offset = 77
      if (window.$('.is-vue-mobile').length) {
        offset = 73
      }
      if (window.$('.nav').height() === 0) {
        offset = 10
      }
      if (checkApi) {
        axios
          .post(`/customers/${ssoUid}/log_email_address_verification`)
          .then((response) => {
            const { data } = response
            console.log('data---- ', data)
            const showMessage = data && data.need_display_popup
            if (showMessage) {
              const withinDisplaying =
                this.$store.getters[
                  'app/getShowSentVerificationEmailMessageEndTime'
                ] &&
                new Date(
                  this.$store.getters[
                    'app/getShowSentVerificationEmailMessageEndTime'
                  ]
                ).getTime() > new Date().getTime()
              if (!skipCheckDisplaying && withinDisplaying) {
                return
              } else if (withinDisplaying) {
                this.$message.closeAll()
              }
              this.$store.commit(
                'app/setShowSentVerificationEmailMessageEndTime'
              )
              this.$message({
                dangerouslyUseHTMLString: true,
                message: `<strong>${this.$t(
                  'registerSuccessfullyWithEmail'
                )}</strong>`,
                customClass: 'register-success-with-email-verification',
                type: '',
                offset: offset,
                duration: 5000
              })
            }
          })
          .catch((error) => {
            console.log('error----', error)
          })
      } else {
        const withinDisplaying =
          this.$store.getters[
            'app/getShowSentVerificationEmailMessageEndTime'
          ] &&
          new Date(
            this.$store.getters[
              'app/getShowSentVerificationEmailMessageEndTime'
            ]
          ).getTime() > new Date().getTime()
        if (!skipCheckDisplaying && withinDisplaying) {
          return
        } else if (withinDisplaying) {
          this.$message.closeAll()
        }
        this.$store.commit('app/setShowSentVerificationEmailMessageEndTime')
        this.$message({
          dangerouslyUseHTMLString: true,
          message: `<strong>${this.$t(
            'registerSuccessfullyWithEmail'
          )}</strong>`,
          customClass: 'register-success-with-email-verification',
          type: '',
          offset: offset,
          duration: 5000
        })
      }
    },
    formatStoreTime (timeStr) {
      if (!timeStr) {
        return ''
      }

      return timeStr.split(':').slice(0, 2).join(':')
    },
    getWeekdayName (dayKey) {
      const weekDays = {
        1: ['Monday', '星期一'],
        2: ['Tuesday', '星期二'],
        3: ['Wednesday', '星期三'],
        4: ['Thursday', '星期四'],
        5: ['Friday', '星期五'],
        6: ['Saturday', '星期六'],
        7: ['Sunday', '星期日']
      }

      const dayNames = weekDays[dayKey]
      if (this.$i18n.locale === 'en') {
        return dayNames[0]
      }
      return dayNames[1]
    },
    isContinousRange (dayIntList) {
      if (dayIntList.length < 2) {
        // only one day
        return false
      }

      for (var i = 1; i < dayIntList.length; i++) {
        if (parseInt(dayIntList[i]) - parseInt(dayIntList[i - 1]) > 1) {
          // not continuous
          return false
        }
      }

      return true
    },
    getWeekdayRangeDisplay (dayIntList) {
      if (!dayIntList.length) {
        console.log('@110, empty day list found')
        return ''
      }
      dayIntList.sort()

      let result = this.getWeekdayName(dayIntList[0])
      if (this.isContinousRange(dayIntList)) {
        result =
          result +
          this.$t('storeWeekdayTo') +
          this.getWeekdayName(dayIntList[dayIntList.length - 1])
      } else {
        // sepearated
        for (var i = 1; i < dayIntList.length; i++) {
          result = result + ', ' + this.getWeekdayName(dayIntList[i])
        }
      }

      return result
    },
    formatApplicableStore (item) {
      // console.log('5----- item', item)
      if (!item || typeof item !== 'object') {
        return {}
      }

      const storeHolidayBusinessOpenHours = item.store_holiday_business_hour
        ? item.store_holiday_business_hour.open_hour
        : ''
      const storeHolidayBusinessCloseHours = item.store_holiday_business_hour
        ? item.store_holiday_business_hour.close_hour
        : ''

      const district = item.district ? item.district.name : ''
      const area = item.district
        ? item.district.region
          ? item.district.region.name
          : ''
        : ''
      let holidayBusinessHours = null
      if (storeHolidayBusinessOpenHours) {
        holidayBusinessHours = {
          businessDay: this.$t('storePublicHoliday'),
          businessTime: item.store_holiday_business_hour.is_close
            ? this.$t('storeClosed')
            : this.formatStoreTime(storeHolidayBusinessOpenHours) +
              ' - ' +
              this.formatStoreTime(storeHolidayBusinessCloseHours)
        }
      }

      const weekBusinessHours = []
      if (item.store_in_week_business_hours) {
        console.log('store business', item.store_in_week_business_hours)
        item.store_in_week_business_hours.map((item) => {
          console.log('bussinessTime--', businessTime)
          if (!item.week_days || item.week_days.length === 0) {
            return
          }

          const businessDay = this.getWeekdayRangeDisplay(item.week_days)
          const businessTime = item.is_close
            ? this.$t('storeClosed')
            : this.formatStoreTime(item.open_hour) +
              ' - ' +
              this.formatStoreTime(item.close_hour)

          weekBusinessHours.push({
            businessDay: businessDay,
            businessTime: businessTime
          })
        })
      }

      let phone = ''
      if (
        item.phone_number_country_code ||
        item.phone_number_subscriber_number
      ) {
        phone =
          item.phone_number_country_code + item.phone_number_subscriber_number
      }

      return {
        ...item,
        id: item.id,
        name: item.name,
        description: item.description,
        phone: phone,
        email: item.email_address,
        district: district,
        area: area,
        address: item.address,
        holidayBusinessHours,
        weekBusinessHours,
        longitude: item.longitude,
        latitude: item.latitude
      }
    },
    formatApplicableStores (stores) {
      const result = []
      if (!stores) {
        return result
      }
      console.log('stores: ', stores)
      stores.map((item) => {
        result.push(this.formatApplicableStore(item))
      })
      return result
    },
    async logoutUser () {
      this.isShowLogoutConfirm = false

      await doLogout(null, true, false)
      /*
      console.log('@580', process.env.VUE_APP_SSO_URL)
      const ssoLogoutUrl = `${process.env.VUE_APP_SSO_URL}/auth/logout`
      // const authLogoutURL = `${process.env.VUE_APP_AUTH_URL}/oauth2/sessions/logout`
      // `${process.env.VUE_APP_AUTH_URL}/signout-redirect?redirect_to=`
      const authLogoutURL = `${process.env.VUE_APP_AUTH_URL}/auth/signout-redirect?redirect_to=` + window.location.origin
      console.log('@591', ssoLogoutUrl, authLogoutURL)
      // const prevWithCredentials = axios.defaults.withCredentials

      // axios.defaults.withCredentials = true
      try {
        const response = await axios.post(ssoLogoutUrl, {
        }, {
          withCredentials: true
        })

        console.log('@530 logout result for sso', response)
      } catch (error) {
        console.log('@586 error while logout sso', error)
      }

      // axios.defaults.withCredentials = prevWithCredentials

      this.isShowLogoutConfirm = false
      this.$store.commit('auth/clearUser')
      this.$store.commit('local/clear')
      pushRouterWithQuery(this.$router, { 'name': 'home' })
      // clean up all workbox user
      // https://stackoverflow.com/questions/54376355/clear-workbox-cache-of-all-content
      caches.keys().then(cacheNames => {
        console.log('@448, removing cache...', cacheNames)
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName)
        })
      }).catch(error => {
        console.log('@448b, failed to remove cache', error)
      })

      window.location.href = authLogoutURL
      */
      return
    },
    isHomePage (routeName) {
      const name = routeName || this.$route.name

      // console.log('@579a', name)
      return name === 'home'
    },

    isExplorePage (routeName) {
      const name = routeName || this.$route.name

      // console.log('@579b', name)
      // return name === 'home'
      return name === 'explore'
    },
    isGoodiebagCampaignsPage (routeName) {
      const name = routeName || this.$route.name

      // console.log('@579a', name)
      return name === 'goodie-bag-campaign-list'
    },

    isSearchResultPage (routeName) {
      const name = routeName || this.$route.name

      // console.log('@579b', name)
      // return name === 'home'
      return name === 'search-goodie-bag-campaign-list'
    },
    isMissionDetailPage (routeName) {
      const name = routeName || this.$route.name
      return name === 'mission-detail'
    },

    setBackToTopBottom (maxTries) {
      if (maxTries === undefined) {
        maxTries = 20 // max 10 seconds
      }

      if (maxTries === 0) {
        return
      }

      const el = window.$('#onetrust-banner-sdk')[0]

      if (!el) {
        setTimeout(() => {
          this.setBackToTopBottom(maxTries - 1)
        }, 500)

        return
      }

      const observer = new window.IntersectionObserver(([e]) => {
        let oneTrustHeight = 0
        if (e.target.style.display) {
          window.$('.back-to-top-bottom')[0].style.bottom = '100px'
          if (window.$('.float-buttons')[0]) {
            window.$('.float-buttons')[0].style.bottom = '170px'
          }
          oneTrustHeight = 0
        } else {
          oneTrustHeight = e.intersectionRect.height

          if (this.isMiniProgram()) {
            window
              .$('.back-to-top-bottom')
              .attr(
                'style',
                'bottom: calc(' +
                  (75 + oneTrustHeight) +
                  'px + var(--sab)) !important'
              )
            window
              .$('.float-buttons')
              .attr(
                'style',
                'bottom: calc(' +
                  (75 + 70 + oneTrustHeight) +
                  'px + var(--sab)) !important'
              )
          } else {
            if (this.isMobile) {
              window
                .$('.back-to-top-bottom')
                .attr(
                  'style',
                  'bottom: ' + (20 + oneTrustHeight) + 'px !important'
                )
              window
                .$('.float-buttons')
                .attr(
                  'style',
                  'bottom: ' + (20 + 70 + oneTrustHeight) + 'px !important'
                )
            } else {
              window
                .$('.back-to-top-bottom')
                .attr(
                  'style',
                  'bottom: ' + (70 + oneTrustHeight) + 'px !important'
                )
              window
                .$('.float-buttons')
                .attr(
                  'style',
                  'bottom: ' + (70 - 5 + 70 + 40 + oneTrustHeight) + 'px !important'
                )
            }
          }
        }
        this.$store.commit('app/setOneTrustHeight', oneTrustHeight)

        setTimeout(() => {
          const unlockTipsBottom = (this.isMiniProgram() ? 66 : 0) + this.oneTrustHeight
          window.$('.is-vue-mobile .document-prize-goodie-bag .unlock-tips-container').attr('style', 'bottom: ' + unlockTipsBottom + 'px;')
          window.$('.is-vue-mobile .document-prize-fortune-bag-campaign-list .unlock-tips-container').attr('style', 'bottom: ' + unlockTipsBottom + 'px;')

          const unlockTipsContainerHeight = window.$('.is-vue-mobile .document-prize-goodie-bag .unlock-tips-container')[0] && window.$('.is-vue-mobile .document-prize-goodie-bag .unlock-tips-container')[0].clientHeight || 0
          window.$('.is-vue-mobile .document-prize-goodie-bag .footer').attr('style', 'margin-bottom: ' + unlockTipsContainerHeight + 'px !important;')

          const unlockTipsContainerInFortuneHeight = window.$('.is-vue-mobile .document-prize-fortune-bag-campaign-list .unlock-tips-container')[0] && window.$('.is-vue-mobile .document-prize-fortune-bag-campaign-list .unlock-tips-container')[0].clientHeight || 0
          window.$('.is-vue-mobile .document-prize-fortune-bag-campaign-list .footer').attr('style', 'margin-bottom: ' + unlockTipsContainerInFortuneHeight + 'px !important;')
        }, 200)
      })
      observer.observe(el)
    },

    adjustAgeRestrictedPopupForOneTrust () {
      let dialogHeight = parseInt(
        window.$('.coupon-alert-bar-dialog').css('height')
      )
      if (dialogHeight === 0) {
        dialogHeight = 400
      }
      const marginBottom =
        window.innerHeight -
        (parseInt(window.$('.coupon-alert-bar-dialog').css('margin-top')) +
          dialogHeight)
      let marginTop = '15vh'
      let height = 'auto'
      console.log('@484', marginBottom, this.oneTrustHeight, dialogHeight)
      if (marginBottom <= this.oneTrustHeight) {
        marginTop = '8vh'
        height = '100px'
      }
      // const maxHeight = 'calc(100vh - 10px - ' + this.oneTrustHeight + 'px)'
      // window.$('.coupon-alert-bar-dialog .age-declaration-content').css('max-height', maxHeight)
      window
        .$('.coupon-alert-bar-dialog .age-declaration-content')
        .css('height', height)
      window.$('.coupon-alert-bar-dialog').css('margin-top', marginTop)
    },
    adjustBasePopupForOneTrust () {
      let dialogHeight = parseInt(
        window.$('.custom-base-dialog').css('height')
      )
      if (dialogHeight === 0) {
        dialogHeight = 400
      }
      const marginBottom =
        window.innerHeight -
        (parseInt(window.$('.custom-base-dialog').css('margin-top')) +
          dialogHeight)
      let marginTop = '15vh'
      // let height = 'auto'
      console.log('@484', marginBottom, this.oneTrustHeight, dialogHeight)
      if (marginBottom <= this.oneTrustHeight) {
        marginTop = '8vh'
        // height = '100px'
      }
      // window
      //   .$('.custom-base-dialog .age-declaration-content')
      //   .css('height', height)
      window.$('.custom-base-dialog').css('margin-top', marginTop)
    },

    enableOverflow () {
      window.$('html').removeClass('no-scroll')
    },
    disableOverflow () {
      setTimeout(() => {
        window.$('html').addClass('no-scroll')
      }, 0)
    },
    removeBodyScrollingWhenModalOpen (modalOpen, initScrollY = 0) {
      console.log('modalOpen: ', modalOpen)
      const body = document.body

      if (modalOpen) {
        // addClass(body, 'el-popup--full-screen');
        window.$('body').addClass('el-popup--full-screen')
      } else {
        // removeClass(body, 'el-popup--full-screen')
        window.$('body').removeClass('el-popup--full-screen')
      }

      if (modalOpen && this.isMobile) {
        const scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
        body.style.position = 'fixed'
        body.style.top = `-${initScrollY || scrollY}px`
      } else if (!modalOpen && this.isMobile) {
        const scrollY = body.style.top
        body.style.position = ''
        body.style.top = ''
        console.log('@@3000: ', scrollY, parseInt(scrollY.replace('px', '') || '0') * -1)
        setTimeout(() => {
          window.scrollTo(0, parseInt(scrollY.replace('px', '') || '0') * -1)
        }, 50)
      } else if (modalOpen) {
        document.body.style.overflowY = 'hidden'
      } else {
        document.body.style.overflowY = 'visible'
      }
    },
    ageContentCancelClick () {
      if (this.goodieBagId) {
        // track click click_exit_in_goodiebag_restriction_pop_up
        sendManualAnalytics(
          this.$route,
          this.$i18n.locale,
          this.$store,
          'click_exit_in_goodiebag_restriction_pop_up',
          'onclick',
          {}
        )
      }
      console.log('@513 cancel')
      this.enableOverflow()
      if (this.$route.name === 'mission-detail') {
        if (this.participatingItem && this.$refs.missionTaskTarget && this.$refs.missionTaskTarget[this.participatingItem.targetIndex]) {
          this.$refs.missionTaskTarget[this.participatingItem.targetIndex].earnDone()
        }
        this.isShowAgeRestricted = false
        return
      }
      const { from } = this.$route.params
      if (from) {
        this.$router.back(-1)
      } else {
        pushRouterWithQuery(this.$router, { name: 'home' })
      }
    },
    async grantGPS (onSuccess, onError, ignorePrompt = false) {
      if (isWechatMiniProgram()) {
        await this.wechatGetLocation(onSuccess, (res) => {
          console.log('wechat get location error: ', res)
          // fail(res)
          if (res.errMsg.indexOf('invalid signature') >= 0 || res.errMsg.indexOf('getLocation:cancel') >= 0) {
            onError({ ...res, errorMessage: res.errMsg })
          } else {
            console.log('wechat get location language: ', this.$i18n.locale)
            window.wx.miniProgram.navigateTo({ url: '/pages/location/location?lang=' + this.$i18n.locale })
            onError({ ...res, errorMessage: res.errMsg, showPrompt: false })
          }
        })
        return
      }

      const options = {
        enableHighAccuracy: true,
        timeout: 30000,
        maximumAge: 0
      }
      if (navigator.geolocation) {
        if (navigator.permissions && navigator.permissions.query) {
          navigator.permissions
            .query({ name: 'geolocation' })
            .then(function (result) {
              console.log('query geolocation result', result)
              if ((result.state === 'prompt' && !ignorePrompt) || result.state === 'granted') {
                navigator.geolocation.getCurrentPosition(function (pos) {
                  console.log('get geolocation pos', pos)
                  const crd = pos.coords
                  if (crd) {
                    onSuccess(crd.latitude, crd.longitude)
                  } else {
                    onError('Unknown error')
                  }
                }, function (err) {
                  onError(err)
                }, options)
              } else if (result.state === 'denied') {
                onError('User has not allowed access to system location.')
              } else {
                onError('Geolocation is not supported by this browser.')
              }
            })
        } else {
          navigator.geolocation.getCurrentPosition(function (pos) {
            console.log('get geolocation pos', pos)
            const crd = pos.coords
            if (crd) {
              onSuccess(crd.latitude, crd.longitude)
            } else {
              onError('Unknown error')
            }
          }, function (err) {
            onError(err.message)
          }, options)
        }
      } else {
        onError('Geolocation is not supported by this browser.')
      }
    },
    formatLatitudeLongitude (lat, lng) {
      if (lat && lng) {
        this.latitude = parseFloat(lat).toFixed(6)
        this.longitude = parseFloat(lng).toFixed(6)
        localStorage.setItem('latitude', this.latitude)
        localStorage.setItem('longitude', this.longitude)
      } else {
        // this.latitude = null
        // this.longitude = null
        localStorage.setItem('latitude', '')
        localStorage.setItem('longitude', '')
      }
    },
    getCookie (name) {
      return Cookies.get(name)
    },
    removeCookie (name) {
      Cookies.remove(name)
    },
    async setCaptcha () {
      if (isWechatMiniProgram()) {
        return
      }

      let grecaptcha = window.grecaptcha
      const recaptchaContainer = document.getElementById('robot')
      const grecaptchaElement = document.querySelector('#robot div.grecaptcha-badge')
      console.log('@RecaptchaSet:', grecaptcha, recaptchaContainer, grecaptchaElement)

      if (!grecaptcha) {
        await new Promise(resolve => {
          setTimeout(() => {
            grecaptcha = window.grecaptcha
            if (!grecaptchaElement && grecaptcha && grecaptcha.enterprise && recaptchaContainer) {
              console.log('@RecaptchaSet-1: start to do recatptcha')
              grecaptcha.enterprise.render(recaptchaContainer, {
                sitekey: process.env.VUE_APP_RECACPTCHA_V3_KEY,
                size: 'invisible'
              })
              // const footerHight = window.$('.sticky-coupon-footer.sticky-campagin-button').css('height')
              // window.$('.grecaptcha-badge').css('bottom', footerHight)
              // window.$('.grecaptcha-badge').css('clip-path', 'inset(-5px -5px 0px -5px)')
            }
          }, 1500)
        })
      } else if (!grecaptchaElement && grecaptcha && grecaptcha.enterprise && recaptchaContainer) {
        console.log('@RecaptchaSet-2: start to do recatptcha')
        grecaptcha.enterprise.render(recaptchaContainer, {
          sitekey: process.env.VUE_APP_RECACPTCHA_V3_KEY,
          size: 'invisible'
        })
        // const footerHight = window.$('.sticky-coupon-footer.sticky-campagin-button').css('height')
        // window.$('.grecaptcha-badge').css('bottom', footerHight)
        // window.$('.grecaptcha-badge').css('clip-path', 'inset(-5px -5px 0px -5px)')
      }
      console.log('@RecaptchaSet: done')
    },
    validateCaptcha () {
      return new Promise((res, rej) => {
        if (!window.grecaptcha || !document.getElementById('robot').hasChildNodes()) {
          // rej(new Error('Recaptcha not available'))
          return res('Recaptcha not available')
        }
        window.grecaptcha.enterprise.ready(() => {
          window.grecaptcha.enterprise.execute({ action:
                 'login' }).then((token) => {
            console.log('@RecaptchaSetValidate:', token)
            return res(token)
          })
        })
      })
    },
    async getRecaptchaString (isVerifyRecaptcha, isGamification) {
      if (isWechatMiniProgram()) {
        return {
          recaptchaString: btoa('miniprogram' + Date.now()),
          errorData: {}
        }
      }

      const grecaptchaElement = document.querySelector('#robot div.grecaptcha-badge')
      if (!grecaptchaElement) {
        await this.setCaptcha()
      }

      // recaptcha verify
      let recaptchaString = null
      let errorData = {}
      if (isVerifyRecaptcha) {
        const token = await this.validateCaptcha()

        try {
          const params = {}
          if (isGamification) {
            params['is_gamification'] = 'true'
          }
          const queryString = new URLSearchParams(params).toString()
          const { data } = await axios.post(`/customers/verify-recaptcha?${queryString}`,
            { token, for: 'acquire' }
          )
          console.log('@RecaptchaResponse:', data)
          recaptchaString = data.recaptcha_verify_credential
          if (!recaptchaString) {
            errorData = {
              errorTitle: this.$t('recaptcha_low_score_title'),
              errorContent: this.$t('recaptcha_low_score_content')
            }
          }
        } catch (error) {
          errorData = {
            errorTitle: this.$t('recaptcha_low_score_title'),
            errorContent: this.$t('recaptcha_fail_content')
          }
          console.log('@RecaptchaResponseError:', error)
        }
      }
      return {
        recaptchaString,
        errorData
      }
    },
    scrollToPosition (target) {
      if (target) {
        const targetTop = target.getBoundingClientRect().top
        let offsetPosition = targetTop + window.pageYOffset - 45 - 26
        if (!this.isMobile) {
          offsetPosition -= 18
        }
        if (this.$route.name === 'my-wallet-all') {
          offsetPosition -= 88
        }
        console.log('657: ', target, target.getBoundingClientRect())
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        })
      }
      console.log('657: ', target)
    }
  }
}
</script>
