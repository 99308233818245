import { isUnavailable, isAvailable, isRedeemedOrExpiredInThreeMonth, isRedeemed, isExpired } from '@/utils/coupon'
import axios from 'axios'
// import moment from 'moment'

export const coupons = {
  namespaced: true,
  state: {
    coupons: [],
    couponDetailList: [],
    fakeCouponDetailList: [],
    query: 'All status',
    couponType: 'ALL',
    needInitQuery: true,
    queryKey: '',
    queryCouponLabel: ''
  },
  actions: {
    refreshCoupons: async ({ commit, rootState }) => {
      const ssoUid = rootState.auth.ssoUid
      const url = `/customers/${ssoUid}/coupons?sort=-date_of_acquisition&page[size]=500&page[number]=1`
      const response = await axios.get(url)
      const coupons = response.data || []
      commit('initCoupons', coupons)
    }
  },
  mutations: {
    initCoupons (state, coupons) {
      state.coupons = coupons
    },
    setNeedInitQuery (state, value) {
      state.needInitQuery = value
    },
    setQuery (state, query) {
      state.query = query
    },
    setQueryKey (state, query) {
      state.queryKey = query
    },
    setQueryCouponLabel (state, query) {
      state.queryCouponLabel = query
    },
    setFilterCouponType (state, couponType) {
      console.log('couopn type: ', couponType)
      state.couponType = couponType
    },
    updateCoupon (state, coupon) {
      console.log('update coupon---', coupon)
      if (coupon.isOnlyPreview) {
        state.fakeCouponDetailList = [
          ...state.fakeCouponDetailList.filter(item => item.id),
          coupon
        ]
        return
      }

      state.couponDetailList = [
        ...state.couponDetailList.filter(item => item.id !== coupon.id),
        coupon
      ]

      state.coupons = [
        ...state.coupons.filter(item => item.id !== coupon.id),
        coupon
      ]
    }
  },
  getters: {
    couponType: (state) => {
      return state.couponType
    },
    needInitQuery: (state) => {
      return state.needInitQuery
    },
    couponList: (state) => (query, couponTypes = null, searchKey = '', couponLabel = null) => {
      // console.log('52---- state.coupons', state.coupons, couponTypes)

      // filter out wrong types
      const coupons = state.coupons.filter(item => {
        /*
        if (!item.campaign || !item.campaign) {
          return false
        }
        */
        // const campaignType = item.campaign ? item.campaign.type : 'COUPON_CAMPAIGN'

        /* if (couponTypes) {
          console.log('@67', couponTypes, campaignType, item.template.display_in)
          if (campaignType === 'GIFT_CAMPAIGN_FOR_GOODIE_BAG') {
            if (!couponTypes.includes('COUPON_SECTION')) {
              return false
            }
          } else if (campaignType === 'COUPON_CAMPAIGN' || campaignType === 'FORTUNE_BAG_CAMPAIGN') {
            if (!couponTypes.includes(item.template.display_in)) {
              return false
            }
          } else {
            if (!couponTypes.includes('VOUCHER_SECTION') &&
            campaignType === 'CASH_VOUCHER_CAMPAIGN_FOR_GOODIE_BAG') {
              return false
            }
            if (!couponTypes.includes('LIMITED_OFFER_SECTION') &&
            campaignType === 'GIFT_CAMPAIGN_FOR_GOODIE_BAG') {
              return false
            }

            // if (!couponTypes.includes('FORTUNE_BAG_SECTION') &&
            // campaignType === 'FORTUNE_BAG_CAMPAIGN') {
            //  return false
            // }

            if (!couponTypes.includes('GENERAL_MESSAGE_SECTION') &&
            campaignType === 'GENERAL_MESSAGE_CAMPAIGN') {
              return false
            }
          }
        }*/

        if (searchKey) {
          const nameArray = [...Object.values(item.template.translations), item.template.name, item.template.english_name]
          const brandNameArray = item.template.brand ? [...Object.values(item.template.brand.translations), item.template.brand.name, item.template.brand.english_name] : []
          const checkKeyFunc = (name) => name?.toLowerCase()?.indexOf(searchKey.toLowerCase()) >= 0

          if (![...nameArray, ...brandNameArray].some(checkKeyFunc)) {
            return false
          }
        }

        if (couponLabel) {
          // if (couponLabel === 'starred') {
          if (couponLabel === 'starred' && (!item.campaign || (item.campaign && !item.campaign.is_starred))) {
            // if (item.campaign && item.campaign.is_starred) {
            //   console.log('coupon detail', item)
            // } else {
            //   return false
            // }

            return false
          } else if (couponLabel !== 'starred' && (!(item.goodie_bag && item.goodie_bag.coupon_label && item.goodie_bag.coupon_label.id === couponLabel))) {
            return false
          }
        }

        if (couponTypes) {
          if (!couponTypes.includes(item.template.display_in)) {
            return false
          }
        }
        // console.log(query, couponTypes, searchKey, couponLabel, item.id, item)
        if (query === 'Redeemed and Expired') {
          return isRedeemedOrExpiredInThreeMonth(item)
        } else if (query === 'Available and Unavailable') {
          return isUnavailable(item) || isAvailable(item)
        } else {
          return isAvailable(item) || isUnavailable(item) || isRedeemedOrExpiredInThreeMonth(item)
        }
      })

      return coupons.sort((a, b) => {
        if (!isRedeemedOrExpiredInThreeMonth(a) && isRedeemedOrExpiredInThreeMonth(b)) {
          return -1
        }
        if (isRedeemedOrExpiredInThreeMonth(a) && !isRedeemedOrExpiredInThreeMonth(b)) {
          return 1
        }
        if (!isRedeemed(a) && isRedeemed(b)) {
          return 1
        }
        if (isRedeemed(a) && !isRedeemed(b)) {
          return -1
        }
        if (isRedeemed(a) && isRedeemed(b) && a.used_at !== b.used_at) {
          return Date.parse(b.used_at) - Date.parse(a.used_at)
        }
        if (isExpired(a) && isExpired(b) && a.expiry_date !== b.expiry_date) {
          return Date.parse(b.expiry_date) - Date.parse(a.expiry_date)
        }
        if (a.date_of_acquisition !== b.date_of_acquisition) {
          return new Date(b.date_of_acquisition) - new Date(a.date_of_acquisition)
        }
        /*
        if (a.date_of_acquisition !== b.date_of_acquisition) {
          const aRecently = moment(new Date()).diff(a.date_of_acquisition, 'hours') <= 24
          const bRecently = moment(new Date()).diff(b.date_of_acquisition, 'hours') <= 24
          if (aRecently && bRecently) {
            return new Date(b.date_of_acquisition) - new Date(a.date_of_acquisition)
          } else if (!aRecently && bRecently) {
            return 1
          } else if (aRecently && !bRecently) {
            return -1
          }
        }
        if (!isAvailable(a) && isAvailable(b)) {
          return 1
        }
        if (isAvailable(a) && !isAvailable(b)) {
          return -1
        }
        // following logic are both a & b not recently
        if (!a.expiry_date && b.expiry_date) {
          return 1
        }
        if (a.expiry_date && !b.expiry_date) {
          return -1
        }
        if (a.expiry_date && b.expiry_date && a.expiry_date !== b.expiry_date) {
          return Date.parse(a.expiry_date) - Date.parse(b.expiry_date)
        }
        */

        // console.log('@96,', a)
        if (a.template.name && b.template.name) {
          return a.template.name.toLowerCase() > b.template.name.toLowerCase() ? 1 : -1
        }

        // template name not exist, should not happen

        return 0
      })
    },
    couponDetail: (state) => (id) => {
      const result = state.couponDetailList.find(coupon => parseInt(coupon.id) === parseInt(id))

      if (result) { return result }

      return state.coupons.find(coupon => parseInt(coupon.id) === parseInt(id))
    },
    fakeCouponDetail: (state) => (id) => {
      return state.fakeCouponDetailList.find(coupon => parseInt(coupon.id) === parseInt(id))
    },
    couponLabels: (state) => (starLabel) => {
      // console.log('52---- state.coupons', state.coupons, couponTypes)

      // filter out wrong types
      const availableCoupons = state.coupons.filter(item => {
        // if (isAvailable(item) || isUnavailable(item) || isRedeemedOrExpiredInThreeMonth(item)) {
        //   if (item.goodie_bag && item.goodie_bag.coupon_label) {
        //     console.log('@@237-0: ', item.goodie_bag && item.goodie_bag.coupon_label)
        //   } else {
        //     console.log('@@237-1: ', item.goodie_bag && item.goodie_bag.coupon_label)
        //   }

        //   return (item.goodie_bag && item.goodie_bag.coupon_label?.id) || (item.campaign && item.campaign.is_starred)
        // }

        // return false

        return (isAvailable(item) || isUnavailable(item) || isRedeemedOrExpiredInThreeMonth(item)) && ((item.goodie_bag && item.goodie_bag.coupon_label?.id) || (item.campaign && item.campaign.is_starred))
      })
      const labels = [...new Map(availableCoupons.map(item => {
        if (item.goodie_bag && item.goodie_bag.coupon_label) {
          return [
            item.goodie_bag.coupon_label.id,
            {
              label: item.goodie_bag.coupon_label.name,
              value: item.goodie_bag.coupon_label.id,
              englishName: item.goodie_bag.coupon_label.english_name,
              order: item.goodie_bag.coupon_label.display_priority
            }
          ]
        }

        return ['starred', {}]
      })).values()]

      const finalLabels = labels.sort((a, b) => a?.order - b?.order).filter(item => item && item.value)

      if (finalLabels.length < labels.length) {
        return [
          { value: 'starred', label: starLabel.name, englishName: starLabel.englishName },
          ...finalLabels
        ]
      }

      return finalLabels
    },
    query: (state) => {
      return state.query
    },
    queryKey: (state) => {
      return state.queryKey
    },
    queryCouponLabel: (state) => {
      return state.queryCouponLabel
    }
  }
}

export default coupons
