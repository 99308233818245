<script>
import { loginSSO } from '@/helpers/http'
// import {
//   isWechatMiniProgram,
//   replaceRouterWithQuery,
//   pushRouterWithQuery
// } from '@/utils/utils'

// import { isWechatMiniProgram, LOCATION_AREA_ID } from '@/utils/utils'
import axios from 'axios'
import { sendManualAnalytics, sendAdobeManualAnalytics } from '@/utils/tracking'

export default {
  data: function () {
    return {
      // barcodeFormat: null
    }
  },
  computed: {
    isTicket () {
      return this.verifyDisplayIn('TICKET_SECTION')
    },
    isVoucher () {
      return this.verifyDisplayIn('VOUCHER_SECTION')
    },
    isLimitedOffer () {
      return this.verifyDisplayIn('LIMITED_OFFER_SECTION')
    },
    isVerifyRecaptcha () {
      const { recaptchaOpen } = this.$store.getters['app/getSystemSettings']
      return recaptchaOpen
    }
  },
  methods: {
    barcodeOptions (barcodeFormat) {
      console.log('@@32: ', barcodeFormat)
      // const barcodeOption = { format: barcodeFormat }
      const barcodeOption = {}
      if (this.isMissionWideCode(barcodeFormat)) {
        barcodeOption['width'] = 1
      }
      return barcodeOption
    },
    isMissionWideCode (barcodeFormat) {
      return barcodeFormat === 'code39' || barcodeFormat === 'code128'
    },
    verifyDisplayIn (template, sectionName) {
      let result = false
      if (template && template.display_in === sectionName) {
        console.log('@1764 isTicket', template.display_in)
        result = true
      }
      return result
    },
    async redeemGamification (postData, onSuccess, onFail) {
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'gamification_campaign_start', 'event', { gamificationCampaignId: postData.gamification_campaign })
      // const lang = getLangName(this.$i18n.locale)
      // console.log('query----', window.location.search)
      // let query = window.location.search
      // if (query) { query += '&' }

      // query += `lang=${lang}`

      // let redeemUrl = `${process.env.VUE_APP_WALLET_URL}/redeem/${query}`

      // redeemUrl = setupDefaultQueryString(this.$route, redeemUrl)

      // window.location.href = redeemUrl
      //   console.log('@@@@552: ', missionId)

      //   if (this.gamificationCampaignRecordId) {
      //     return
      //   }
      const isOnlyCheck = ('check_link' in postData)
      const ssoUid = this.$store.getters['auth/ssoUid']
      // console.log('@@Recaptcha-454----', ssoUid, this.$store, localStorage.getItem('vuex'))
      if (ssoUid) {
        // const postData = {
        //   gamification_campaign: this.gamificationCampaignId,
        //   sign: this.sign,
        //   tracking_code: this.tracking_code,
        //   recaptcha_verify_credential: 'fake verify'

        // }
        let query = ''
        let recaptchaStringLocal = ''
        if (isOnlyCheck) {
          query = '?check_link=true'
        } else {
          const {
            recaptchaString,
            errorData
          } = await this.getRecaptchaString(this.isVerifyRecaptcha, true)
          if (errorData.errorContent) {
            errorData.isRecaptchaError = true
            errorData.display_error_content = errorData.errorContent
            errorData.detail = errorData.errorContent
            if (onFail) {
              onFail(errorData)
            }
            // this.showErrorMessageDialog(errorData.errorTitle, errorData.errorContent)
            return
          }

          recaptchaStringLocal = recaptchaString
        }

        if (postData.participatingItem) {
          delete postData.participatingItem
        }
        postData.recaptcha_verify_credential = recaptchaStringLocal
        // gamificationCampaignId=727&source=email&amount=1&entitle_id=1&ts=1736468190&sign=f79f64791c7942073cc0bb677a5ddebdb4b4beaa
        // ?gamificationCampaignId=892&source=email&sign=e0ac495c313f4890ac72199d8af3cff08bd4010f&t=MDExNTAx
        const { gamificationCampaignId, source, amount, entitle_id, ts, sign, t } = this.$route.query
        let search = ''
        if (entitle_id) {
          search = `gamificationCampaignId=${gamificationCampaignId}&source=${source}&amount=${amount}&entitle_id=${entitle_id}&ts=${ts}&sign=${sign}`
        } else if (t) {
          search = `gamificationCampaignId=${gamificationCampaignId}&sign=${sign}&t=${t}`
        }
        postData.search = search
        await axios.post(`/customers/${ssoUid}/gamification_campaign_participation_records${query}`, postData).then(response => {
          console.log('response---', response.data)
          const created = response.status === 201
          if (!isOnlyCheck) {
            this.gamificationCampaignRecordId = response.data.id
            this.$store.commit('gamification/updateGamificationList', {
              id: postData.gamification_campaign,
              detail: {
                gamification_campaign_participation_record_id: response.data.id
              }
            })
          }
          if (onSuccess) {
            onSuccess(created)
          }
          // if (this.isLanding) {
          //   window.location.href = `/${this.$i18n.locale}/gamification-detail?gamificationCampaignId=${this.gamificationCampaignId}`
          //   // pushRouterWithQuery(this.$router, { path: 'gamification-detail', query: { gamificationCampaignId: this.gamificationCampaignId }})
          // } else if (missionId) {
          //   pushRouterWithQuery(this.$router, { path: 'mission-detail', query: { missionId: missionId, gamificationCampaignRecordId: this.detail.gamification_campaign_participation_record_id }})
          // }
        }).catch(error => {
          const { data } = error.response
          if (onFail) {
            onFail(data)
          }
          sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'game|join fail', { gamificationId: this.detail.id, join: '0', errorMessage: data.display_error_content || data.detail })
        //   const display_error_content = data.display_error_content
        //   // alert(display_error_content)
        //   this.displayDialogErrorMessage = display_error_content
        //   this.showErrorDialog = true
        //   console.log('@@478----', error)
        })
      } else {
        if (!isOnlyCheck) {
          console.log('@@454----')
          loginSSO(null, false, { participatingItem: postData.participatingItem })
        } else {
          onSuccess()
        }
      }
    },
    async ajaxCheckCoupon (payload, couponId) {
      const query = this.isGamificationPrize ? `&is_gamification=true` : ''
      const response = await axios.post(`/coupons/check_valid?id=${couponId}${query}`, payload)
      const { data } = response
      console.log('response', response, data)
      // this.$store.commit('coupons/updateCoupon', coupon)
      return response
    },
    checkUseCouponFailHandle (error) {
      const response = {}
      console.log('@@122: ', error)
      let errorDetail = error
      if (typeof error === 'object' && !Array.isArray(error) && error !== null) {
        errorDetail = error.response.data.detail
      }

      if (errorDetail === 'error_use_coupon_unreach_special_time_coupon') {
        response.goodieBagFailTitle = 'couponNotAvailableInSpecificDaysPromptTitle'
        response.goodieBagFailText = this.$t('couponNotAvailableInSpecificDaysPrompt')
        response.isShowGoodieBagFail = true
      } else if (errorDetail === 'Coupon is expired.') {
        response.goodieBagFailTitle = 'couponInvalidPromptTitle'
        response.goodieBagFailText = this.$t('CouponExpiredErrorMessage', { couponExpiredOn: this.getDateString(error.response.data.expiry_date, 'D MMM YYYY') })
        response.useCouponFailToMyWallet = true
        response.isShowGoodieBagFail = true
        response.couponData.expiry_date = error.response.data.expiry_date
      } else if (errorDetail === 'Coupon is used.') {
        response.goodieBagFailTitle = 'couponInvalidPromptTitle'
        response.goodieBagFailText = this.$t('couponUsedPrompt')
        response.useCouponFailToMyWallet = true
        response.isShowGoodieBagFail = true
      } else if (errorDetail === 'Coupon is unavailable.') {
        response.goodieBagFailTitle = 'couponUnavailablePromptTitle'
        response.goodieBagFailText = this.$t('couponNotInActivePeriodPrompt')
        response.isShowGoodieBagFail = true
        if (!response.couponData) {
          response.couponData = {}
        }
        response.couponData.effective_date = error.response.data.effective_date
      } else if (errorDetail === 'Coupon is inactive') {
        response.goodieBagFailTitle = 'couponUnavailablePromptTitle'
        response.goodieBagFailText = this.$t('couponInactivePrompt')
        response.useCouponFailToMyWallet = true
        response.isShowGoodieBagFail = true
      } else if (errorDetail === 'error_use_coupon_gps_limit') {
        response.goodieBagFailTitle = 'couponUseFailPromptTitle'
        response.goodieBagFailText = this.$t('couponLocationLimitPrompt')
        response.useCouponFailToMyWallet = false
        response.isShowGoodieBagFail = true
      }
      console.log('@@161: ', response, error)
      if (!response.goodieBagFailText && typeof error === 'string') {
        response.displayErrorMessage = error
      } else {
        response.displayErrorMessageTitle = this.$t(response.goodieBagFailTitle)
        response.displayErrorMessage = response.goodieBagFailText
      }

      return response
    },
    handleRedeemGamificationError (error) {
      if (error.isRecaptchaError) {
        return error.display_error_content
      }

      let display_error = error.display_error_content
      if (error.detail === 'Acquired by others') {
        // display_error = 'The campaign have been acquired'
        display_error = this.$t('gamificationLandingError4')
      } else if (error.detail === 'Acquired already') {
        display_error = ''
      }
      return display_error
    },
    setCollectStampSuccessDialog (isCheckIn, data, checkInLocation = null) {
      console.log('1542: ', checkInLocation)
      this.collectStampSuccessDialog = null
      const acquiredStamps = data.collected_stamps?.acquired_stamp_count
      if (isCheckIn) {
        this.collectStampSuccessDialog = {
          checkIn: {
            on: this.getDateString(data.creation_date, 'D MMM YYYY HH:mm:ss'),
            at: checkInLocation || ''
          }
        }
      }

      console.log('1542: ', data, checkInLocation, this.collectStampSuccessDialog, acquiredStamps)
      if (acquiredStamps) {
        this.collectStampSuccessDialog = {
          ...(this.collectStampSuccessDialog || {}),
          gamificationCampaignId: data.collected_stamps?.gamification_campaign_id,
          missionId: data.mission,
          count: acquiredStamps,
          earnedStamps: data.collected_stamps?.total_acquired_stamp_count,
          totalStamps: data.collected_stamps?.total_stamp_count,
          rewards: data.collected_stamps?.stamp_rewards?.filter((item) => item.collected_reward === true).map((item) => {
            let imageUrl = null
            if (item.reward_type === 'GOODIE_BAG') {
              imageUrl = this.isMobile ? item.mobile_photo_for_prize_url || item.goodie_bag.mobile_photo_url : item.cover_photo_for_prize_url || item.goodie_bag.cover_photo_url
            } else {
              imageUrl = (this.isMobile ? item.mobile_photo_for_prize_url : item.cover_photo_for_prize_url) || item.coupon_template.cover_photo_url
            }
            return {
              name: item.goodie_bag?.id ? item.goodie_bag.name : item.coupon_template.name,
              // imageUrl: item.reward_icon_collected_url || '/img/reward-collected-icon-default.png',
              imageUrl,
              type: item.goodie_bag?.id || item.goodie_bag.reward_type,
              isCoupon: item.reward_type !== 'GOODIE_BAG' && (item.coupon_template?.id || item.coupon_ids),
              goodieBagParticipationRecords: item.goodie_bag_participation_record_ids,
              fortuneBagCouponIds: item.coupon_ids,
              quantity: item.quantity,
              index: item.index
            }
          })
        }

        this.showAcquireStampSuccessDialog = true
        if (this.collectStampSuccessDialog.rewards.filter((item) => item.isCoupon || item.fortuneBagCouponIds?.length).length) {
          this.$store.dispatch('coupons/refreshCoupons')
        }
        if (this.collectStampSuccessDialog.rewards.filter((item) => item.goodieBagParticipationRecords?.length).length) {
          this.$store.dispatch('goodiebagparticipationrecords/refreshRecords')
        }
        if (this.collectStampSuccessDialog?.earnedStamps) {
          this.$store.commit('gamification/updateGamificationList', {
            id: this.collectStampSuccessDialog.gamificationCampaignId,
            detail: { collected_stamp_rewards_count: this.collectStampSuccessDialog.earnedStamps }
          })
        }
      }
    }
  }
}
</script>
