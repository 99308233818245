<template>
  <div class="main-container">
    <div class="header-container">
      <div class="page-title-container">
        <div class="page-title">{{ $t("headerMyGamifications")}}</div>
        <el-tooltip
          class="item"
          :content="$t('gamificationListToolTips')"
          placement="bottom"
          @mouseover.native="analyticsMouseOver()"
        >
          <img
            class="question-mark-icon"
            srcset="/img/info.1x.png, /img/info.2x.png 2x, /img/info.3x.png 3x"
            src="/img/info.2x.png"
          >
        </el-tooltip>
      </div>
    </div>

    <div class="goodie-bag-list-container">
      <div
        class="goodie-bag-list"
        :style="{ ...goodieBagListStyle, height: isMobile ? 'unset' : getItemsListStyles(mixRecordType).totalHeight + 'px' }"
      >
        <goodie-bag-gamification-no-data
          v-if="initFinished && !(itemList && itemList.length)"
          icon="/img/empty_gamifications_image.png"
          :title="$t('gamificationListEmptyTitle')"
          :description="$t('gamificationListEmptyDescription')"
        />
        <div
          v-for="(item, itemListIndex) in desktopItemList"
          :id="`${item.recordType}-${item.id}`"
          :key="`desktop-${item.recordType}-${item.id}`"
          ref="mixItems"
          class="mix-item-outside"
          :style="getItemsListStylesByIndex(itemListIndex)"
        >
          <goodie-bag-and-gamification-list-item
            :item="item"
            :card-orientation="'VERTICAL'"
          />
        </div>
        <goodie-bag-and-gamification-list-item
          v-for="item in mobileItemList"
          :id="`${item.recordType}-${item.id}`"
          :key="`${item.recordType}-${item.id}`"
          :item="item"
          :card-orientation="'VERTICAL'"
        />
        <!-- <div v-if="!isMobile" class="goodie-bag-list-columns">
          <div class="goodie-bag-list-column">
            <goodie-bag-and-gamification-list-item
              v-for="item in leftItemList"
              :key="`${item.recordType}-${item.id}`"
              :item="item"
              :card-orientation="'VERTICAL'"
              @showTnc="toggleTncDialog"
            />
          </div>
          <div class="goodie-bag-list-column">
            <goodie-bag-and-gamification-list-item
              v-for="item in rightItemList"
              :key="`${item.recordType}-${item.id}`"
              :item="item"
              :card-orientation="'VERTICAL'"
              @showTnc="toggleTncDialog"
            />
          </div>
        </div> -->
      </div>
      <div
        :class="['goodie-bag-list-overlay', 'left', {active: showLeftArrow}]"
        @click="moveLeft"
      >
        <div class="arrow-btn">
          <img
            class="arrow-icon"
            srcset="/img/arrow-icon.1x.png, /img/arrow-icon.2x.png 2x, /img/arrow-icon.3x.png 3x"
            src="/img/arrow-icon.2x.png"
          >
        </div>
      </div>
      <div
        :class="['goodie-bag-list-overlay', 'right', {active: showRightArrow}]"
        @click="moveRight"
      >
        <div class="arrow-btn">
          <img
            class="arrow-icon"
            srcset="/img/arrow-icon.1x.png, /img/arrow-icon.2x.png 2x, /img/arrow-icon.3x.png 3x"
            src="/img/arrow-icon.2x.png"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilsMixin from '@/mixins/utilsMixin'
import backHandlerMixin from '@/mixins/backHandlerMixin'
import GoodieBagAndGamificationListItem from '@/components/GoodieBagAndGamificationListItem'
import GoodieBagGamificationNoData from '@/components/GoodieBagGamificationNoData'
import { sendManualAnalytics, sendAdobePageViewAnalytics } from '../utils/tracking'
import { isWechatMiniProgram, replaceRouterWithQuery } from '@/utils/utils'
// import { replaceRouterWithQuery } from '@/utils/utils'

export default {
  name: 'GamificationList',
  components: {
    GoodieBagAndGamificationListItem,
    GoodieBagGamificationNoData
  },
  mixins: [utilsMixin, backHandlerMixin],
  data () {
    return {
      index: 0,
      // tncItemId: 0,
      showTnc: false,
      isFirstLoad: false,
      itemsListHeights: [],
      itemsListStyles: {
        itemsStyles: [],
        totalHeight: 100
      }
    }
  },
  computed: {
    isMobile () {
      return this.$store.getters['app/isMobile']
    },
    hasGoodieBag () {
      return this.$store.getters['goodiebagparticipationrecords/hasRecord']
    },
    initFinished () {
      return this.$store.getters['goodiebagparticipationrecords/initFinished'] && this.$store.getters['gamification/initFinished']
    },
    banners () {
      const banners = this.$store.getters['app/getMyRewardBanners']
      return banners && banners.length > 0 ? banners.slice(0, 5) : []
    },
    emptyRewardWords () {
      if (isWechatMiniProgram()) {
        return {
          Title: this.$t('myRewardEmptyTitle'),
          Desc: this.$t('myRewardEmptyDesc'),
          Link: ''
        }
      }
      return {
        Title: this.$t('myRewardEmptyTitle'),
        Desc: this.$t('myRewardEmptyDesc'),
        Link: ''
      }
    },
    showLeftArrow () {
      return this.index > 0
    },
    showRightArrow () {
      return this.itemList && this.itemList.length - this.index >= 3
    },
    goodieBagListStyle () {
      let offset = this.index * 373
      console.log('goodieBagListStyle', this.itemList.length - this.index)
      if (this.itemList && this.itemList.length - this.index === 2) {
        offset -= 248
      }
      return { transform: `translateX(-${offset}px)` }
    },
    mixRecordType () {
      return this.$store.getters['mixRecordType']
    },
    isAllActive () {
      return this.mixRecordType === 'all'
    },
    isGoodieBagActive () {
      return this.mixRecordType === 'goodiebag'
    },
    isGamificationActive () {
      return this.mixRecordType === 'gamification'
    },
    itemList () {
      if (!this.initFinished) {
        return []
      }
      return this.$store.getters['gamification/gamificationDetailList'](this.$store.getters['auth/isTestUser'], this.$store.getters['auth/countryCode'])
    },
    activeList () {
      return this.itemList
    },
    allLength () {
      return this.itemList.length
    },
    gamificationRecordsLength () {
      return this.itemList.length
    },
    desktopItemList () {
      if (!this.isMobile) {
        return this.itemList
      }
      return []
    },
    mobileItemList () {
      if (this.isMobile) {
        return this.activeList
      }
      return []
    }
    // leftItemList () {
    //   return this.activeList.filter((item, index) => index % 2 === 0)
    // },
    // rightItemList () {
    //   return this.activeList.filter((item, index) => index % 2 === 1)
    // },
    // tncItem () {
    //   const { 0: tncItem } = this.itemList.filter(item => item.recordType === 'goodiebag' && item.id === this.tncItemId)
    //   console.log('tncItem', tncItem)
    //   return tncItem
    // }
  },
  watch: {
    'initFinished': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.$nextTick(function () {
          // if (!this.hasGoodieBag) {
          //   replaceRouterWithQuery(this.$router, { name: 'home' })
          // }
          setTimeout(() => {
            this.itemsListHeights = this.getItemsListHeights()
            console.log('??? itemsListHeights mounted', this.itemsListHeights)
            this.itemsListStyles = this.getItemsListStyles(this.mixRecordType)
            console.log('??? itemsStyles mounted', this.itemsListStyles)
          }, 500)
        })
      }
    },
    'isMobile': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$nextTick(function () {
          setTimeout(() => {
            this.itemsListHeights = this.getItemsListHeights()
            console.log('??? itemsListHeights mounted', this.itemsListHeights)
            this.itemsListStyles = this.getItemsListStyles(this.mixRecordType)
            console.log('??? itemsStyles mounted', this.itemsListStyles)
          }, 10)
        })
      }
    }
  },
  beforeDestroy () {
    // this.$store.commit('auth/closeGoodieAlertFromOtherChannels')
    // this.$store.commit('app/setMiniProgramBackTitle', ' Stamp campaigns')

    this.$store.commit('app/setNavType', 'logo_language_menu')
  },
  async mounted () {
    const { name } = this.$route
    this.changeTab(name, false)
    // this.$store.commit('app/setMiniProgramBackTitle', this.$t('toHome'))
    this.$store.commit('app/setNavType', 'back_language_menu')
    sendAdobePageViewAnalytics(this.$route, this.$i18n.locale, this.$store, 'my gamifications|list')
  },
  updated () {},
  methods: {
    analyticsMouseOver () {
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'stamp_gamification_info_click', 'onclick')
    },
    // toggleTncDialog (tncItemId) {
    //   this.tncItemId = tncItemId
    //   this.showTnc = !this.showTnc
    // },
    moveLeft () {
      if (this.index > 0) {
        this.index -= 1
      }
    },
    moveRight () {
      if (this.itemList && this.itemList.length - this.index >= 3) {
        this.index += 1
      }
    },
    showMessage () {
      if (!this.redeemedRecords || this.redeemedRecords.length === 0) {
        return
      }

      /* const { 0: {
        goodie_bag: {
          // name,
          id: goodie_bag_id
          // assign_quota_per_link: quota,
          // assign_credit_per_link: credit,
          // reward_type: rewardType
        }
      }} = this.redeemedRecords*/

      console.log('@777', this.redeemedRecords)

      const message = this.$t('goodiebagSuccessfullyUnlockedXBoth')
      /*
      if (this.redeemedRecords.length === 1) {
        if (rewardType === 'BOTH') {
          message = this.$t('goodiebagSuccessfullyUnlockedBoth', { name, quota, credit })
        } else if (rewardType === 'CASH_VOUCHER') {
          message = this.$t('goodiebagSuccessfullyUnlockedCash', { name, credit })
        } else {
          message = this.$t('goodiebagSuccessfullyUnlockedGift', { name, quota })
        }
      } else {
        const count = this.redeemedRecords.length
        console.log('goodiebagSuccessfullyUnlockedXBoth', this.redeemedRecords, count, name, quota, credit)
        if (rewardType === 'BOTH') {
          message = this.$t('goodiebagSuccessfullyUnlockedXBoth', { count, name, quota: quota * count, credit: credit * count })
        } else if (rewardType === 'CASH_VOUCHER') {
          message = this.$t('goodiebagSuccessfullyUnlockedXCash', { count, name, credit: credit * count })
        } else {
          message = this.$t('goodiebagSuccessfullyUnlockedXGift', { count, name, quota: quota * count })
        }
      }*/

      let customClass = 'goodiebag-unlock-success'
      let offset = 73
      if (this.$store.getters['app/isMobile']) {
        customClass += ' mobile'
        offset = 61
      }

      // sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'acquire_goodie_bag_success', 'event', { 'id': goodie_bag_id })
      this.$message({
        type: 'success',
        offset,
        duration: 5000,
        dangerouslyUseHTMLString: true,
        message,
        customClass
      })

      console.log('@266')
      /*
      const query = Object.assign({}, this.$route.query)
      delete query.record_id
      delete query.response_code
      replaceRouterWithQuery(this.$router, { query })
      */
    },
    changeTab (tabName, changeUrl = true) {
      let newRecordType = 'all'
      if (tabName === 'my-goodie-bags') {
        newRecordType = 'goodiebag'
        this.$store.commit('setMixRecordType', 'goodiebag')
      } else if (tabName === 'my-gamifications') {
        newRecordType = 'gamification'
        this.$store.commit('setMixRecordType', 'gamification')
      } else {
        this.$store.commit('setMixRecordType', 'all')
      }
      if (changeUrl) {
        // update path name
        const to = this.$i18nRoute(this.$route, tabName)
        replaceRouterWithQuery(this.$router, to)
      }
      this.isFirstLoad = !changeUrl
      this.$nextTick(() => {
        setTimeout(() => {
          this.itemsListHeights = this.getItemsListHeights()
          console.log('??? itemsListHeights mounted', this.itemsListHeights)
          this.itemsListStyles = this.getItemsListStyles(newRecordType)
          console.log('??? itemsStyles mounted', this.itemsListStyles)
        }, 10)

        const gotoGoodieBagRecord = this.$route.hash
        console.log('@@147: ', gotoGoodieBagRecord)
        if (gotoGoodieBagRecord && !this.isBackNavigation) {
          setTimeout(() => {
            const el = document.getElementById(gotoGoodieBagRecord.replace('#', ''))
            this.scrollToPosition(el)
          }, 1500)
        }
      })
    },
    getItemsListHeights () {
      const itemsHeights = []
      if (this.$refs.mixItems && this.itemList) {
        this.$refs.mixItems.forEach((item, index) => {
          let height = 0
          if (this.itemList[index].recordType === 'gamification') {
            // height = 387.2 - 39
            height = 32 + 244.69 + 16 + 8 + 8 + 44
            const titleElement = this.$refs.mixItems[index].getElementsByClassName('gamification-title')[0]
            // console.log('???', index, titleElement, titleElement.clientHeight)
            if (titleElement.clientHeight > 50) {
              // height = height + 42.75 * 2
              height = height + 49.39 * 2
            } else if (titleElement.clientHeight > 0) {
              // height = height + 42.75
              height = height + 49.39
            }
            const descriptionElement = this.$refs.mixItems[index].getElementsByClassName('gamification-description')[0]
            if (descriptionElement.clientHeight > 24 + 5) {
              height = height + 24 * 2
            } else if (descriptionElement.clientHeight > 0) {
              height = height + 24
            }
            const labelsElement = this.$refs.mixItems[index].getElementsByClassName('gamification-labels')[0]
            let missionLabels = []
            if (this.itemList[index].show_mission_labels && this.itemList[index].mission_labels && this.itemList[index].mission_labels.length) {
              missionLabels = this.itemList[index].mission_labels.filter((missionLabel) => missionLabel)
            }
            if (missionLabels && missionLabels.length) {
              if (labelsElement.clientHeight > 45) {
                height = height + labelsElement.clientHeight + 9
              } else {
                height = height + 39
              }
            }
          } else {
            height = 350.25 + 24 + 41 + 20 + 23 + 36
            const titleElement = this.$refs.mixItems[index].getElementsByClassName('goodie-bag-info-container')[0].getElementsByClassName('goodie-bag-title')[0]
            if (titleElement && titleElement.clientHeight > 41) {
              height = height + 41
            }

            const effectiveDateElement = this.$refs.mixItems[index].getElementsByClassName('effective-date-container')[0]
            if (effectiveDateElement && effectiveDateElement.clientHeight > 20) {
              height = height + 20
            }
            const campaignSlots = this.itemList[index].campaign_slots || []
            campaignSlots.forEach((item, subindex) => {
              // height = height + 89
              height = height + 12 + 8 + 12 + 31 + 4 + 26
              const titleElement = this.$refs.mixItems[index].getElementsByClassName('button-description')[subindex]
              if (titleElement.clientHeight > 31) {
                height = height + 23
              }
              const labelElement = this.$refs.mixItems[index].getElementsByClassName('button-label')[subindex]
              if (labelElement && labelElement.clientHeight > 10) {
                height = height + 21.7 + 8
              }
            })
            const {
              generic_terms_and_conditions_title: title,
              generic_terms_and_conditions_content: content
            } = this.itemList[index].goodie_bag || {}
            if (title || content) {
              height = height + 27
            }
          }
          itemsHeights.push(height)
          // console.log('???2', index, height, this.$refs.mixItems[index], this.$refs.mixItems[index].clientHeight)
          // itemsHeights.push(this.$refs.mixItems[index].clientHeight - 17)
        })
      }
      // console.log('??? itemsHeights', itemsHeights)
      return itemsHeights
    },
    getItemsListStyles (newRecordType) {
      const itemsStyles = []
      const itemsHeights = this.itemsListHeights
      // const itemsHeights = this.getItemsListHeights()
      // const itemsHeights = this.isFirstLoad ? this.itemsListHeights : this.getItemsListHeights()
      const H = [30, 30]
      itemsHeights.forEach((elementHeight, index) => {
        let leftOffset = 0
        let topOffset = 0
        let visibility = 'unset'

        const gap = 30
        const width = (1024 - gap - 30 * 2) / 2
        const tag = H.indexOf(Math.min(...H))

        if (newRecordType !== 'all' && newRecordType !== '' && (this.itemList[index].recordType !== newRecordType)) {
          visibility = 'hidden'
        } else {
          leftOffset = 30 + tag * (width + gap)
          topOffset = H[tag]
          H[tag] += itemsHeights[index] + gap
        }
        if (this.isMobile) {
          visibility = 'hidden'
        }
        itemsStyles.push({ left: leftOffset + 'px', top: topOffset + 'px', width: width + 'px', height: elementHeight + 'px', visibility })
      })
      // console.log('??? getItemsListStyles', Math.max(...H), itemsStyles)
      return {
        itemsStyles: itemsStyles,
        totalHeight: Math.max(...H)
      }
    },
    getItemsListStylesByIndex (index) {
      // const itemsStyles = this.getItemsListStyles()
      const itemsStyles = this.itemsListStyles
      // const itemsStyles = this.isFirstLoad ? this.itemsListStyles : this.getItemsListStyles()
      // console.log('??? getItemsListStylesByIndex', index, itemsStyles.itemsStyles[index])
      return itemsStyles.itemsStyles[index]
    }
  }

}
</script>

<style lang="scss">
.el-message.goodiebag-unlock-success {
  background: #E6F4E7;
  border: 1px solid rgba(128, 189, 120, 0.1);

  .el-message__icon{
    font-size: 20px;
  }

  .el-message__content {
    color: #000000;
  }

  &.mobile {
    border-radius: 6px;
    min-width: calc(100vw - 40px);

    .el-message__content {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

/*
@media screen and (max-width: 1024px) {
  .el-tooltip__popper.is-dark[x-placement^="bottom"] {
    right: 22px;
  }
}
*/

.el-tooltip__popper.is-dark[x-placement^="bottom"] {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  font-size: 14px;
  line-height: 22px;
}

.is-vue-desktop .goodie-bag-carousel-container{
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 60px;
}

.goodie-bag-carousel-container {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 24px;
}
</style>

<style lang="scss" scoped>
.is-vue-desktop {
  .main-container {
    background-color: #F2F3F6;

    .header-container {
      // background: linear-gradient(180deg, #FFFFFF 80%, #F7F7F7 20%);
      background: #FFFFFF;
    }

    .goodie-bag-list-menu-container {
      // gap: 32px;
      padding: 18px 30px;

      .menu-item {
        .menu-text {
          font-size: 15px;
          font-weight: 700;
          // line-height: 20.43px;
        }
      }
    }
    .goodie-bag-list-menu-container .menu-item:nth-child(n+2) {
      margin-left: 32px;
    }

    .goodie-bag-list {
      // padding: 30px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // gap: 30px;
      padding: 0px 30px 30px 30px;
      position: relative;

      .goodie-bag-list-columns {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 30px;
        div {
          width: 100%;
        }
        .goodie-bag-list-column {
          display: flex;
          flex-direction: column;
          gap: 30px;
        }
      }
    }
    .goodie-bag-list .goodie-bag-empty-list,
    // .goodie-bag-list .mix-item-outside,
    .goodie-bag-list .mix-item {
      margin-top: 0px;
    }

    .header-container {
      // height: 189px;
      padding-top: 28px;
      padding-left: 30px;
      padding-bottom: 30px;
      padding-right: 30px;
      display: flex;
      flex-direction: column;

      .page-title-container {
        // margin-bottom: 30px;

        .page-title {
          font-size: 30px;
          line-height: 41px;
        }
      }

      .my-wallet-container{
        cursor: pointer;
        width: 600px;
        align-self: center;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 30px;
        padding-right: 30px;
        min-height: unset;

        .info-container{

          .wallet-title {
            font-size: 20px;
            line-height: 27px;
          }

          .wallet-description{
            font-size: 15px;
            line-height: 22px;
            padding-right: 60px;
          }
        }
      }
    }
  }

}

.main-container{
  .goodie-bag-list-overlay{
    display: none;
  }

  .goodie-bag-list-menu-container {
    display: flex;
    // gap: 16px;
    padding: 18px 16px;
    // background: #F7F7F7;

    .menu-item {
      display: inline-block;
      text-transform: uppercase;
      cursor: pointer;

      .menu-text {
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 700;
        // line-height: 19.07px;
        color: #999999;
      }
    }
    .menu-item.is-active {
      border-left: 2px solid #E2002C;
      .menu-text {
        color: #E2002C;
        margin-left: 4px;
      }
    }
  }
  .goodie-bag-list-menu-container .menu-item:nth-child(n+2) {
    margin-left: 16px;
  }

  .goodie-bag-list {
    // padding: 16px;
    display: flex;
    flex-direction: column;
    // gap: 16px;
    padding: 0px 16px 16px 16px;

    .goodie-bag-list-columns {
      display: none;
    }

    .mix-item-outside {
      width: 467px;
      position: absolute;
      visibility: hidden;
    }
  }
  .goodie-bag-list .goodie-bag-empty-list,
  // .goodie-bag-list .mix-item-outside,
  .goodie-bag-list .mix-item {
    margin-top: 16px;
  }

  .header-container {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;
    padding-bottom: 16px;
    background: #FFFFFF;

    .my-wallet-container{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      min-height: 80px;
      box-shadow: 0px 1px 4px 1px rgba(143, 143, 143, 0.25);
      border-radius: 10px;
      padding-left: 20px;
      padding-right: 16px;
      background-color: #FFFFFF;

      .info-container{
        flex: 1;
        padding-left: 14px;
        padding-right: 14px;

        .wallet-title {
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
          display: flex;
          align-items: center;
          b{
            color: #E2002C;
          }
        }

        .wallet-description{
          font-size: 14px;
          line-height: 20px;
          color: #000000;
        }
      }
    }

    .page-title-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      // margin-bottom: 20px;

      .page-title {
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        color: #000000;
        margin-right: 5px;
      }

      .question-mark-icon:focus,  .question-mark-icon:hover {
        outline-style: none;
      }
    }

  }

}

</style>
