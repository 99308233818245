<template>
  <div class="applicable-store-mobile">
    <applicable-store :applicablestoreslist="applicableStoresList" :pagetype="applicableStoresPageType" :index="position" @pagetypechange="pageTypeChange" />
  </div>
</template>
<script>
import ApplicableStore from '@/components/ApplicableStore'
import utilsMixin from '@/mixins/utilsMixin'

export default {
  name: 'ApplicableStoreForMobile',
  components: {
    ApplicableStore
  },
  mixins: [utilsMixin],
  data () {
    return {
      applicableStoresPageType: 'detail'
    }
  },
  computed: {
    applicableStoresList () {
      const campaignId = this.$route.query.campaignId
      const couponId = this.$route.query.couponId
      const locationId = this.$route.query.locationId
      if (campaignId) {
        const campaginData = this.$store.getters['campaigns/campaignDetail'](campaignId)
        const stores = campaginData && campaginData.detail && campaginData.detail.stores ? campaginData.detail.stores : []
        return this.formatApplicableStores(stores)
      } else if (couponId) {
        const couponData = this.$store.getters['coupons/couponDetail'](couponId)
        console.log(' coupons data', couponData)
        const stores = couponData && couponData.template ? couponData.template.applicable_stores : []
        return this.formatApplicableStores(stores)
      } else if (locationId) {
        const missionLocations = (this.$store.getters['campaigns/missionLocations'] || []).map((item) => item.location || {})
        return this.formatApplicableStores(missionLocations)
      }
      return []
    },
    position () {
      const storeId = this.$route.query.storeId
      const locationId = this.$route.query.locationId
      const queryId = storeId || locationId
      // const queryKey = storeId ? 'id' : 'location'
      let position = this.$route.query.index

      if (storeId) {
        this.applicableStoresList.find((item, index) => {
          // const itemId = queryKey === 'location' ? item.location.id : item.id
          if (parseInt(item.id) === parseInt(queryId)) {
            position = index
            return true
          }
          return
        })
      } else if (locationId) {
        (this.$store.getters['campaigns/missionLocations'] || []).find((item, index) => {
          // const itemId = queryKey === 'location' ? item.location.id : item.id
          if (parseInt(item.id) === parseInt(queryId)) {
            position = index
            return true
          }
          return
        })
      }
      // console.log('@@46-1: ', storeId, locationId)
      // console.log('@@46-1-1: ', this.applicableStoresList)

      console.log('@@46-2: ', position)
      return position
    }
  },
  mounted () {
    this.$store.commit('app/setNavType', 'back_language_menu')
    if (this.$store.getters['app/isMobile']) {
      this.$store.commit('app/hideFooter')
    }
  },
  beforeDestroy () {
    this.$store.commit('app/setNavType', 'logo_language_menu')
    this.$store.commit('app/showFooter')
  },
  methods: {
    pageTypeChange (type) {
      this.applicableStoresPageType = type
    }
  }
}
</script>
