<template>
  <div v-if="isNoData" />
  <div v-else class="gamification-detail-container prize-goodiebag-view" :class="langClass">
    <div class="goodie-bag-content">
      <div class="header-container">
        <div v-if="prizeTags && prizeTags.length" class="prize-tags-container">
          <label v-for="(tag, tagIndex) in prizeTags" :key="tagIndex" class="tag-label is-collapse-1lines">{{ tag }}</label>
        </div>
        <div class="title" :class="langClass">{{ title }}</div>
        <div v-if="description">
          <div ref="descriptionTarget" class="description toastui-editor-contents" :class="{'is-collapse-2lines-html': isCollapseDescription}" v-html="description" />
          <div v-if="isCollapseDescription" class="see-more-button" @click="seeMoreDescription">
            {{ $t('goodiebagSeemore') }}
            <img src="/img/down-arrow-purple.png">
          </div>
        </div>
      </div>
      <div class="campaign-slots-container">
        <goodie-bag-slot-item
          v-for="campaignSlot in goodieBagData.campaign_slots || []"
          :key="`campaign-slot-${campaignSlot.id}`"
          :gamification-campaign-id="gamificationCampaignId"
          :goodie-bag-id="goodieBagId"
          :campaign-slot="campaignSlot"
          :stamp-reward-id="stampRewardId"
          :is-loading-campaign-list="isLoadingGoodieBag || isLoadingCampaignList"
          :campaigns="slotsCampaigns[campaignSlot.id] || []"
        />
      </div>
      <div class="unlock-tips-container">
        <img src="/img/lock-icon-white.png">
        <label>{{ $t('gamificationPrizeItemUnlockTips', {count: stampRewardData.index}) }}</label>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
// import NotFind from '@/components/NotFind'
import utilsMixin from '@/mixins/utilsMixin'
import { isWechatMiniProgram, replaceRouterWithQuery, getChannelName } from '@/utils/utils'
import GoodieBagSlotItem from '@/components/GoodieBagSlotItem'
import backHandlerMixin from '@/mixins/backHandlerMixin'
// import { fetchCampaigns } from '@/helpers/http'
import {
  // sendPageViewAnalytics,
  // sendManualAnalytics,
  // sendAdobeManualAnalytics,
  sendAdobePageViewAnalytics
} from '@/utils/tracking'

export default {
  name: 'GoodieBagSlots',
  components: {
    // NotFind
    GoodieBagSlotItem
  },
  mixins: [utilsMixin, backHandlerMixin],
  data () {
    return {
      isLoadingGoodieBag: true,
      isLoadingCampaignList: true,
      gamificationCampaignId: null,
      stampRewardId: null,
      goodieBagId: null,
      stampRewardData: {},
      isCollapseDescription: null
    }
  },
  computed: {
    isNoData () {
      if (this.goodieBagData.id) {
        return false
      }

      return true
    },
    prizeTags () {
      return (this.stampRewardData && this.stampRewardData.prize_categories || []).filter(v => v && v.trim().length > 0)
    },
    goodieBagData () {
      return this.$store.getters['goodiebags/goodieBagDetail'](this.goodieBagId) || {}
    },
    slotsCampaigns () {
      return this.$store.getters['goodiebags/slotsCampaigns'](this.goodieBagId) || {}
    },
    title () {
      return this.stampRewardData.prize_title || this.goodieBagData.name
    },
    description () {
      return this.stampRewardData.prize_content || this.goodieBagData.short_description
    }
  },
  updated () {
    const height = this.$refs.descriptionTarget?.clientHeight
    if (this.isCollapseDescription === null && height > 14 * 1.6 * 2) {
      this.isCollapseDescription = true
    }

    if (this.isOneTrustDisplay) {
      setTimeout(() => {
        const unlockTipsBottom = (this.isMiniProgram() ? 66 : 0) + this.oneTrustHeight
        window.$('.is-vue-mobile .document-prize-goodie-bag .unlock-tips-container').attr('style', 'bottom: ' + unlockTipsBottom + 'px;')
        const unlockTipsContainerHeight = window.$('.is-vue-mobile .document-prize-goodie-bag .unlock-tips-container')[0] && window.$('.is-vue-mobile .document-prize-goodie-bag .unlock-tips-container')[0].clientHeight || 0
        window.$('.is-vue-mobile .document-prize-goodie-bag .footer').attr('style', 'margin-bottom: ' + unlockTipsContainerHeight + 'px !important;')
      }, 500)
    } else {
      // setTimeout(() => {
      //   console.log('???unlock3', this.oneTrustHeight)
      //   const unlockTipsBottom = this.isMiniProgram() ? 66 : 0
      //   window.$('.is-vue-mobile .document-prize-goodie-bag .unlock-tips-container').attr('style', 'bottom: ' + unlockTipsBottom + 'px;')
      //   const unlockTipsContainerHeight = window.$('.is-vue-mobile .document-prize-goodie-bag .unlock-tips-container')[0] && window.$('.is-vue-mobile .document-prize-goodie-bag .unlock-tips-container')[0].clientHeight || 0
      //   window.$('.is-vue-mobile .document-prize-goodie-bag .footer').attr('style', 'margin-bottom: ' + unlockTipsContainerHeight + 'px !important;')
      // }, 500)
    }
  },
  mounted () {
    this.$store.commit('app/setNavType', 'back_language_menu')
    console.log('113: ', this.$route)
    const { gamificationCampaignId, stampRewardId, goodieBagId } = this.$route.query
    this.gamificationCampaignId = gamificationCampaignId
    this.stampRewardId = stampRewardId
    this.goodieBagId = goodieBagId

    if (!this.goodieBagData.id) {
      this.getGoodieBagDetail()
    } else {
      this.isLoadingGoodieBag = false
    }

    const gamificationData = this.$store.getters['gamification/gamificationDetail'](gamificationCampaignId) || {}
    this.stampRewardData = gamificationData.stamp_rewards?.find((stampReward) => parseInt(stampReward.id) === parseInt(stampRewardId)) || {}
    if (!this.stampRewardData.id) {
      this.getStampRewardDetail()
    }

    if (!Object.keys(!this.slotsCampaigns).length) {
      this.getGoodieBagCampaigns()
    }
    setTimeout(() => {
      sendAdobePageViewAnalytics(this.$route, this.$i18n.locale, this.$store, 'game-default', { gamificationId: this.gamificationCampaignId, goodieBagId: this.goodieBagId, rewardId: this.stampRewardId })
    }, 3000)
  },
  methods: {
    isMiniProgram () {
      return isWechatMiniProgram()
    },
    async getGoodieBagDetail () {
      let notFound = false
      const isNotAvaliableContent = {}
      try {
        const params = {
          is_gamification: 'true'
        }
        const queryString = new URLSearchParams(params).toString()
        await this.$store.dispatch('goodiebags/updateGoodieBag', { goodieBagId: this.goodieBagId, queryString })
      } catch (error) {
        const { data } = error.response || {}
        const { detail } = data || {}
        console.log('371--- error', error.response, detail)
        notFound = detail === 'Not found.'
      }
      this.isLoadingGoodieBag = false
      if (notFound) {
        replaceRouterWithQuery(this.$router, { name: 'page-not-found', query: { ...isNotAvaliableContent, from: 'GOODIE_BAG' }})
      }
    },
    async getStampRewardDetail () {
      try {
        const { data } = await axios.get(`/gamification_rewards/${this.stampRewardId}`)
        this.stampRewardData = data
        console.log('stampRewardData', data)
      } catch (error) {
        console.log('error', error)
      }
    },
    async getGoodieBagCampaigns () {
      const params = {
        lang: this.$i18n.locale,
        sort: ['new_is_out_of_stock', '-is_featured', '-id'],
        type_in: ['CASH_VOUCHER_CAMPAIGN_FOR_GOODIE_BAG', 'GIFT_CAMPAIGN_FOR_GOODIE_BAG'],
        channel: getChannelName(),
        goodie_bag: this.goodieBagId,
        group_slots: 'true',
        is_gamification: 'true'
      }

      try {
        const queryString = new URLSearchParams(params).toString()
        const { data } = await axios.get(`/campaigns?${queryString}`)
        const slotsCampaigns = {}
        if (data && data.length) {
          data.forEach((campaign) => {
            campaign.goodie_bag_campaign_slots?.forEach((goodie_bag_campaign_slot) => {
              if (goodie_bag_campaign_slot.id in slotsCampaigns) {
                slotsCampaigns[goodie_bag_campaign_slot.id].push(campaign)
              } else {
                slotsCampaigns[goodie_bag_campaign_slot.id] = [campaign]
              }
            })
          })
        }
        this.$store.commit('goodiebags/updateSlotsCampaigns', { goodieBagId: this.goodieBagId, slotsCampaigns })
        console.log('slotsCampaigns', slotsCampaigns, data, slotsCampaigns[3301])
      } catch (error) {
        console.log('error', error)
      }
      this.isLoadingCampaignList = false
    },
    seeMoreDescription () {
      this.isCollapseDescription = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/common.scss';
.prize-goodiebag-view {
  background-color: #F4F4F4;
  padding: 0px;
  .goodie-bag-content {
    .header-container {
      padding: 16px;
      background-color: #FFFFFF;
      .prize-tags-container {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 4px;

        .tag-label {
          width: fit-content;
          height: fit-content;
          // font-family: Manrope;
          font-size: 12px;
          font-weight: 700;
          line-height: 14.4px;
          color: #FF1700;

          border: 1px solid #FF1700;
          border-radius: 4px;
          padding: 4px 8px;
          margin-right: 8px;
          margin-bottom: 8px;
        }
      }
      .description {
        margin-top: 12px;

        // font-family: Manrope;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        color: #3B002B;
      }
    }
    .campaign-slots-container {
      padding-top: 16px;
      background-color: #F4F4F4;
    }
    .unlock-tips-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 16px;
      background: #000000CC;
      width: 100%;
      position: fixed;
      bottom: 0px;
      z-index: 2;
      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
      label {
        // font-family: Manrope;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
        color: #FFFFFF;
      }
    }
  }
}

.is-vue-desktop .prize-goodiebag-view {
  .goodie-bag-content {
    .header-container {
      .prize-tags-container {
        .tag-label {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.is-vue-mobile {
  .document-prize-goodie-bag .footer {
    margin-bottom: 57.59px !important;
  }
}
.document-prize-goodie-bag .header-container .description,
.document-prize-goodie-bag .header-container .description * {
  color: #3B002B !important;
}
</style>
