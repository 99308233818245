<template>
  <div v-if="isNoData" class="no-data">
    <not-find v-show="!isLoading"/>
  </div>
  <div v-else class="gamification-detail-container other-info-detail-view" :class="`${isTutorials ||isMissionTutorials ? 'is-tutorials':''} ${langClass}`">
    <label class="title" :class="langClass">{{ info.title }}</label>
    <img v-if="!(isTutorials || isMissionTutorials)" class="info-image" :src="info.image_url"/>
    <div class="content toastui-editor-contents" v-html="info.content"></div>
    <div v-if="info.tutorials && info.tutorials.length || !isRewardInformation" class="tutorials-steps">
        <div v-for="(item, index) in info.tutorials" :key="index" class="step">
            <img :src="item.image_url"/>
            <div class="content toastui-editor-contents" v-html="item.content"></div>
        </div>
    </div>
    <div v-if="isRewardInformation" class="button-container">
      <button class="common-button" @click="gotoMissionSection">{{ $t('gamificationRewardDetailBtn') }}</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { asyncGetGamificationCampaignDetail } from '@/helpers/http'
import { sendAdobeManualAnalytics } from '@/utils/tracking'
import NotFind from '@/components/NotFind'
import { pushRouterWithQuery } from '@/utils/utils'

export default {
  name: 'OtherInfoPage',
  components: {
    NotFind
  },
  data () {
    return {
      gamificationCampaignId: null,
      missionId: null,
      infoId: null,
      isLoading: true
    }
  },
  computed: {
    isNoData () {
      console.log('@@40: ', this.info)
      if (this.info?.title) {
        return false
      }
      console.log('@@40---1: ')
      return true
    },
    langClass () {
      if (this.$i18n.locale === 'zh-s') {
        return 'zhs'
      } else if (this.$i18n.locale === 'zh-t') {
        return 'zht'
      }
      return 'en'
    },
    isTutorials () {
      return this.$router.history.current.path.indexOf('tutorials') >= 0
    },
    isMissionTutorials () {
      return this.$router.history.current.path.indexOf('earn-tutorial') >= 0
    },
    isRewardInformation () {
      return this.$router.history.current.path.indexOf('reward-infomation') >= 0
    },
    detail () {
      let result = null
      if (this.isMissionTutorials) {
        result = this.$store.getters['gamification/missionDetail'](this.gamificationCampaignId, this.missionId)
      } else if (this.gamificationCampaignId) {
        result = this.$store.getters['gamification/gamificationDetail'](this.gamificationCampaignId)
        console.log('616-306------result: ', result)
      }
      if (!result) {
        result = null
      }

      return result
    },
    info () {
      let info = {}
      if (this.detail) {
        if (this.isTutorials || this.isMissionTutorials) {
          info = {
            title: this.detail.tutorial_title,
            english_name: this.detail.english_tutorial_title,
            content: this.detail.tutorial_content,
            tutorials: this.detail.tutorials
          }
          const array = this.detail.tutorials
          if (array?.length > 0) {
            info.tutorials = array.sort((a, b) => a.index - b.index)
          }
        } else if (this.isRewardInformation) {
          info = this.detail.reward_informations?.filter((item) => parseInt(item.id) === parseInt(this.infoId))?.[0]
        } else {
          info = this.detail.other_informations?.filter((item) => parseInt(item.id) === parseInt(this.infoId))?.[0]
          if (info && !info?.image_url) {
            if (info.index === 1) {
              info.image_url = '/img/eligible-default.png'
            } else if (info.index === 2) {
              info.image_url = '/img/how-to-participate-default.png'
            } else if (info.index === 3) {
              info.image_url = '/img/faq-default.png'
            }
          }
        //   const array = this.detail.other_informations
        //   const sortedInfoes = array?.sort((a, b) => a.id - b.id)
        //   console.log(sortedInfoes)
        //   const index = this.detail.other_informations.findIndex((item) => parseInt(item.id) === parseInt(this.infoId))
        //   if (index >= 0) {
        //     info = sortedInfoes[index]
        //   }
        }
      }
      console.log('52: ', info)
      return info
    }
  },
  async mounted () {
    const { gamificationCampaignId, infoId, missionId } = this.$route.query

    this.gamificationCampaignId = gamificationCampaignId
    this.infoId = infoId
    this.missionId = missionId
    console.log('@@@@26: ', this.detail, this.isMissionTutorials)
    if (!this.detail) {
      this.isLoading = true
      if (this.isMissionTutorials) {
        this.getMissionDetail()
      } else {
        this.getGamificationDetail()
      }
    } else {
      this.isLoading = false
    }
    // this.isLanding = this.$router.history.current.path.indexOf('landing-gamification') >= 0
    // this.getDetail(this.gamificationCampaignId)
    this.$store.commit('app/setNavType', 'back_language_menu')
  },
  watch: {
    'detail': function (newVal, oldVal) {
      if (newVal?.id && !oldVal?.id) {
        let pageName = `other information|${this.info.english_title}`
        let eventName = 'other information'
        let extra = { gamificationId: this.gamificationCampaignId }
        if (this.isTutorials) {
          pageName = 'game|tutorial|game'
          eventName = 'tutorial'
        } else if (this.isRewardInformation) {
          pageName = `reward info|${this.info.english_title}`
          eventName = 'reward information'
        } else if (this.isMissionTutorials) {
          pageName = 'game|tutorial|mission'
          eventName = 'tutorial'

          extra = { missionId: this.missionId }
        }

        // extra.pageName = `${pageName}|${newVal.english_name}`
        extra.pageName = pageName

        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, `game|${eventName}`, extra)
      }
    }
  },
  methods: {
    async getMissionDetail () {
      await axios
        .get(`/gamification_missions/${this.missionId}`)
        .then(response => {
          console.log('@@26-response---', response)
          const data = response.data

          this.$store.commit('gamification/updateMissionList', {
            gamificationCampaignId: this.gamificationCampaignId,
            missionId: this.missionId,
            detail: data
          })
        })
        .catch(error => {
          console.log('@@26-errro', error)
        })
        .finally(() => {
          console.log('@@40-response---')
          this.isLoading = false
        })
    },
    async getGamificationDetail () {
      // await asyncGetGamificationCampaignDetail(this.gamificationCampaignId)
      // console.log('@@40-response---')
      // this.isLoading = false
      // console.log('@@40-response---11')

      try {
        await asyncGetGamificationCampaignDetail(this.gamificationCampaignId)
      } catch (err) {
        console.log('@@40-response---', err)
      }

      this.isLoading = false
    },
    gotoMissionSection () {
      pushRouterWithQuery(this.$router, { path: 'gamification-detail', query: this.$route.query, hash: 'earn-reward' })
    }
  }
}
</script>
<style scoped lang='scss'>
@import '@/assets/common.scss';

.other-info-detail-view {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 40px;

  .title {
    font-weight: 700;
  }

  &.is-tutorials {
    gap: unset;
    .content {
        margin-top: 16px;
        margin-bottom: 44px;
    }
  }

  .info-image {
    width: 343px;
    align-self: center;
  }

  .content {
    margin-top: 12px;
    color: var(--Temple-St-Dusk-Maroon) !important;
    margin-bottom: 0px;
    width: 100%;
    font-size: 14px;
  }

  .tutorials-steps {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      img {
        width: 100%;
        // height: 200px;
      }
    }
  }

  .button-container {
    margin-bottom: 40px;
    button {
      margin: auto;
      height: 44px;
      background-color: var(--Junk-Boat-Red);
      color: #FFFFFF;
    }
  }
}

.is-vue-desktop .gamification-detail-container.other-info-detail-view {
  padding: 32px;
  .title {
    font-size: 56px !important;
  }

  .tutorials-steps {
    flex-wrap: wrap;

    .step {
      width: calc(50% - 8px);
      img {
        width: auto;
        height: 200px;
      }
    }
  }

  .content {
    font-size: 16px
  }
}
</style>
<style lang='scss'>
@import '@/assets/gamification-font.scss';
.other-info-detail-view {
  .content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--Temple-St-Dusk-Maroon) !important;
      border: none;

      strong {
        color: var(--Temple-St-Dusk-Maroon) !important;
      }
    }

    ol {
      padding-left: 16px
    }

    ol > li::before {
      margin-left: -28px;
      content: '';
    }
    p {
      br.ProseMirror-trailingBreak {
        display: none;
      }
    }
  }
  .content, .content * {
    color: var(--Temple-St-Dusk-Maroon) !important;
  }
}
</style>
