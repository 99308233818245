var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"map-view-container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isUsingBaiduMap),expression:"isUsingBaiduMap"}],attrs:{"id":"baidu_map"}},[_c('baidu-map',{attrs:{"id":"baidu-container","ak":_vm.baiduMapKey,"center":_vm.centerPoint,"zoom":_vm.zoom,"scroll-wheel-zoom":true},on:{"ready":_vm.handler,"moveend":_vm.syncCenterAndZoom,"zoomend":_vm.syncCenterAndZoom}},[_c('bm-navigation',{attrs:{"anchor":"BMAP_ANCHOR_TOP_RIGHT"}}),_c('img',{staticClass:"locate-icon",attrs:{"src":"/img/locate.png"},on:{"click":_vm.getCurrentLocation}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isUsingBaiduMap),expression:"!isUsingBaiduMap"}],attrs:{"id":"google_map"}},[_c('gmap-map',{ref:"googleMapRef",attrs:{"center":{ lng: 114.169361,lat: 22.319304 },"zoom":17,"options":{
        zoomControl: true,
        zoomControlOptions: {
          position: 'RIGHT_TOP',
        },
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false
      }}},[_c('img',{ref:"locateIconRef",staticClass:"locate-icon",attrs:{"src":"/img/locate.png"},on:{"click":_vm.getCurrentLocation}}),(_vm.targetPoint)?_c('gmap-marker',{attrs:{"position":_vm.targetPoint,"clickable":false,"draggable":false,"icon":'/img/location.2x.png'}}):_vm._e(),(_vm.currentPoint)?_c('gmap-marker',{attrs:{"position":_vm.currentPoint,"clickable":false,"draggable":false,"icon":'/img/location.2x.png'}}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }