const isDev = () => {
  // use port to check
  /*
  if (window.location.host.endsWith('-demo.com')) {
    return true
  }
  */

  if (window.location.host.startsWith('localhost')) {
    return true
  }

  if (process.env.VUE_APP_IS_DEBUG) {
    return true
  }
  /*
  if (window.location.host.includes('-uat')) {
    return true
  }
  */

  return false
}

const isShowVConsole = () => {
  if (!isWechatMiniProgram()) {
    return false
  }

  if (process.env.VUE_APP_SERVER_ENVIR === 'UAT') {
    return true
  }

  console.log('@667', window.location.search)
  const urlParams = new URLSearchParams(window.location.search)
  const showVConsole = urlParams.get('show_vconsole')
  console.log('@667b', !!showVConsole)
  return !!showVConsole
}

const isWechatMiniProgram = () => {
  var ua = window.navigator.userAgent.toLowerCase()
  var isWXWork = ua.match(/wxwork/i) && ua.match(/wxwork/i)[0] === 'wxwork'
  var isWeixinMiniProgram = !isWXWork && ua.match(/miniprogram/i) && ua.match(/miniprogram/i)[0] === 'miniprogram'
  return isWeixinMiniProgram
}

const isWechatBrowser = () => {
  if (isWechatMiniProgram()) {
    return false
  }

  var ua = window.navigator.userAgent.toLowerCase()
  var isWXWork = ua.match(/wxwork/i) && ua.match(/wxwork/i)[0] === 'wxwork'
  var isWeixin = !isWXWork && ua.match(/micromessenger/i) && ua.match(/micromessenger/i)[0] === 'micromessenger'

  return isWeixin
}

const getChannelName = () => {
  if (isWechatMiniProgram()) {
    return 'WeChat Mini Program'
  }

  return ''
}

const setI18nLocale = (lang, i18n) => {
  if (lang !== i18n.locale) {
    i18n.locale = lang
  }
}

const setupDefaultQuery = (currentQuery, newQuery) => {
  if (currentQuery && currentQuery.wf) {
    const { wf } = currentQuery
    if (!newQuery) {
      newQuery = {}
    }
    if (!newQuery.wf) {
      Object.assign(newQuery, { wf })
    }
  }
  if (currentQuery && currentQuery.aaid) {
    const { aaid } = currentQuery
    if (!newQuery) {
      newQuery = {}
    }
    if (!newQuery.aaid) {
      Object.assign(newQuery, { aaid })
    }
  }
  if (currentQuery && currentQuery.adobe_mc) {
    const { adobe_mc } = currentQuery
    if (!newQuery) {
      newQuery = {}
    }
    if (!newQuery.adobe_mc) {
      Object.assign(newQuery, { adobe_mc })
    }
  }

  return newQuery
}

const setupDefaultQueryString = (currentRoute, queryString) => {
  let newQueryString = queryString

  console.log('@87', currentRoute)
  if (currentRoute && currentRoute.query && currentRoute.query.wf) {
    const { wf } = currentRoute.query

    console.log('@87a', wf)
    if (newQueryString.includes('?')) {
      newQueryString += '&wf=' + wf
    } else {
      newQueryString += '?wf=' + wf
    }
  }
  if (currentRoute && currentRoute.query && currentRoute.query.aaid) {
    const { aaid } = currentRoute.query

    console.log('@87a', aaid)
    if (newQueryString.includes('?')) {
      newQueryString += '&aaid=' + aaid
    } else {
      newQueryString += '?aaid=' + aaid
    }
  }
  if (currentRoute && currentRoute.query && currentRoute.query.adobe_mc) {
    const { adobe_mc } = currentRoute.query

    console.log('@87a', adobe_mc)
    if (newQueryString.includes('?')) {
      newQueryString += '&adobe_mc=' + adobe_mc
    } else {
      newQueryString += '?adobe_mc=' + adobe_mc
    }
  }

  console.log('@87b', newQueryString)
  return newQueryString
}

const updateRouteWithQuery = (action, router, newPathInfo) => {
  const { currentRoute } = router
  console.log('@71', router, currentRoute)

  const wf = currentRoute && currentRoute.query ? currentRoute.query.wf : ''

  if (wf && (!newPathInfo.query || !newPathInfo.query.wf)) {
    console.log('@71b')
    newPathInfo.query = setupDefaultQuery(currentRoute.query, newPathInfo.query)
    console.log('@71b2', newPathInfo.query)
  }

  const aaid = currentRoute && currentRoute.query ? currentRoute.query.aaid : ''

  if (aaid && (!newPathInfo.query || !newPathInfo.query.aaid)) {
    console.log('@71b')
    newPathInfo.query = setupDefaultQuery(currentRoute.query, newPathInfo.query)
    console.log('@71b2', newPathInfo.query)
  }

  const adobe_mc = currentRoute && currentRoute.query ? currentRoute.query.adobe_mc : ''

  if (adobe_mc && (!newPathInfo.query || !newPathInfo.query.adobe_mc)) {
    console.log('@71b')
    newPathInfo.query = setupDefaultQuery(currentRoute.query, newPathInfo.query)
    console.log('@71b2', newPathInfo.query)
  }

  console.log('@71c', newPathInfo, action)
  if (action === 'push') {
    router.push(newPathInfo)
  } else if (action === 'replace') {
    router.replace(newPathInfo)
  } else {
    throw new Error('Unknown action: ' + action)
  }
}
const pushRouterWithQuery = (router, newPathInfo) => {
  updateRouteWithQuery('push', router, newPathInfo)
}

const replaceRouterWithQuery = (router, newPathInfo) => {
  updateRouteWithQuery('replace', router, newPathInfo)
}

const getGoodieBagCampaignSlot = (goodiebagDetail, slotId) => {
  let campaignSlot = null
  if (slotId && goodiebagDetail) {
    const recordDetail = goodiebagDetail
    const campaignSlots = recordDetail.campaign_slots || []
    campaignSlots.forEach(item => {
      if (item.id.toString() === slotId.toString()) {
        campaignSlot = item
        return
      }
    })
  }
  return campaignSlot
}

const convertToQueryString = (params) => {
  const result = new URLSearchParams(params).toString()
  return result
}

const getCampaignAPIPrefix = () => {
  if (process.env.VUE_APP_RECACPTCHA_V3_KEY) {
    return `/v2`
  }
  return ``
}

const LOCATION_AREA_ID = 'L_AREA'
const LOCATION_DISTRICT_ID = 'L_DISTRICT'

export {
  isDev,
  isWechatMiniProgram,
  isWechatBrowser,
  getChannelName,
  setI18nLocale,
  getGoodieBagCampaignSlot,
  convertToQueryString,
  getCampaignAPIPrefix,
  LOCATION_AREA_ID,
  LOCATION_DISTRICT_ID,
  isShowVConsole,
  pushRouterWithQuery,
  replaceRouterWithQuery,
  setupDefaultQuery,
  setupDefaultQueryString
}
