<template>
  <div>
    <el-dialog
      :visible.sync="showGrantGPSErrorDialog"
      class="fail-to-grant-gps"
      width="316px"
      center
      :show-close="false"
      custom-class="grant-gps-error-dialog"
      @close="closeGrantGPSErrorDialog()"
    >
      <div slot="title" class="fail-to-grant-gps-title">{{ $t('grantGPSErrorTitle') }}</div>
      <div class="fail-to-grant-gps-content ">{{ $t('grantGPSErrorContent') }}</div>

      <span slot="footer" class="dialog-footer">
        <el-button
          tag="button"
          type="button"
          class="el-button el-button--primary primary-button"
          @click="closeGrantGPSErrorDialog()"
        >
          {{ $t('goodiebagOk') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'GrantGPSErrorDialog',
  props: {
    showGrantGPSErrorDialog: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeGrantGPSErrorDialog () {
      this.$emit('setShowGrantGPSErrorDialog', false)
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/assets/carousel.scss';

.fail-to-grant-gps{
  .fail-to-grant-gps-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #000000;
    padding: 24px 24px 0;
  }
  .fail-to-grant-gps-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    margin-top: 20px;
    padding: 0 24px;
  }
}

.is-vue-mobile {
  .wide-code .el-dialog.grant-gps-error-dialog .scan-code-success-bar-code{
    padding-bottom: 34px;
  }
}
</style>

<style lang='scss'>
.grant-gps-error-dialog.el-dialog--center{
  border-radius: 10px;
  .el-dialog__body {
    padding: 0px;
  }
}

.wide-code .grant-gps-error-dialog{
  width: calc(100% - 6px) !important;
  .scan-code-success-bar-footer{
    width: 100%;
    text-align: center;
  }
}

.el-dialog.desktop-grant-gps-error-dialog{
  margin-top: auto !important;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.40);
  .el-dialog__body{
    padding-top: 0px;
  }
}

.el-dialog.grant-gps-error-dialog {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;

  .el-dialog__header{
    padding: 0px;
  }

  .el-dialog__footer {
    padding: 20px 24px 24px;

    .dialog-footer {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .el-button{
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        background-color: #E2002C;
        color: #ffffff;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px 30px 10px;
        gap:10px;
        border-radius: 50px;
      }
    }
  }
}
</style>

