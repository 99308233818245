<template>
    <div class="goodie-bag-empty-list">
        <div class="empty-reward-image">
        <img
            class="empty-reward-icon"
            :src="icon"
        >
        </div>
        <div class="empty-reward-title" v-html="title"></div>
        <div v-if="linkUrl" class="empty-reward-desc-link" v-html="description"></div>
        <div v-else class="empty-reward-desc" v-html="description"></div>
        <!-- <div class="empty-reward-title">There’s no stamp campaigns available yet</div>
        <div class="empty-reward-desc">Please looking forward to our coming campaign</div> -->
    </div>
</template>

<script>

export default {
  name: 'GoodieBagGamificationNoData',
  props: {
    icon: {
      type: String,
      default: '/img/empty_gamifications_image.png'
    },
    title: {
      type: String,
      default: ''
    },
    linkUrl: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.is-vue-desktop {
    .goodie-bag-empty-list {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;

      .empty-reward-image {
        height: 120px;
        // padding-left: 27px;
        align-self: center;
        img {
            height: 120px;
        }
      }

      .empty-reward-title {
        margin-top: 20px;

        color: var(--Black, #000);
        text-align: center;

        /* Desktop_Title1 */
        font-family: Open Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .empty-reward-desc-link {
        margin-top: 6px;

        color: #E2002C;
        text-align: center;
        font-family: Open Sans;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 160% */
        text-decoration-line: underline;
      }
      .empty-reward-desc {
        margin-top: 6px;

        color: #666;
        text-align: center;
        font-family: Open Sans;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 160% */
      }
    }
}
.goodie-bag-empty-list {
    padding-left: 30px;
    padding-right: 30px;
    // padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .empty-reward-image {
      height: 91px;
    //   padding-left: 27px;
      align-self: center;
      img {
        height: 91px;
      }
    }

    .empty-reward-title {
      margin-top: 24px;

      color: var(--Black, #000);
      text-align: center;

      /* Desktop_Title1 */
      font-family: Open Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .empty-reward-desc-link {
      margin-top: 6px;

      color: #E2002C;
      text-align: center;
      font-family: Open Sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 160% */
      text-decoration-line: underline;
    }
    .empty-reward-desc {
        margin-top: 12px;

        color: #666;
        text-align: center;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 160% */
    }
}
</style>
