<template>
  <div v-if="participateDirectId" class="loading-view">
    <img src="/img/loading.gif"/>
    <div id="robot" />
    </div>
  <div
    v-else-if="isNoData"
    class="no-data"
  >
    <not-find />
  </div>
  <div v-else class="gamification-detail-container gamification-campaign" :class="langClass" :style="{marginBottom: isLanding ? '70px' : isMobile ? '0px' : '16px'}">
    <agile
      v-if="photos && photos.length"
      :slides-to-show="1"
      class="carousel detail"
      :autoplay="photos.length>1"
      :nav-buttons="isMobile && photos.length && photos.length>1"
      :dots="photos.length && photos.length>1"
      :infinite="photos.length && photos.length>1"
    >
      <div v-for="(item, index) in photos" :key="index" class="slide">
        <div class="slide-image" :style="{backgroundImage: 'url('+item+')'}" />
      </div>
      <template slot="prevButton"><div class="action action-prev"><img :data-aid="'offer_detail_image_view'" src="/img/carousel_left.png"></div></template>
      <template slot="nextButton"><div class="action action-next"><img :data-aid="'offer_detail_image_view'" src="/img/carousel_right.png"></div></template>
    </agile>
    <div class="gamification-detail-container-main">
      <div class="detail-stamps">
        <div class="detail-info">
          <div v-if="gamificationLabels && gamificationLabels.length" class="gamification-labels">
            <label v-for="(gamificationLabel, labelIndex) in gamificationLabels" :key="`gamification-label-${labelIndex}`">
              {{ gamificationLabel }}
            </label>
          </div>
          <label class="title" :class="langClass">{{ detail.name }}</label>
          <div v-if="ValidTil" class="gamification-valid">
            <label v-if="ValidTil" class="active-period">
              {{ ValidTil }}
              <!-- <el-tooltip
                class="item"
                :content="$t('gamificationValidPeriodTips')"
                placement="bottom"
              >
                <img
                  width="20"
                  height="20"
                  class="question-mark-icon"
                  src="/img/question-icon.png"
                >
              </el-tooltip> -->
              <img
                width="18"
                height="18"
                class="question-mark-icon"
                src="/img/question-icon.png"
                @mouseover="toShowCustomTooltips(true)"
                @focus="toShowCustomTooltips(true)"
                @mouseleave="toShowCustomTooltips(false)"
              >
            </label>
            <custom-tooltip
              :visible="showGamificationTips"
              class="gamification-tooltip-container"
              :show-hover="true"
              :content="$t('gamificationValidPeriodTips')"
              :custom-tooltip-slot-element="customTooltipSlotElement"
              :custom-tooltip-reference-element="customTooltipReferenceElement"
              :space-between="0"
            />
          </div>

          <div v-if="detail.short_description">
            <div class="description toastui-editor-contents" ref="descriptionTarget" :class="{'is-collapse-2lines-html': isCollapseDescription}" v-html="detail.short_description" />
            <div v-if="isCollapseDescription" class="see-more-button" @click="seeMore">
              {{ $t('myWalletSeeMore') }}
              <img src="/img/down-arrow-purple.png">
              <!-- <div class="arrow down"/> -->
            </div>
          </div>

          <div class="see-more-button tutorial" @click="gotoTutorial">
            <img
            width="20"
            height="20"
            class="question-mark-icon"
            src="/img/question-icon.png"
         />
            {{ $t('gamificationStampSectionTutorial') }}
            <img src="/img/right-arrow-purple.png">
          </div>
        </div>

        <stamp-list
          v-if="detail.stamp_count > 0"
          :gamification-data="detail"
          :show-toturial="true"
          :show-see-more="true"
          :started-journey="detail.is_public || !!detail.gamification_campaign_participation_record_id"
          :disabled="!canStartJourney"
          @gotoStampPrizeList="gotoStampPrizeList"
          @startJourney="startGamification"
        />
      </div>

      <div v-if="detail.mission_section_title" ref="missionSectionRef" class="header section-header mission-section-header">
        <img
          srcset="/img/mission_section_icon.1x.png, /img/mission_section_icon.2x.png 2x, /img/mission_section_icon.3x.png 3x"
          src="/img/mission_section_icon.2x.png"
        >
        <div>
          <label class="title subtitle" :class="langClass">{{ detail.mission_section_title }}</label>
          <label v-if="detail.mission_section_description">{{ detail.mission_section_description }}</label>
        </div>
        <div>
          <!-- See all prize
          <img class="right" src="/img/right-arrow-purple.png"> -->
        </div>
      </div>
      <div ref="earnTargetRef" name="earn" class="map-view mission-section-container">
        <!-- <div class="cover-image">
          <img v-show="missionImageLoaded" ref="earnImageRef" id="idEarnCover" class="earn-cover" @load="onloadMissionImage" :src="isMobile ? (detail.mobile_photo_for_mission_section_url || detail.cover_photo_for_mission_section_url) : detail.cover_photo_for_mission_section_url"/>
          <div class="cover-overview" />
        </div>

        <div class="cover-content cover-overview" :class="{'only-one-mission': isOnlyOneMission}">
          <div>
            <div class="earn-content">
              <label class="title subtitle" :class="langClass">{{ detail.mission_section_title }}</label>
              <label class="sub-header">{{ detail.mission_section_subtitle }}</label>
              <label class="content">{{ detail.mission_section_description }}</label>
            </div>
          </div>
        </div> -->

        <img v-show="missionImageLoaded" ref="earnImageRef" id="idEarnCover" class="earn-cover" @load="onloadMissionImage" :src="isMobile ? (detail.mobile_photo_for_mission_section_url || detail.cover_photo_for_mission_section_url) : detail.cover_photo_for_mission_section_url"/>
        <div class="cover-overview" :class="{'only-one-mission': isOnlyOneMission}">
          <div>
            <div class="earn-content">
              <!-- <label class="title subtitle" :class="langClass">{{ detail.mission_section_title }}</label> -->
              <label class="sub-header">{{ detail.mission_card_title }}</label>
              <label class="content">{{ detail.mission_card_subtitle }}</label>
            </div>
            <button v-show="false">
              <img src="/img/mapviewIcon.png">
              <span>{{ $t('gamificationMissionSectionViewMap') }}</span>
            </button>
          </div>
        </div>
      </div>

      <div v-if="missionList && missionList.length > 0" ref="missionsTargetRef" :class="['mission-cats-view', isVerticalCard ? 'vertical-mode' : 'horizontal-mode', {'only-one-mission': isOnlyOneMission}]" :style="missionStyle">
        <div v-for="(item, index) in missionList" :key="index" :class="['mission-cat-area', {'disabled': item.isExpired}]" @click="gotoMission(item)">
          <div v-if="item.isExpired" class="item-tip-container">
            <div class="coupon-offer-label">
              <img src="@/assets/images/offer-label-shadow.png" class="coupon-offer-label-shadow">
              <div class="coupon-offer-label-content">
                <div class="coupon-offer-label-font">
                  <div class="coupon-offer-label-font-item">{{ $t('myWalletExpired') }}</div>
                </div>
                <div class="coupon-offer-label-right-image" />
                <!--
                <img src="@/assets/images/offer-label-right.png" class="coupon-offer-label-right-image">
                -->
              </div>
            </div>
          </div>
          <div class="mission-content">
            <div class="mission-border">
              <div class="misson-cat-name is-collapse-3lines">
                <!-- <img :src="item && item.iconImage"/> -->
                <label>{{ item && item.name }}</label>
              </div>
              <div v-if="!isVerticalCard">
                <button class="common-button" @click="gotoMission(item)">
                  <img :src="item && item.iconImage">
                  <!-- {{ item && $t(item.redeemButtonName) }}{{ item.isNotReachStartTime ? getDateString(item.start_date, 'D MMM YYYY'): '' }}{{ item.isExpired ? getDateString(item.end_date, 'D MMM YYYY'): '' }} -->
                  {{ getRewardButtonText(item) }}
                </button>
                <div class="info-tips">
                  <div v-if="item.show_statement_wording_for_stamp_limit && item.statement_wording_for_stamp_limit" class="text per-time-tips is-collapse-2lines-html" v-html="item.show_statement_wording_for_stamp_limit && item.statement_wording_for_stamp_limit && item.statement_wording_for_stamp_limit.replaceAll(/\{X( stamp\(s\)|个印花|個印花)\}/g, `<span>${item.per_action_stamp_reward_quantity}${$t('gamificationStamps2')}</span>`)" />
                  <div v-if="item.show_statement_wording_for_stamp_limit && item.statement_wording_for_stamp_limit && (item.per_head_limit || item.per_head_limit === 0)" class="line"></div>
                  <div v-if="item.per_head_limit || item.per_head_limit === 0" class="text max-tips">{{ $t('gamificationMissionMaxTips', {count: item.per_head_limit, missionType: $t(item.mission_type)}) }}</div>
                </div>
              </div>
            </div>
            <img v-if="isMobile ? (item.mobile_photo_url || item.cover_photo_url) : item.cover_photo_url" class="cover-image" :src="isMobile ? item.mobile_photo_url || item.cover_photo_url : item.cover_photo_url">
            <div v-if="!(isMobile ? (item.mobile_photo_url || item.cover_photo_url) : item.cover_photo_url)" class='default-image'></div>
          </div>
          <div v-if="isVerticalCard">
            <button class="common-button" @click="gotoMission(item)">
              <img :src="item && item.iconImage">
              <!-- {{ item && $t(item.redeemButtonName) }}{{ item.isNotReachStartTime ? getDateString(item.start_date, 'D MMM YYYY'): '' }}{{ item.isExpired ? getDateString(item.end_date, 'D MMM YYYY'): '' }} -->
              {{ getRewardButtonText(item) }}
            </button>
            <div class="info-tips">
              <div v-if="item.show_statement_wording_for_stamp_limit && item.statement_wording_for_stamp_limit" class="text per-time-tips is-collapse-2line-html" v-html="item.show_statement_wording_for_stamp_limit && item.statement_wording_for_stamp_limit && item.statement_wording_for_stamp_limit.replaceAll(/\{X( stamp\(s\)|个印花|個印花)\}/g, `<span>${item.per_action_stamp_reward_quantity}${$t('gamificationStamps2')}</span>`)" />
              <div v-if="item.show_statement_wording_for_stamp_limit && item.statement_wording_for_stamp_limit && (item.per_head_limit || item.per_head_limit === 0)" class="line"></div>
              <div v-if="item.per_head_limit || item.per_head_limit === 0" class="text max-tips">{{ $t('gamificationMissionMaxTips', {count: item.per_head_limit, missionType: $t(item.mission_type)}) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="filler-mission" />

      <div v-if="rewardInformations.length > 0" :class="['rewards-view', { 'vertical-mode': isMobile, 'single-mode': rewardInformations.length === 1 }]">
        <div class="header section-header">
          <img
            srcset="/img/reward_section_icon.1x.png, /img/reward_section_icon.2x.png 2x, /img/reward_section_icon.3x.png 3x"
            src="/img/reward_section_icon.2x.png"
          >
          <div>
            <label class="title subtitle" :class="langClass">{{ detail.reward_section_title || $t('gamificationRewardSectionTitle') }}</label>
            <label>{{ detail.reward_section_description || $t('gamificationRewardSectionDesc') }}</label>
          </div>
          <div>
            <!-- See all prize
            <img class="right" src="/img/right-arrow-purple.png"> -->
          </div>
        </div>

        <div class="main">
          <div v-for="(item, index) in rewardInformations" :key="index" class="one-cat" ref="rewardInformations" @click.prevent="gotoRewardInfoDetail(item)">
            <!-- <img :src="item.image_url"> -->
            <empty-image v-if="item.image_url" :className="{'item-header': true}" :source="item.image_url" :isVertical="isMobile" />
            <div class="detail">
              <div class="detail-content">
                <div :class="['item-title']">{{ item.title }}</div>
                <div :class="['item-content', getRewardInformationItemContentClass(index)]" v-html="item.content" />
              </div>
              <!-- <button class="zoom" :style="{}">{{ $t('homeShowMore') }}</button> -->
              <div class="see-more-button">
                {{ $t('myWalletSeeMore') }}
                <img class="right" src="/img/right-arrow-purple.png">
              </div>
            </div>
          </div>
        </div>

      </div>

      <div v-if="otherInformations.length > 0" class="other-info-view padding-horizontal-16">
        <label class="title subtitle other-info-header" :class="langClass">
          <img src="/img/info_gm.png"/>
          {{ $t('gamificationOtherSectionTitle') }}
        </label>
        <div class="main">
          <div v-for="(item, index) in otherInformations" :key="index" class="one-card" @click.prevent="gotoOtherInfoDetail(item)">
            <div>
              <div class="info-content">
                <label class="is-collapse-2lines">{{ item.title }}</label>
                <div class="other-content is-collapse-2lines-html" v-html="item.content" />
              </div>
              <div class="see-more-button">
                {{ $t('myWalletSeeMore') }}
                <img class="right" src="/img/right-arrow-purple.png">
              </div>
            </div>

            <img v-if="item.index < 4 || item.index > 0 && item.image_url" :src="getOtherInfoImage(item)">
          </div>
        </div>
      </div>

      <div v-if="detail.generic_terms_and_conditions_content" class="detail-info terms-view padding-horizontal-16">
        <label>{{ detail.generic_terms_and_conditions_title || $t('genericTerms&ConditionsGTConditions') }}</label>
        <div v-if="detail.generic_terms_and_conditions_content">
          <div class="description toastui-editor-contents" ref="tcTarget" :class="{'is-collapse-2lines-html': isCollapseTC}" v-html="detail.generic_terms_and_conditions_content" />
          <div v-if="isCollapseTC" class="see-more-button" @click="seeMoreTC">
            {{ $t('myWalletSeeMore') }}
            <img src="/img/down-arrow-purple.png">
            <!-- <div class="arrow down"/> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="float-buttons">
      <button class="earn-button" @click="scrollTo('earn')">
        <img src="/img/earnIcon.png">
        <label>{{ $t('gamificationEarn') }}</label>
      </button>
    </div> -->

    <el-dialog
      :visible.sync="showErrorDialog"
      class="fail-to-acquire-coupon"
      width="316px"
      :title="displayDialogErrorMessage"
      center
      :show-close="false"
    >
      <span slot="footer" class="dialog-footer">

        <el-button
          tag="button"
          type="button"
          class="el-button el-button--primary primary-button"
          @click="closeErrorDialog"
        >
          {{ $t('goodiebagOk') }}
        </el-button>
      </span>
    </el-dialog>

    <!-- <el-dialog
      title=""
      :visible.sync="showMoreStamps"
      width="343px"
      center
      custom-class="more-stamps_card"
      :lock-scroll="true"
    >
      <div class="stamp-card">
        <stamp-list :stamp-rewards="stampRewards" show-sperate-line="True" />
        <button class="common-button" @click="showAllStamps">{{ $t('gamificationDone') }}</button>
      </div>
    </el-dialog> -->

    <div id="robot" />
  </div>
</template>
<script>
import utilsMixin from '@/mixins/utilsMixin'
import backHandlerMixin from '@/mixins/backHandlerMixin'
import missionMixin from '@/mixins/missionMixin'
import NotFind from '@/components/NotFind'
import EmptyImage from '@/components/EmptyImage'
import StampList from '@/components/StampList'
import CustomTooltip from '@/components/CustomTooltip'
import { asyncGetGamificationCampaignDetail } from '@/helpers/http'
import { pushRouterWithQuery, replaceRouterWithQuery } from '@/utils/utils'
import { VueAgile } from 'vue-agile'
// import { getLangName } from '@/utils/lang'
// import { isWechatMiniProgram, getCampaignAPIPrefix, replaceRouterWithQuery, setupDefaultQueryString } from '@/utils/utils'
import { sendPageViewAnalytics, sendAdobeManualAnalytics } from '@/utils/tracking' // sendManualAnalytics,
// import { loginSSO } from '@/helpers/http'
import axios from 'axios'

export default {
  name: 'GamificationDetail',
  components: {
    agile: VueAgile,
    // ApplicableStore,
    // TourCard,
    // barcode: VueBarcode,
    // AcquiresCreditsSuccess,
    // GoodieBagBanner,
    NotFind,
    EmptyImage,
    CustomTooltip,
    StampList
  },
  mixins: [utilsMixin, backHandlerMixin, missionMixin],
  data () {
    return {
      participateDirectId: null,
      isCollapseDescription: null,
      isCollapseTC: null,
      gamificationCampaignId: '',
      showMoreStamps: false,
      isLoading: true,
      showGamificationTips: false,
      customTooltipSlotElement: null,
      customTooltipReferenceElement: null,
      showErrorDialog: false,
      displayDialogErrorMessage: '',
      errorNext: '',
      prevRoute: null,
      missionImageLoaded: false,
      isLoadingMission: false,
      canStartJourney: false,
      previewMode: false
    }
  },
  computed: {
    isMobile () {
      return this.$store.getters['app/isMobile']
    },
    langClass () {
      if (this.$i18n.locale === 'zh-s') {
        return 'zhs'
      } else if (this.$i18n.locale === 'zh-t') {
        return 'zht'
      }
      return 'en'
    },
    isLanding () {
      return this.$router.history.current.path.indexOf('landing-gamification') >= 0
    },
    isNoData () {
      if (this.isLoading) {
        return false
      }

      let result = false

      const detail = this.$store.getters['gamification/gamificationDetail'](this.gamificationCampaignId)
      if (!(detail && detail.id)) {
        result = true
      } else if (!this.previewMode && !this.isLanding && detail.is_public === false && !detail.gamification_campaign_participation_record_id) {
        result = true
      }

      return result
    },
    isVerticalCard () {
      // return true
      if (this.isMobile) {
        return true
      }
      if (this.detail.mission_card_orientation) {
        return this.detail.mission_card_orientation === 'VERTICAL'
      }
      return false
    },
    ValidTil () {
      if (this.detail.end_date) {
        return this.$t('gamificationValidTime', { endDate: this.getDateString(this.detail.end_date) })
      }
      return ''
      // return this.getGoodieBagValidDateString(this.detail.start_date, this.detail.endDate, itemAlias)
      // return this.getCampaignVaidTil(null, this.detail.end_date, false)
    },
    gamificationLabels () {
      let missionLabels = []
      if (this.detail.show_mission_labels && this.detail.mission_labels && this.detail.mission_labels.length) {
        missionLabels = this.detail.mission_labels.filter((missionLabel) => missionLabel)
      }
      return missionLabels
    },
    detail () {
      let result = null
      if (this.gamificationCampaignId) {
        result = this.$store.getters['gamification/gamificationDetail'](this.gamificationCampaignId)
        console.log('616-306------result: ', result)
      }
      if (!result) {
        result = {}
      }

      console.log('303---- result--- coupons', result.other_informations)
      return result
    },
    photos () {
      let photo = this.detail.photos_url
      if (this.isMobile && this.detail.mobile_photos_url?.length > 0) {
        photo = this.detail.mobile_photos_url
      }

      return photo
    },
    missionList () {
      let result = null
      if (this.gamificationCampaignId) {
        result = this.$store.getters['gamification/missionList'](this.gamificationCampaignId)
        console.log('317------result: ', result)
      }
      if (!result) {
        result = []
      }
      // console.log('303---- result--- coupons', result)
      return result
    },
    missionStyle () {
      // const margin = this.missionMargin()
      // if (margin) {
      //   return { 'margin-top': `${margin}px` }
      // }
      return {}
    },
    isOnlyOneMission () {
      return !this.isVerticalCard && this.missionList?.length === 1
      // return true
    },
    otherInformations () {
      const array = this.detail?.other_informations
      if (array?.length > 0) {
        return array?.filter((item) => item.title || item.content).sort((a, b) => a.index - b.index)
      }

      return []
    },
    rewardInformations () {
      const array = this.detail?.reward_informations
      console.log('@@401: ', this.detail)
      if (array?.length > 0) {
        // return [array[0]]
        return array?.filter((item) => item.title || item.content).sort((a, b) => a.index - b.index)
      }

      return []
    }
  },
  watch: {
    '$store.state.app.OneTrustHeight': function () {
      if (!this.oneTrustHeight) {
        if (window.$('.sticky-coupon-footer')[0]) {
          window.$('.sticky-coupon-footer')[0].style.marginBottom = '0px'
        }
      }
    },
    '$store.state.app.windowWidth': function (newVal, oldVal) {
      if (newVal < 1024 && oldVal >= 1024) {
        window.$('.mission-cats-view')[0].style.marginTop = '24px'
      } else if (oldVal < 1024 && newVal >= 1024) {
        setTimeout(() => {
          this.adjustMissionMargin()
        }, 150)
      }
    },
    'detail': function (newVal, oldVal) {
      console.log('@@420-0: ', this.getCookie('__logintask'))
      this.removeCookie('__logintask')
      if (newVal?.id && !oldVal?.id) {
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'game|game detail', { gamificationId: this.detail.id })

        console.log('@@420: ', this.participateDirectId)
        if (!this.detail.cover_photo_for_mission_section_url) {
          setTimeout(() => {
            this.adjustMissionMargin()
          }, 50)
        }

        if (this.participateDirectId) {
          console.log('@@454----000')
          this.startGamification()
        } else if (this.isLanding && this.detail.gamification_campaign_participation_record_id) {
          replaceRouterWithQuery(this.$router, { name: 'gamification-detail', query: { gamificationCampaignId: this.gamificationCampaignId }})
        }
      }
    },
    'missionImageLoaded': function (newVal, oldVal) {
      console.log('@@528: ', newVal)
      if (newVal) {
        setTimeout(() => {
          this.adjustMissionMargin()
        }, 50)
      }
    },
    'isLoadingMission': function (newVal, oldVal) {
      if (!newVal && newVal !== oldVal) {
        setTimeout(() => {
          this.adjustMissionMargin()
        }, 50)
      }
    }
  },
  beforeUpdate () {

  },
  updated () {
    this.$nextTick(() => {
      const height = this.$refs.descriptionTarget?.clientHeight
      if (this.isCollapseDescription === null && height > 14 * 1.6 * 2) {
        this.isCollapseDescription = true
      }

      const tcHeight = this.$refs.tcTarget?.clientHeight
      if (this.isCollapseTC === null && tcHeight > 14 * 1.6 * 2) {
        this.isCollapseTC = true
      }
    })
  },
  beforeDestroy () {
    this.$store.commit('app/setNavType', 'logo_language_menu')
    // this.$store.commit('app/setMiniProgramBackTitle', ' gamification')
    // if (this.isLanding) {
    //   window.removeEventListener('scroll', this.handleScroll, true)
    // }

    // this.$t('toMyWallet')
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  async mounted () {
    // this.getInitialData()
    this.participateDirectId = this.getCookie('__logintask')

    const { gamificationCampaignId, sign, t, is_preview } = this.$route.query
    this.gamificationCampaignId = gamificationCampaignId
    this.sign = sign
    this.tracking_code = t
    this.previewMode = is_preview

    if (!this.detail.id) {
      this.isLoading = true
    }

    try {
      this.$store.dispatch('app/refreshSystemSettings')
    } catch (error) {
      console.log('error--', error)
    }
    this.setCaptcha()

    this.getDetail(this.gamificationCampaignId)
    this.getMissionList(this.gamificationCampaignId)

    // else {
    //   window.addEventListener('scroll', this.handleScroll, true)
    // }

    this.$store.commit('app/setNavType', 'back_language_menu')

    // if (this.isBackNavigation) {
    //   // console.log('@@@497: ', this.canGoBack(), this.$store.getters['gamification/getGamificationFrom'])
    //   // if (this.canGoBack() && this.$store.getters['gamification/getGamificationFrom']) {
    //   //   if (this.$store.getters['gamification/getGamificationFrom'] === 'home') {
    //   //     this.$store.commit('app/setMiniProgramBackTitle', this.$t('toHome'))
    //   //   } else {
    //   //     this.$store.commit('app/setMiniProgramBackTitle', this.$t('toMyRewards'))
    //   //   }
    //   // }
    // } else {
    //   this.$store.commit('gamification/setGamificationFrom', this.prevRoute && this.prevRoute.name)
    // }

    const gotoReward = this.$route.hash && this.$route.hash.replace('#', '')
    if (gotoReward === 'earn-reward') {
      setTimeout(() => {
        // this.$refs.missionSectionRef.scrollIntoView({ behavior: 'smooth', block: 'start' })
        this.scrollToPosition(this.$refs.missionSectionRef)
      }, 1500)
    }
  },
  methods: {
    onloadMissionImage () {
      this.missionImageLoaded = true
    },
    adjustMissionMargin () {
      if (!this.isMobile) {
        console.log('adjustMissionMargin')
        const earnCoverImageHeight = document.getElementsByClassName('earn-cover')[0]?.offsetHeight
        const earnCoverHeight = this.$refs.earnTargetRef?.offsetHeight
        const earnContentHeight = document.getElementsByClassName('earn-content')[0].offsetHeight
        let marginTop = earnCoverHeight - earnContentHeight - 32 - 24
        if (this.isOnlyOneMission) {
          marginTop = marginTop + earnContentHeight + 32
        }
        const missionCatsView = document.getElementsByClassName('mission-cats-view')[0]
        const fillerMission = document.getElementsByClassName('filler-mission')[0]
        if (!missionCatsView || !fillerMission) {
          return
        }
        // const missionHeight = missionCatsView.offsetHeight
        console.log('@@adjustMissionMargin:', earnCoverImageHeight)
        if (earnCoverImageHeight <= 0) {
          marginTop = document.getElementsByClassName('cover-overview')[0].offsetHeight - 24
          // if (!this.detail?.mission_section_title && !this.detail?.mission_section_subtitle && !this.detail?.mission_section_description) {
          //   marginTop -= 24
          // }

          missionCatsView.style.marginTop = `${marginTop}px`
        } else {
          if (!this.detail?.mission_section_title && !this.detail?.mission_section_subtitle && !this.detail?.mission_section_description) {
            marginTop -= 24
          }

          // if (missionHeight - marginTop <= 0 && document.getElementsByClassName('rewards-view')[0]) {
          //   // document.getElementsByClassName('rewards-view')[0].style.marginTop = `${marginTop - missionHeight + 40}px`
          //   missionCatsView.style.minHeight = `${marginTop + 40}px`
          // }
          missionCatsView.style.marginTop = `-${marginTop}px`
          missionCatsView.style.minHeight = `${marginTop}px`
          if (this.missionList && this.missionList.length > 1) {
            const missionRows = Math.ceil(this.missionList.length / 3)
            const maxMissionCatsView = 467 * missionRows + 16 * (missionRows - 1)
            if (maxMissionCatsView < marginTop) {
              missionCatsView.style.minHeight = `${maxMissionCatsView}px`
              fillerMission.style.minHeight = `${marginTop - maxMissionCatsView}px`
            }
          } else {
            missionCatsView.style.minHeight = `${marginTop + 40}px`
          }
        }
      }
    },
    toShowCustomTooltips (show) {
      this.customTooltipSlotElement = { queryName: '.question-mark-icon', index: 0 }
      this.customTooltipReferenceElement = { queryName: '.gamification-valid', index: 0 }
      if (show) {
        this.showGamificationTips = true
      } else {
        this.showGamificationTips = false
      }
    },
    async getDetail (gamificationCampaignId) {
      console.log('@@582: ', gamificationCampaignId)
      if (gamificationCampaignId) {
        try {
          await asyncGetGamificationCampaignDetail(gamificationCampaignId, this.tracking_code)

          sendPageViewAnalytics(this.$route, this.$i18n.locale, this.$store)

          const now = new Date()
          let isNotAvaliableContent = null
          if (new Date(this.detail.display_start_date) > now) {
            isNotAvaliableContent = { title: 'goodiebagHasNotStartedYet' }
          } else if (this.detail.display_end_date && new Date(this.detail.display_end_date) < now) {
            isNotAvaliableContent = { title: 'goodiebagHasExpired' }
          }

          const trackingCodeIsActive = this.detail.tracking_code_is_active
          if (this.tracking_code && !trackingCodeIsActive) {
            isNotAvaliableContent = { title: 'goodiebagIsNotAvaliable' }
          }
          if (isNotAvaliableContent) {
            replaceRouterWithQuery(this.$router, { name: 'page-not-found', query: { ...isNotAvaliableContent, gamificationCampaignId, from: 'GAMIFICATION' }})
          } else {
            this.isLoading = false

            if (!this.previewMode && !this.participateDirectId && !this.detail.gamification_campaign_participation_record_id) {
              this.startGamification(null, true)
            }
          }
        } catch (error) {
          if (error.response.status === 404) {
            this.isLoading = false
          }
        }
      }
    },
    getMissionList (gamificationCampaignId) {
      if (gamificationCampaignId) {
        this.isLoadingMission = true
        axios.get(`/gamification_missions?gamification_campaign=${this.gamificationCampaignId}&lang=${this.$i18n.locale}&page[size]=300`).then(response => {
          console.log('response---', response.data)
          this.$store.commit('gamification/updateGamificationMissionList', {
            gamificationCampaignId,
            list: response.data
          })
          this.isLoadingMission = false

          // if (response.data && response.data.template.show_color_indicator) {
          //   this.isShowCouponIndicator = true
          //   this.couponIndicatorColor = response.data.template.indicator_color
          //   this.couponIndicatorText = response.data.template.indicator_text
          // } else if (response.data && response.data.goodie_bag && response.data.goodie_bag.show_color_indicator) {
          //   this.isShowCouponIndicator = true
          //   this.couponIndicatorColor = response.data.goodie_bag.indicator_color
          //   this.couponIndicatorText = response.data.goodie_bag.indicator_text
          // }

          // if (firstLoad) {
          //   sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon|detail', { couponId })
          // }
        }).catch(error => {
          console.log('371--- errro', error)
          this.isLoadingMission = false
          // const { data } = error.response || {}
          // const { detail } = data || {}
          // console.log('371--- errro', error.response, detail)
          // this.couponNotFound = detail === 'Not found.'
        })
      }
    },
    getRewardInformationItemContentClass (index) {
      if (this.$refs.rewardInformations && !this.isMobile && this.isVerticalCard) {
        const element = this.$refs.rewardInformations[index].getElementsByClassName('item-title')[0]
        console.log('???---', index, element, element.offsetWidth, element.offsetHeight, element.clientHeight)
        if (element.clientHeight < 48) {
          return 'is-collapse-3lines-html'
        }
      }
      return 'is-collapse-2lines-html'
    },
    getOtherInfoImage (item) {
      if (item.image_url) {
        return item.image_url
      }

      if (item.index === 2) {
        return '/img/how-to-participate-default.png'
      }

      if (item.index === 3) {
        return '/img/faq-default.png'
      }

      return '/img/eligible-default.png'
    },
    seeMore () {
      this.isCollapseDescription = false
    },
    seeMoreTC () {
      this.isCollapseTC = false
    },
    showAllStamps () {
      this.showMoreStamps = !this.showMoreStamps
    },
    getRewardButtonText (item) {
      if (item?.isNotReachStartTime) {
        return this.$t(item.redeemButtonName, { startDate: this.getDateString(item.start_date, 'D MMM YYYY') })
      }

      if (item.isExpired) {
        return this.$t(item.redeemButtonName, { endDate: this.getDateString(item.end_date, 'D MMM YYYY') })
      }

      return this.$t(item.redeemButtonName)
    },
    gotoMission (mission) {
      let query = { missionId: mission.id, gamificationCampaignId: this.gamificationCampaignId }
      if (this.isLanding) {
        query = {
          ...query,
          ...(this.$route.query || {})
        }
      } else if (this.detail.gamification_campaign_participation_record_id) {
        query.gamificationCampaignRecordId = this.detail.gamification_campaign_participation_record_id
      }
      this.$store.commit('app/setLastMissionCampaignListState', {})
      pushRouterWithQuery(this.$router, { path: 'mission-detail', query })
    },
    gotoRewardInfoDetail (rewardInfo) {
      pushRouterWithQuery(this.$router, { path: 'reward-infomation', query: { gamificationCampaignId: this.gamificationCampaignId, infoId: rewardInfo.id }})
    },
    gotoOtherInfoDetail (otherInfo) {
      pushRouterWithQuery(this.$router, { path: 'other-infomation', query: { gamificationCampaignId: this.gamificationCampaignId, infoId: otherInfo.id }})
    },
    scrollTo (des) {
      if (des === 'earn') {
        // this.$refs.missionSectionRef.scrollIntoView({ behavior: 'smooth', block: 'start' })
        this.scrollToPosition(this.$refs.missionSectionRef)
      }
    },
    gotoStampPrizeList (item) {
      let hash = ''
      if (item?.index >= 0) {
        hash = `reward-${item.index}`
      }

      let query = { gamificationCampaignId: this.gamificationCampaignId }
      if (this.isLanding) {
        query = this.$route.query
      }
      console.log('693: ', hash, query)
      pushRouterWithQuery(this.$router, { path: 'stamps-prize', query, hash })
    },
    gotoTutorial () {
      pushRouterWithQuery(this.$router, { path: 'tutorials', query: { gamificationCampaignId: this.gamificationCampaignId }})
    },
    async startGamification (missionId = null, isCheck = false) {
      if (!isCheck && !this.participateDirectId) {
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'game|join start', { gamificationId: this.detail.id })
      }

      const postData = {
        gamification_campaign: this.gamificationCampaignId || null,
        sign: this.sign,
        tracking_code: this.tracking_code,
        participatingItem: this.gamificationCampaignId
      }
      if (isCheck) {
        postData.check_link = true
      }
      this.redeemGamification(postData, (created) => {
        console.log('@@709-1: ', created)
        if (created) {
          sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'game|join success', { gamificationId: this.detail.id, join: '1' })
        }

        this.participateDirectId = null
        this.canStartJourney = true
        if (!isCheck) {
          if (this.isLanding) {
            // window.location.href = `/${this.$i18n.locale}/gamification-detail?gamificationCampaignId=${this.gamificationCampaignId}`
            replaceRouterWithQuery(this.$router, { path: 'gamification-detail', query: { gamificationCampaignId: this.gamificationCampaignId }})
            // pushRouterWithQuery(this.$router, { path: 'gamification-detail', query: { gamificationCampaignId: this.gamificationCampaignId }})
          } else if (missionId) {
            pushRouterWithQuery(this.$router, { path: 'mission-detail', query: { missionId: missionId, gamificationCampaignRecordId: this.detail.gamification_campaign_participation_record_id }})
          }
        }
      }, (error) => {
        console.log('@@709: ', error)
        this.participateDirectId = null
        this.canStartJourney = false

        if (!(isCheck && ['error_grant_gamification_campaign_not_reach_start_date', 'error_grant_gamification_campaign_expired'].indexOf(error.code) >= 0)) {
          this.displayDialogErrorMessage = this.handleRedeemGamificationError(error)
          if (error.code === 'error_grant_gamification_campaign_invalid_country_code') {
            this.errorNext = 'my-gamifications'
          } else if (error.display_error_content !== this.displayDialogErrorMessage) {
            this.errorNext = 'home'
          }

          if (this.displayDialogErrorMessage && !((this.isBackNavigation && isCheck))) {
            this.showErrorDialog = true
          }
        }
      })
    },
    closeErrorDialog () {
      if (this.errorNext) {
        pushRouterWithQuery(this.$router, { path: this.errorNext })
      }
      this.showErrorDialog = false
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/gamification-font.scss';

.gamification-detail-container .carousel.detail .agile__nav-button.agile__nav-button--prev img{
  margin-left: 12px;
}

.gamification-detail-container .carousel.detail .agile__nav-button.agile__nav-button--next img{
  margin-right: 12px;
}

.gamification-detail-container .carousel.detail .agile__actions{
  height: 2px;
}

.gamification-detail-container .carousel.detail .agile__list {
  border-radius: 10px;
}

.gamification-detail-container.en .toastui-editor-contents *:not(table) {
  line-height: 1.2;
}

.more-stamps_card {
  border-radius: 10px;
  div.el-dialog__header {
    display: none
  }

  div.el-dialog__body {
    margin: 0px 8px;
    padding: 16px 4px;
    // height: Hug (237px)px;
    background: #FFFFFF;
    position: relative;
  }
}
.is-vue-desktop {
  div.main-container:has(> div.gamification-campaign) {
    width: 100% !important;

    .gamification-detail-container .carousel.detail .agile__list {
      border-radius: unset;
    }
  }
}

.mission-cat-area {
  .info-tips {
    .text {
      span {
        font-size: 12px;
        font-weight: 700;
        line-height: 14.4px;
        color: #FF1700;
      }
    }
  }
}
.is-vue-desktop {
  .mission-cats-view {
    .info-tips {
      .text {
        span {
          font-size: 14px;
          font-weight: 700;
          line-height: 16.8px;
        }
      }
    }
  }
}

.detail-info .description, .detail-info .description * {
  color: #3B002B !important;
}

</style>
<style lang='scss' scoped>
@import '@/assets/common.scss';

.detail-info {
  display: flex;
  flex-direction: column;
  padding: 24px 8px;
  gap: 16px;

  .gamification-labels {
    margin-bottom: -4px;
    display: flex;
    flex-wrap: wrap;
    // gap: 4px 8px;
    label {
      margin-right: 8px;
      margin-bottom: 8px;
      width: fit-content;
      padding: 4px 8px;
      border-radius: 4px;
      border: 1px solid var(--Junk-Boat-Red);

      // font-family: Manrope;
      font-size: 12px;
      font-weight: 700;
      // line-height: 14.4px;
      color: var(--Junk-Boat-Red);
    }
  }

  .title {
    font-size: 36px;

    text-align: left;
    color: var(--Temple-St-Dusk-Maroon);

    margin-bottom: 0px;
  }

  .gamification-valid {
    position: relative;
  }

  .active-period {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: left;

    color: var(--temple-st-dusk-maroon-50);

    .question-mark-icon {
      width: 20px;
      height: 20px;
    }
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
  }

  .see-more-button.tutorial {
    justify-content: flex-start;
    margin-top: 24px;
    // gap: 6px;

    img.question-mark-icon {
      width: 24px !important;
      height: 24px;
      margin-right: 6px;
    }
  }
}

.section-header {
  display: flex;
  align-items: center;
  img {
    width: 56px;
    height: 56px;
  }
  div:first-of-type {
    display: flex;
    flex-direction: column;

    margin-left: 8px;
    gap: 8px;
    // .title {
    //   font-size: 26px;
    // }

    label:last-child {
      font-size: 14px;
      font-weight: 400;
      color:  var(--Temple-St-Dusk-Maroon);
    }
  }
  div:last-child {
    display: flex;
    margin-left: auto;
    padding: 6.5px 0px;
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    color:  var(--Temple-St-Dusk-Maroon);

    img {
      width: 12px;
      height: 12px;
      margin-top: 2px;
      margin-left: 4px;
    }
  }
  .title {
    margin-bottom: 0px !important;
  }
}
.mission-section-header {
  margin-top: 40px;
}

.map-view {
  position: relative;
  padding-top: 16px;
  margin-top: 0px !important;
  min-height: 254px;

  width: 100%;
  // height: 269px;
  border-radius: 10px;

  background-size: cover;

  .cover-overview {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    min-height: fit-content;
    display: flex;
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    flex-direction: column;

    div:first-of-type {
      margin-top: auto;
    }
    div.earn-content {
      display: flex;
      flex-direction: column;
      margin: 12px;
      gap: 8px;
      color: #FFF;

      margin-top: auto;
      .title {
        font-size: 28px;
        text-align: left;

        color: #FFF;
        margin-bottom: unset;

      }

      .sub-header {
        font-size: 20px;
        font-weight: 800;
        text-align: left;
      }

      .content {
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: left;

        margin-bottom: unset;
      }
    }
    button {
      margin: 8px 0px 16px 12px;
      width: fit-content;
// height: Fixed (44px)px;
      padding: 10px 24px 10px 24px;
      gap: 4px;
      border-radius: 10px;
      border: 2px solid #FFF;
      background: transparent;

      display: flex;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
      }

      span {
        font-size: 16px;
        font-weight: 600;
        text-align: left;

        color: #FFF;

      }
    }
  }

  div.cover-image {
    position: relative;
  }

  img.earn-cover {
    width: 100%;
    display: block;
  }

  div.cover-overview.cover-content {
    background: none;
  }

  img {
    border-radius: 10px;
  }
}

.mission-cats-view {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 24px 8px 40px;
  // padding: 0px 8px;
  gap: 16px;

  .mission-cat-area {
    cursor: pointer;
    position: relative;
    width: 100%;

    border-radius: 10px;
    box-shadow: 0px 0px 16px 0px #00000024, 0px 0px 4px 0px #0000000F;
    display: flex;
    flex-direction: column;
    padding: 12px;

    label {
      cursor: pointer;
    }

    .mission-content {
      display: flex;
      flex-direction: row;
      position: relative;
    }

    .cover-image, div.default-image {
      // width: 102px;
      // height: 76.5px;
      width: calc((100% - 12px) / 3);
      aspect-ratio: 4/3;
      border-radius: 8px;
      margin: 0px;
      margin-left: 12px;
      object-fit: cover;
    }

    div.default-image {
      background: var(--gray);
    }

    .item-tip-container {
      position: absolute;
      top: 25px;
      // left: 14.37px;
      left: calc((100% - 12px) / 3 * 2 + 12px - 6.37px);
      z-index: 1;
      .coupon-offer-label {
        display: flex;
        flex-direction: column;
        .coupon-offer-label-content {
          display: flex;
        }
        .coupon-offer-label-shadow {
          height: 2.37px;
          width: 2.37px;;
        }
        .coupon-offer-label-right-image {
          width: 10px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        .coupon-offer-label-font {
          background-color: #FE4534;
          padding-left: 7.1px;
          padding-top: 2.2px;
          font-size: 8.91px;
          line-height: 10px;
          text-transform: uppercase;
          color: #FFF;
          font-weight: bold;
          padding-right: 2px
        }
        .coupon-offer-label-right-image{
          background-image: -webkit-image-set(
              url("/img/offer-label-right.1x.png") 1x,
              url("/img/offer-label-right.2x.png") 2x,
              url("/img/offer-label-right.3x.png") 3x);
          background-image: image-set(
              url("/img/offer-label-right.1x.png") 1x,
              url("/img/offer-label-right.2x.png") 2x,
              url("/img/offer-label-right.3x.png") 3x);
          background-size: contain;
        }
      }
    }

    div.mission-border {
      display: flex;
      flex-direction: column;
      width: calc((100% - 12px) / 3 * 2);

      .misson-cat-name {
        border: none;
        display: flex;
        min-height: unset;

        label {
          font-size: 18px;
          font-weight: 700;
          // text-indent: 2rem;
          // text-align: left;
          color: var(--Temple-St-Dusk-Maroon);
        }
      }
    }
    .common-button {
      // width: calc((100% - 12px) / 3 * 2);
      width: 100%;
      margin-top: 24px;
      margin-left: 0;
      padding: 6px 0px;
      display: flex;
      justify-content: center;
      height: fit-content;
      img {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
    }

    .info-tips {
      div {
        max-height: 28px;

        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2;
        -webkit-box-orient: vertical;

        * {
          display: inline;
        }
      }
      .text {
        // font-family: Manrope;
        font-size: 12px;
        font-weight: 600;
        line-height: 14.4px;
        text-align: center;
        color: #3B002B;
        margin-top: 8px;
      }
      .max-tips {
        color: #9D8095;
      }
      .line {
        margin-top: 8px;
        width: 100%;
        border-top: 1px solid #D8CCD5;
      }
    }
  }
  .mission-cat-area.disabled {
    .mission-content, .common-button, .info-tips {
      opacity: 0.5;
    }
  }
}

.rewards-view {
  padding: 0px 4px;

  .main {
    padding:16px 2px 40px;
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
    .one-cat {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      // border: 2px solid var(--temple-st-dusk-maroon-80);
      box-shadow: 0px 0px 16px 0px #00000024, 0px 0px 4px 0px #0000000F;
      border-radius: 10px;
      width: calc((100% - 16px)/1);
      height: 168px;
      color:  var(--Temple-St-Dusk-Maroon);
      .item-header {
        background: #F0E7FF;
        border-radius: 10px 0px 0px 10px;
        width: 40%;
        aspect-ratio: 4 / 3;
      }

      div.detail {
        display: flex;
        flex-direction: column;

        padding: 12px;
        gap: 24px;
        .item-title {
          margin-bottom: 4px;
          font-weight: 700;
          font-size: 16px !important;
          min-height: 38px;

          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 2; /* number of lines to show */
                  line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .item-content {
          font-weight: 400;
          font-size: 14px !important;
          min-height: 51px;

          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 3; /* number of lines to show */
                  line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        button {
          margin: 0px 0px;
          margin-top: 8px;
          padding: 8.5px 27.25px;
          font-weight: 600;
          font-size: 16px !important;
          border: 2px solid var(--Junk-Boat-Red);
          border-radius: 24px;
          color: var(--Junk-Boat-Red);
          background: #FFF;
          width: auto;
        }

        .see-more-button {
          justify-content: flex-start;
          margin-top: auto;
        }

        // button.zoom {
        //   transition: transform .2s;
        // }
        // button.zoom:hover {
        //   transform: scale(1.1);
        // }
      }
    }
  }
}
.rewards-view.vertical-mode {
  .one-cat {
    flex-direction: column;
    width: calc((100% - 16px)/2);
    height: auto;
    // height: 291px;
    .item-header {
      border-radius: 10px 10px 0px 0px;
      width: 100%;
    }

    div.detail {
      .item-title {
        margin-bottom: 8px;
        min-height: 38px;

        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .item-content {
        min-height: 51px;

        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 3; /* number of lines to show */
                line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      button {
        margin-top: 12px;
        padding: 8.5px 0px;
        width: 100%;
      }
    }
  }
}

.padding-horizontal-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.other-info-view {
  .other-info-header {
    display: flex;
    gap: 9px;
    img {
      width: 44px;
      height: 44px;
      align-self: center;
    }
  }
  .main {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;

    div.one-card {
      cursor: pointer;
      border-radius: 10px;
      // border: 2px solid var(--temple-st-dusk-maroon-80);
      box-shadow: 0px 0px 16px 0px #00000024, 0px 0px 4px 0px #0000000F;
      background: #FFF;

      padding: 12px;
      display: flex;
      flex-direction: row-reverse;
      gap: 16px;

      .info-content {
        min-height: 84px;
      }

      label {
        cursor: pointer;
      }

      div:first-of-type {
        color: var(--Temple-St-Dusk-Maroon);
        display: flex;
        flex-direction: column;
        width: -webkit-fill-available;
        gap: 8px;

        label:first-child {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 4px;
        }

        div.other-content {
          font-size: 14px;
          font-weight: 400;
          word-break: break-word;
        }

        div.see-more-button {
          // margin-top: auto;
          align-self: self-start;
          margin-top: 4px;
        }
      }

      img {
        width: 139px;
        // max-height: 139px;
        aspect-ratio: 4/3;
        object-fit: cover;
        // margin-left: auto;

        margin-right: auto;
        // margin-left: unset;
      }

      div.blank-image {
        min-width: 139px;
        // max-height: 139px;
        aspect-ratio: 4/3;
        object-fit: cover;
        // margin-left: auto;

        margin-right: auto;
      }
    }
  }
}

.terms-view {
  gap: 8px;
  padding-top: 24px;

  label {
    font-size: 16px;
    font-weight: 800;
    color: var(--Temple-St-Dusk-Maroon);
  }
}

.sticky-coupon-footer {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 11px;
  border-top:  1px solid #D1D1D1;
  background-color: #fff;
  cursor: pointer;
  flex-direction: column;
  padding-left: 14px;
  padding-right: 14px;
  z-index: 2;
}

.more-stamps_card .stamp-card {
  margin: unset;
  // height: Hug (237px)px;
  border-radius: unset;
  background: unset;

  button {
    width: 100%;
    text-align: center;
    height: 44px;
    display: block;
  }
}

.float-buttons {
  position: fixed;
  right: 45px;
  bottom: calc(75px + 70px + var(--sab)) !important;
  z-index: 2;

  .earn-button {
    background-color: var(--Temple-St-Dusk-Maroon);
    box-shadow: 0px 1px 4px 0px #00000080;
    color: #FFFFFF;

    border-radius: 50%;
    // padding: 14px 20px;
    display: flex;
    flex-direction: column;
    // text-align: center;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    padding: 0px;

    img {
      width: 20px;
    }

    label {
      font-family: Open Sans;
      font-size: 12px;
      font-weight: 600;
      line-height: 16.34px;
    }
  }
}

.is-vue-desktop .gamification-detail-container {
  padding: 0px;
  display: flex;
  flex-direction: column;
  background: unset;
  .slide-image {
    padding-top: calc(500/1920 * 100%);
    border-radius: 0px;
  }

  .padding-horizontal-16 {
    padding-left: 32px;
    padding-right: 32px;
  }

  .gamification-detail-container-main {
    width: 1024px;
    align-self: center;
    background: #FFF;
  }

  .gamification-labels {
    label {
      font-size: 14px;
      font-weight: 700;
      // line-height: 16.8px;
    }
  }

  .detail-info {
    .description {
      font-size: 16px;
    }
    .see-more-button.tutorial {
      justify-content: flex-start;
      margin-top: 24px;
      // gap: 6px;

      img.question-mark-icon {
        width: 24px !important;
        height: 24px;
        margin-right: 6px;
      }
    }
  }

  .detail-stamps {
    display: flex;
    gap: 16px;
    padding: 40px 32px;

    .detail-info {
      padding: unset;
      .title {
        font-size: 56px !important;
      }

      .active-period {
        font-size: 16px;
      }

      .description {
        font-size: 16px;
      }
    }

    .stamp-card {
      margin-right: unset;
    }
  }

  .section-header {
    div:first-of-type label:last-child {
      font-size: 16px;
    }
  }
  .mission-section-header {
    margin-top: unset;
    padding: 0 32px;
  }

  .map-view {
    position: relative;
    min-height: 80px;
    height: unset;
    border-radius: unset;

    img#idEarnCover {
      object-fit: cover;
      width: 100%;
      border-radius: unset;
    }

    .cover-overview {
      min-height: 100%;
      height: fit-content;
      flex-direction: row;
      padding: 32px 32px;
      border-radius: unset;

      color: #FFF;

      div:first-of-type {
        width: 100%;
        display: flex;
        margin-top: 0px;
      }

      div {
        height: fit-content;
        // margin-left: 30px;
      }

      div.earn-content {
        margin-top: 0;
        margin-left: 0px;

        .title {
          font-size: 48px !important;
        }

        .sub-header {
          font-size: 22px !important;
        }
        .content {
          font-size: 16px;
        }
      }
      button {
        height: fit-content;
        margin-left: auto;
        // min-width: 20%;
        width: fit-content;
        margin-top: auto;

        padding: 11px 20px;

        span {
          white-space: nowrap;
          margin-right: 20px;
          font-size: 18px;
        }
        // width: 18px;
      }
    }

    .cover-overview.only-one-mission {
      flex-direction: column;

      div:first-of-type {
        flex-direction: column;
      }

      div.earn-content {
        width: calc((100% - 16px) * 0.4);
        margin-right: 0px;
      }
      button {
        margin-left: 0px;
        margin-top: 8px;
      }
    }
  }

  .mission-cats-view {
    .info-tips {
      div {
        max-height: 34px;
      }
      .text {
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;
      }
    }
    .item-tip-container {
      position: absolute;
      top: 31px;
      // left: -3px;
      left: 14.37px;
      .coupon-offer-label {
        display: flex;
        flex-direction: column;
        .coupon-offer-label-content {
          display: flex;
        }
        .coupon-offer-label-shadow {
          height: 3px;
          width: 3px;;
        }
        .coupon-offer-label-right-image {
          width: 15px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        .coupon-offer-label-font {
          background-color: #FE4534;
          padding-left: 9px;
          padding-top: 2px;
          padding-bottom: 3px;
          font-size: 10px;
          line-height: 13px;
          color: #FFF;
          font-weight: bold;
          padding-right: 2px
        }
      }
    }
  }
  .mission-cats-view.vertical-mode {
    flex-direction: row;
    margin: 24px 32px 0px;

    .mission-cat-area {
      // flex-direction: column-reverse;
      justify-content: space-between;
      width: calc((100% - 32px) / 3);
      background-color: #FFF;
      // padding-top: 0px !important;
      padding: 16px;
      max-height: 467px;

      .mission-content {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;

        img.cover-image {
          width: 100%;
          height: auto;
          margin-bottom: auto;
          margin-left: unset;
        }
        div.default-image {
          height: auto;
          width: 100%;
          margin-bottom: auto;
          margin-left: unset;
        }
      }

      .mission-border {
        // width: 278.67px;
        // height: -webkit-fill-available;
        height: 100%;

        margin-top: 16px;
        width: 100%;

        .misson-cat-name {
          // min-height: 68px;
          min-height: 72px;
          label {
            font-size: 20px !important;
          }
        }
      }
      .common-button {
        margin-top: 24px;
        width: 100%;
      }
      .info-tips {
        .text {
          text-align: center;
        }
      }
    }
  }

  .mission-cats-view.horizontal-mode {
    flex-direction: row;
    // margin: 24px 32px 40px;
    margin: 24px 32px 0px;
    align-content: baseline;

    &.only-one-mission {
      justify-content: flex-end;
      .mission-cat-area {
        width: calc((100% - 24px) * 0.6)
      }
    }

    .mission-cat-area {
      flex-direction: column;
      justify-content: flex-start;
      background: #FFF;
      width: calc((100% - 16px) / 2);
      padding: 0px;
      height: fit-content;
      // padding-top: 0px !important;

      .mission-content {
        padding: 16px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;

        img.cover-image {
          margin-left: unset;
          width: calc((100% - 16px) * 0.4);
          height: fit-content;
        }

        div.default-image {
          margin-left: unset;
          height: auto;
          width: calc((100% - 16px) * 0.4);
        }
      }

      .mission-border {
        width: calc((100% - 16px) * 0.6);
        padding: 0px;
        margin-left: 16px;
        // height: -webkit-fill-available;

        // margin: 16px 0px;
        // gap: 16px;

        .misson-cat-name {
          // min-height: 68px;
          min-height: 72px;
          label {
            font-size: 20px !important;
          }
        }
      }
      .common-button {
        margin-top: 16px;
        width: 100%;
      }
      .info-tips {
        .text {
          text-align: center;
        }
      }
    }
  }

  .rewards-view {
    margin-top: 40px;
    padding: 0px 32px;

    .one-cat {
      width: calc((100% - 16px)/2);
      height: 143.77px;
      // height: 141.6px;
      div.detail {
        .item-title {
          margin-bottom: 4px;
          font-weight: 700;
          font-size: 20px !important;
          min-height: 24px;

          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 1; /* number of lines to show */
                  line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .item-content {
          font-weight: 400;
          font-size: 16px !important;
          min-height: 38px;

          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 2; /* number of lines to show */
                  line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        button {
          margin-top: 8px;
          padding: 7px 20px;
          font-weight: 600;
          font-size: 18px !important;
          width: auto;
        }
      }
    }
  }
  .rewards-view.vertical-mode,
  .rewards-view.vertical-mode.single-mode {
    .one-cat {
      width: calc((100% - 32px)/3);
      height: auto;
      div.detail {
        .detail-content {
          min-height: 94.38px;
        }
        .item-title {
          margin-bottom: 8px;
          min-height: 24px;

          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 2; /* number of lines to show */
                  line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .item-content {
          min-height: 38px;

          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 2; /* number of lines to show */
                  line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .item-content.is-collapse-3lines-html {
          -webkit-line-clamp: 3; /* number of lines to show */
                  line-clamp: 3;
        }

        button {
          margin-top: 16px;
          padding: 7px 20px;
          width: auto;
        }
      }
    }
  }
  .rewards-view.single-mode {
    .one-cat {
      width: 80%;
      height: 227.59px;
      // height: 231.6px;

      div.detail {
        .item-title {
          margin-bottom: 4px;
          font-weight: 800;
          font-size: 18px !important;
          min-height: 44px;

          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 2; /* number of lines to show */
                  line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .item-content {
          font-weight: 400;
          font-size: 16px !important;
          min-height: 57px;

          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 3; /* number of lines to show */
                  line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        button {
          margin-top: 54.6px;
          padding: 7px 20px;
          font-weight: 600;
          font-size: 18px !important;
          width: auto;
        }
      }
    }
  }

  .other-info-view {
    padding-top: 40px;
    background: var(--gray);

    .other-info-header {
      img {
        width: 56px;
        height: 56px;
        // align-self: center;
      }
    }
    .main {
      flex-direction: row;
      flex-wrap: wrap;

      div.one-card {
        flex-direction: row-reverse;
        width: 49%;
        padding: 16px;

        .info-content {
          min-height: 94px;
        }

        div:first-of-type {
          label:first-child {
            font-size: 20px !important;
          }
          div.other-content {
            font-size: 16px;
            min-height: 38px;
          }
        }

        div.see-more-button {
          margin-top: 18px;
        }
      }
    }
  }

  .terms-view {
    padding-top: 32px;
    background: var(--gray);

    label {
      font-size: 18px !important;
    }

    // .is-collapse-2lines-html {
    //   max-height: 45px;
    // }
  }
}

.is-vue-mobile {
  .slide-image {
    padding-top: calc(9/16 * 100%);
    border-radius: 10px;
    // aspect-ratio: 16/9;
  }
}
</style>
<style lang='scss'>
.gamification-tooltip-container {
  // margin-top: -16px;
}
// .is-vue-desktop .gamification-tooltip-container {
//   margin-top: -16px;
// }
</style>
