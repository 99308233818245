<template>
  <div class="acquires-stamp-success">
    <div class="main">
        <!-- <img src="/img/stamp-congratulations-B.gif" /> -->
        <img
          :class="['congratulations-img', { 'single': !earnedRewardCount}]"
          srcset="/img/stamp-congratulations-C.1x.png, /img/stamp-congratulations-C.2x.png 2x, /img/stamp-congratulations-C.3x.png 3x"
          src="/img/stamp-congratulations-C.2x.png"
        >
        <!-- <label>{{ $t(isCheckIn? 'gamificationCheckInsuccess1': 'gamificationUseCouponSuccess1') }}</label>
        <label class="redeemed">{{ $t(isCheckIn? 'gamificationCheckInsuccess2': 'gamificationUseCouponSuccess2') }}</label>
        <label class="content">{{ $t(isCheckIn? 'gamificationCheckInsuccess3': 'gamificationUseCouponSuccess3', {count: stamps}) }}</label> -->
        <!-- <label class="congratulations">{{$t('EarnedStampCongratulations')}}</label> -->
        <!-- <label class="redeemed">{{ $t(isCheckIn? 'gamificationCheckInsuccess2': 'gamificationUseCouponSuccess2') }}</label> -->
        <label class="congratulations" v-html="$t('EarnedStampCongratulations') + '<br />' + $t('EarnedStampOnly', { stampCount: stamps })"></label>

        <div v-if="isCheckIn" class="checkin-area">
          <label class="content">{{$t('LocationCheckinSuccess')}}</label>
          <label class="stamps-reminder">{{ checkIn.on }}</label>
          <label class="stamps-reminder at">{{ checkIn.at }}</label>
        </div>
        <label v-if="earnedRewardCount" class="content">{{ $t('EarnedStampAchieved') }}</label>
        <!-- <label v-if="count" class="content">{{ earnedRewardCount ? $t('EarnedStampWithReward', { stampCount: stamps, rewardCount: earnedRewardCount}) : $t('EarnedStampOnly', { stampCount: stamps }) }}</label> -->

        <!-- <div v-if="count" class="stamp-card reward-card" @click="viewNext(-1)">
          <div class="stamps">
            <label class="redeemed">{{$t('EarnedStampNumberOnly', { count: stamps })}}</label>
            <label>{{$t('EarnedStampNumberUnit')}}</label>
          </div>
          <label class="stamps-reminder">{{$t('EarnedStampProgress', { count: latestStamps, total: totalStamps })}}</label>
          <button class="common-button">{{$t('EarnedStampViewStamp')}}</button>
        </div> -->
        <div v-if="earnedRewardCount" class="reward-area">
          <div v-for="(item, index) in rewards" :key="index" class="reward-card" @click="viewNext(item.index)">
            <!-- <label class="redeemed">{{ $t('gamificationUseCouponUnlockTitle', {count: rewards.length}) }}</label> -->
            <empty-image :class-name="{'reward-image': true}" :source="item.imageUrl" />
            <div class="reward-card-info-container">
              <div class="other-container">
                <label class="title is-collapse-3lines">{{ item.name }}</label>
                <label class="reward-count">x{{ item.quantity }}</label>
                <!-- <button class="common-button">{{ $t('EarnedStampViewReward') }}</button> -->
              </div>
              <div class="button-container">
                  {{ $t('EarnedStampViewReward') }}
                  <img
                    class="arrow-icon"
                    srcset="/img/arrow-icon-2.1x.png, /img/arrow-icon-2.2x.png 2x, /img/arrow-icon-2.3x.png 3x"
                    src="/img/arrow-icon-2.2x.png"
                  >
                </div>
            </div>
          </div>
        </div>
    </div>
    <div class="footer-buttons">
        <div class="footer-next-mobile-button" @click="done">{{ $t('gamificationDone') }}</div>
        <!-- <div v-if="viewButtonName"><a class="content" href="">{{viewButtonName}}</a></div> -->
    </div>
  </div>
</template>
<script>
// import agileBanner from '@/components/AgileBanner'
import EmptyImage from '@/components/EmptyImage'
// import { sendManualAnalytics } from '@/utils/tracking'
import { pushRouterWithQuery } from '@/utils/utils'

export default {
  name: 'AcquiresStampSuccess',
  components: {
    EmptyImage
  },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  computed: {
    checkIn () {
      return this.data?.checkIn || null
    },
    count () {
      return this.data?.count || 0
    },
    rewards () {
      return this.data?.rewards || null
    },
    earnedStamps () {
      return this.data?.earnedStamps || 0
    },
    totalStamps () {
      return this.data?.totalStamps || 0
    },
    gamificationCampaignId () {
      return this.data?.gamificationCampaignId || null
    },
    isCheckIn () {
      console.log('@@@46: ', this.data)
      if (this.checkIn?.on) {
        return true
      }
      return false
    },
    stamps () {
      // console.log('@@@46: ', this.collectStampSuccessDialog, this.count)
      if (this.count && this.count < 2) {
        return '1'
      }

      return `${this.count || 0}`
    },
    earnedRewardCount () {
      if (this.rewards) {
        return this.rewards.length
      }

      return 0
    },
    latestStamps () {
      return parseInt(this.earnedStamps) // + parseInt(this.stamps)
    },
    viewButtonName () {
      if (this.rewards.length > 0) {
        if (this.rewards[0]?.isCoupon) {
          return this.$t('gamificationUseCouponViewRewards')
        }

        return this.$t('gamificationUseCouponViewStamp')
      }
      return null
    }
  },
  methods: {
    done () {
      this.$emit('done')
      console.log('done')
    },
    viewNext (rewardIndex) {
      console.log('view')
      this.$emit('done')
      if (rewardIndex < 0) {
        pushRouterWithQuery(this.$router, { path: 'stamps-prize', query: { gamificationCampaignId: this.gamificationCampaignId }})
      } else if (rewardIndex >= 0) {
        // const reward = this.rewards.filter(v => v.index === rewardIndex)?.[0]
        // console.log('@@127: ', this.rewards, rewardIndex, reward)
        // if (reward.isCoupon && reward.isCoupon.length > 0) {
        //   pushRouterWithQuery(this.$router, { path: 'coupon-detail', query: { couponId: reward.isCoupon[0], from: this.$route.path }})
        // } else if (reward.type === 'FORTUNE_BAG') {
        //   // const couponIds = reward.fortuneBagCouponIds || []
        //   // const couponList = this.$store.getters['coupons/couponList']().filter((coupon) => couponIds.includes(coupon.id))
        //   // const hash = couponList.length ? `coupon-${couponList[0].id}` : ''
        //   pushRouterWithQuery(this.$router, { path: 'my-wallet-all' })
        // } else if (reward.type) {
        //   const recordId = reward.goodieBagParticipationRecords?.[0]
        //   const hash = recordId ? `goodiebag-${recordId}` : ''
        //   pushRouterWithQuery(this.$router, { path: 'my-goodie-bags', hash })
        // }
        pushRouterWithQuery(this.$router, { path: 'stamps-prize', query: { gamificationCampaignId: this.gamificationCampaignId }, hash: `reward-${rewardIndex}` })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/common.scss';
.acquires-stamp-success {
  max-height: calc(100vh - 81px);
  overflow-y: auto;
    .content {
        font-size: 16px;
        font-weight: 800;
        text-align: center;
        color: var(--Temple-St-Dusk-Maroon, #3B002B);

        margin-bottom: 0px;
    }
    .stamps-reminder {
      font-size: 14px;
      font-weight: 600;
      color: var(--temple-st-dusk-maroon-50, #9D8095);
    }
    .main {
        display: flex;
        flex-direction: column;
        // height: calc(100vh - 125px);
        align-items: center;
        // justify-content: center;
        // gap: 8px;
        padding: 16px;

        * {
          line-height: 1.2;
        }

        .congratulations-img {
            width: 120px;
            height: auto;
            margin-bottom: 24px;
        }
        .congratulations-img.single {
          margin-top: 70px;
        }

        label.congratulations {
            font-size: 20px;
            font-weight: 700;
            // line-height: 24px;
            text-align: center;
            color: var(--Temple-St-Dusk-Maroon, #3B002B);

            margin-bottom: 40px;

            span {
              color: var(--Junk-Boat-Red, #FF1700);
            }
        }
        label.redeemed {
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            color: var(--temple-st-dusk-maroon-50, #9D8095);
        }
        label.content {
          font-size: 16px;
          font-weight: 700;
          text-align: center;
          color: var(--Temple-St-Dusk-Maroon, #3B002B);
          margin-bottom: 16px;
        }

        .checkin-area {
          display: flex;
          flex-direction: column;
          gap: 8px;
          align-items: center;
          margin-bottom: 24px;
          .on {

          }

          .at {
            text-align: center;
          }
        }

        .stamp-card {
          margin-bottom: 0px !important;
          .stamps {
            display: flex;
            flex-direction: column;
            label:first-of-type {
              font-size: 36px;
              font-weight: 800;
              line-height: 0.8;
              color: var(--Temple-St-Dusk-Maroon, #3B002B);
            }
            label:nth-child(2) {
              font-size: 16px;
              font-weight: 600;
              margin-top: 4px;
              color: var(--Temple-St-Dusk-Maroon, #3B002B);
            }
          }
          .stamps-reminder {
            font-size: 14px;
            font-weight: 600;
            color: var(--temple-st-dusk-maroon-50, #9D8095);
          }
        }

        .reward-area {
          width: 100%;
        }

        div.reward-card {
            margin-bottom: 16px;
            display: flex;
            // flex-direction: column;
            // align-items: center;
            // gap: 12px;
            width: 100%;
            min-height: 114px;

            padding: 12px;
            // background: var(--gray, #F4F4F4);
            // border: 1px solid var(--temple-st-dusk-maroon-80, #D8CCD5);
            border-radius: 10px;
            box-shadow: 0px 0px 16px 0px #00000024, 0px 0px 4px 0px #0000000F;

            cursor: pointer;
            label {
              cursor: pointer;
            }

            .reward-image {
                // width: 120px;
                width: calc(40% - 4.8px);
                height: auto;
                min-height: 90px;
                border-radius: 5px;
            }

            .reward-card-info-container {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: calc(60% - 7.2px);
              margin-left: 12px;
            }

            .title {
              // font-family: Manrope;
              font-size: 14px;
              font-weight: 600;
              color: var(--Temple-St-Dusk-Maroon, #3B002B);
              text-align: left;
              margin: 0px;
            }

            .other-container {
              display: flex;
              flex-direction: row;
              gap: 5px;
              max-height: 70px;
            }
            .reward-count {
              font-size: 14px;
              font-weight: 600;
              color: var(--temple-st-dusk-maroon-50, #9D8095);
              text-align: right;
            }
            .button-container {
              display: flex;
              align-items: center;
              margin-top: 11px;

              font-size: 14px;
              font-weight: 600;
              text-align: left;
              text-decoration: underline;
              // text-decoration-line: underline;
              // text-decoration-style: solid;
              // text-underline-position: from-font;
              color: var(--Junk-Boat-Red, #FF1700);

              img {
                margin: 0px 0px 0px 4px;
                width: 12px;
                height: 12px;
              }
            }

            img.next-icon {
                width: 24px;
                height: 24px;
            }

            label.content {
                color: var(--Temple-St-Dusk-Maroon, #3B002B);

                // text-align: left;
                font-weight: 600;
                font-size: 14px;
            }
            // div:has(> label.content) {
            //     display: flex;
            //     align-items: center;
            //     gap: 12px;

            // }

        }

        button.common-button {
          margin-right: auto;
        }
    }

    .footer-buttons {
        position: fixed;
        padding: 16px;
        // margin-bottom: 8px;
        border-top: 1px solid #DADADA;
        background: #FFF;
        bottom: 0;
        width: 100%;

        .footer-next-mobile-button {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #E2002C;
            color: #FFF;
            font-weight: bold;
            font-size: 18px;
            line-height: 25px;
            border: 2px solid #E2002C;
            box-sizing: border-box;
            border-radius: 41px;
            height: 44px;
            cursor: pointer;
        }

        div:has(> a.content) {
            height: 44px;
            padding-top: 16.5px;
            text-align: center;
            a {
                color: var(--Junk-Boat-Red, #FF1700);
                text-decoration: underline;
            }
        }

    }
}

.is-vue-desktop {
  .acquires-stamp-success {
    height: 746px;
    max-height: 746px;
    align-content: center;
    .main {
      // height: 636px;
      // max-height: 636px;
      padding-top: 24px;
      padding-bottom: 124px;
      padding-left: 76px;
      padding-right: 76px;
    }
  }

  .footer-buttons {
    // display: flex;
    // flex-direction: row-reverse;
    position: absolute;
    padding: 16px 76px;

    div.footer-next-mobile-button {
      // padding: 11px 37px;
      width: 240px;
      justify-self: center;
    }

    div:nth-child(2) {
      margin-right: auto;
    }
  }
}
</style>
