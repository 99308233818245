import { render, staticRenderFns } from "./GoodieBagListItem.vue?vue&type=template&id=61ff4f43&scoped=true&"
import script from "./GoodieBagListItem.vue?vue&type=script&lang=js&"
export * from "./GoodieBagListItem.vue?vue&type=script&lang=js&"
import style0 from "./GoodieBagListItem.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./GoodieBagListItem.vue?vue&type=style&index=1&id=61ff4f43&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61ff4f43",
  null
  
)

export default component.exports