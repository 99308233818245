<template>
    <div v-if="couponItem" class="scan-code-success-dialog-content-info">
        <div
            v-if="couponItem && couponItem.showColorIndicator"
            class="coupon-indicator"
            :style="{ backgroundColor: couponItem ? couponItem.indicatorColor || '' : '' }"
        >{{ couponItem ? couponItem.indicatorText || '' : '' }}</div>
        <!--
        <img class="scan-code-success-img" src="/img/qrcode_success.png">
        -->
        <div class="scan-code-success-check">&check;</div>
        <div class="scan-code-success-name">{{ couponItem.name }}</div>
        <div class="scan-code-success-description">{{ $t('myWalletSuccessfullyRedeemedTitle') }}</div>
        <div class="scan-code-success-message" :style="storeName ? 'margin-bottom: 4px' : 'margin-bottom: 12px'">{{ couponItem.redeemedTime }}</div>
        <div v-if="storeName" class="scan-code-success-message scan-code-store">{{ storeName }} ({{ storeAddress }})</div>
        <div v-if="couponItem.referenceId" class="reference-id-contain">{{ $t('referenceID') }}<br />{{ couponItem.referenceId }}</div>
        <div v-if="$t('myWalletSuccessfullyRedeemedSuffix')" class="scan-code-success-description qrcode-successfully-redeemed-suffix">{{ $t('myWalletSuccessfullyRedeemedSuffix') }}</div>
        <div class="coupon-content">
            <div class="coupon-type-container">
                <div class="coupon-type-tags">
                <div v-if="couponItem.couponCampaignLabel" class="coupon-type starred">{{ couponItem.couponCampaignLabel }}</div>
                <!-- <div class="coupon-type" :class="{'limited-offer':isLimitedOffer,'voucher':isVoucher,'ticket':isTicket}">
                    {{ isLimitedOffer?$t('goodiebagLimitedOffers'):isVoucher?$t('goodiebagCashVouchers'): isTicket?$t('myWalletTickets'): $t('myWalletCoupons') }}
                </div> -->
                <div class="coupon-type" :class="couponItem.couponTypeClassName">
                    {{ couponItem.couponTypeText }}
                </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import utilsMixin from '@/mixins/utilsMixin'
export default {
  name: 'CouponCodeDisplayRedeem',
  mixins: [utilsMixin],
  props: {
    couponItem: {
      type: Object,
      default: null
    }
  },
  components: {
  },
  computed: {
    storeName () {
      return this.couponItem.storeName || this.couponItem.storeInfo?.name || ''
    },
    storeAddress () {
      return this.couponItem.storeAddress || this.couponItem.storeInfo?.address || ''
    }
  },
  mounted () {
    console.log('@@55: ', this.couponItem)
  },
  methods: {
  }
}
</script>
<style lang='scss' scoped>

.scan-code-success-dialog-content-info{
    display: flex;
    align-items: center;
    flex-flow: column;
    padding: 0px 24px 24px;

    .scan-code-success-check {
        margin-bottom: 24px;  /* 44px - 16px */
        margin-top: 0;
        color: #04BD81;
        font-size: 80px;
        font-weight: 300;
        line-height: 87px;
        // font-family: Open Sans, Microsoft JHei TC Regular, arial, sans-serif;
    }

    .scan-code-success-name {
        text-align: center;
        font-weight: 800;
        font-size: 20px;
        color: var(--Temple-St-Dusk-Maroon, #3B002B);
        word-wrap: break-word;
        margin-bottom: 12px;
    }

    .scan-code-success-description{
        font-weight: 800;
        font-size: 16px;
        color: var(--Temple-St-Dusk-Maroon, #3B002B);
        margin-bottom: 4px;
    }

    .scan-code-success-message{
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        color: var(--temple-st-dusk-maroon-50, #9D8095);
        // word-break: keep-all;
        word-wrap: break-word;
    }

    .scan-code-success-message.scan-code-store{
        margin-bottom: 12px;
    }

    // .dialog-footer .scan-code-success-done{
    //   background: #E2002C;
    //   border-radius: 41px;
    //   width: 150px;
    // }
    .reference-id-contain {
        text-align: center;
        margin-bottom: 12px;
        font-weight: 600;
        font-size: 14px;
        justify-content: left;
        color: var(--temple-st-dusk-maroon-50, #9D8095);

    }

    .coupon-content .coupon-type-container  {

        width: 100%;
        padding-left: 24px;
        padding-right: 24px !important;

        .coupon-type-tags {
            display: flex;
            align-items: center;
            padding-right: 0px;

            .coupon-type{
            &.starred {
                margin-left: 0px;
            }
            border-radius: 3px;
            // display: inline-block;
            padding: 4px 8px;
            font-weight: 700;
            font-size: 12px;

            border: 1px solid hsla(0, 0%, 40%, 1);
            // color: #4875AA;
            color: hsla(0, 0%, 40%, 1);
            // text-transform: uppercase;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            width: fit-content;
            max-width: 100%;
            }
        }
    }
    // .rotation-reminder {
    //   padding-left: 16px;
    //   padding-right: 16px;
    //   justify-content: space-evenly;
    //   display: flex;
    //   flex-flow: row;
    //   color: var(--temple-st-dusk-maroon-50, #9D8095);
    //   font-size: 14px;
    //   font-weight: 500;
    //   text-align: left;
    //   margin-top: 8px;
    //   align-items: center;
    //   img {
    //     height: 30px;
    //     margin-right: 5px;
    //   }
    // }
}
</style>
