<template>
  <div class="campaign-slot-container">
    <div class="slot-section-header-container">
      <div class="title slot-section-title" :class="langClass">{{ campaignSlot.name || (campaignSlot.reward_type === 'CASH_VOUCHER' ? $t('goodiebagCashVouchers') : $t('goodiebagLimitedOffers')) }}</div>
      <div class="slot-section-link-container">
        <a
          class="slot-section-link"
          @click="pushRouterWithQuery($router, { path: 'prize-goodie-bag-campaign-list', query: { gamificationCampaignId, recordId: goodieBagId, goodieBagId, type: campaignSlot.reward_type === 'CASH_VOUCHER' ? 'CASH_VOUCHER_CAMPAIGN_FOR_GOODIE_BAG' : 'GIFT_CAMPAIGN_FOR_GOODIE_BAG', from: $route.name, slotId: campaignSlot.id, stampRewardId }})"
        >
          {{ $t('homeShowMore') }}
        </a>
        <img class="right-arrow-icon" src="/img/right-arrow-purple.png">
      </div>
    </div>
    <div class="tips-container">
      <img src="/img/info.svg"><label v-html="slotDescription" />
    </div>
    <div class="slot-section-campaigns-container">
      <div
        class="campaign-list-container"
      >
        <transition-group
          name="fade"
          class="campaign-list"
          :style="campaignListStyle"
        >
          <coupon-loading-item
            v-for="index in 8"
            v-show="isLoadingCampaignList"
            :key="`campaign-${index}-loading`"
            class="campaign-item"
          />
          <coupon-item
            v-for="(campaign, campaignIndex) in campaigns"
            :key="`campaign-${campaign.id}-${campaignIndex}`"
            class="campaign-item"
            :item="campaign"
            :is-coupon="false"
            :is-campaign="true"
            :using-special-goto="true"
            @gotoDetail="handleClickCampaignItem(campaign)"
          />

          <!-- <div
            v-if="(!campaigns || campaigns.length === 0) && !isLoadingCampaignList"
            key="empty-result"
            class="empty-result"
          >
            <div
              class="empty-result-title"
            >
              {{ $t('goodiebagNoResultsFound') }}
            </div>
          </div> -->
        </transition-group>
      </div>

      <div
        key="left-arrow"
        class="arrow-btn"
        :class="['arrow-btn','left', {active: showLeftArrow}]"
        @click="moveLeft"
      >
        <img
          class="arrow-icon"
          srcset="/img/arrow-icon.1x.png, /img/arrow-icon.2x.png 2x, /img/arrow-icon.3x.png 3x"
          src="/img/arrow-icon.2x.png"
        >
      </div>
      <div
        key="right-arrow"
        :class="['arrow-btn','right', {active: showRightArrow}]"
        @click="moveRight"
      >
        <img
          class="arrow-icon"
          srcset="/img/arrow-icon.1x.png, /img/arrow-icon.2x.png 2x, /img/arrow-icon.3x.png 3x"
          src="/img/arrow-icon.2x.png"
        >
      </div>
    </div>
  </div>
</template>
<script>
// import NotFind from '@/components/NotFind'
import utilsMixin from '@/mixins/utilsMixin'
import CouponLoadingItem from '@/components/CouponLoadingItem'
import CouponItem from '@/components/CouponItem'
import backHandlerMixin from '@/mixins/backHandlerMixin'
import { pushRouterWithQuery } from '@/utils/utils'

export default {
  name: 'GoodieBagSlotItem',
  components: {
    // NotFind
    CouponLoadingItem,
    CouponItem
  },
  mixins: [utilsMixin, backHandlerMixin],
  props: {
    gamificationCampaignId: {
      type: [String, Number],
      default: ''
    },
    goodieBagId: {
      type: [String, Number],
      default: ''
    },
    campaignSlot: {
      type: Object,
      default: null
    },
    stampRewardId: {
      type: [String, Number],
      default: ''
    },
    isLoadingCampaignList: {
      type: Boolean,
      default: true
    },
    campaigns: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      scrollIndex: 0
    }
  },
  computed: {
    showLeftArrow () {
      return this.scrollIndex > 0
    },
    showRightArrow () {
      return this.campaigns.length - this.scrollIndex > 3
    },
    campaignListStyle () {
      let offset = this.scrollIndex * 260
      if (this.campaigns.length - this.scrollIndex === 3) {
        offset -= 204
      }

      return { transform: `translateX(-${offset}px)` }
    },
    slotDescription () {
      if (this.campaignSlot.reward_type === 'GIFT') {
        if (this.campaignSlot.assign_reward_per_link === null) {
          return this.$t('goodiebagSlotsNoLimitedOffer')
        }
        return this.$t('goodiebagSlotsQuota', { count: this.campaignSlot.assign_reward_per_link })
      }
      return this.$t('goodiebagSlotsCredit', { count: this.campaignSlot.assign_reward_per_link })
    }
  },
  watch: {
    'isMobile': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.scrollIndex = 0
      }
    }
  },
  mounted () {},
  methods: {
    moveLeft () {
      if (this.scrollIndex > 0) {
        this.scrollIndex -= 1
      }
    },
    moveRight () {
      if (this.campaigns.length - this.scrollIndex > 3) {
        this.scrollIndex += 1
      }
    },
    handleClickCampaignItem (campaign) {
      pushRouterWithQuery(this.$router, { name: 'prize-campaign-detail', query: { campaignId: campaign.id, recordId: this.goodieBagId, slotId: this.campaignSlot.id }})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/coupon-tab-menu.scss';
@import '@/assets/coupon-item.scss';
@import '@/assets/common.scss';
.campaign-slot-container {
  margin-bottom: 8px;
  .slot-section-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // align-items: flex-end;
    align-items: center;
    margin: 7px 16px;

    .slot-section-title {
      // font-family: HK Remix;
      font-size: 28px;
      font-weight: 700;
      // line-height: 22.4px;
      color: #3B002B;
    }
    .slot-section-link-container {
      display: flex;
      align-items: center;
    }
    .slot-section-link {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      text-decoration-line: underline;
      color: #3B002B;
      position: relative;
      // padding-right: 14px;
      margin-left: 12px;
      white-space: nowrap;

      // &::after {
      //   content: "";
      //   position: absolute;
      //   right: 2px;
      //   top: 8px;
      //   width: 8px;
      //   height: 8px;
      //   border: 2px solid #3B002B;
      //   border-top: 0;
      //   border-left: 0;
      //   transform: rotate(-45deg);
      // }
    }
    .right-arrow-icon {
      width: 12px;
      height: 12px;
      margin-left: 4px;
    }
  }
  .tips-container {
    margin: 8px 16px 0;
    display: flex;
    align-items: flex-end;
    img {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
    label {
      font-size: 14px;
      font-weight: 600;
      // line-height: 16.8px;
      color: #3B002B;

      span {
        font-size: 20px;
        font-weight: 800;
        // line-height: 24px;
      }
    }
  }
  .slot-section-campaigns-container {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    position: relative;

    .campaign-list-container {
      overflow: hidden;
      margin-top: -10px;

      .campaign-list {
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        transition: transform 300ms ease-in-out;
        padding-top: 10px;
        padding-left: 6px;
        padding-right: 6px;
        display: flex;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }

        .campaign-item {
          flex: 0 0 auto;
        }
      }
    }

    .arrow-btn {
      cursor: pointer;
      width: 60px;
      height: 60px;
      position: absolute;
      top: 164px;
      right: -30px;;
      background: #FFFFFF;
      border-radius: 30px;
      // display: flex;
      display: none;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

      .arrow-icon {
        margin-right: -1px;
      }

      &.active {
        z-index: 1;
        opacity: 1;
      }
      &.left {
        right: unset;
        left: -30px;
        transform: rotate(180deg);

        .arrow-icon {
          margin-right: 0;
          margin-left: -1px;
        }

      }
    }
  }
}

.is-vue-desktop .campaign-slot-container {
  .slot-section-campaigns-container {
    .campaign-list-container {
      .campaign-list {
        padding-left: 20px;
        padding-right: 20px;
        overflow-x: unset;
        overflow-y: unset;
      }
    }
    .arrow-btn.active {
      display: flex;
    }
  }
}
</style>
<style lang="scss">

</style>
