import { render, staticRenderFns } from "./GamificationList.vue?vue&type=template&id=77066361&scoped=true&"
import script from "./GamificationList.vue?vue&type=script&lang=js&"
export * from "./GamificationList.vue?vue&type=script&lang=js&"
import style0 from "./GamificationList.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./GamificationList.vue?vue&type=style&index=1&id=77066361&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77066361",
  null
  
)

export default component.exports