<template>
  <img v-if="source" :class="[{ 'vertical-mode': isVertical }, className]" :src="source" />
  <div v-else class="empty" :class="[{ 'vertical-mode': isVertical }, className]" />
</template>

<script>

export default {
  name: 'EmptyImage',
  props: {
    source: {
      type: String,
      default: ''
    },
    className: {
      type: Object,
      default: null
    },
    isVertical: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {

    }
  },

  computed: {
  },

  mounted () {

  },

  beforeDestroy () {

  },

  methods: {

  }
}
</script>
<style lang='scss'>
  div.empty {
    width: 100%;
    height: 100% !important;
  }
  div.empty.vertical-mode {
    height: unset !important;
  }
</style>

