<template>
    <div class="acquired-code-area">
        <!-- <div v-if="couponItem && !isMobile" class="scan-code-success-dialog-content">
          <div class="scan-code-success-check">&check;</div>
          <div class="scan-code-success-name">{{ couponItem.name }}</div>
          <div class="scan-code-success-description">{{ $t('myWalletSuccessfullyRedeemedTitle') }}</div>
          <div class="scan-code-success-message">{{ couponItem.redeemedTime }}</div>
          <div v-if="couponItem.referenceId" class="reference-id-contain">{{ $t('referenceID') }} {{ couponItem.referenceId }}</div>
          <div v-if="$t('myWalletSuccessfullyRedeemedSuffix')" class="scan-code-success-description qrcode-successfully-redeemed-suffix">{{ $t('myWalletSuccessfullyRedeemedSuffix') }}</div>
          <div class="coupon-content">
            <div class="coupon-type-container">
              <div class="coupon-type-tags">
                <div v-if="couponItem.couponCampaignLabel" class="coupon-type starred">{{ couponItem.couponCampaignLabel }}</div>
                <div class="coupon-type" :class="couponItem.couponTypeClassName">
                  {{ couponItem.couponTypeText }}
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <coupon-code-display-redeem :coupon-item="couponItem" />

        <div v-if="data.isShowQrCanvas" class="qr-canvas-contain">
          <div class="qr-canvas-title">{{ data.isTicket?$t('myWalletUseTicket'): $t('myWalletUseCoupon') }}</div>
          <!-- <canvas id="dialog-qr-canvas" /> -->
          <img id="dialog-qr-canvas" :src="qrcodeUrl"/>
          <div v-if="srk" class="qr-canvas-srk" :style="{ width: qrCodeWidth + 'px' }">{{ srk }}</div>
        </div>
        <div v-else class="qr-canvas-contain coupon-detail-mobile-copy-container" :class="{'partner-qr-bar-code':data.isPartnerCode }">
          <div class="coupon-detail-mobile-button-container">
            <div v-if="data.couponCodeFormat==='COUPON_CODE'" class="coupon-detail-mobile-button-tip">
              {{ $t('myWalletUseCouponDiscount') }}
            </div>
            <div v-else-if="data.isPartnerCode" class="coupon-detail-mobile-button-tip">
              {{ data.isTicket? $t('showTicketCodeToStaff') : $t('showCodeToStaff') }}
            </div>
            <div v-else class="coupon-detail-mobile-button-tip">
              {{ $t('myWalletUseCouponDiscountLink') }}
            </div>
            <div class="coupon-detail-mobile-button-text">
              <div v-if="data.couponCodeFormat==='COUPON_CODE' || data.isPartnerCode" class="coupon-detail-mobile-copy-button-text">
                <div class="coupon-detail-mobile-code mobile" :class="{'partner-bar-code': data.isShowPartnerBarCode, 'partner-code': data.isPartnerCode}">
                  <!-- <canvas v-if="data.isShowPartnerQRCode" id="dialog-partner-qr-code-img" /> -->
                  <img v-if="data.isShowPartnerQRCode" id="dialog-partner-qr-code-img" :src="qrcodeUrl"/>

                  <barcode v-else-if="data.isShowPartnerBarCode" :value="data.barcode" :format="data.barcodeFormat" :options="barcodeOption" />
                  <div v-else class="code">
                    {{ data.couponCode || 'N/A' }}
                  </div>

                  <div v-if="data.isShowPartnerBarCode" class="rotation-reminder">
                    <img src="@/assets/images/rotation-hint.png">
                    <p>{{ $t('mycouponfullWindowBarcode') }}</p>
                  </div>
                </div>
                <div v-if="data.displayCouponCode" class="partner-qr-bar-code-code">{{ data.barcode }}</div>
                <div v-else-if="data.isPartnerCode" class="partner-qr-bar-code-reference-id">{{ $t('partnerCodeReferenceId') }} {{ data.referenceId }}</div>
                <div v-else class="coupon-detail-mobile-button copy-code" :style="data.couponCodeUrl ? 'margin-bottom: 0px' : ''" @click="copyCouponCode">{{ $t('myWalletCopyCode') }}</div>
                <div v-if="data.couponCodeUrl" class="coupon-detail-mobile-button shop-now secondary-button" @click="useCouponCodeUrl()">{{ data.couponShopNowName }}</div>
                <!--<div v-if="couponCodeUrl" class="shop-now-url">{{ couponCodeUrl }}</div>-->
              </div>
              <div v-else-if="data.couponCodeFormat==='COUPON_URL'" class="coupon-detail-mobile-copy-button-text">
                <div v-if="data.couponCodeUrl" class="coupon-detail-mobile-url">
                  <div>
                    {{ data.couponCodeUrl }}
                  </div>
                </div>
                <div v-else class="coupon-detail-mobile-url">
                  <div>
                    {{ data.couponCode }}
                  </div>
                </div>
                <div class="coupon-detail-mobile-button copy-code secondary-button primary-button" @click="useCouponCodeUrl()">{{ data.couponShopNowName }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isMerchant" class="merchant-reminder">
          {{$t('myWalletUsingQRCodetip')}}
        </div>
        <div class="footer-buttons">
          <label v-if="isMerchant">{{$t('misisonTaskUseCouponWithMerchantReminder')}}</label>
          <div class="footer-next-mobile-button" :class="{'footer-next-mobile-button-red-bg': !hasNext}" @click="next">{{nextButtonName}}</div>
        </div>
    </div>
</template>
<script>
import VueBarcode from '@chenfengyuan/vue-barcode'
import utilsMixin from '@/mixins/utilsMixin'
import CouponCodeDisplayRedeem from '@/components/CouponCodeDisplayRedeem'
export default {
  name: 'CouponCodeDisplay',
  mixins: [utilsMixin],
  props: {
    data: {
      type: Object,
      default: null
    },
    barcodeOption: {
      type: Object,
      default: null
    },
    hasNext: {
      type: Boolean,
      default: true
    }
  },
  components: {
    barcode: VueBarcode,
    CouponCodeDisplayRedeem
  },
  data () {
    return {
      qrcodeUrl: ''
    }
  },
  computed: {
    couponItem () {
      return this.data.couponItem
    },
    nextButtonName () {
      console.log('@@114: ', this.hasNext)
      if (this.hasNext) {
        return this.$t('gamificationNext')
      }
      return this.$t('gamificationDone')
    },
    srk () {
      return this.data.srk
    },
    isMerchant () {
      return this.data.isMerchant
    }
  },
  mounted () {
    console.log('113: ', this.data)
    if (this.data.isShowPartnerQRCode) {
      this.showQrCodeImg(this.data.barcode, 'dialog-partner-qr-code-img')
    } else if (this.data.isShowQrCanvas) {
      this.showQrCodeImg(this.data.serial_number || this.data.barcode, 'dialog-qr-canvas')
    }
  },
  updated () {
    console.log('113-1: ', this.data.barcode, this.data.isShowPartnerQRCode)
  },
  methods: {
    showQrCodeImg (code, elementId = 'qr-canvas') {
      const canvasCode = code

      if (elementId === 'dialog-qr-canvas') {
        this.isShowQrCanvas = true
        this.qrCodeWidth = '262'
        // canvasCode = this.qrCodeCouponCode serial_number
      } else if (elementId === 'dialog-partner-qr-code-img') {
        this.qrCodeWidth = '262'
        if (this.isMobile) {
          this.qrCodeWidth = '262'
        }
      }

      // const canvas = document.getElementById(elementId)
      // console.log('@@113-2: ', canvas, canvasCode)
      const QRCode = require('qrcode')
      // QRCode.toCanvas(canvas, canvasCode, { width: this.qrCodeWidth, margin: 0 }, function (error, canvass) {
      //   if (error) console.error(error)

      //   console.log('@@113-3: ', canvass.children)
      // })
      const vm = this
      QRCode.toDataURL(canvasCode, { width: this.qrCodeWidth, margin: 0 }, function (err, url) {
        if (err) console.error(err)
        vm.qrcodeUrl = url || ''
      })

      // if (elementId === 'qr-canvas') {
      //   window.scrollTo(0, 0)
      // }
    },
    async copyCouponCode () {
      console.log('copy code: ', this.data.couponCode)
      // this.$clipboard(this.data.couponCode)
      try {
        await navigator.clipboard.writeText(this.data.couponCode)
        console.log('Content copied to clipboard')
        /* Resolved - text copied to clipboard successfully */
      } catch (err) {
        console.error('Failed to copy: ', err)
        /* Rejected - text failed to copy to the clipboard */
      }
      if (this.data.couponCode.startsWith('http')) {
        this.showShortLinkCopySuccess()
      } else {
        this.showCodeCopySuccess()
      }
    },
    async copyCouponCodeUrl () {
      console.log('copy code url')
      // this.$clipboard(this.data.couponCodeUrl)
      try {
        await navigator.clipboard.writeText(this.data.couponCodeUrl)
        console.log('Content copied to clipboard')
        /* Resolved - text copied to clipboard successfully */
      } catch (err) {
        console.error('Failed to copy: ', err)
        /* Rejected - text failed to copy to the clipboard */
      }
      this.showShortLinkCopySuccess()
    },
    useCouponCodeUrl () {
      if (this.data.couponCodeUrl) {
        if (this.isWechatMiniProgram) {
          this.copyCouponCodeUrl()
        } else {
          window.open(this.data.couponCodeUrl)
        }
      } else {
        if (this.isWechatMiniProgram) {
          this.copyCouponCode()
        } else {
          console.log('@@154: ', this.data)
          window.open(this.data.couponCode)
        }
      }
    },
    next () {
      console.log('goto next')
      // if (this.data.isShowPartnerQRCode) {
      //   this.showQrCodeImg(this.data.barcode, 'partner-qr-code-img')
      // }
      this.$emit('next')
    }
  }
}
</script>
<style lang='scss' scoped>

.acquired-code-area {
  padding: 16px;

  .coupon-detail-mobile-button-container, .coupon-detail-button-container {
    background-color: #fff;
    // margin-top: 12px;
    border-radius: 10px;
    width: 100%;

    .coupon-detail-mobile-button-tip {
      background: #f4f4f4;
      padding: 12px;
      font-weight: 600;
      font-size: 14px;
      line-height: 1.2;
      color: var(--Temple-St-Dusk-Maroon, #3B002B);

      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .coupon-detail-button-tip{
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      background: #E8E8E8;
      padding: 16px;
      color: #000000;
    }

    .coupon-detail-mobile-copy-button-text:has(> .partner-code) {
      padding: 16px;
      padding-bottom: 0px !important;
      gap: 16px;
    }

    .coupon-detail-mobile-copy-button-text:has(> .partner-code.partner-bar-code) {
      padding: 16px 0px;
      // padding-bottom: 0px !important;
      // gap: 16px;

      .partner-bar-code {
        margin: 0px;
      }

      .rotation-reminder {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;
        color: var(--temple-st-dusk-maroon-50, #9D8095);
      }
    }

    .coupon-detail-mobile-use-button-text, .coupon-detail-mobile-copy-button-text {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .coupon-detail-mobile-use-button-text .coupon-unavailable {
      line-height: 20px;
      opacity: 0.3;
      padding: 4px 8px;
    }

    .coupon-detail-mobile-copy-button-text {
      .partner-qr-bar-code-code, .partner-qr-bar-code-reference-id {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.2;
        color: var(--Temple-St-Dusk-Maroon, #3B002B);

      }
    }

    .coupon-detail-mobile-code, .coupon-detail-code-contain {
      border-bottom: 1px dashed #979797;
      color: #8B8E96;
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 24px;
      padding-bottom: 24px;
      padding-left: 16px;
      padding-right: 16px;
      word-break: break-all;
      span{
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .code{
        background: #E6E9F3;
        padding: 20px 32px;
        // padding-left: 35px;
        // padding-right: 35px;
        // padding-top: 14px;
        // padding-bottom: 14px;
        border: 2px dashed #05218A;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.2;
        background: #05218A1A;
        color: #05218A;
        text-align: center;
        border-radius: 5px;
      }
    }

    .coupon-detail-mobile-code.mobile{

      &.partner-code {
        border-bottom: unset;
      }
      border-bottom: 1px solid var(--temple-st-dusk-maroon-80, #D8CCD5)
      // padding-bottom: 0px;
    }

    .coupon-detail-mobile-button, .coupon-detail-button {
      cursor: pointer;
      background: var(--Junk-Boat-Red);
      border-radius: 24px;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 600;
      height: 36px;
      text-align: center;
      line-height: 1.2;
      margin: 20px 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .coupon-detail-mobile-button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      color: var(--Junk-Boat-Red);
      font-weight: 600;
      font-size: 16px;
      line-height: 1.2;
      border: 2px solid var(--Junk-Boat-Red);
      box-sizing: border-box;
      border-radius: 41px;
      // margin-left: 42px;
      // margin-right: 42px;
      // margin-top: 24px;
    }
  }

  // .coupon-detail-mobile-copy-container {
  //   margin-left: 14px;
  //   margin-right: 14px;
  // }

  .coupon-detail-mobile-button.copy-code{
    background: var(--Junk-Boat-Red);
    color: #FFFFFF;
    margin-bottom: 0px;
  }
  .coupon-detail-mobile-button.shop-now{
    // margin-top: 0px;
    margin-bottom: 0px;
  }

  .qr-canvas-contain{
    display: flex;
    flex-flow: column;
    align-items: center;
    // box-shadow: 0px 2px 4px rgba(204, 204, 204, 0.5);
    // border: 1px solid rgba(204, 204, 204, 0.5);
    box-shadow: 0px 0px 16px 0px #00000024, 0px 0px 4px 0px #0000000F;
    border-radius: 10px;
    gap: 16px;
    padding-bottom: 16px;
    // margin: 16px;
    // margin-top: 16px;
    // margin-left: 16px;
    // margin-right: 16px;
    // margin-bottom: 21px;
  }
  .qr-canvas-title{
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    background: #f4f4f4;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: var(--Temple-St-Dusk-Maroon, #3B002B);
    text-align: center;
  }
  .qr-canvas-srk{
    background-color: #FFFFFF;
    font-weight: 600;
    line-height: 1.2;
    color: var(--Temple-St-Dusk-Maroon, #3B002B);
    text-align: center;
    // padding-bottom: 16px;
    font-size: 14px;
  }

  .merchant-reminder {
    color: var(--temple-st-dusk-maroon-50, #9D8095);
    font-size: 14px;
    font-weight: 600;
    margin-top: 16px;
    text-align: left;
  }

  .qr-canvas-footer{
    margin: 16px;
    color: #999999;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
  }

  .coupon-detail-mobile-url, .coupon-detail-url{
    padding: 24px 12px;
    text-align: center;
    color: var(--temple-st-dusk-maroon-50, #9D8095);

    font-weight: 700;
    font-size: 16px;
    border-bottom: 1px dashed var(--temple-st-dusk-maroon-80, #D8CCD5);
    word-break: break-all;
    div{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .footer-buttons {
    position: fixed;
    padding: 12px 16px 16px;
    margin-left: -16px;
    background: #FFF;
    border-top: 1px solid #DADADA;
    bottom: 0;
    width: 100%;

    label {
      font-size: 16px;
      font-weight: 600;
      color: var(--Junk-Boat-Red, #FF1700);
      margin-bottom: 8px;
      display: block;
      text-align: left;
    }

    .footer-next-mobile-button {
      display: flex;
      justify-content: center;
      align-items: center;

      background: #FFF;
      color: var(--Junk-Boat-Red);

      font-weight: 600;
      font-size: 16px;
      line-height: 1.2;
      border: 2px solid var(--Junk-Boat-Red);;
      box-sizing: border-box;
      border-radius: 41px;
      height: 44px;
      cursor: pointer;
    }

    .footer-next-mobile-button-red-bg {
      background: var(--Junk-Boat-Red);;
      color: #fff;
    }
  }
}

.is-vue-desktop {
  .acquired-code-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 124px;
    padding-top: 24px;

    height: 746px;
    max-height: 746px;
    justify-content: center;

    .qr-canvas-contain {
      border: none;
      padding-bottom: 0px;
    }

    .scan-code-success-dialog-content{
      display: flex;
      align-items: center;
      flex-flow: column;
      margin-bottom: 24px;

      .scan-code-success-check {
        margin-bottom: 24px;  /* 44px - 16px */
        margin-top: 0;
        color: #04BD81;
        font-size: 80px;
        font-weight: 300;
        line-height: 87px;
        // font-family: Open Sans, Microsoft JHei TC Regular, arial, sans-serif;
      }

      .scan-code-success-name {
        text-align: center;
        font-weight: 800;
        font-size: 20px;
        color: var(--Temple-St-Dusk-Maroon, #3B002B);
        word-wrap: break-word;
        margin-bottom: 12px;
      }

      .scan-code-success-description{
        font-weight: 600;
        font-size: 14px;
        color: var(--temple-st-dusk-maroon-50, #9D8095);
        margin-bottom: 4px;
      }

      .scan-code-success-message{
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        color: var(--Temple-St-Dusk-Maroon, #3B002B);
        // word-break: keep-all;
        word-wrap: break-word;
        margin-bottom: 12px;
      }

      // .scan-code-success-message.scan-code-store{
      //   margin-bottom: 8px;
      // }

      // .dialog-footer .scan-code-success-done{
      //   background: #E2002C;
      //   border-radius: 41px;
      //   width: 150px;
      // }
      .reference-id-contain{
        margin-bottom: 12px;
        font-weight: 600;
        font-size: 16px;
        justify-content: left;
        color: var(--Temple-St-Dusk-Maroon, #3B002B);

      }

      .coupon-content .coupon-type-container  {

        width: 100%;
        padding-left: 24px;
        padding-right: 24px !important;

        .coupon-type-tags {
          display: flex;
          align-items: center;
          padding-right: 0px;

          .coupon-type{
            &.starred {
              margin-left: 0px;
            }
            border-radius: 3px;
            // display: inline-block;
            padding: 4px 8px;
            font-weight: 700;
            font-size: 12px;

            border: 1px solid hsla(0, 0%, 40%, 1);
            // color: #4875AA;
            color: hsla(0, 0%, 40%, 1);
            // text-transform: uppercase;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            width: fit-content;
            max-width: 100%;
          }
        }
      }
    }

    .coupon-detail-mobile-copy-container {
      width: 400px;
      border-radius: 10px;
      .coupon-detail-mobile-button-container {
        margin-top: 0px;
        border-radius: 10px;
        border: 2px solid var(--temple-st-dusk-maroon-80, #D8CCD5);

        .coupon-detail-mobile-button-tip {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }

        .coupon-detail-mobile-code.mobile{
          &.partner-code {
            padding: 0px;
          }
          // border-bottom: 1px solid var(--temple-st-dusk-maroon-80, #D8CCD5)
          // padding-bottom: 0px;
        }
      }

      .partner-bar-code {
        flex-direction: column;
        .rotation-reminder {
          display: none;
          padding-left: 12px;
          padding-right: 12px;
          justify-content: space-evenly;
          // display: flex;
          flex-flow: row;
          color: var(--temple-st-dusk-maroon-50, #9D8095);
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          margin-top: 8px;
          align-items: center;
          img {
            height: 30px;
            margin-right: 5px;
          }
        }
      }
    }

    .coupon-detail-mobile-button.copy-code{
      margin-bottom: 16px;
    }
    .coupon-detail-mobile-button.shop-now{
      // margin-top: 0px;
      margin-bottom: 16px;
    }

    .footer-buttons {
      text-align: -webkit-center;
      position: absolute;
      margin-left: 0px;

      .footer-next-mobile-button {
        // padding: 14.5px 47.5px;
        // width: fit-content;

        // margin-right: auto;
        width: 240px;
        justify-content: center;
      }
    }
  }
}
</style>
