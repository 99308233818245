<template>
  <div :class="['mission-task-item-container', {'disabled': isExpired || isOutOfStock}]" @click="gotoDetail">
    <div v-if="isExpired" class="item-tip-container">
      <div class="coupon-offer-label">
        <img src="@/assets/images/offer-label-shadow.png" class="coupon-offer-label-shadow">
        <div class="coupon-offer-label-content">
          <div class="coupon-offer-label-font">
            <div class="coupon-offer-label-font-item">{{ $t('myWalletExpired') }}</div>
          </div>
          <div class="coupon-offer-label-right-image" />
          <!--
          <img src="@/assets/images/offer-label-right.png" class="coupon-offer-label-right-image">
          -->
        </div>
      </div>
    </div>
    <div v-if="isOutOfStock" class="item-out-stock-label">{{ $t('campaignOutOf') }}</div>
    <div class="item-container-div">
      <div class="item-container">

        <div class="item-content">
          <div v-if="tags && tags.length > 0" class="tags"><label v-for="(tag, tagIndex) in tags" :key="tagIndex" class="tag-label is-collapse-1lines">{{ tag }}</label></div>

          <label class="item-title" :class="titleMaxHeight">{{ name }}</label>
          <!-- <div class="label-container">
            <div v-if="item.is_featured" class="featured-label">{{ $t('goodiebagFeatured') }}</div>
            <div v-if="item.offer_type_label && item.offer_type_label.name" class="featured-label">{{ item.offer_type_label.name }}</div>
          </div> -->

          <div v-if="!isCampaign" class="location-area">
            <label class="location-district">{{ item.district.name }}</label>
            <label :class="['location-address', {'is-collapse-2lines': brandName || !!tags.length }]" >{{ item.address }}</label>
          </div>

          <label v-if="distance" class="distance">{{ $t('missionTaskKmAway', { distance }) }}</label>
        </div>
        <div class="photos">
          <!-- <img
            :class="{'item-header': true}"
            :src="coverImageUrl"
          /> -->
          <empty-image :class-name="{'item-header': true}" :source="coverImageUrl" />
          <!-- <img v-if="coverImageUrl" :class="{'item-header': true}" :src="coverImageUrl" />
          <div v-else :class="{'item-header': true}"/> -->
          <div v-if="logoLabels && logoLabels.length > 0" class="item-logo-container">
            <!-- <component
              :is="logo.icon_url ? 'img' : 'div'"
              v-for="logo in logoLabels.slice(0, 3)"
              :key="logo.id"
              class="item-logo"
              :src="logo.icon_url || false"
            /> -->
            <img
              v-for="logo in logoLabels.slice(0, 3)"
              :key="logo.id"
              class="logo-label-icon"
              :src="logo.icon_url"
            >
          </div>

        </div>

      </div>

      <div class="acquire-area" :style="displayNoteMessage ? {} : {}">
        <!-- <hr /> -->
        <div v-if="brandName" class="item-merchant-container">
          <img
            class="item-merchant-icon"
            :src="brandImageUrl"
          >
          <div class="item-merchant-name clamp-2 toastui-editor-contents" v-html="brandName" />
        </div>
        <button
          v-if="!isEarning && ((!isMobile && !item.error_message) || (isMobile)) && !isCompleted"
          class="button primary-button"
          :disabled="!isValid || !isInActivePeriod"
          @click.stop="getOrUseCoupon"
        >
          {{ buttonName }}
        </button>
        <div v-if="isEarning" class="button loading-dot" @click.stop><img src="/img/loading-red.gif" /></div>
        <div v-if="!isEarning && isCompleted" class="completed-button">{{ $t('missionTaskCompletedBtn') }}</div>
      </div>
      <!-- <div><label v-if="displayNoteMessage" class="reminder">{{ displayNoteMessage }}</label></div> -->
      <label v-if="displayNoteMessage" class="reminder">{{ displayNoteMessage }}</label>
      <!-- <label v-if="item.id % 2 > 0" class="reminder">You have already reached the acquisition limit for the ‘Star offer name’ dining vouchers .</label> -->
      <!-- <div v-if="brandName && logoLabels && logoLabels.length" class="divider" />
      <div v-if="logoLabels && logoLabels.length" class="logo-label-container">
        <div class="left">
          <div
            class="logo-label-icon"
            :style="logoLabels[0].icon_url ? {backgroundImage: 'url(' + logoLabels[0].icon_url+ ')'} : false"
          />
          <div class="logo-label-name clamp-2 toastui-editor-contents" v-html="logoLabels[0].name" />
        </div>
        <img class="right-arrow-icon" src="/img/right-arrow-purple.png">
      </div> -->
    </div>

  </div>
</template>
<script>
// import axios from 'axios'
import utilsMixin from '@/mixins/utilsMixin'
import missionMixin from '@/mixins/missionMixin'
// import { getCampaignlimit } from '@/helpers/http'
import EmptyImage from '@/components/EmptyImage'

import { sendManualAnalytics } from '@/utils/tracking'
// import { pushRouterWithQuery, getCampaignAPIPrefix, getChannelName, isWechatMiniProgram } from '@/utils/utils'

export default {
  name: 'MissionTaskItem',
  components: {
    EmptyImage
  },
  mixins: [utilsMixin, missionMixin],
  props: {
    missionData: {
      type: Object,
      default: null
    },
    missionIsValid: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object,
      default: null
    },
    isCampaign: {
      type: Boolean,
      default: true
    },
    isInActivePeriod: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: null
    },
    latitude: {
      type: String,
      default: null
    },
    longitude: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isEarning: false
    }
  },
  computed: {
    item () {
      if (this.isCampaign) {
        return this.data
      }

      return this.data.location
    },
    tags () {
      let missionLabels = []
      if (this.item.show_mission_labels && this.item.mission_labels && this.item.mission_labels.length) {
        missionLabels = this.item.mission_labels.filter((missionLabel) => missionLabel)
      }
      return missionLabels
    },
    isUseCoupon () {
      return this.item.can_use_coupon_id
    },
    coverImageUrl () {
      let url = '/img/trans1px.png'
      url = this.item.cover_photo_url
      return url
    },
    name () {
      let name = this.item.name
      if (this.isCampaign && this.isUseCoupon) {
        name = this.item.coupon_campaign_type_coupon_template && this.item.coupon_campaign_type_coupon_template.name
      }
      return name || ''
    },
    buttonName () {
      let name = ''
      if (this.isCampaign) {
        name = this.$t('missionTaskUseBtn')
        // if (this.isUseCoupon) {
        //   name = this.isTicket ? this.$t('homeUseTicket') : this.$t('homeUseCoupon')
        // }
      } else {
        name = this.$t('missionTaskCheckInBtn')
      }
      return name
    },
    brandName () {
      let name = ''
      const brand = this.item.brand
      if (brand) {
        name = brand.name
      }
      return name || ''
    },
    brandImageUrl () {
      let result = ''
      const brand = this.item.brand
      if (brand && brand.icon_url) {
        result = brand.icon_url
      }
      return result
    },
    logoLabels () {
      let result = null
      if (this.isCampaign && this.item.logo_labels) {
        result = [...this.item.logo_labels].sort((a, b) => b.display_priority - a.display_priority)
      }
      return result
    },
    needCheckGPS () {
      let needCheckGPS = false
      if (this.isCampaign) {
        if (this.item.can_use_coupon_id) {
          needCheckGPS = this.item.coupon_campaign_type_coupon_template.is_check_gps
        } else {
          needCheckGPS = this.item.is_check_gps
        }
      } else {
        needCheckGPS = this.data.check_in_mode !== 'QR_CODE'
      }

      return needCheckGPS
    },
    titleMaxHeight () {
      if (this.isMobile) {
        return 'is-collapse-3lines'
      }

      if (this.tags?.length && this.distance) {
        return 'is-collapse-3lines'
      }

      if (this.tags?.length || this.distance) {
        return 'is-collapse-4lines'
      }

      return 'is-collapse-5lines'
    },
    formatDistance () {
      if (this.item.distance) {
        return this.item.distance.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }

      return null
    },
    isExpired () {
      const now = new Date()
      if (!this.isInValidCountryCode) {
        return false
      }
      if (this.isUseCoupon) {
        if ((this.item.coupon.expiry_datetime && new Date(this.item.coupon.expiry_datetime) < now) || this.item.error_message === this.$t('couponInvalidPrompt')) {
          return true
        }
      } else if (this.isCampaign) {
        return this.item.end_date && new Date(this.item.end_date) < now
      }
      return false
    },
    isOutOfStock () {
      if (!this.isUseCoupon && ((this.item.coupon_campaign_type_coupon_template?.id && !this.item.coupon_campaign_type_coupon_stock) || this.item.is_out_of_stock)) {
        return true
      }
      return false
    },
    isCompleted () {
      return this.data.is_completed
    },
    isTicket () {
      if (this.isUseCoupon) {
        return this.verifyDisplayIn('TICKET_SECTION')
      }
      return null
    },
    isReachStampLimit () {
      if (
        (this.missionData.per_head_limit || this.missionData.per_head_limit === 0) &&
        this.missionData.collected_stamp_rewards_count + this.missionData.per_action_stamp_reward_quantity > this.missionData.per_head_limit
      ) {
        return true
      }
      // if (
      //   (this.missionData.per_head_periodic_limit || this.missionData.per_head_periodic_limit === 0) &&
      //   this.missionData.collected_stamp_rewards_in_period_count >= this.missionData.per_head_periodic_limit
      // ) {
      //   return true
      // }
      return false
    },
    isValid () {
      let result = this.missionIsValid
      const now = new Date()
      if (this.isUseCoupon) {
        if (
          (this.item.coupon.effective_date && new Date(this.item.coupon.effective_date) > now) ||
          (this.item.coupon.expiry_datetime && new Date(this.item.coupon.expiry_datetime) < now)
        ) {
          result = false
        } else if (this.item.error_message) {
          result = false
        }
      } else if (this.isCampaign) {
        if (this.item.is_valid === false) {
          result = false
        } else {
          // if (this.isReachStampLimit) {
          //   result = false
          // }
        }
      }
      console.log('@@isValid: ', this.item.error_message, result)
      return result
    },
    isInValidCountryCode () {
      let result = true
      if (this.isCampaign) {
        if (this.item.is_valid === false) {
          const profile = this.$store.getters['auth/userProfile']
          const customerCountryCode = profile?.mobile_phone_number_country_code
          const country_codes = this.item.country_codes || []
          if (
            customerCountryCode &&
            country_codes.length &&
            !country_codes.filter((countryCodeData) => countryCodeData.id === customerCountryCode).length
          ) {
            result = false
          }
        }
      }
      return result
    },
    displayNoteMessage () {
      let result = ''
      const now = new Date((new Date()).getTime() + 1000)
      if (this.isUseCoupon) {
        if (this.item.coupon.effective_date && new Date(this.item.coupon.effective_date) > now) {
          const effectiveDate = this.getDateString(this.item.coupon.effective_date, 'D MMM YYYY')
          result = `${this.isTicket ? this.$t('myWalletTicketAvailableTime') : this.$t('myWalletAvailableTime')} ${effectiveDate} ${this.$t('myWalletAvailableTimeSuffix')}`
        } else if (this.item.coupon.expiry_datetime && new Date(this.item.coupon.expiry_datetime) < now || this.item.error_message === this.$t('couponInvalidPrompt')) {
          // usually won't have this situation
          result = this.$t('CouponExpiredErrorMessage', { couponExpiredOn: this.getDateString(this.item.coupon.effective_date, 'D MMM YYYY') })
        } else {
          const formatError = this.checkUseCouponFailHandle(this.item.error_message)
          result = formatError.displayErrorMessage
          if (!result) {
            if (this.isReachStampLimit) {
              result = 'No more stamp(s) will be issued.'
            }
          }
        }
      } else if (this.isCampaign) {
        if (this.item.is_valid === false) {
          if (!this.isInValidCountryCode) {
            result = this.item.error_message
          } else if (new Date(this.item.start_date) > now) {
            const startTime = this.getDateString(new Date(this.item.start_date), 'D MMM YYYY')
            result = this.$t('availableOn', { startDate: startTime })
          } else if (this.isExpired) {
            // result = `You can earn ${this.missionData.per_action_stamp_reward_quantity} stamp until the coupon is used.`
            result = this.$t('gamificationMissionExpiredOn') + this.getDateString(this.item.end_date, 'D MMM YYYY')
          } else if (this.isOutOfStock) {
            result = ''
          } else if (this.item.error_message) {
            result = this.item.error_message
          } else if (this.isReachStampLimit) {
            result = 'No more stamp(s) will be issued.'
          } else {
            // result = `You can earn ${this.missionData.per_action_stamp_reward_quantity} stamp until the coupon is used.`
            result = ''
          }
        } else {
          // result = `You can earn ${this.missionData.per_action_stamp_reward_quantity} stamp until the coupon is used.`
          result = ''
        }
      }
      return result
    },
    distance () {
      if (!this.isCampaign) {
        // var R = 6371 // km
        // var dLat = this.toRad(this.item.latitude - this.latitude)
        // var dLon = this.toRad(this.item.longitude - this.longitude)
        // var lat1 = this.toRad(this.latitude)
        // var lat2 = this.toRad(this.item.latitude)

        // var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        //   Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
        // var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        // var d = R * c
        // console.log('@@361: ', d, this.data.distance)
        return this.data.distance && this.data.distance.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }

      console.log('@@350---: ', this.item.stores, this.latitude, this.longitude)
      if (this.latitude && this.longitude) {
        const distances = this.item.stores?.filter(item => item.latitude && item.longitude).map((item) => {
          var R = 6371 // km
          var dLat = this.toRad(item.latitude - this.latitude)
          var dLon = this.toRad(item.longitude - this.longitude)
          var lat1 = this.toRad(this.latitude)
          var lat2 = this.toRad(item.latitude)

          var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
          var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
          var d = R * c
          return d
        })

        return distances && Math.min(...(distances || [])).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    }
  },
  mounted () {

  },
  methods: {
    toRad (value) {
      return value * Math.PI / 180
    },
    verifyDisplayIn (sectionName) {
      let result = false
      if (this.item.coupon_campaign_type_coupon_template && this.item.coupon_campaign_type_coupon_template.display_in === sectionName) {
        result = true
      }
      return result
    },
    gotoDetail () {
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'gamification_campaign_mission_list_task_click', 'event', { task_id: this.item.id, task_name: this.item.name })
      this.$emit('gotoTaskDetail', this.data)
    },
    getOrUseCoupon () {
      this.isEarning = true
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'gamification_campaign_mission_detail_task_click', 'onclick', { task_id: this.item.id, task_name: this.item.name, for: this.isUseCoupon ? 'use' : 'redeem' })
      this.$emit('participateMissionTask', { ...this.data, targetIndex: this.index })
      // setTimeout(() => {
      //   this.earnDone()
      // }, 1500)
    },
    // updateErrorMessage (error) {
    //   if (error.coupon.effective_date) {
    //   }
    // },
    earnDone () {
      this.isEarning = false
    }
  },
  updated () {
    // if (this.item) {
    //   this.item.errorMessage = 'You have already reached the acquisition limit for the ‘Star offer name’ dining vouchers .'
    // }
  }
}
</script>
<style scoped lang='scss'>
@import "@/assets/mission-task-item.scss";
</style>
