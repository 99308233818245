import axios from 'axios'
import moment from 'moment'
import { getCampaignAPIPrefix } from '@/utils/utils'

export const getGoodieBagList = (items, isTestUser) => {
  // Color List: Red, Blue, Yellow
  const itemBgColorList = ['#E2002C', '#015999', '#D6B345']
  let lastItemGoodieBagId
  let lastItemBrandId
  let itemColorIndex = 0
  items = items
    .sort((a, b) => { return parseInt(a.id) - parseInt(b.id) })
    .map((item) => {
      item.recently_added = moment(new Date()).diff(item.creation_date, 'hours') <= 24
      const {
        goodie_bag: goodieBag,
        brand_id: brandId,
        coupon_acquire_end_date: expiryDate
      } = item

      const {
        id: goodieBagId,
        display_start_date: displayStartDate,
        display_end_date: displayEndDate
      } = goodieBag || {}

      if (goodieBagId !== lastItemGoodieBagId || brandId !== lastItemBrandId) {
        itemColorIndex += 1
      }
      const validStartDate = displayStartDate ? new Date(displayStartDate) : new Date('0000-01-01')
      const validEndDate = displayEndDate ? new Date(displayEndDate) : new Date('9999-12-31')
      const colorIndex = (itemColorIndex - 1) % 3
      const { [colorIndex]: color } = itemBgColorList
      let backgroud_color = color
      const is_expired = expiryDate && new Date() > new Date(expiryDate)
      const is_invalid = new Date() > validEndDate || new Date() < validStartDate
      lastItemBrandId = brandId
      lastItemGoodieBagId = goodieBagId
      backgroud_color = '#E2002C' // update all color goodie bag card background color as red

      // rderValue for mix with gamification campaign
      let orderValue1 = ''
      let orderValue2 = ''
      let orderValue3 = ''
      let orderValue4 = ''
      if (isTestUser) {
        orderValue1 = item.goodie_bag.id
      } else {
        orderValue1 = item.creation_date
        orderValue2 = item.coupon_acquire_end_date
        orderValue3 = item.goodie_bag.name
        orderValue4 = item.goodie_bag.id
      }
      return {
        ...item,
        backgroud_color,
        is_expired,
        is_invalid,
        recordType: 'goodiebag',
        orderValue1,
        orderValue2,
        orderValue3,
        orderValue4
      }
    })
  let activeItems = []
  let expiredItems = []
  if (isTestUser) {
    activeItems = items.sort((a, b) => {
      return parseInt(b.goodie_bag.id) - parseInt(a.goodie_bag.id)
    })
  } else {
    activeItems = items
      .filter((item) => !item.is_expired && !item.is_invalid)
      .sort((a, b) => {
        if (a.creation_date !== b.creation_date) {
          if (a.recently_added && b.recently_added) {
            return new Date(b.creation_date) - new Date(a.creation_date)
          } else if (!a.recently_added && b.recently_added) {
            return 1
          } else if (a.recently_added && !b.recently_added) {
            return -1
          }
        }
        // following logic are both a & b not recently
        if (!a.coupon_acquire_end_date && b.coupon_acquire_end_date) {
          return 1
        }
        if (a.coupon_acquire_end_date && !b.coupon_acquire_end_date) {
          return -1
        }
        if (a.coupon_acquire_end_date && b.coupon_acquire_end_date && a.coupon_acquire_end_date !== b.coupon_acquire_end_date) {
          return Date.parse(a.coupon_acquire_end_date) - Date.parse(b.coupon_acquire_end_date)
        }

        if (a.goodie_bag.name && b.goodie_bag.name) {
          return a.goodie_bag.name.toLowerCase() > b.goodie_bag.name.toLowerCase() ? 1 : -1
        }

        // template name not exist, should not happen
        return 0
      })
    expiredItems = items
      .filter((item) => item.is_expired && !item.is_invalid)
      .sort((a, b) => {
        if (a.goodie_bag.name && b.goodie_bag.name) {
          return a.goodie_bag.name.toLowerCase() > b.goodie_bag.name.toLowerCase() ? 1 : -1
        }

        // template name not exist, should not happen
        return 0
      })
  }
  return [...activeItems, ...expiredItems]
}

const getActiveRecords = (records) => {
  return records
    .filter((item) => {
      const { goodie_bag: { display_end_date: expiryDate }
      } = item
      const isExpired = expiryDate && new Date() > new Date(expiryDate)
      return !isExpired
    })
}

export const goodiebagparticipationrecords = {
  namespaced: true,
  state: {
    records: [],
    initFinished: false
  },
  actions: {
    refreshRecords: async ({ commit, rootState }) => {
      const ssoUid = rootState.auth.ssoUid
      const url = getCampaignAPIPrefix() + `/customers/${ssoUid}/goodie_bag_participation_records?sort=id&page[number]=1&page[size]=500`
      console.log('@113  123---', url)
      const response = await axios.get(url)
      const records = response.data || []
      console.log('@258x', records)
      commit('initRecord', records)
    },
    updateRecord: async ({ commit, rootState }, params) => {
      const { recordId, isGamoficationPrize } = params
      const query = isGamoficationPrize ? '?is_gamification=true' : ''
      const ssoUid = rootState.auth.ssoUid
      const url = getCampaignAPIPrefix() + `/customers/${ssoUid}/goodie_bag_participation_records/${recordId}${query}`
      console.log('@122  123---', url)
      const response = await axios.get(url)
      const record = response.data || []
      commit('updateRecord', record)
    }
  },
  mutations: {
    initRecord (state, records) {
      state.records = records
      state.initFinished = true
    },
    updateRecord (state, record) {
      state.records = [
        ...state.records.filter(item => item.id !== record.id),
        record
      ]
    }
  },
  getters: {
    initFinished: (state) => { return state.initFinished },
    recordList: (state) => (isTestUser) => {
      return getGoodieBagList(state.records, isTestUser)
    },
    hasRecord: (state) => {
      const activeItems = getActiveRecords(state.records)
      return activeItems.length > 0
    },
    hasRecentRecord: (state) => {
      const activeItems = getActiveRecords(state.records)
      return activeItems.some(
        record => {
          const {
            creation_date: creationDate,
            coupon_acquire_end_date: expiryDate
          } = record
          const within24hr = moment(new Date()).diff(creationDate, 'hours') <= 24
          const isExpired = expiryDate && new Date() > new Date(expiryDate)
          return within24hr && !isExpired
        }
      )
    },
    recordDetail: (state) => (id) => {
      return state.records.find(records => parseInt(records.id) === parseInt(id))
    }
  }
}

export default goodiebagparticipationrecords
