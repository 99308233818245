<template>
  <div>
    <div v-if="participateDirectId" class="loading-view">
      <img src="/img/loading.gif"/>
    </div>
    <div v-else-if="isNoData" class="no-data">
      <not-find />
    </div>
    <div v-else class="gamification-detail-container mission-location-detail-main-container applicable-stores-detail" :class="langClass">
      <label v-if="isMobile" class="title" :class="langClass">{{ locationData && locationData.name }}</label>

      <div class="cover-images">
        <agile
          v-if="scrollphotos && scrollphotos.length"
          class="carousel detail"
          :slides-to-show="1"
          :autoplay="scrollphotos.length > 1"
          :nav-buttons="scrollphotos.length && scrollphotos.length > 1"
          :dots="scrollphotos.length && scrollphotos.length > 1"
          :infinite="scrollphotos.length && scrollphotos.length > 1"
        >
          <div v-for="(item, index) in scrollphotos" :key="index" class="slide">
            <div class="slide-image" :style="{ backgroundImage: 'url(' + item + ')' }" />
          </div>
          <template slot="prevButton">
            <div class="action action-prev">
              <img :data-aid="'mission_location_detail_image_view'" src="/img/carousel_left.png">
            </div>
          </template>
          <template slot="nextButton">
            <div class="action action-next">
              <img :data-aid="'mission_location_detail_image_view'" src="/img/carousel_right.png">
            </div>
          </template>
        </agile>
      </div>

      <div class="mission-block">
        <label v-if="!isMobile" class="title" :class="langClass">
          {{ locationData && locationData.name }}
        </label>
        <div v-if="locationData.description">
          <div ref="descriptionTarget" class="description toastui-editor-contents" :class="{'is-collapse-8lines-html': !isMobile && isCollapseDescription, 'is-collapse-2lines-html': isMobile && isCollapseDescription}" v-html="locationData.description" />
          <div v-if="isCollapseDescription" class="see-more-button" @click="seeMore">
            {{ $t('myWalletSeeMore') }}
            <img src="/img/down-arrow-purple.png">
            <!-- <div class="arrow down"/> -->
          </div>
        </div>
        <button v-if="!isMobile && missionId && !isChecking" :class="['common-button check-in-button', { 'disabled-button': isCompleted }]" @click="ctaButtonClick">{{ $t('missionTaskCheckInBtn') }}</button>
        <div v-if="!isMobile && missionId && isChecking" class="loading-dot check-in-button"><img src="/img/loading-white.gif" /></div>
        <!-- <div class="applicable-stores-section" @click="goToMap">
          <div class="location-title">Arress & go to map button</div>
          <img
            class="right desktop_hide"
            srcset="/img/more.1x.png, /img/more.2x.png 2x, /img/more.3x.png 3x"
            src="/img/more.2x.png"
          >
        </div> -->

        <div v-if="locationData.address" class="applicable-stores-section-container" @click="goToMap">
          <div v-if="locationData.address" class="applicable-stores-section">
            <img
              style="margin-top: 0px;"
              width="24px"
              src="/img/location-address-icon.png"
            >
            <div class="location-section-content flex-column">
              <label class="location-title">{{ $t('LocationAddress') }}</label>
              <label>{{ locationData.address }}</label>
            </div>
          </div>
          <button class="map-guide">
            <!-- <img src="/img/address-guide.png"/> -->
            <img
              class="arrow-icon"
              srcset="/img/arrow-icon-2.1x.png, /img/arrow-icon-2.2x.png 2x, /img/arrow-icon-2.3x.png 3x"
              src="/img/arrow-icon-2.2x.png"
            >
          </button>
        </div>
        <div v-if="locationData.holidayBusinessHours || (locationData.weekBusinessHours && locationData.weekBusinessHours.length)" class="applicable-stores-section">
          <img
            width="24px"
            src="/img/location-hours-icon.png"
          >
          <div class="location-section-content flex-column">
            <label class="location-title">{{ $t('LocationOpenHour') }}</label>
            <div v-for="(item, i) in locationData.weekBusinessHours" :key="i" class="business-hours">
              <label class="weekdays">{{ item.businessDay }}</label>
              <label>{{ item.businessTime }} </label>
            </div>
            <div v-if="locationData.holidayBusinessHours" class="business-hours">
              <label>{{ locationData.holidayBusinessHours.businessDay }}</label>
              <label>{{ locationData.holidayBusinessHours.businessTime }}</label>
            </div>
            <div v-if="locationData.closedDays" class="business-hours">
              <label>{{ locationData.closedDays.businessDay }}</label>
              <label>{{ locationData.closedDays.businessTime }}</label>
            </div>
          </div>
        </div>
        <div v-if="locationData.remarks" class="applicable-stores-section">
          <img
            style="margin-top: 0px;"
            width="24px"
            src="/img/location-remarks-icon.png"
          >
          <div class="location-section-content flex-column">
            <label class="location-title">{{ $t('LocationRemarks') }}</label>
            <div class="remarks toastui-editor-contents" v-html="locationData.remarks" />
          </div>
        </div>

        <div v-if="locationData.phone" class="applicable-stores-section">
          <img
            width="24px"
            src="/img/location-mobile-icon.png"
          >
          <div class="location-section-content flex-column">
            <label class="location-title">{{ $t('LocationMobile') }}</label>
            <a class="applicable-stores-section" :href="'tel:' + locationData.phone">{{ formatedPhone }}</a>
          </div>
        </div>

        <div v-if="locationData.email" class="applicable-stores-section">
          <img
            width="24px"
            src="/img/location-email-icon.png"
          >
          <div class="location-section-content flex-column">
            <label class="location-title">{{ $t('LocationEmail') }}</label>
            <a class="applicable-stores-section" :href="'mailto:' + locationData.email">{{ locationData.email }}</a>
          </div>
        </div>
      </div>

      <div
        v-if="isMobile && missionId"
        class="sticky-coupon-footer"
      >
        <div class="sticky-coupon-footer-button">
          <div v-if="!isChecking" :class="['sticky-footer-button common-button check-in-button', { 'disabled-button': isCompleted }]" @click.prevent="ctaButtonClick">{{ $t('missionTaskCheckInBtn') }}</div>
          <div v-if="isChecking" class="sticky-footer-button loading-dot check-in-button"><img src="/img/loading-white.gif" /></div>
        </div>
      </div>
    </div>

    <scan-qrcode
      ref="scanTarget"
      v-show="isScaningQrcode"
      :is-check-in="true"
      @closeScanQrcode="closeScanQrcode"
      @showError="showError"
      @scanSuccess="scanSuccess"/>

    <el-dialog
      :visible.sync="showErrorDialog"
      class="fail-to-acquire-coupon"
      width="316px"
      :title="displayDialogErrorMessage"
      center
      :show-close="false"
    >
      <span slot="footer" class="dialog-footer">

        <button
          tag="button"
          type="button"
          class="el-button el-button--primary primary-button"
          @click="closeErrorDialog"
        >
          {{ $t('goodiebagOk') }}
        </button>
      </span>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="showAcquireStampSuccessDialog"
      :width="`${isMobile ? '316px' : '614px'}`"
      center
      :custom-class="`display-code-dialog ${isMobile ? 'full-dialog' : ''}`"
      :lock-scroll="true"
      :show-close="false"
    >
      <acquire-stamp-success
        :data="collectStampSuccessDialog"
        @done="acquireStampDone"/>
    </el-dialog>
    <!-- :earned-stamps="collectStampSuccessDialog && collectStampSuccessDialog.earnedStamps"
        :total-stamps="collectStampSuccessDialog && collectStampSuccessDialog.totalStamps"
        :gamification-campaign-id="collectStampSuccessDialog && collectStampSuccessDialog.gamificationCampaignId" -->

    <!-- desktop use coupon/check-in to mobile -->
    <el-dialog
      title=""
      :visible.sync="showToMobileDetailQRcode"
      class="to-mobile-dialog"
      :width="`${isMobile ? '316px' : '600px'}`"
      center
      :custom-class="''"
      :lock-scroll="true"
      :show-close="false"
    >
      <canvas id="link-qr-canvas" />
      <label>{{$t('missionTaskCheckInToUseMobile')}}</label>
      <button class="common-button red-bg-button" @click="showToMobileDetailQRcode = false">{{$t('goodiebagOk')}}</button>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="isShowApplicableStores"
      width="1024px"
      center
      class="applicable-stores-dialog"
      :lock-scroll="true"
      :before-close="beforeApplicableStoresClose()"
    >
      <applicable-store :applicablestoreslist="applicableStoresList" :pagetype="'detail'" :index="0" @pagetypechange="pageTypeChange" />
    </el-dialog>

    <div id="robot" />
  </div>

</template>

<script>
import { VueAgile } from 'vue-agile'
import NotFind from '@/components/NotFind'
import ScanQrcode from '@/components/ScanQrcode'
import utilsMixin from '@/mixins/utilsMixin'
import backHandlerMixin from '@/mixins/backHandlerMixin'
import axios from 'axios'
import { isWechatMiniProgram, replaceRouterWithQuery } from '@/utils/utils'
import { loginSSO } from '@/helpers/http'
import missionMixin from '@/mixins/missionMixin'
import AcquireStampSuccess from '@/components/AcquireStampSuccess'
import { sendPageViewAnalytics, sendManualAnalytics, sendAdobeManualAnalytics } from '@/utils/tracking'
import { pushRouterWithQuery } from '@/utils/utils'
import ApplicableStore from '@/components/ApplicableStore'

export default {
  name: 'MissionLocationDetail',
  components: {
    agile: VueAgile,
    NotFind,
    ApplicableStore,
    ScanQrcode,
    AcquireStampSuccess
  },
  mixins: [utilsMixin, backHandlerMixin, missionMixin],
  data () {
    return {
      participateDirectId: null,
      storeId: null,
      isCollapseDescription: null,
      isScaningQrcode: false,
      showErrorDialog: false,
      errorNext: null,
      latitude: null,
      longitude: null,
      isShowApplicableStores: false,
      code: null,
      buttonPosition: '',
      missionId: null,
      locationId: null,
      gamificationId: null,
      gamificationCampaignRecordId: null,

      displayDialogErrorMessage: '',
      showAcquireStampSuccessDialog: false,
      collectStampSuccessDialog: null,

      isLoading: true,
      isChecking: false,
      showToMobileDetailQRcode: false,
      isFirstJoinGame: false
    }
  },
  computed: {
    isMobile () {
      return this.$store.getters['app/isMobile']
    },
    isLogined () {
      return localStorage.getItem('refreshToken')
    },
    isNoData () {
      // alert(this.isLoading)
      if (this.isLoading) {
        return false
      }

      return !(this.locationData && this.locationData.name)
    },
    missionLocationData () {
      let result = this.$store.getters['campaigns/missionLocationDetail'](this.storeId)

      if (!result) {
        result = {}
      }
      return result
    },
    locationData () {
      return this.formatApplicableStore({
        ...this.missionLocationData.location,
        mission_location_id: this.missionLocationData.mission_location_id
      })
    },
    isCompleted () {
      return this.missionLocationData.is_completed
    },
    applicableStoresList () {
      const result = this.$store.getters['campaigns/missionLocationDetail'](this.storeId) || {}
      return [result.location]
    },
    scrollphotos () {
      return this.locationData.photos_url || []
    },
    formatedPhone () {
      let result = this.locationData.phone
      if (!result) {
        return result
      }
      if (!this.locationData.phone.startsWith('+')) {
        result = '+' + this.locationData.phone
      }
      if (result.startsWith('+852') || result.startsWith('+853')) {
        result = result.slice(0, 4) + ' ' + result.slice(4, 8) + ' ' + result.slice(8)
      } else if (result.startsWith('+86')) {
        result = result.slice(0, 3) + ' ' + result.slice(3)
      }
      return result
    },
    missionData () {
      console.log('@@230: ', this.$store.getters['gamification/missionDetail'](this.gamificationId, this.missionId))
      return this.$store.getters['gamification/missionDetail'](this.gamificationId, this.missionId)
    }
  },
  updated () {
    this.isUpdated = true
    if (this.$store.getters['app/getIsOneTrustDisplay']) {
      if (this.isMiniProgram()) {
        window.$('.back-to-top-bottom')?.attr('style', 'bottom: calc(' + (150 + this.oneTrustHeight) + 'px + var(--sab)) !important')
      } else if (this.isMobile) {
        window.$('.back-to-top-bottom')?.attr('style', 'bottom: ' + (90 + this.oneTrustHeight) + 'px !important')
      } else {
        window.$('.back-to-top-bottom')?.attr('style', 'bottom: ' + (20 + this.oneTrustHeight) + 'px !important')
      }
      this.adjustStickyFooter(true)
    }

    this.adjustSuccessPopupForOneTrust()

    this.$nextTick(() => {
      const height = this.$refs.descriptionTarget?.clientHeight
      // if (this.isCollapseDescription === null && height > 14 * 1.6 * 8) {
      let descriptionMaxHeight = 16 * 1.3 * 8
      if (this.isMobile) {
        descriptionMaxHeight = 14 * 1.3 * 2
      }
      if (this.isCollapseDescription === null && height > descriptionMaxHeight) {
        this.isCollapseDescription = true
      }
    })
  },

  async mounted () {
    if (this.detailNotFound) {
      replaceRouterWithQuery(this.$router, { name: 'page-not-found' })
      return
    }

    if (!this.isMobile) {
      const plugin = document.createElement('script')
      plugin.setAttribute(
        'src',
        window.location.origin + '/js/html5-qrcode.min.js'
      )
      plugin.async = true
      document.head.appendChild(plugin)
    }

    this.participateDirectId = this.getCookie('__logintask')

    this.$store.commit('app/setNavType', 'back_language_menu')
    const { missionId, locationId, gamificationCampaignRecordId, gamificationId } = this.$route.query
    this.missionId = missionId
    this.storeId = locationId
    this.gamificationCampaignRecordId = gamificationCampaignRecordId || null
    this.gamificationId = gamificationId || null

    this.isLoading = true
    try {
      this.$store.dispatch('app/refreshSystemSettings')
    } catch (error) {
      console.log('error--', error)
    }
    this.setCaptcha()
    this.getMissionLocationDetail()
    if (!this.missionData) {
      console.log('@@@252------')
      this.getMissionDetail()
    }

    const plugin = document.createElement('script')
    plugin.setAttribute(
      'src',
      window.location.origin + '/js/html5-qrcode.min.js'
    )
    plugin.async = true
    document.head.appendChild(plugin)

    if (isWechatMiniProgram()) {
      const wechatTokenURL = window.location.href.split('#')[0]
      this.$store.commit('app/setWechatTokenURLWithoutLimit', wechatTokenURL)
    }

    if (this.$store.getters['app/getIsOneTrustDisplay']) {
      if (this.isMiniProgram() || this.isMobile) {
        window.$('.el-drawer').attr('style', 'height: 60% !important')
        if (window.$('.el-drawer')[0]) {
          window.$('.el-drawer')[0].style.overflowY = 'scroll'
        }
      }
      if (window.$('.el-drawer')[0]) {
        window.$('.el-drawer')[0].style.marginBottom = this.oneTrustHeight + 'px'
      }
      this.adjustSuccessPopupForOneTrust()
    }

    if (this.isMobile) {
      window.addEventListener('scroll', this.handleScroll, true)
    }
  },
  watch: {
    'oneTrustHeight': function (newVal, oldVal) {
      if (this.isMobile && (!oldVal && newVal) || (oldVal && !newVal)) {
        this.adjustStickyFooter()
      }
    },
    'locationData': function (newVal, oldVal) {
      this.removeCookie('__logintask')
      if (newVal.id &&
        parseInt(this.participateDirectId) === parseInt(this.storeId) &&
        parseInt(newVal.mission_location_id) === parseInt(this.participateDirectId)
      ) {
        this.ctaButtonClick()
      }
    }
  },
  beforeDestroy () {
    this.$store.commit('app/setNavType', 'logo_language_menu')

    if (this.isMobile) {
      window.removeEventListener('scroll', this.handleScroll, true)
    }
  },
  methods: {
    isMiniProgram () {
      return isWechatMiniProgram()
    },
    adjustSuccessPopupForOneTrust () {
      this.adjustAgeRestrictedPopupForOneTrust()
    },
    adjustStickyFooter (onScroll) {
      if (this.isMobile) {
        let margin = 0
        if (this.$store.getters['app/getIsOneTrustDisplay']) {
          margin += this.oneTrustHeight
        }

        if (this.isMiniProgram()) {
          const miniBarHeight = document.getElementsByClassName('mini-navbar')[0].clientHeight
          margin += miniBarHeight

          // if (window.$('.sticky-coupon-footer-button')[0] && window.$('.sticky-coupon-footer-button')[0].childElementCount) {
          //   // window.$('.sticky-coupon-footer').attr('style', 'margin-bottom: ' + margin + 'px')
          //   window.$('.sticky-coupon-footer').attr('style', 'bottom: ' + margin + 'px')
          // }
        } else if (onScroll) {
          const scrollTop = window.innerHeight + window.pageYOffset
          const pageHeight = document.body.offsetHeight

          // const atBottom = scrollTop >= pageHeight
          const offsetBottom = pageHeight - scrollTop

          const footerHeight = document.getElementsByClassName('footer-container')[0].clientHeight
          const stickyButtonMargin = parseInt(window.$('.sticky-coupon-footer').css('margin-bottom')?.replace('px', '') || 0)
          // if (atBottom) {
          //   // margin += footerHeight
          //   if (offsetBottom < 0) {
          //     margin -= offsetBottom
          //   } else {
          //     margin += footerHeight
          //   }
          // } else if (offsetBottom < footerHeight && offsetBottom > 0) {
          //   if (margin <= 0) {
          //     margin += (footerHeight - offsetBottom)
          //   }
          // }
          if (margin <= 0) { // not show one-trust
            if (offsetBottom < footerHeight) {
              if (stickyButtonMargin <= footerHeight) {
                margin += (footerHeight - offsetBottom)
              } else {
                margin += footerHeight
              }
            }
            // console.log(`2. (${footerHeight}, ${offsetBottom}, ${stickyButtonMargin}, m-${margin})`)
          } else {
            const oneTrustHeightDiffFooter = footerHeight - margin
            if (offsetBottom < oneTrustHeightDiffFooter) {
              margin += (oneTrustHeightDiffFooter - offsetBottom)
            }
            // console.log(`1. (${footerHeight}, ${offsetBottom}, ${stickyButtonMargin}, m-${margin})`)
          }
          // this.buttonPosition = ``
        }
        // this.buttonPosition = `2.(${onScroll}, ${footerHeight}, ${offsetBottom}, m-${margin})`
        // console.log(`2.(${onScroll}, ${footerHeight}, ${offsetBottom}, m-${margin})`)

        if (window.$('.sticky-coupon-footer-button')[0] && window.$('.sticky-coupon-footer-button')[0].childElementCount) {
          window.$('.sticky-coupon-footer').attr('style', 'margin-bottom: ' + margin + 'px')
          // window.$('.sticky-coupon-footer').attr('style', 'bottom: ' + margin + 'px')
        }
      }
    },
    handleScroll () {
      this.adjustStickyFooter(true)
    },
    async getMissionDetail () {
      if (!this.missionId) {
        return
      }
      let query = ''
      if (this.gamificationCampaignRecordId) {
        query = `?gamification_campaign_participation_record=${this.gamificationCampaignRecordId}`
      }
      await axios
        .get(`/gamification_missions/${this.missionId}${query}`)
        .then(response => {
          // console.log('response---', response)
          // this.missionData = response.data
          // this.$store.commit('campaigns/setMissionDetail', this.missionData)
          console.log('@@252----11')
          this.$store.commit('gamification/updateMissionList', {
            gamificationCampaignId: this.gamificationId,
            missionId: this.missionId,
            detail: response.data
          })
          this.isLoading = false

          if (this.locationData.id) {
            sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'game|check-in location detail', { gamificationId: this.gamificationId, missionId: this.missionId, misisonLocationId: this.storeId })
          }
        })
        .catch(error => {
          console.log('371--- errro', error)
          const { data } = error.response || {}
          const { detail } = data || {}
          console.log('371--- errro', error.response, detail)
          // this.couponNotFound = detail === 'Not found.'

          this.isLoading = false
        })
      this.missionDetailCollected = true
    },
    async getMissionLocationDetail () {
      // const data = this.$store.getters['campaigns/missionLocationDetail'](this.storeId)
      // if (data && data.mission_location_id) {
      //   this.data = this.formatApplicableStore(data)
      //   this.detailCollected = true
      //   console.log('???getMissionLocationDetail', this.data)
      //   return
      // }

      let query = ''
      if (this.missionId) {
        query = `?mission=${this.missionId}`
      }
      await axios.get(`/gamification_mission/check_in/${this.storeId}${query}`).then(response => {
        const data = response.data
        data.mission_location_id = response.data.id
        this.$store.commit('campaigns/updateMissionLocations', data)
        // this.data = this.formatApplicableStore(data)
        console.log('???getMissionLocationDetail', this.locationData)
        if (this.missionData.id) {
          sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'game|check-in location detail', { gamificationId: this.gamificationId, missionId: this.missionId, misisonLocationId: this.storeId })
        }
      }).catch(error => {
        console.log('371--- errro', error)
        const { data } = error.response || {}
        const { detail } = data || {}
        this.detailNotFound = detail === 'Not found.'
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false
        }, 1500)
      })
    },
    // goToMap () {
    //   console.log('goToMap')
    //   if (this.isMiniProgram()) {
    //     sendPageViewAnalytics(this.$route, this.$i18n.locale, this.$store, { pageName: 'mission-location-map' })
    //     window.wx.miniProgram.navigateTo({ url: `/pages/store/store?hideDetail=true&id=${this.locationData.id}&lang=${this.$i18n.locale}` })
    //     return
    //   }
    //   // pushRouterWithQuery(this.$router, { name: 'mission-location-map', query: { storeId: this.locationData.id, gamificationId: this.gamificationId, missionId: this.missionId }})
    //   window.open(`${window.location.origin}/${this.$i18n.locale}/mission-location-map?storeId=${this.locationData.id}&gamificationId=${this.gamificationId}&missionId=${this.missionId}`, '_blank').focus()
    // },
    goToMap () {
      if (window.$('.is-vue-mobile').length) {
        const query = Object.assign({}, this.$route.query)
        if (this.missionId) {
          query['missionId'] = this.missionId
        }

        sendPageViewAnalytics(this.$route, this.$i18n.locale, this.$store, { pageName: 'mission-location-map' })
        if (this.isMiniProgram()) {
          window.wx.miniProgram.navigateTo({ url: `/pages/store/store?id=${this.locationData.id}&lang=${this.$i18n.locale}` })
          return
        }

        pushRouterWithQuery(this.$router, { name: 'applicable-store-detail', query: query })
      } else {
        this.isShowApplicableStores = !this.isShowApplicableStores
        this.disableOverflow()
      }
    },
    beforeApplicableStoresClose () {
      if (!this.isShowApplicableStores) {
        this.enableOverflow()
      }
    },
    pageTypeChange (type) {
      console.log('type', type)
    },
    getGamificationCampaignRecordId () {
      const gamification = this.$store.getters['gamification/getGamificationByMission'](this.missionId)
      if (gamification) {
        return gamification.gamification_campaign_participation_record
      }

      return null
    },
    async ajaxCheckin (isCheck = false) {
      // console.log('418===================', this.storeId, this.missionData)

      try {
        const payload = {
          mission_location: this.storeId,
          check_in_latitude: this.latitude,
          check_in_longitude: this.longitude,
          gamification_campaign_participation_record: this.gamificationCampaignRecordId || this.getGamificationCampaignRecordId(),
          code: this.code,
          // recaptcha_verify_credential: 'aaaa',
          mission: this.missionId
        }
        let query = ''
        if (isCheck) {
          query = '?check_link=true'
        } else {
          const {
            recaptchaString,
            errorData
          } = await this.getRecaptchaString(this.isVerifyRecaptcha, true)
          if (errorData.errorContent) {
            this.displayDialogErrorMessage = errorData.errorContent
            this.showErrorDialog = true
            return false
          }

          payload.recaptcha_verify_credential = recaptchaString
        }

        const ssoUid = this.$store.getters['auth/ssoUid']
        const response = await axios.post(`/customers/${ssoUid}/gamification_check_in${query}`, payload)
        console.log(response)
        // alert('earn stamp success from check in')

        // this.displayDialogErrorMessage = `earn stamp success from check in`
        // this.showErrorDialog = true
        if (!isCheck) {
          this.$refs.scanTarget?.close()
          const { data } = response
          console.log('@@624: ', true, data, this.locationData.name)
          this.setCollectStampSuccessDialog(true, data, this.locationData.name)
          console.log('@@624-2: ', this.collectStampSuccessDialog)
          this.disableOverflow()
          this.showAcquireStampSuccessDialog = true

          this.participateDirectId = null

          // this.updateMissionCollectedStamps()
          this.missionData.collected_stamp_rewards_count = this.missionData.collected_stamp_rewards_count + (this.collectStampSuccessDialog?.count || 0)
          this.$store.commit('gamification/updateMissionList', {
            gamificationCampaignId: this.gamificationId,
            detail: this.missionData
          })
          this.$store.commit('campaigns/updateMissionLocations', { ...this.missionLocationData, is_completed: true, check_in_count: (this.missionLocationData.check_in_count || 0) + 1 })

          sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'game|check-in success', {
            gamificationId: this.gamificationId,
            missionId: this.missionId,
            misisonLocationId: this.storeId,
            stamps: data.collected_stamps?.acquired_stamp_count || '0',
            rewards: data.collected_stamps?.stamp_rewards?.filter((item) => item.collected_reward === true).map(r => r.id),
            out_of_stock: data.collected_stamps?.stamp_rewards_error_messages?.length > 0 ? '1' : '0',
            join: this.isFirstJoinGame ? '1' : '0'
          })
          if (data.collected_stamps?.acquired_stamp_count) {
            sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'gamification_campaign_earn_success', 'event', data.collected_stamps)
          } else if (data.collected_stamps?.error_type) {
            sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'gamification_campaign_earn_fail', 'event', data.collected_stamps)
          }
        }
      } catch (error) {
        console.log('@634', error.response)

        if (error.response.data.display_error_content.toLowerCase() === 'invalid code') {
          if (isWechatMiniProgram()) {
            this.isScaningQrcode = true
          }
          this.$refs.scanTarget.showQrcodeError()
        } else {
          this.$refs.scanTarget?.close()
          this.displayDialogErrorMessage = error.response.data.display_error_content
          this.showErrorDialog = true
        }
        if (error.response.data.code === 'error_grant_mission_location_reach_limit') {
          this.$store.commit('campaigns/updateMissionLocations', { ...this.missionLocationData, is_completed: true })
        }

        this.participateDirectId = null
        sendAdobeManualAnalytics(
          this.$route,
          this.$i18n.locale,
          this.$store,
          'game|check-in fail',
          {
            gamificationId: this.gamificationId,
            missionId: this.missionId,
            misisonLocationId: this.storeId,
            stamps: '0',
            errorMessage: error.response.data.display_error_content
          })

        return false
      }

      return true
    },
    async participateCheckIn (latitude = null, longitude = null) {
      // this.setCaptcha()
      this.formatLatitudeLongitude(latitude, longitude)
      // alert(`${this.latitude}, ${this.longitude}`)
      console.log('@@657: ', this.missionData.check_in_mode, this.missionData)
      if (this.missionData.check_in_mode === 'BOTH') {
        // check location
        const checkSuccess = await this.ajaxCheckin(true)
        if (checkSuccess) {
          this.isScaningQrcode = true
          this.$refs.scanTarget.initQrcode(latitude, longitude)

          this.participateDirectId = null
        }
      } else {
        this.ajaxCheckin()
      }
    },
    gotoCheckin () {
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'gamification_campaign_location_check_in', 'onclick', { task_id: this.locationData.id, task_name: this.locationData.name })
      console.log('@@545: ', this.missionData)
      if (this.missionData.check_in_mode === 'QR_CODE') {
        this.isScaningQrcode = true
        this.$refs.scanTarget.initQrcode(null, null)

        this.participateDirectId = null
      } else {
        this.grantGPS(this.participateCheckIn, (error) => {
          console.log('---189---', error)
          this.displayDialogErrorMessage = this.$t('grantGPSErrorContent')
          this.showErrorDialog = true

          this.participateDirectId = null
        })
      }
    },
    async ctaButtonClick () {
      if (this.isCompleted) {
        return
      }

      if (!this.isMobile) {
        this.showToMobileDetailQRcode = true
        setTimeout(() => {
          const QRCode = require('qrcode')

          const canvas = document.getElementById('link-qr-canvas')
          console.log('@@canvas', canvas)
          QRCode.toCanvas(canvas, window.location.href, { width: '142' }, function (error) {
            console.log('generate url code error: ', error)
          })
        }, 100)
        return
      }

      if (!this.isLogined) {
        loginSSO(null, false, { participatingItem: this.storeId })
        return
      }

      sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'game|check-in start', { gamificationId: this.gamificationId, missionId: this.missionId, misisonLocationId: this.storeId })

      this.isChecking = true
      if (this.gamificationCampaignRecordId) {
        this.isFirstJoinGame = false
        this.gotoCheckin()
      } else {
        // const {
        //   recaptchaString,
        //   errorData
        // } = await this.getRecaptchaString(this.isVerifyRecaptcha, true)
        // if (errorData.errorContent) {
        //   this.displayDialogErrorMessage = errorData.errorContent
        //   this.showErrorDialog = true
        //   return
        // }

        const { sign, t } = this.$route.query
        this.redeemGamification({
          gamification_campaign: this.gamificationId,
          sign: sign || null,
          tracking_code: t || null,
          // recaptcha_verify_credential: recaptchaString,
          participatingItem: this.storeId
        }, (created) => {
          console.log('@@775: ', created)
          if (created) {
            this.isFirstJoinGame = true
          } else {
            this.isFirstJoinGame = false
          }
          this.gotoCheckin()
        }, (error) => {
          // const display_error_content = error.display_error_content
          // alert(display_error_content)
          this.displayDialogErrorMessage = this.handleRedeemGamificationError(error)
          // this.showErrorDialog = true
          this.errorNext = ''
          if (error.code === 'error_grant_gamification_campaign_invalid_country_code') {
            this.errorNext = 'my-gamifications'
          } else if (error.display_error_content !== this.displayDialogErrorMessage) {
            this.errorNext = 'home'
          }
          if (this.displayDialogErrorMessage) {
            this.showErrorDialog = true
          }

          this.participateDirectId = null
          console.log('@@478----', error)
        })
      }
    },
    seeMore () {
      this.isCollapseDescription = false
    },
    closeScanQrcode () {
      this.isScaningQrcode = false
    },
    showError () {
      console.log('show errror')
    },
    scanSuccess (code) {
      // this.setCaptcha()
      this.code = code
      this.ajaxCheckin()
    },
    closeErrorDialog () {
      if (this.errorNext) {
        pushRouterWithQuery(this.$router, { path: this.errorNext })
      }

      this.showErrorDialog = false
      this.isChecking = false
    },
    acquireStampDone () {
      this.showAcquireStampSuccessDialog = false
      this.collectStampSuccessDialog = null
      this.isChecking = false
      this.enableOverflow()
    }
  }
}
</script>

<style scoped lang='scss'>
@import '@/assets/carousel.scss';
@import '@/assets/common.scss';

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
}

.check-in-button {
  background-color: var(--Junk-Boat-Red);
  color: #FFF;
  width: 100%;

  min-height: 44px;
  font-size: 18px;
  text-align: center;
  justify-content: center
}
.disabled-button {
  opacity: 0.6;
}

.mission-location-detail-main-container {
  // background-color: #E5E5E5;
  padding: 32px 32px 30px 32px;
  display: flex;
  gap: 24px;
  font-size: 16px;

  .cover-images {
    width: 48%;
  }

  .mission-block {
    display: flex;
    flex-direction: column;
    width: 48%;
    margin-bottom: 11px;
    padding-bottom: 40px;
    gap:24px;
    // background-color: #f7f7f7

    .description,
    .see-more-button {
      font-size: 16px;
    }
  }

  .slide-image {
    padding-top: calc(3/4 * 100%);
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .collpase-arrow{
    cursor: pointer;
  }

  .loading-dot {

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 41px;
    width: 100%;
    border-radius: 100px;

    img {
      width: 25%;
      margin-top: -8%;
    }
  }
}

.is-vue-mobile .mission-location-detail-main-container {
  display: flex;
  flex-direction: column;

  padding: 16px;

  .mission-block {
    width: 100%;
  }

  .cover-images {
    width: 100%;
  }

  .map-guide img {
    // min-width: 24px;
  }

  .location-section-content {

    label {
      font-size: 14px;
    }

    .location-title {
      font-size: 16px !important;
      font-weight: 800;
    }

    .remarks {
      font-size: 14px;
      color: var(--Temple-St-Dusk-Maroon);
    }

    a {
      font-size: 14px;
      color: var(--Temple-St-Dusk-Maroon);
    }
  }
  .check-in-button {
    font-size: 16px;
  }
}

.applicable-stores-section-container {
  display: flex;
  flex-flow: row;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--temple-st-dusk-maroon-80);
  cursor: pointer;

  label {
    cursor: pointer;
  }

  .applicable-stores-section {
    padding-bottom: 0px;
    border-bottom: 0px solid var(--temple-st-dusk-maroon-80);
    margin-right: 16px;
  }
}

.applicable-stores-section {
  display: flex;
  flex-flow: row;
  align-items:flex-start;
  gap: 8px;

  padding-bottom: 16px;
  border-bottom: 1px solid var(--temple-st-dusk-maroon-80);

  .location-section-content {
    // gap: 8px;

    label {
      font-size: 16px;
      color: var(--Temple-St-Dusk-Maroon);
    }

    .location-title {
      font-size: 18px !important;
      font-weight: 800;
      margin-bottom: 8px;
    }

    .remarks {
      font-size: 16px;
      color: var(--Temple-St-Dusk-Maroon);
    }

    a {
      font-size: 16px;
      color: var(--Temple-St-Dusk-Maroon);
    }

    .business-hours {
      display: flex;
      gap: 8px
    }
  }
  // .text{
  //   padding-right: 32px;
  // }
  // .bold{
  //   font-weight: 600;
  // }
}

.applicable-stores-section:last-child {
  border-bottom: none !important;
}

.map-guide {
  cursor: pointer;
  width: fit-content;
  background: transparent;
  border: none;
  outline: none;
  margin-left: auto;
  padding: 0;

  img {
    width: 12px;
    max-width: none;
  }
}

.sticky-coupon-footer {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 11px;
  // border-top:  1px solid #D1D1D1;
  // background-color: #fff;
  cursor: pointer;
  flex-direction: column;
  padding-left: 14px;
  padding-right: 14px;
  z-index: 2;

  background: #FFF;
  box-shadow: 0px -1px 4px 0px #00000033;

  .sticky-coupon-footer-button {
    width: 100%;
  }
}

.is-vue-desktop .mission-location-detail-main-container {
  // max-height: calc(100vh - 91px - 86px);
  // overflow-y: auto;

  .title {
    font-size: 56px !important;
  }

  .cover-images {
    position: fixed;
    max-width: 485px;
    right: calc((100% - 1024px) / 2 + 32px);
  }

  // .loading-dot {

  //   img {
  //     width: 30%;
  //     margin-top: -11%;
  //   }
  // }
}

</style>
<style lang='scss'>
@import '@/assets/gamification-font.scss';
.fail-to-acquire-coupon {
  [role="dialog"] {
    .el-dialog__header {
      padding-top: 30px;
      padding-bottom: 0;
    }
  }
}

.is-vue-desktop {
  .to-mobile-dialog {
    border-radius: 10px;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      label {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2;
        text-align: center;
        color: #000000;
      }
      button {
        cursor: pointer;
        justify-content: center;
        margin-right: auto;
        width: 240px;
      }
    }
  }
}

.mission-location-detail-main-container {
  .description, .description *,
  .remarks, .remarks * {
    color: #3B002B !important;
  }
}
</style>
