<template>
  <div>
    <div v-if="isShowVisualizeFilter" class="agile-title" />
    <div v-if="isShowVisualizeFilter" class="category-carousel-contain">
      <img
        v-if="categoryIndex>0 && !this.$store.getters['app/isMobile']"
        width="40px"
        class="category-carousel-nav-left"
        srcset="/img/categoryCarouselLeft.1x.png, /img/categoryCarouselLeft.2x.png 2x, /img/categoryCarouselLeft.3x.png 3x"
        src="/img/categoryCarouselLeft.2x.png"
        @click="categoryMoveLeft"
      >
      <img
        v-if="categoryIndex<slides.length-categoryPerPage && !this.$store.getters['app/isMobile']"
        width="40px"
        class="category-carousel-nav-right"
        srcset="/img/categoryCarouselRight.1x.png, /img/categoryCarouselRight.2x.png 2x, /img/categoryCarouselRight.3x.png 3x"
        src="/img/categoryCarouselRight.2x.png"
        @click="categoryMoveRight"
      >
      <carousel class="category-carousel" :per-page="categoryPerPage" :per-page-custom="[[90, 2], [332, 3.5], [1024, categoryPerPage]]" :scroll-per-page="false" :navigate-to="categoryIndex" :mouse-drag="this.$store.getters['app/isMobile']" :pagination-enabled="false">
        <slide v-for="slide,index in slides" :key="slide.id + '-categoryPerPage'">
          <div class="category-carousel-slide" :class="{'selected': categorySelectedIndex == index}" @click="slideClick(index)">
            <div class="img-contain" :class="$store.getters['app/isMobile']? 'mobile': 'desktop'">
              <img
                v-if="slide.id===0"
                class="all-img"
                srcset="/img/all.1x.png, /img/all.2x.png 2x, /img/all.3x.png 3x"
                src="/img/all.2x.png"
              >
              <img
                v-else-if="slide.icon_url"
                class="icon-img"
                :src="slide.icon_url"
              >
              <img
                v-else
                class="icon-img"
                srcset="/img/placeholder.1x.png, /img/placeholder.2x.png 2x, /img/placeholder.3x.png 3x"
                src="/img/placeholder.2x.png"
              >
            </div>
            <div class="text">{{ slide.name?slide.name:slide.english_name }}</div>
          </div>
        </slide>
        <slide v-if="this.$store.getters['app/isMobile']">
          <div class="empty" />
        </slide>
      </carousel>
    </div>
    <div class="sort-filter-line" :class="{'combine-filters': isCombine}">
      <div v-if="isShowResultCount" class="results" v-html="searchKey ? inFiltering ? $t('inSearching') : $t('searchResultCount', {keyword: searchKey, count: campaignCount}) : $t('goodiebagResults', {count: campaignCount})" />

      <div
        :class="['sort-filters-container', {active: showFilterSideBar, disabled: disabled, 'only-one-filter': displaySortInsideFilter}]"
      >
        <div v-if="!isCombine" class="filter-types" >
          <div
            v-if="!displaySortInsideFilter"
            class="sort"
            :data-aid="isExplorePage()?'home_sorting': isMissionDetailPage()?'':'goodie_bag_sorting'"
            @click="toggleSortDropdown"
          >
            <img
              class="sort-logo"
              srcset="/img/sort-logo.1x.png, /img/sort-logo.2x.png 2x, /img/sort-logo.3x.png 3x"
              src="/img/sort-logo.2x.png"
            >
            <div class="sort-text">{{ sortingLabel }}</div>
          </div>
          <div v-if="!displaySortInsideFilter" v-show="campaignSettings.location" class="sort-divider" />
          <div
            v-show="campaignSettings.location"
            class="filters"
            :class="{'disable': isLocationFiltersDisable}"
            data-aid="home_filter_location"
            @click="!isLocationFiltersDisable && toggleFilterSideBar('location')"
          >
            <img
              v-if="!displaySortInsideFilter"
              class="filters-logo"
              srcset="/img/icn_location.1x.png, /img/icn_location.2x.png 2x, /img/icn_location.3x.png 3x"
              src="/img/icn_location.2x.png"
            >
            <div v-if="!displaySortInsideFilter" class="filter-text">{{ $t('goodiebagLocationFilters') }}</div>
            <div v-if="hasLocationFilter" class="red-dot" />
          </div>

          <div v-if="isShowFilterSection" class="sort-divider" />
          <div
            v-if="isShowFilterSection"
            class="filters"
            :class="{'disable': isFiltersDisable}"
            :data-aid="isExplorePage()?'home_filter': isMissionDetailPage()?'':'goodie_bag_filter'"
            @click="!isFiltersDisable && toggleFilterSideBar()"
          >
            <img
              class="filters-logo"
              srcset="/img/filters-logo.1x.png, /img/filters-logo.2x.png 2x, /img/filters-logo.3x.png 3x"
              src="/img/filters-logo.2x.png"
            >
            <div class="filter-text">{{ $t('goodiebagFilters') }}</div>
            <div v-if="hasFilter" class="red-dot" />
          </div>
        </div>

        <div
          v-if="isCombine"
          class="filters"
          :class="{'disable': isFiltersDisable}"
          :data-aid="isExplorePage()?'home_filter': isMissionDetailPage()?'':'goodie_bag_filter'"
          @click="!isFiltersDisable && ((isShowFilterSection && toggleFilterSideBar()) || (!isShowFilterSection && toggleFilterSideBar('location')) )"
        >
          <img
            v-if="isCombine"
            class="filters-logo"
            src="/img/filter-icon-purple.png"
          >
          <div v-if="hasFilter" class="red-dot" />
        </div>

        <div class="disabled-overlay" />
        <div :class="['sort-dropdown', {'active': showSortDropdown}]">
          <div
            v-if="showSortDropdown"
            class="sort-overlay"
            @click="toggleSortDropdown"
          />
          <div class="sort-option-list">
            <div
              v-for="option in filterOptions.sorting || []"
              :key="option.value"
              :data-aid="isExplorePage()?'home_sorting_{selection}':isMissionDetailPage()?'':'goodie_bag_campaignlist_sorting_click'"
              :data-akey="option.label"
              :class="['sort-option', {selected: option.value === value.sorting}]"
              @click="selectSortOption(option.value)"
            >
              <div class="sort-option-label">
                {{ option.label }}
              </div>
              <img
                v-if="option.value === value.sorting"
                class="sort-tick"
                srcset="/img/sort-tick.1x.png, /img/sort-tick.2x.png 2x, /img/sort-tick.3x.png 3x"
                src="/img/sort-tick.2x.png"
              >
            </div>
          </div>
        </div>

        <div
          v-if="showFilterSideBar"
          class="filter-overlay"
          @click="toggleFilterSideBar"
        />

        <div :class="['filter-sidebar', {active: showFilterSideBar, 'mini-program': isWechatMiniProgram}]" :style="'margin-bottom:'+oneTrustHeight+'px'">
          <div
            :class="['sidebar-container', {active: !!subMenu && subMenu !== 'location' }]"
          >
            <!-- temp fix -->
            <!-- <div >{{combineTemp}} - {{showFilterSideBar}}</div> -->
            <div class="left-sidebar general-filter">
              <div v-if="displaySortInsideFilter" class="filter-header">
                <div class="filter-title">Sort</div>
                <div
                  v-if="showFilterSideBar"
                  class="filter-close-header-container"
                  @click="toggleFilterSideBar"
                >
                  <img
                    class="filter-header-close"
                    srcset="/img/filter-header-close.1x.png, /img/filter-header-close.2x.png 2x, /img/filter-header-close.3x.png 3x"
                    src="/img/filter-header-close.2x.png"
                  >
                </div>
              </div>
              <div v-if="displaySortInsideFilter" class="sort-options-container">
                <div
                  v-for="option in filterOptions.sorting || []"
                  :key="option.value"
                  :data-aid="isExplorePage()?'home_sorting_{selection}':isMissionDetailPage()?'':'goodie_bag_campaignlist_sorting_click'"
                  :data-akey="option.label"
                  :class="['sort-option submenu-option', {selected: option.value === sortOption}]"
                  @click="selectFilterSortOption(option.value)"
                >
                  <div class="sort-option-label submenu-option-label">
                    {{ option.label }}
                  </div>
                  <img
                    v-if="option.value === sortOption"
                    class="sort-tick"
                    srcset="/img/sort-tick.1x.png, /img/sort-tick.2x.png 2x, /img/sort-tick.3x.png 3x"
                    src="/img/sort-tick.2x.png"
                  >
                </div>
              </div>
              <div v-if="subMenu !== 'location'" class="filter-header">
                <div class="filter-title">{{ $t('goodiebagFilters') }}</div>
                <div class="filter-clear-all" data-aid="home_filter_clear" @click="clearAll('general')">{{ $t('goodiebagClearAll') }}</div>
                <div
                  v-if="showFilterSideBar && !displaySortInsideFilter"
                  class="filter-close-header-container"
                  @click="toggleFilterSideBar"
                >
                  <img
                    class="filter-header-close"
                    srcset="/img/filter-header-close.1x.png, /img/filter-header-close.2x.png 2x, /img/filter-header-close.3x.png 3x"
                    src="/img/filter-header-close.2x.png"
                  >
                </div>
              </div>
              <div class="filter-options-container">
                <template
                  v-for="filterOption in filterOptionLabels"
                >
                  <div
                    v-if="isShowFilterOptionLabels(filterOption)"
                    :key="filterOption"
                    :class="['filter-options', {selected: getFilterOptionSelectedValue(filterOption),
                                                disabled: isFilterOptionDisabled(filterOption)}]"
                    @click="changeSubmenu(filterOption)"
                  >
                    <div class="filter-label">{{ titleRefs[filterOption] }}</div>
                    <div
                      v-if="getFilterOptionSelectedValue(filterOption)"
                      class="filter-selected"
                    >
                      {{ getFilterOptionSelectedValue(filterOption) }}
                    </div>
                  </div>
                </template>
                <div
                  v-if="filterOptions.creditRange"
                  :class="['filter-options', {selected: creditRange}]"
                  @click="changeSubmenu('creditRange')"
                >
                  <div class="filter-label">{{ titleRefs['creditRange'] }}</div>
                  <div
                    v-if="creditRange"
                    class="filter-selected"
                  >
                    {{ creditRange }}
                  </div>
                </div>
                <template
                  v-for="filterOption in customFilterOptionLabels"
                >
                  <div
                    v-if="customFilterOptionLabels.includes(filterOption)"
                    :key="filterOption"
                    :class="['filter-options', {selected: getFilterOptionSelectedValue(filterOption),
                                                disabled: isFilterOptionDisabled(filterOption)}]"
                    @click="changeSubmenu(filterOption)"
                  >
                    <div class="filter-label">{{ titleRefs[filterOption] }}</div>
                    <div
                      v-if="getFilterOptionSelectedValue(filterOption)"
                      class="filter-selected"
                    >
                      {{ getFilterOptionSelectedValue(filterOption) }}
                    </div>
                  </div>
                </template>
                <div
                  v-if="false"
                  class="filter-options"
                  @click="changeSubmenu('validPeriod')"
                >
                  <div class="filter-label">{{ titleRefs['validPeriod'] }}</div>
                  <div
                    v-if="validPeriod"
                    class="filter-selected"
                  >
                    {{ validPeriod }}
                  </div>
                </div>

                <div v-if="displaySortInsideFilter && subMenu === 'location'" class="filter-header">
                  <div class="filter-title">{{ titleRefs['distance'] }}</div>
                  <!-- <div class="filter-clear-all" data-aid="home_filter_clear" @click="clearAll('general')">{{ $t('goodiebagClearAll') }}</div>
                  <div
                    v-if="showFilterSideBar && !displaySortInsideFilter"
                    class="filter-close-header-container"
                    @click="toggleFilterSideBar"
                  >
                    <img
                      class="filter-header-close"
                      srcset="/img/filter-header-close.1x.png, /img/filter-header-close.2x.png 2x, /img/filter-header-close.3x.png 3x"
                      src="/img/filter-header-close.2x.png"
                    >
                  </div> -->
                </div>
                <div
                  v-if="displaySortInsideFilter && subMenu === 'location'"
                  class="filter-options distance-filter"
                >
                  <!-- <div class="filter-label">{{ titleRefs['distance'] }}</div> -->
                  <div
                    class="distance-filter-options"
                  >
                    <div v-for="(item, index) in filterOptions.distance" :key="index" class="distance-filter-option" :class="{'selected': item.value === distanceValue}" @click="updateDistanceValue(item.value)">
                      {{item.label}}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-show="subMenu==='location'" class="left-sidebar location-filter" :style="displaySortInsideFilter ? `top: ${(isMobile ? 275 : 340) + (filterOptions.sorting.length - 2) * 62.5}px` : ''">
              <div class="filter-header">
                <div class="filter-title">{{ $t('goodiebagLocationFilters') }}</div>

                <div v-if="hasLocationSelected" class="filter-clear-all" data-aid="home_filter_clear" @click="clearAll('location')">{{ $t('goodiebagClearAll') }}</div>
                <div v-else class="submenu-action" @click="selectAllOption">{{ $t('goodiebagSelectAll') }}</div>
                <div
                  v-if="showFilterSideBar && !displaySortInsideFilter"
                  class="filter-close-header-container"
                  @click="toggleFilterSideBar"
                >
                  <img
                    class="filter-header-close"
                    srcset="/img/filter-header-close.1x.png, /img/filter-header-close.2x.png 2x, /img/filter-header-close.3x.png 3x"
                    src="/img/filter-header-close.2x.png"
                  >
                </div>
              </div>

              <div class="filter-options-container" :style="displaySortInsideFilter ? 'height: calc(100vh - 430px)' : ''">
                <div
                  class="submenu-option-list custom-options"
                >
                  <category-option
                    v-for="item in filterOptions['location']"
                    :key="item.id"
                    v-model="filterValue['location']"
                    category-type="location"
                    :category="item"
                    :debug="item.id"
                    :result-count="subMenuResultCount[item.id]"
                    @input="locationInputChanged"
                  />
                </div>
              </div>
            </div>
            <div class="right-sidebar" :style="{display: !!subMenu && subMenu !== 'location' ? 'flex' : 'none'}">
              <div class="submenu-header">
                <div class="back-btn-container" @click="changeSubmenu(null)">
                  <img
                    class="filter-close"
                    srcset="/img/submenu-back.1x.png, /img/submenu-back.2x.png 2x, /img/submenu-back.3x.png 3x"
                    src="/img/submenu-back.2x.png"
                  >
                </div>
                <div class="submenu-title">{{ titleRefs[subMenu] }}</div>
                <div
                  v-if="subMenu === 'category' && visualizeFilterValue[subMenu] && (visualizeFilterValue[subMenu].length > 0 || Object.keys(visualizeFilterValue[subMenu]).length > 0)"
                  class="submenu-action"
                />
                <div
                  v-else-if="subMenu !== 'validPeriod' && filterValue[subMenu] && (filterValue[subMenu].length > 0 || Object.keys(filterValue[subMenu]).length > 0)"
                  class="submenu-action"
                  @click="clearAllOption"
                >
                  {{ $t('goodiebagClearAll') }}
                </div>
                <div
                  v-else-if="subMenu == 'validPeriod' && showValidPeroidClearAll"
                  class="submenu-action"
                  @click="clearValidPeriod"
                >
                  {{ $t('goodiebagClearAll') }}
                </div>
                <div
                  v-else-if="subMenu == 'creditRange' && showCreditRangeClearAll"
                  class="submenu-action"
                  @click="clearAllOption"
                >
                  {{ $t('goodiebagClearAll') }}
                </div>
                <div
                  v-else-if="!['validPeriod', 'creditRange'].includes(subMenu)"
                  class="submenu-action"
                  @click="selectAllOption"
                >
                  {{ $t('goodiebagSelectAll') }}
                </div>
              </div>
              <div
                v-if="filterOptions['customOptions'] && Object.keys(filterOptions['customOptions']).includes(subMenu)"
                class="submenu-option-list custom-options"
              >
                <category-option
                  v-for="item in filterOptions['customOptions'][subMenu].values"
                  :key="item.english_name"
                  v-model="filterValue[subMenu]"
                  :category="item"
                  :result-count="item.number_of_campaigns || item.number_of_mission_locations ?{'all': item.number_of_campaigns || item.number_of_mission_locations}:{'all': 0}"
                  @input="categoryInputChanged"
                />
              </div>
              <div
                v-else-if="!['validPeriod', 'creditRange', 'category'].includes(subMenu)"
                class="submenu-option-list"
              >
                <div
                  v-for="option in filterOptions[subMenu]"
                  :key="option.id"
                  :class="['submenu-option', {disabled: !subMenuResultCount[option], 'hide': JSON.stringify(visualizeSubMenuResultCount) !== '{}' && !visualizeSubMenuResultCount[option]}]"
                  data-aid="home_filter_{selection}"
                  :data-akey="option"
                  :data-atype="subMenu"
                  @click="selectFliterOption(option)"
                >
                  <div class="submenu-option-label">
                    {{ option }}
                  </div>
                  <img
                    v-if="filterValue[subMenu] && filterValue[subMenu].includes && filterValue[subMenu].includes(option)"
                    class="sort-tick"
                    srcset="/img/sort-tick.1x.png, /img/sort-tick.2x.png 2x, /img/sort-tick.3x.png 3x"
                    src="/img/sort-tick.2x.png"
                  >
                </div>
              </div>
              <div
                v-else-if="subMenu === 'category'"
                class="submenu-option-list"
              >
                <category-option
                  v-for="category in filterOptions[subMenu]"
                  :key="category.id"
                  v-model="filterValue[subMenu]"
                  :category="category"
                  :result-count="subMenuResultCount[category.id]"
                  :data-debug="category.id"
                  :is-visualize-filter="!!(visualizeFilterValue[subMenu] && visualizeFilterValue[subMenu][category.id])"
                  :class="{'category-option-hide': isHideCategory(category.id)}"
                />
              </div>
              <div
                v-else-if="subMenu === 'creditRange'"
                class="filter-credit-range-container"
              >
                <div class="input-container min">
                  <div class="input-label">{{ $t('goodiebagMin') }} </div>
                  <input
                    type="number"
                    :placeholder="filterOptions.creditRange.min"
                    :value="filterValue.creditRange && filterValue.creditRange.min"
                    @blur="updateCreditRangeMin"
                  >
                </div>

                <div class="input-container max">
                  <div class="input-label">{{ $t('goodiebagMax') }}</div>
                  <input
                    type="number"
                    :placeholder="filterOptions.creditRange.max"
                    :value="filterValue.creditRange && filterValue.creditRange.max"
                    @blur="updateCreditRangeMax"
                  >
                </div>
              </div>

              <div
                v-else-if="subMenu === 'validPeriod' && false"
                :class="['filter-valid-period-container', {disabled: filterValue.noExpiryDate }]"
              >
                <v-date-picker
                  v-model="filterValue.validPeriod"
                  color="red"
                  :available-dates="availableDates"
                  :masks="{ weekdays: 'WW', title:'MMM YYYY' }"
                  is-range
                  is-expanded
                  trim-weeks
                />
                <div
                  v-if="filterValue.noExpiryDate"
                  class="valid-period-overlay"
                />
                <div class="no-expiry-date-container" @click="toggleNoExpiryDate ">
                  <div
                    class="no-expiry-date-checkbox-container"
                  >
                    <img
                      v-if="filterValue.noExpiryDate"
                      srcset="/img/no-expiry-checked.1x.png, /img/no-expiry-checked.2x.png 2x, /img/no-expiry-checked.3x.png 3x"
                      src="/img/no-expiry-checked.2x.png"
                    >
                    <img
                      v-else
                      srcset="/img/no-expiry-uncheck.1x.png, /img/no-expiry-uncheck.2x.png 2x, /img/no-expiry-uncheck.3x.png 3x"
                      src="/img/no-expiry-uncheck.2x.png"
                    >
                  </div>
                  <label for="no-expiry-date">{{ $t('goodiebagNoRxpiryDate') }}</label>
                </div>
              </div>
            </div>

          </div>

          <div
            class="view-items-button primary-button"
            :data-aid="isExplorePage()?'home_filter_apply':isMissionDetailPage()?'':'goodie_bag_campaignlist_filter_click'"
            :data-akey="JSON.stringify(analyticsFilterValue)"
            @click="emitFilter(subMenu)"
          >
            {{ $t('goodiebagViewItems') }}
          </div>

          <div
            class="cancel-button secondary-button"
            @click="toggleFilterSideBar"
          >
            {{ $t('commonLogoutCancel') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isWechatMiniProgram } from '@/utils/utils'
import CategoryOption from '@/components/CategoryOption'
import { Carousel, Slide } from 'vue-carousel'
import { LOCATION_DISTRICT_ID, LOCATION_AREA_ID } from '../utils/utils'
import utilsMixin from '@/mixins/utilsMixin'
// import stringify from 'json-stable-stringify'

export default {
  name: 'SortFilterBar',
  components: {
    CategoryOption,
    Carousel,
    Slide
  },
  mixins: [utilsMixin],
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    isShowResultCount: {
      type: Boolean,
      default: true
    },
    isShowCategoryCarousel: {
      type: Boolean,
      default: true
    },
    isShowFilterSection: {
      type: Boolean,
      default: true
    },
    displaySortInsideFilter: {
      type: Boolean,
      default: false
    },
    filterName: {
      type: String,
      default: 'filter'
    },
    filterOptions: {
      type: Object,
      default: () => {}
    },
    filterResultCount: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    customFilterOptionLabels: {
      type: Array,
      default: () => []
    },
    titleCustomRefs: {
      type: Object,
      default: () => {}
    },
    campaignCount: {
      type: [Number, String],
      default: () => 0
    },
    campaignSettings: {
      type: Object,
      default: () => {}
    },
    numberOfVisualizeThreads: {
      type: [Number],
      default: () => 0
    },
    searchKey: {
      type: String,
      default: ''
    },
    searchSettingType: {
      type: String,
      default: ''
    },
    inFiltering: {
      type: Boolean,
      default: () => false
    },
    isCombine: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const now = new Date()
    // const today = now.toISOString().split('T')[0]
    // const todayDate = new Date(today)

    return {
      baseFilterOptionLabels: ['category', 'brand', 'offerType'],
      showSortDropdown: false,
      showFilterSideBar: false,
      subMenu: 'category',
      defaultFilterValue: {
        // validPeriod: { start: todayDate, end: todayDate },
        noExpiryDate: false,
        category: null,
        brand: null,
        offerType: null,
        location: {},
        searchKey: ''
      },
      filterValue: {
        // validPeriod: { start: todayDate, end: todayDate },
        noExpiryDate: false,
        category: null,
        brand: null,
        offerType: null,
        location: {},
        searchKey: '',
        distance: ''
      },
      visualizeFilterValue: {
        // validPeriod: { start: todayDate, end: todayDate },
        noExpiryDate: false,
        category: null
      },
      sortOption: '',
      dateRange: { start: now, end: now },
      vcTheme: {
        container: {
          light: 'vc-calendar-container',
          dark: 'vc-calendar-container'
        }
      },
      titleBaseRefs: {
        validPeriod: this.$t('goodiebagValidPeriod'),
        creditRange: this.$t('goodiebagCreditRange'),
        offerType: this.$t('goodiebagOfferTypelabel'),
        brand: this.$t('goodiebagBrand1'),
        category: this.$t('goodiebagCategory')
      },
      analyticsFilterValue: {},
      categoryIndex: 0,
      categoryPerPage: 7,
      categorySelectedIndex: 0,
      hasValueUpdated: false,
      distanceValue: '',
      combineTemp: false
    }
  },
  computed: {
    allFilterOptionLabels () {
      const result = this.baseFilterOptionLabels.concat(this.customFilterOptionLabels)
      console.log('@471', result, this.customFilterOptionLabels)
      return result
    },
    titleRefs () {
      const titleRefs = this.titleBaseRefs
      Object.assign(titleRefs, this.titleCustomRefs)
      console.log('@@645: ', titleRefs, this.titleCustomRefs)
      return titleRefs
    },
    sortingLabel () {
      const { sorting: options } = this.filterOptions
      const { sorting: selected } = this.value
      const { 0: selectedOption } = options.filter(option => option.value === selected)
      const { label, shortLabel } = selectedOption || {}
      const sortingLabel = shortLabel || label
      return sortingLabel ? `${this.$t('goodiebagSort')}-${sortingLabel}` : this.$t('goodiebagSort')
    },
    isShowVisualizeFilter () {
      if (!this.isShowCategoryCarousel) {
        return false
      }
      let isShowVisualizeFilter = true
      if (!this.slides.length) {
        isShowVisualizeFilter = false
      } else if (this.campaignSettings) {
        isShowVisualizeFilter = this.campaignSettings.category
      }
      this.$emit('is-show-visualize-filter', isShowVisualizeFilter)
      return isShowVisualizeFilter
    },
    isLocationFiltersDisable () {
      console.log('@567', this.filterResultCount, this.filterOptions)
      let isDisable = true
      if (this.filterResultCount && this.filterResultCount.location) {
        if (this.filterResultCount.location[LOCATION_AREA_ID] && this.filterResultCount.location[LOCATION_AREA_ID].all) {
          isDisable = false
        }
      } else {
        // filterResultCount not ready
        if (this.filterOptions && this.filterOptions.location &&
        this.filterOptions.location[0] && this.filterOptions.location[0].attribute_tags.length) {
          isDisable = false
        }
      }
      console.log('@567@568', isDisable)
      return isDisable
    },
    isFiltersDisable () {
      if (this.isCombine) {
        return false
      }

      let isFiltersDisable = false
      console.log('@@728-1: ', this.campaignSettings, this.filterOptionLabels.length)
      if (this.campaignSettings && !(this.campaignSettings.category || this.campaignSettings.brand || this.campaignSettings.offerType)) {
        isFiltersDisable = true
      } else if (!this.filterOptionLabels.length) {
        isFiltersDisable = true
      } else if (this.visualizeFilterValue && this.visualizeFilterValue.brand && this.visualizeFilterValue.offerType && !this.showCreditRangeClearAll) {
        isFiltersDisable = true
        this.visualizeFilterValue.brand.keys().forEach((item) => {
          if (this.visualizeFilterValue.brand[item]) {
            isFiltersDisable = false
          }
        })
        this.visualizeFilterValue.offerType.keys().forEach((item) => {
          if (this.visualizeFilterValue.offerType[item]) {
            isFiltersDisable = false
          }
        })
      }
      return isFiltersDisable
    },
    slides () {
      let slides = []

      if (this.filterOptions && this.filterOptions.category) {
        const category = this.filterOptions.category

        category.forEach((item, index) => {
          const slide = {}
          slide.id = item.id
          slide.name = item.name
          slide.english_name = item.english_name
          slide.icon_url = item.icon_url
          if (item.attribute_tags) {
            const attribute_tags = []
            item.attribute_tags.forEach((attribute_tag, index) => {
              attribute_tags.push(attribute_tag.id)
            })
            slide.attribute_tags = attribute_tags
          }
          slides.push(slide)
        })
      }
      if (slides.length > 1) {
        slides.unshift({ name: this.$t('goodiebagAll'), id: 0 })
      } else {
        slides = []
      }
      return slides
    },
    isWechatMiniProgram () {
      return isWechatMiniProgram()
    },
    filterOptionLabels () {
      console.log('??? filterOptionLabels', this.allFilterOptionLabels)
      console.log('??? filterOptionLabels--', this.filterOptions)

      return this.allFilterOptionLabels.filter(
        optionLabel => (this.filterOptions[optionLabel] && this.filterOptions[optionLabel].length > 0) ||
        this.customFilterOptionLabels.includes(optionLabel) && (this.filterOptions['customOptions'][optionLabel] && this.filterOptions['customOptions'][optionLabel].values && this.filterOptions['customOptions'][optionLabel].values.length > 0)
      )
    },
    hasLocationSelected () {
      return this.hasLocationValue(this.filterValue)
    },
    hasLocationFilter () {
      return this.hasLocationValue(this.value)
    },
    hasFilter () {
      let hasFilter = this.allFilterOptionLabels
        .some((option) => this.value[option] && (this.value[option].length > 0 || Object.keys(this.value[option]).length > 0))
      console.log('hasFilter', hasFilter, this.showValidPeroidClearAll)
      if (!hasFilter && this.value.noExpiryDate) {
        hasFilter = true
      }
      if (!hasFilter && this.hasValidPeroidValue(this.value)) {
        hasFilter = true
      }
      if (!hasFilter && this.hasCreditRangeValue(this.value)) {
        hasFilter = true
      }
      return hasFilter
    },
    showValidPeroidClearAll () {
      return this.hasValidPeroidValue(this.filterValue) || this.filterValue.noExpiryDate
    },
    showCreditRangeClearAll () {
      return this.hasCreditRangeValue(this.filterValue)
    },
    availableDates () {
      if (this.filterValue.noExpiryDate) {
        return { start: new Date('9999-12-31'), end: null }
      }
      return false
    },
    subMenuResultCount () {
      const subMenu = this.subMenu

      console.log('@649@478', subMenu, JSON.stringify(this.filterResultCount[subMenu]))
      return this.filterResultCount[subMenu] || {}
    },
    visualizeFilterResultCount () {
      if (this.visualizeFilterValue) {
        return this.filterResultCount
      }

      return {}
    },
    visualizeSubMenuResultCount () {
      return this.visualizeFilterResultCount[this.subMenu] || {}
    },
    creditRange () {
      let { max: valueMax = '', min: valueMin = '' } = this.filterValue.creditRange || {}
      const { max: optionMax = '', min: optionMin = '' } = this.filterOptions.creditRange || {}
      valueMax = valueMax || optionMax
      valueMin = valueMin || optionMin
      if (['', null, undefined].includes(valueMax) && ['', null, undefined].includes(valueMin)) {
        return null
      }
      return `${valueMin}-${valueMax}`
    },
    validPeriod () {
      const { start: validPeriodStart, end: validPeriodEnd } = this.filterValue.validPeriod || {}
      if (!validPeriodStart && !validPeriodEnd) {
        return null
      }
      const timeZone = 'Asia/Hong_Kong'
      let start = ''
      if (validPeriodStart) {
        start = new Date(validPeriodStart).toLocaleString('en-HK', {
          timeZone,
          month: 'short',
          year: 'numeric',
          day: '2-digit'
        })
      }

      let end = ''
      if (validPeriodEnd) {
        end = new Date(validPeriodEnd).toLocaleString('en-HK', {
          timeZone,
          month: 'short',
          year: 'numeric',
          day: '2-digit'
        })
      }

      return `${start} - ${end}`
    }
  },
  watch: {
    'value': function (newVal, oldVal) {
      console.log('@732', newVal, oldVal)
      if (newVal !== oldVal) {
        console.log('@732@d0', newVal.categorySelectedIndex, this.categorySelectedIndex)
        if (newVal.categorySelectedIndex !== this.categorySelectedIndex) {
          // this triggered when the value from store is collected
          console.log('@732d1, slide click', newVal.categorySelectedIndex)
          if (this.$store.getters['app/isMobile'] && !this.isWechatMiniProgram) {
            if (newVal.location === undefined) {
              setTimeout(() => {
                this.clearAll('location')
              }, 1500)
            }
          }
          this.slideClick(newVal.categorySelectedIndex || 0, true)
        }
      }
    },
    'searchKey': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        console.log('756: ', newVal, this.value)
        this.filterValue = { ...this.defaultFilterValue }
        // this.clearAll('location')
        // this.clearAll(false)
      }
    }
  },
  updated () {
    // console.log('@478@513', this.subMenu, JSON.stringify(this.subMenuResultCount))
  },
  mounted () {
    console.log('@478a', this.titleCustomRefs, JSON.stringify(this.titleCustomRefs))
    // console.log('@732b', JSON.stringify(this.value), JSON.stringify(this.filterValue))
    // console.log('@478b', this.filterOptions, JSON.stringify(this.filterOptions))
    /*
    console.log('@478a', this.titleCustomRefs, JSON.stringify(this.titleCustomRefs))
    console.log('@478b', this.filterOptions, JSON.stringify(this.filterOptions))
    console.log('@478c', this.filterResultCount, JSON.stringify(this.filterResultCount))
    */
  },
  methods: {
    beforeDestroy () {
      console.log('768: ', this.filterName, this.filterValue)
      this.$store.commit('app/setFilterValue', this.filterName, this.filterValue)
      this.$store.commit('app/setVisualizeFilterValue', this.filterName, this.visualizeFilterValue)
    },
    hasLocationValue (value) {
      console.log('@642', value)
      if (value && value.location) {
        if (value.location[LOCATION_AREA_ID] && value.location[LOCATION_AREA_ID].length) {
          return true
        }
        if (value.location[LOCATION_DISTRICT_ID] && value.location[LOCATION_DISTRICT_ID].length) {
          return true
        }
      }
      return false
    },
    locationInputChanged () {
      this.hasValueUpdated = true
      console.log('@756@input@location')
      console.log('filterSubMenuChanged - filter - location796')
      this.$parent.updateFilteredResultCount({ subMenu: 'location', filterValue: this.filterValue, searchSettingType: this.searchSettingType })
    },
    categoryInputChanged () {
      this.hasValueUpdated = true
      console.log('@756@input@category')
    },
    isShowFilterOptionLabels (option) {
      const isShowFilterOptionLabels = this.baseFilterOptionLabels.includes(option) && (!this.campaignSettings || this.campaignSettings[option])

      return isShowFilterOptionLabels
    },
    isHideCategory (id) {
      let isHideCategory = false
      if (this.visualizeFilterValue && this.visualizeFilterValue.category) {
        isHideCategory = true
        if (this.visualizeFilterValue.category[id] && this.visualizeFilterValue.category[id].length >= 0) {
          isHideCategory = false
        }
      }
      return isHideCategory
    },
    dealCategorySelect (subMenu) {
      if (subMenu === null && this.filterValue.category && !Object.keys(this.filterValue.category).length &&
          this.visualizeFilterValue.category && Object.keys(this.visualizeFilterValue.category).length &&
          this.filterOptions && this.filterOptions.category) {
        let categoryHasLevel2 = false
        this.filterOptions.category.forEach((item) => {
          if (item && item.attribute_tags && item.attribute_tags.length > 1) {
            categoryHasLevel2 = true
          }
        })
        if (categoryHasLevel2) {
          this.filterValue.category = this.visualizeFilterValue.category
        }
      }
    },
    changeSubmenu (subMenu) {
      if (this.numberOfVisualizeThreads > 0) {
        return
      }
      console.log('@790', subMenu, this.hasValueUpdated)
      this.subMenu = subMenu
      this.dealCategorySelect(subMenu)
      this.$emit('sub-menu-changed', { subMenu, filterValue: this.filterValue, hasValueUpdated: this.hasValueUpdated })
      if (!subMenu) {
        this.hasValueUpdated = false
      }
    },
    hasCreditRangeValue (value) {
      const {
        max: creditMax,
        min: creditMin
      } = value.creditRange || {}
      return !isNaN(creditMax) || !isNaN(creditMin)
    },
    updateCreditRangeMin (event) {
      const min = event.target.value
      let { creditRange } = this.filterValue
      creditRange = creditRange || {}
      const limitMin = 0
      let {
        max: limitMax
      } = creditRange
      limitMax = limitMax || 100000000

      let valueMin = Math.max(limitMin, min)
      valueMin = Math.min(limitMax, valueMin)
      this.filterValue = { ...this.filterValue, creditRange: { ...creditRange, min: valueMin }}
    },
    updateCreditRangeMax (event) {
      const max = event.target.value
      console.log('updateCreditRangeMax', max)
      let { creditRange } = this.filterValue
      creditRange = creditRange || {}
      const limitMax = 100000000
      let {
        min: limitMin
      } = creditRange
      limitMin = limitMin || 0

      let valueMax = Math.max(limitMin, max)
      valueMax = Math.min(limitMax, valueMax)
      this.filterValue = { ...this.filterValue, creditRange: { ...creditRange, max: valueMax }}
    },
    hasValidPeroidValue (value) {
      const startOfToday = this.$moment.utc().startOf('day').toDate()
      const {
        start: validPeriodStart,
        end: validPeriodEnd
      } = value.validPeriod || {}

      if (!validPeriodStart && !validPeriodEnd) {
        return false
      }

      return validPeriodStart.getTime() !== startOfToday.getTime() ||
       validPeriodEnd.getTime() !== startOfToday.getTime()
    },
    toggleNoExpiryDate () {
      this.filterValue.noExpiryDate = !this.filterValue.noExpiryDate
      console.log('toggleNoExpiryDate', this.filterValue.noExpiryDate)
      if (this.filterValue.noExpiryDate) {
        this.filterValue.validPeriod = {}
      } else {
        this.filterValue.validPeriod = this.defaultFilterValue.validPeriod
      }
    },
    updateDistanceValue (value) {
      console.log('@@1025: ', value, this.filterValue)
      if (this.filterValue.distance === value) {
        this.filterValue.distance = null
        this.distanceValue = ''
      } else {
        this.filterValue.distance = value
        this.distanceValue = value
      }
    },
    emitInput (newValue, onlySortChange = false, subMenu = null, selectedCategory = null) {
      this.$emit('input', { ...newValue, onlySortChange, subMenu, selectedCategory })
    },
    emitFilter (subMenu = null, selectedCategory = null) {
      console.log('@618', this.filterValue, this.visualizeFilterValue, subMenu)
      this.dealCategorySelect(null)
      const { category: selectedCategoryIDs } = this.filterValue
      let analyticsFilterValue = this.filterValue
      this.filterValue.visualize = this.visualizeFilterValue
      if (selectedCategoryIDs) {
        const categoryOptions = this.filterOptions['category'] || []
        const selectedCategoryNames = categoryOptions
          .filter(item => Object.keys(selectedCategoryIDs).includes(item.id.toString()))
          .map(item => item.name)
          .join(', ')
        analyticsFilterValue = {
          ...this.filterValue,
          category: selectedCategoryNames
        }
      }
      this.analyticsFilterValue = analyticsFilterValue
      console.log('@491@618b', JSON.stringify(this.filterValue), this.categorySelectedIndex)
      this.emitInput({ ...this.filterValue, categorySelectedIndex: this.categorySelectedIndex, sorting: this.sortOption }, false, subMenu, selectedCategory)
      this.showFilterSideBar = false
      window.$('html').removeClass('no-scroll')
      this.filterValue = { ...this.defaultFilterValue }
      setTimeout(() => { this.$emit('toggle-sidebar', false) }, 300)
    },
    clearAll (filterType) {
      console.log('@788')
      if (filterType === 'location') {
        // clear location
        this.filterValue = { ...this.filterValue, location: {}}
        console.log('filterSubMenuChanged - filter - location937 - clear')
        this.$parent.updateFilteredResultCount({ subMenu: 'location', filterValue: this.filterValue, searchSettingType: this.searchSettingType })
        this.$parent.getCustomAttributeTags(this.filterValue, this.searchSettingType)
      } else {
        // clear everything except location
        this.filterValue = { ...this.defaultFilterValue, location: { ...this.filterValue.location }}
        console.log('filterSubMenuChanged - filter - location937 - clearAll')
        console.log('@916', JSON.stringify(this.filterValue))
        this.$parent.updateAllSubmenus(this.filterValue, this.searchSettingType)
        // this.$parent.getCustomAttributeTags(this.filterValue, this.searchSettingType)
        // clear filters
      }

      /*
      if (clearCategory && this.visualizeFilterValue) {
        this.filterValue = { ...this.visualizeFilterValue }
      } else {
        this.filterValue = { ...this.defaultFilterValue }
      }
      */
    },
    clearValidPeriod () {
      this.filterValue = {
        ...this.filterValue,
        validPeriod: this.defaultFilterValue.validPeriod,
        noExpiryDate: false
      }
    },
    clearAllOption () {
      this.filterValue = { ...this.filterValue, [this.subMenu]: null }
    },
    selectAllOption () {
      let options
      let customOptions
      if (this.subMenu === 'category' || this.subMenu === 'location') {
        options = {}
        const categories = this.filterOptions[this.subMenu] || []
        categories.map((category) => {
          const resultCount = this.subMenuResultCount[category.id]
          if (resultCount && resultCount.all > 0) {
            let { attribute_tags: attributeTags } = category
            attributeTags = attributeTags || []
            options[category.id] = attributeTags.map(tag => tag.id)
          }
        })
      } else if (this.customFilterOptionLabels.includes(this.subMenu)) {
        customOptions = {}
        const customLevelTwoOptions = {}
        const customLevelOneOptions = this.filterOptions['customOptions'][this.subMenu] || []
        customLevelOneOptions.values.map((item) => {
          if (item.number_of_campaigns || item.number_of_mission_locations) {
            customLevelTwoOptions[item.id] = []
          }
        })
        customOptions[customLevelOneOptions.key] = customLevelTwoOptions
      } else {
        options = this.filterOptions[this.subMenu] || []
        options = options.filter(option => this.subMenuResultCount[option])
        options = [...options]
      }
      this.filterValue = { ...this.filterValue, [this.subMenu]: options, ...customOptions }
    },

    selectFliterOption (option) {
      if (!this.subMenuResultCount[option]) {
        return
      }

      let { [this.subMenu]: selected } = this.filterValue
      selected = selected || []
      const newSelected = [...selected]
      const index = newSelected.indexOf(option)
      if (index !== -1) {
        newSelected.splice(index, 1)
      } else {
        newSelected.push(option)
      }
      this.filterValue = { ...this.filterValue, [this.subMenu]: newSelected }
      this.hasValueUpdated = true
    },
    selectSortOption (sortOption) {
      this.showSortDropdown = false
      this.emitInput({ ...this.value, sorting: sortOption }, true)
    },
    selectFilterSortOption (sortOption) {
      console.log('???', this.sortOption, sortOption)
      this.sortOption = sortOption
    },
    toggleSortDropdown () {
      if (this.disabled) {
        return
      }
      this.showSortDropdown = !this.showSortDropdown
    },
    toggleFilterSideBar (filterType) {
      if (this.disabled) {
        return
      }

      console.log('@946, toggling')
      if (!filterType) {
        filterType = 'general'
      }

      this.showFilterSideBar = !this.showFilterSideBar

      this.subMenu = null
      console.log('@1019-0: ', this.showFilterSideBar, filterType)
      if (this.showFilterSideBar) {
        // if (this.isCombine) {
        // }
        if (this.isCombine) {
          window.$('.search-filter-area').attr('style', 'z-index: 500')
        }

        console.log('@1019')
        window.$('html').addClass('no-scroll')
        this.$emit('toggle-sidebar', true)
        this.filterValue = { ...this.defaultFilterValue, ...this.value }
        if ('sorting' in this.filterValue) {
          this.sortOption = this.filterValue['sorting']
          delete this.filterValue['sorting']
        }
        console.log('1019-filterSubMenuChanged - filter - toggle: ', this.filterValue)
        if (filterType === 'location') {
          this.subMenu = 'location'
          this.$parent.updateFilteredResultCount({ subMenu: 'location', filterValue: this.filterValue, searchSettingType: this.searchSettingType })

          if (!this.displaySortInsideFilter) {
            window.$('.general-filter').attr('style', 'display: none')
          }
        } else {
          window.$('.general-filter').attr('style', '')
          // inside the menu changed event
          this.$parent.getCustomAttributeTags(this.filterValue, this.searchSettingType)
          this.$parent.updateFilteredResultCount({ subMenu: 'category', filterValue: this.filterValue })
          this.$parent.updateFilteredResultCount({ subMenu: 'brand', filterValue: this.filterValue })
          this.$parent.updateFilteredResultCount({ subMenu: 'offerType', filterValue: this.filterValue })
        }

        setTimeout(() => {
          this.combineTemp = !this.combineTemp
        }, 100)
        /*
        if (this.filterType === 'location') {
          this.changeSubmenu('location')
        }
        */
      } else {
        if (this.isCombine) {
          window.$('.search-filter-area').attr('style', '')
        }

        window.$('html').removeClass('no-scroll')
        this.$emit('toggle-sidebar', false)
        this.filterValue = { ...this.defaultFilterValue }
      }
    },
    categoryMoveLeft () {
      this.categoryIndex -= 3
      this.categoryIndex = this.categoryIndex < 0 ? 0 : this.categoryIndex
      console.log(this.categoryIndex)
    },
    categoryMoveRight () {
      this.categoryIndex += 3
      this.categoryIndex = this.categoryIndex >= this.slides.length - this.categoryPerPage ? this.slides.length - this.categoryPerPage : this.categoryIndex
      console.log(this.categoryIndex)
    },
    slideClick (index, skipEmitFilter) {
      console.log('filterSubMenuChanged - filter - location937 - slideClick')
      this.clearAll(false)

      console.log('@732d@1053', this.slides)
      if (this.slides.length && this.slides[index].id !== 0) {
        let level_2 = []
        if (this.slides[index].attribute_tags) {
          level_2 = this.slides[index].attribute_tags
        }
        this.visualizeFilterValue.category = {}
        this.visualizeFilterValue.category[this.slides[index].id] = level_2
      } else {
        this.visualizeFilterValue = {}
      }
      console.log('@732d@1103', index)
      this.categorySelectedIndex = index
      // this.updateVisualizeFilter()
      if (!skipEmitFilter) {
        this.emitFilter('category', this.slides[index])
      }
    },
    isFilterOptionDisabled (filterOption) {
      if (!this.filterResultCount) {
        return false // not ready, don't hide options yet
      }

      if (!['category', 'brand', 'offerType'].includes(filterOption)) {
        return false
      }

      const { [filterOption]: resultCount } = this.filterResultCount

      console.log('@1073@1058a', filterOption, resultCount, this.filterResultCount, typeof resultCount)

      if (!resultCount) {
        return true
      }
      for (const key in resultCount) {
        const value = resultCount[key]
        // console.log('@1073@1058b:' + filterOption, resultCount, key, value, typeof value)
        if (value) {
          if (value && parseInt(value) > 0) {
            // console.log('@1073c, value found', value)
            return false
          }
          if (value.all && parseInt(value.all) !== '0') {
            // console.log('@1073d, value found', value.all)
            return false
          }
        }
      }

      console.log('@1073b, no value found')
      return true
    },
    getFilterOptionSelectedValue (filterOption) {
      let selectedValue = ''
      // this.filterOptionLabels.map((filterOption) => {
      const { [filterOption]: selected } = this.filterValue
      if (!selected) {
        return
      }

      if (filterOption === 'distance') {
        console.log('@1288: ', selected, this.filterValue)
        selectedValue = selected
      } else if (filterOption === 'category' && Object.keys(selected).length > 0) {
        const { category: categoryOptions } = this.filterOptions
        selectedValue = categoryOptions
          .filter(item => Object.keys(selected).includes(item.id.toString())).map(item => item.name).join(', ')
      } else if (this.customFilterOptionLabels.includes(filterOption) && this.filterOptionLabels.includes(filterOption) && Object.keys(selected).length > 0) {
        const { [filterOption]: filterOptionItem } = this.filterOptions['customOptions']
        if (filterOptionItem) {
          selectedValue = filterOptionItem.values
            .filter(item => Object.keys(selected).includes(item.id.toString())).map(item => item.name).join(', ')
        }
      } else if (selected.length > 0) {
        selectedValue = selected.join(', ')
      }
      // })
      return selectedValue
    }
  }
}
</script>

<style lang="scss">
.filter-valid-period-container{

  &.disabled {
     .vc-container{
        .vc-arrow, .vc-title, .vc-weekday, .vc-day-content, .vc-day.is-not-in-mont {
          color: #CCCCCC;
          cursor: unset;
          &:focus, &:hover {
            font-weight: var(--font-semibold);
            background-color: unset
          }
        }
     }
  }

  .valid-period-overlay{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .vc-container{
    border: none;
    --rounded-full: 0px;
    --red-600: #E2002C;

    .vc-arrow {
      color: #E2002C;
    }

    .vc-day.is-not-in-month {
      opacity: 1;
      color: #CCCCCC;
    }

    .vc-title {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      color: #000000;
    }
    .vc-weekday {
      padding-bottom: 12px;
      padding-top: 12px;
      font-size: 12px;
      color: #999999;
    }

    .vc-day-content {
      font-weight: 600;
      font-size: 13px;
      text-align: center;
      color: #000000;
    }

    .vc-weeks:before {
      content: '';
      height: 1px;
      top: 40px;
      position: absolute;
      left: 0;
      right: 0;
      background: #EEEEEE;
    }

    .vc-weeks:after {
      content: '';
      height: 1px;
      bottom: 0px;
      position: absolute;
      left: 0;
      right: 0;
      background: #EEEEEE;
    }

  }
}
.sort-filter-line {
  .results {
    b {
      font-weight: 600;
    }
  }
}
</style>

<style lang="scss" scoped>
.is-vue-desktop {

  .sort-filter-line{
    flex-direction: row;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 12px;
    .results{
      width: 100%;
      margin-top: 0px;
      padding-left: 10px;
    }
    .sort-filters-container{
      // width: 345px;
    }
  }
  .agile-title{
    margin-top: 5px;
  }
  .sort-filters-container{

    .filter-close-container{
      display: none;
    }

    .filter-sidebar {
      width: 500px;
      right: -500px;

      .cancel-button {
        display: none;
      }

      .view-items-button {
        width: 324px;
        font-size: 20px;
        line-height: 27px;
      }

      .filter-header, .submenu-header {
        height: 77px;
        padding-left: 20px;
        padding-right: 32px;

        .submenu-title, .filter-title {
          font-size: 30px;
          line-height: 41px;
        }
        .filter-clear-all, .submenu-action {
          padding-top: 4px;
          font-size: 18px;
          line-height: 25px;
        }
        .filter-close-header-container{
          display: flex;
          cursor: pointer;
          margin-left: 20px;
          justify-content: center;
          align-items: center;
        }
      }

      .sidebar-container {
        bottom: 80px;
        width: 200%;

        &.active {
          transform: translateX(-500px);
        }

        .left-sidebar {
          width: 500px;
        }
        .right-sidebar {
          width: 500px;
          left: 500px;
        }
      }

      .filter-options-container{
        overflow-y: auto;
        height: calc(100vh - 160px);
      }

      .filter-options {
        height: 62.5px;
        padding-left: 20px;

        &.selected {
          height: 71.5px;
        }

        &:before {
          left: 20px;
        }

        .filter-label{
          font-size: 18px;
          line-height: 25px;
        }

        .filter-selected {
          font-size: 15px;
          line-height: 23px;
        }

      }

      .submenu-option {
        height: 62.5px;
        padding-left: 20px;

        .submenu-option-label {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }

  }
}

.distance-filter {
  .distance-filter-options {
    display: flex;
    gap: 8px;

    .distance-filter-option {
      &.selected {
        background: var(--Temple-St-Dusk-Maroon);
        color: #FFF
      }
      width: 60px;
      padding: 5px 3px;
      border: 1px solid var(--Temple-St-Dusk-Maroon);
      border-radius: 5px;
    }
  }
}

.sort-filter-line {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 12px;

  .sort-filters-container{
    width: calc(100% - 30px);

    .filter-types {
      display: flex;
      width: 100%;
    }
  }
  .results{
    padding-left: 16px;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    width: 100%;
    margin-top: 9px;
    color: #8B8B8B;
  }
}

.sort-filters-container{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 48px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 7px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-shadow: 0px 1px 4px rgba(175, 175, 175, 0.2);
  border-radius: 4px;
  position: relative;

  &.active, &.disabled {
    cursor: default;
  }

  &.disabled .disabled-overlay{
    display: block;
  }

  &.only-one-filter {
    width: unset;
    aspect-ratio: 1 / 1;
  }

  .disabled-overlay {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: white;
    opacity: 0.4;
  }

  .filter-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #1A1A1A;
    opacity: 0.6;
    z-index: 31
  }

  .filter-close-container{
    position: fixed;
    top: 19px;
    z-index: 32;
    right: 27px + 300px
  }

  .filter-sidebar{
    transition: right 300ms ease-in-out;
    position: fixed;
    top: 0;
    width: 304px;
    right: -304px;
    bottom: 0;
    background: #1A1A1A;
    //z-index: 32;
    background: #FFFFFF;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 24px;
    flex-direction: column;
    z-index: 900;  // filter sidebar, need to be higher than nav bar

    .filter-credit-range-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 24px;
      padding-right: 22px;
      position: relative;

      &:before {
        content: '';
        height: 1px;
        top: 0;
        position: absolute;
        left: 15px;
        right: 0;
        background: #EEEEEE;
      }

      &:after {
        content: '';
        height: 1px;
        bottom: 0;
        position: absolute;
        left: 15px;
        right: 0;
        background: #EEEEEE;
      }

      .input-container {
        display: flex;
        flex-direction: row;
        height: 60px;
        justify-content: flex-start;
        align-items: center;

        input{
          background: #FFFFFF;
          padding: 8px 12px;
          width: 75px;
          height: 35px;
          border: 1px solid #D5D8DE;
          box-sizing: border-box;
          border-radius: 4px;
        }

        .input-label{
          width: 57px;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #000000;
        }
      }
    }

  .filter-valid-period-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 16px;
    position: relative;

    .no-expiry-date-container{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-top: 16px;
      z-index: 1;

      .no-expiry-date-checkbox-container{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
      }

      label {
        margin-left: 12px;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
        line-height: 19px;
        color: #000000;
      }
    }
  }

  .submenu-option-list {
    overflow-y: auto;
    flex: 1
  }

  .submenu-option.hide{
    display: none;
  }

  .submenu-option {
    &:before{
      content: '';
      height: 1px;
      top: 0;
      position: absolute;
      left: 15px;
      right: 0;
      background: #EEEEEE;
    }

    &:last-child:after {
      content: '';
      height: 1px;
      bottom: 0;
      position: absolute;
      left: 15px;
      right: 0;
      background: #EEEEEE;
    }

    padding-left: 24px;
    padding-right: 16px;
    height: 54px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    &.disabled {
      display: none;
      .submenu-option-label {
        color: #999999;
        cursor: unset;
      }
    }

    .submenu-option-label{
      font-weight: 600;
      cursor: pointer;
      font-size: 14px;
      flex: 1;
      line-height: 19px;
      color: #000000;
    }
  }

  .submenu-header{
    height: 57px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;

    .back-btn-container{
      display: flex;
      cursor: pointer;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .submenu-title {
      margin-left: 10px;
      flex: 1;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #000000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 16px;
    }

    .submenu-action{
      font-size: 14px;
      cursor: pointer;
      line-height: 22px;
      text-align: right;
      color: #939393;
    }

  }

  .cancel-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 30px 10px;
    width: 271px;
    box-sizing: border-box;
    border-radius: 50px;
    margin-top: 12px;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
  }

  .view-items-button {
    width: 273px;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 30px 10px;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
  }

  .sidebar-container {
    top: 0;
    bottom: 140px;
    left: 0;
    right: 0;
    width: 608px;
    position: absolute;
    transition: transform 300ms ease-in-out;
    transform: translateX(0px);
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;

    overflow-y: auto;
    &.active {
      transform: translateX(-304px);
    }

    .left-sidebar{
      width: 304px;
      position: absolute;
      top: 0;
      left: 0;
    }

    .right-sidebar{
      width: 304px;
      display: flex;
      flex-direction: column;
      bottom: 0px;
      position: absolute;
      top:0;
      left: 304px;
    }
  }

  &.active {
    right: 0px;
  }

  .filter-options{
    padding-left: 24px;
    padding-right: 16px;
    height: 51px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    cursor: pointer;

    &.selected {
      height: 54px;
    }

    &.disabled{
      display: none;
      .filter-label {
        color: #999999;
      }
    }

    &:before{
      content: '';
      height: 1px;
      top: 0;
      position: absolute;
      left: 15px;
      right: 0;
      background: #EEEEEE;
    }

    &:last-child:after {
      content: '';
      height: 1px;
      bottom: 0;
      position: absolute;
      left: 15px;
      right: 0;
      background: #EEEEEE;
    }

    .filter-label {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .filter-selected {
      font-size: 12px;
      line-height: 20px;
      color: #999999;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .filter-header{
    height: 57px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;

    .filter-title{
      flex: 1;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #000000;
    }

    .filter-clear-all {
      cursor: pointer;
      font-size: 14px;
      line-height: 22px;
      color: #939393;
    }

    .filter-close-header-container {
      display: none;
    }
  }
}

.sort-dropdown{
  position: absolute;
  top: 45px;
  left: 0;
  z-index: 2;
  max-height: 0;
  width: 265px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: max-height 300ms ease-in-out;

  &.active {
    max-height: 1000px;
  }

  .sort-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
  }

  .sort-option-list {
    background: #FFFFFF;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
    border: 1px solid #EEEEEE;
    box-shadow: 0px 1px 4px rgba(175, 175, 175, 0.2);
    z-index: 1;

    .sort-option{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;

      .sort-option-label {
        flex: 1;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
      }

      &.selected .sort-option-label {
        color: #E2002C;
      }
    }
  }
}

.sort-divider{
  width: 1px;
  height: 32px;
  background: #ECECEC;
}

.sort, .filters {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  .red-dot {
    width: 7px;
    height: 7px;
    border-radius: 3.5px;
    top: -6px;
    left: 11px;
    background: #FF0000;
    position: relative;
    margin-left: -7px;
  }

  .filter-text {
    margin-left: 4px;
  }

  .sort-text {
    margin-left: 5px;
  }

  .filter-text, .sort-text{
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #E2002C;
  }
}
}
.document-home.mini .filter-sidebar.mini-program{
  bottom: calc(66px + var(--sab));
}

.document-home.mini{
  .agile-title{
    margin-left: 6px;
  }
  .results{
    padding-left: 16px;
  }
  .sort-filter-line{
    padding-bottom: 20px;
  }
}

.agile-title{
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  padding-left: 10px;
  color: #000000;
  margin-top: 16px;
}
.category-carousel-contain{
  position: relative;
  margin-top: 20px;
  .category-carousel-nav-left{
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 100; //category carousel nav left
    cursor: pointer;
  }
  .category-carousel-nav-right{
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 100; //category carousel nav right
    cursor: pointer;
  }
  .category-carousel-slide{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 14px;
    padding-left: 14px;
    .text{
      margin-top: 8px;
      color: #000000;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 120px;
      cursor: pointer;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-align: center;
    }
  }
  .category-carousel-slide.selected{
    .img-contain{
      background: rgba(226, 0, 44, 0.1);
      border: solid 1px rgba(226, 0, 44, 1);
    }
    .text{
      color: rgba(226, 0, 44, 1);;
    }
  }
  .category-carousel-slide:hover{
    .text{
      color: rgba(226, 0, 44, 1);;
    }
  }
  .img-contain{
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(175, 175, 175, 0.2);
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .all-img{
      width: 48px;
    }
    .icon-img{
      max-width: 108px;
      border-radius: 50%;
    }
    &.mobile {
      width: 90px;
      height: 90px;

      .icon-img{
        max-width: 90px;
        border-radius: 50%;
      }
    }
  }
}

div.combine-filters {
  padding: 0px;
  margin-left: 12px;
  border-radius: 10px;

  div.sort-filters-container {
    width: fit-content !important;
    padding: 12px;
    margin: 0px;
    border-radius: 10px;
    border: 2px solid var(--temple-st-dusk-maroon-80);

    div.filters {
      width: 24px;
      img {
        width: 24px;
        height: 24px
      }
    }
  }
}

.filters.disable{
  filter: opacity(0.4);
  cursor: default;
}

.category-option-hide{
  display: none;
}

.is-vue-mobile .category-carousel-contain{
  width: 100vw;
  overflow: hidden;
}

.is-vue-mobile .category-carousel-slide{
  padding-right: 9px;
  padding-left: 9px;
  .text{
    max-width: 90px;
  }
}

</style>
<style>
.is-vue-mobile .category-carousel{
  width: calc(100vw);
  height: 100%;
  /* min-height: 156px; */
  min-height: 136px;
}
</style>
