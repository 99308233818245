<template>
  <div v-if="participateDirectId" class="loading-view">
    {{ participateDirectId }}
    <img src="/img/loading.gif">
  </div>
  <div v-else-if="isNoData" />
  <div v-else class="gamification-detail-container stamp-prize-view" :class="langClass">
    <div class="stamps-title-info">
      <label class="title" :class="langClass">{{ $t('gamificationStampSectionTitle') }}</label>
      <img
        width="18"
        height="18"
        class="question-mark-icon"
        src="/img/question-icon.png"
        @click="gotoTutorial"
      >
    </div>
    <div :class="{'stamps-bg': !isMobile}">
      <stamp-list
        v-if="gamificationData.stamp_count > 0"
        ref="stampsTarget"
        :show-all="true"
        :gamification-data="gamificationData"
        :force-use-stamp-rewards-data="true"
        :stamp-rewards-data="stampRewards"
        :show-toturial="false"
        :show-see-more="false"
        :has-prize-button="false"
        :started-journey="gamificationData.is_public || !!gamificationData.gamification_campaign_participation_record_id"
        :disabled="!canStartJourney"
        @gotoStampPrizeList="gotoStampPrizeList"
        @startJourney="startJourney"
        @hook:mounted="handleStampsMounted"
      />
    </div>

    <div v-if="prizeList.length > 0" ref="prizeListTarget" class="prize-list-view">
      <label class="title" :class="langClass">{{ $t('gamificationStampSectionPrizeListTitle') }}</label>
      <label class="title-description">{{ $t('gamificationStampSectionPrizeListDesc') }}</label>
      <div
        v-for="(item, index) in prizeList"
        ref="prizeItemTarget"
        :key="`${item.id}-${item.index}`"
        :class="['prize-item', {'is-dimmed': item.isOutOfStock}]"
      >
        <div class="process">
          <div class="process-cricle">
            <img :src="item.imgSource">
            <span>{{ item.index }}</span>
          </div>
          <div v-if="index < prizeList.length-1" class="process-line" :class="{'collected': prizeList[index+1].isCollected}" />
        </div>
        <div class="reward-detail" @click="viewPrizeDetail(item)">
          <div class="img-container">
            <label v-if="item.isOutOfStock" class="out">{{ $t('gamificationStampSectionOutOfStockLabel') }}</label>
            <img class="cover" :src="item.prizeImageUrl">
          </div>
          <div class="reward-info">
            <div v-if="item.prize_categories && item.prize_categories.length > 0" class="reward-tags">
              <div v-for="(tag, tagIndex) in item.prize_categories.filter(v => v && v.trim().length > 0)" :key="tagIndex" class="tag">{{ tag }}</div>
            </div>
            <label :class="['prize-name is-collapse-2lines']">{{ item.prizeTitle }}</label>
            <div class="prize-description toastui-editor-contents" :class="{'is-collapse-3lines-html': isMobile, 'is-collapse-2lines-html': !isMobile}" v-html="item.prizeDescription" />
            <button class="common-button" :class="{'not-collected': !item.isCollected}" @click.stop="viewReward(item)">
              <img v-if="!item.isCollected" src="/img/lock-icon.png">
              <label>{{ item.isCollected ? $t('gamificationStampSectionPrizeViewBtn') : $t('gamificationStampSectionPrizeListBtn', {count: item.index}) }}</label>
            </button>
            <label v-if="!item.isCollected" class="tips">{{ $t('gamificationStampSectionPrizeItemLockedDesc', {count: item.index}) }}</label>
            <label v-else-if="item.isCollected && !item.isOutOfStock" class="tips">
              <img src="/img/phone_verified.png"> {{ rewardUnlockedText(item) }}
            </label>
            <div v-if="item.isCollected && item.isOutOfStock" class="out-of-stock-info" style="position: relative">
              <div v-if="item.isCollected && item.isOutOfStock" class="out-tips">
                <label>{{ $t('gamificationStampSectionPrizeItemUnlockedOutDesc', {count: item.index}) }}</label>
                <img
                  class="info-mark-icon"
                  srcset="/img/info.1x.png, /img/info.2x.png 2x, /img/info.3x.png 3x"
                  src="/img/info.2x.png"
                >
              </div>
              <custom-tooltip
                :visible="!isBackNavigation && showPrizeOutTips[item.index] && showPrizeOutTips[item.index].show"
                class="gamification-tooltip-container"
                :show-hover="true"
                :content="$t('gamificationStampSectionPrizeItemOutDesc')"
                :custom-tooltip-slot-element="showPrizeOutTips[item.index] && showPrizeOutTips[item.index].customTooltipSlotElement"
                :custom-tooltip-reference-element="showPrizeOutTips[item.index] && showPrizeOutTips[item.index].customTooltipReferenceElement"
                :tooltip-index="showPrizeOutTips[item.index] && showPrizeOutTips[item.index].index"
                :space-between="0"
                :show-close="true"
                :revert="true"
                @closeTips="closePrizeOutTips(item.index)"
              />
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- <div class="go-to-earn">
      <label class="title" :class="langClass">Earn your stamps to unlock rewards</label>
      <button class="common-button red-bg-button" @click="gotoEarn">Go to earn > </button>
    </div> -->

    <el-dialog
      :visible.sync="showErrorDialog"
      class="fail-to-acquire-coupon"
      width="316px"
      :title="displayDialogErrorMessage"
      center
      :show-close="false"
    >
      <span slot="footer" class="dialog-footer">

        <el-button
          tag="button"
          type="button"
          class="el-button el-button--primary primary-button"
          @click="closeErrorDialog"
        >
          {{ $t('goodiebagOk') }}
        </el-button>
      </span>
    </el-dialog>

    <div id="robot" />
  </div>
</template>

<script>
import axios from 'axios'
// import NotFind from '@/components/NotFind'
import utilsMixin from '@/mixins/utilsMixin'
import missionMixin from '@/mixins/missionMixin'
import backHandlerMixin from '@/mixins/backHandlerMixin'
import StampList from '@/components/StampList'
import CustomTooltip from '@/components/CustomTooltip'
import { asyncGetGamificationCampaignDetail } from '@/helpers/http'
import { pushRouterWithQuery, replaceRouterWithQuery } from '@/utils/utils'
import { sendAdobeManualAnalytics } from '@/utils/tracking'
export default {
  name: 'StampPrizeList',
  components: {
    CustomTooltip,
    StampList
    // NotFind
  },
  mixins: [utilsMixin, missionMixin, backHandlerMixin],
  data () {
    return {
      isLoading: true,
      participateDirectId: null,
      gamificationCampaignId: null,
      showErrorDialog: false,
      displayDialogErrorMessage: '',
      prizeList: [],
      showPrizeOutTips: {},
      canStartJourney: false,
      errorNext: null
    }
  },
  computed: {
    isNoData () {
      if (this.isLoading) {
        return false
      }

      if (this.gamificationData.id) {
        return false
      }

      return true
    },
    gamificationData () {
      return this.$store.getters['gamification/gamificationDetail'](this.gamificationCampaignId) || {}
    },
    stampRewards () {
      // let stampRewards = this.$store.getters['gamification/stampRewards'](this.gamificationCampaignId) || []
      // if (!stampRewards.length) {
      //   stampRewards = this.gamificationData.stamp_rewards || []
      // }
      // return stampRewards
      return this.$store.getters['gamification/stampRewards'](this.gamificationCampaignId) || []
    }
  },
  watch: {
    'gamificationData': function (newVal, oldVal) {
      this.removeCookie('__logintask')
      if (newVal.id && !oldVal.id) {
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'game|prize list', { gamificationId: newVal.id })

        if (this.participateDirectId) {
          this.startJourney()
        } else if (!this.participateDirectId && !this.gamificationData.gamification_campaign_participation_record_id) {
          this.startJourney(true)
        }
      }
    },
    'prizeList': function (newVal, oldVal) {
      const gotoReward = this.$route.hash
      console.log('@@147: ', gotoReward)
      if (gotoReward && newVal?.length && !this.isBackNavigation) {
        setTimeout(() => {
          const rewardIndex = parseInt(gotoReward.replace('#reward-', ''))
          console.log('@@150: ', rewardIndex, rewardIndex === 0)
          if (rewardIndex === 0) {
            // this.$refs.prizeListTarget?.scrollIntoView({ behavior: 'smooth' })
            this.scrollToPosition(this.$refs.prizeListTarget)
          } else if (rewardIndex > 0) {
            const index = this.prizeList.findIndex(v => v.index === rewardIndex)
            // this.$refs.prizeItemTarget?.[index]?.scrollIntoView({ behavior: 'smooth' })
            console.log('@@150: ', this.$refs.prizeItemTarget?.[index])
            this.scrollToPosition(this.$refs.prizeItemTarget?.[index])
          }
        }, 50)
      }
    }
  },
  async mounted () {
    this.participateDirectId = this.getCookie('__logintask')

    this.$store.commit('app/setNavType', 'back_language_menu')

    const { gamificationCampaignId } = this.$route.query
    this.gamificationCampaignId = gamificationCampaignId

    this.getRewardsData()

    if (!this.gamificationData.id) {
      this.getDetail()
    } else {
      this.isLoading = false
    }

    try {
      this.$store.dispatch('app/refreshSystemSettings')
    } catch (error) {
      console.log('error--', error)
    }
    this.setCaptcha()
  },
  methods: {
    async getRewardsData () {
      try {
        const params = {
          'page[size]': 300,
          gamification_campaign: this.gamificationCampaignId,
          gamification_campaign_participation_record: this.gamificationData?.gamification_campaign_participation_record_id || ''
        }
        const queryString = new URLSearchParams(params).toString()
        const { data } = await axios.get(`/gamification_rewards?${queryString}`)
        this.$store.commit('gamification/updateGamificationStampRewards', {
          id: this.gamificationCampaignId,
          data
        })
        setTimeout(() => {
          this.handleStampsMounted()
        }, 500)
      } catch (error) {
        console.log('error', error)
      }
    },
    async getDetail () {
      if (this.gamificationCampaignId) {
        await asyncGetGamificationCampaignDetail(this.gamificationCampaignId)
        setTimeout(() => {
          this.isLoading = false
          this.handleStampsMounted()
        }, 500)
      }
    },
    handleStampsMounted () {
      if (this.$refs.stampsTarget) {
        this.prizeList = this.$refs.stampsTarget?.getRewardList()
        const showPrizeOutTips = {}
        let outTipIndex = 0
        this.prizeList.forEach((prizeItem, index) => {
          if (prizeItem.isOutOfStock && prizeItem.isCollected) {
            outTipIndex += 1
            showPrizeOutTips[prizeItem.index] = {
              show: true,
              customTooltipSlotElement: { queryName: '.out-tips img', index: outTipIndex - 1 },
              customTooltipReferenceElement: { queryName: '.out-of-stock-info', index: outTipIndex - 1 },
              index: outTipIndex - 1
            }
            // console.log('???', window.$('.out-tips img')[outTipIndex - 1], window.$('.out-tips')[outTipIndex - 1])
          }
        })
        this.showPrizeOutTips = showPrizeOutTips
      } else {
        this.prizeList = []
        this.showPrizeOutTips = {}
      }
    },
    gotoTutorial () {
      pushRouterWithQuery(this.$router, { path: 'tutorials', query: { gamificationCampaignId: this.gamificationCampaignId }})
    },
    gotoStampPrizeList (item) {
      if (item.index) {
        const index = this.prizeList.findIndex(v => v.index === item.index)
        // this.$refs.prizeItemTarget[index].scrollIntoView({ behavior: 'smooth' }
        this.scrollToPosition(this.$refs.prizeItemTarget?.[index])
      }
    },
    async startJourney (isCheck = false) {
      // this.setCaptcha()
      // const {
      //   recaptchaString,
      //   errorData
      // } = await this.getRecaptchaString(this.isVerifyRecaptcha, true)
      // if (errorData.errorContent) {
      //   this.displayDialogErrorMessage = errorData.errorContent
      //   this.showErrorDialog = true
      //   return
      // }

      const { sign, t } = this.$route.query
      const postData = {
        gamification_campaign: this.gamificationCampaignId || null,
        sign: sign,
        tracking_code: t,
        // recaptcha_verify_credential: recaptchaString,
        participatingItem: this.gamificationCampaignId
      }

      if (isCheck) {
        postData.check_link = true
      }

      this.redeemGamification(postData, (created) => {
        console.log('@@221: ', created)
        this.participateDirectId = null
        this.canStartJourney = true
      }, (error) => {
        this.participateDirectId = null
        this.displayDialogErrorMessage = this.handleRedeemGamificationError(error)
        this.errorNext = ''
        if (!isCheck && this.displayDialogErrorMessage) {
          if (error.code === 'error_grant_gamification_campaign_invalid_country_code') {
            this.errorNext = 'my-gamifications'
          } else if (error.display_error_content !== this.displayDialogErrorMessage) {
            this.errorNext = 'home'
          }
          this.showErrorDialog = true
        }

        if (this.displayDialogErrorMessage) {
          this.canStartJourney = false
        } else {
          this.canStartJourney = true
        }
        console.log('@@221: ', this.canStartJourney)
      })
    },
    closeErrorDialog () {
      if (this.errorNext) {
        pushRouterWithQuery(this.$router, { path: this.errorNext })
      }
      this.showErrorDialog = false
    },
    rewardUnlockedText (item) {
      if (item.reward_type === 'GOODIE_BAG' && item.goodie_bag?.reward_type !== 'FORTUNE_BAG') {
        return this.$t('gamificationStampSectionPrizeItemUnlockedGoodiebagDesc')
      }
      return this.$t('gamificationStampSectionPrizeItemUnlockedCouponDesc')
    },
    closePrizeOutTips (itemIndex) {
      this.showPrizeOutTips[itemIndex] = {
        show: false
      }
    },
    viewPrizeDetail (item) {
      console.log('@294 item', item)
      // replaceRouterWithQuery(this.$router, { name: 'stamps-prize', query: this.$route.query, hash: '' })
      if (item.reward_type === 'GOODIE_BAG') {
        if (item.isCollected && !item.isOutOfStock) {
          if (item.goodie_bag?.reward_type === 'FORTUNE_BAG') {
            const couponIds = item.coupon_ids || []
            const couponList = this.$store.getters['coupons/couponList']().filter((coupon) => couponIds.includes(coupon.id))
            const hash = couponList.length ? `coupon-${couponList[0].id}` : ''
            pushRouterWithQuery(this.$router, { path: 'my-wallet-all', hash })
          } else {
            const recordId = item.goodie_bag_participation_record_ids?.length ? item.goodie_bag_participation_record_ids[0] : null
            const hash = recordId ? `goodiebag-${recordId}` : ''
            pushRouterWithQuery(this.$router, { path: 'my-goodie-bags', hash })
          }
        } else {
          if (item.goodie_bag?.reward_type === 'FORTUNE_BAG') {
            const campaignSlot = item.goodie_bag.campaign_slots[0] || {}
            pushRouterWithQuery(this.$router, { path: 'prize-fortune-bag-campaign-list', query: { gamificationCampaignId: this.gamificationCampaignId, stampRewardId: item.id, recordId: item.goodie_bag?.id, goodieBagId: item.goodie_bag?.id, type: 'FORTUNE_CAMPAIGN_FOR_GOODIE_BAG', slotId: campaignSlot.id, from: 'stamps-prize' }})
          } else {
            pushRouterWithQuery(this.$router, { path: 'prize-goodie-bag', query: { gamificationCampaignId: this.gamificationCampaignId, goodieBagId: item.goodie_bag?.id, stampRewardId: item.id, from: 'stamps-prize' }})
          }
          // if (item.goodie_bag.campaign_slots.length > 1) {
          //   pushRouterWithQuery(this.$router, { path: 'prize-goodie-bag', query: { gamificationCampaignId: this.gamificationCampaignId, goodieBagId: item.goodie_bag.id, stampRewardId: item.id, from: 'stamps-prize' }})
          // } else {
          //   const campaignSlot = item.goodie_bag.campaign_slots[0] || {}
          //   pushRouterWithQuery(this.$router, { path: 'prize-goodie-bag-campaign-list', query: { gamificationCampaignId: this.gamificationCampaignId, recordId: item.goodie_bag.id, goodieBagId: item.goodie_bag.id, type: campaignSlot.reward_type === 'CASH_VOUCHER' ? 'CASH_VOUCHER_CAMPAIGN_FOR_GOODIE_BAG' : 'GIFT_CAMPAIGN_FOR_GOODIE_BAG', slotId: campaignSlot.id, stampRewardId: item.id, from: 'stamps-prize' }})
          // }
        }
      } else {
        if (item.isCollected && !item.isOutOfStock) {
          const couponId = item.coupon_ids?.length ? item.coupon_ids[0] : null
          if (couponId) {
            pushRouterWithQuery(this.$router, { path: 'coupon-detail', query: { couponId, from: 'stamps-prize' }})
          } else {
            pushRouterWithQuery(this.$router, { path: 'prize-coupon-detail', query: { couponId: item.coupon_template.id, gamificationId: this.gamificationCampaignId, stampRewardId: item.id, from: 'stamps-prize' }})
          }
        } else {
          pushRouterWithQuery(this.$router, { path: 'prize-coupon-detail', query: { couponId: item.coupon_template.id, gamificationId: this.gamificationCampaignId, stampRewardId: item.id, from: 'stamps-prize' }})
        }
      }
    },
    viewReward (item) {
      this.viewPrizeDetail(item)
      // console.log('@@221: ', item)
      // if (!item.isCollected) {
      //   return
      // }
      // if (item.reward_type === 'GOODIE_BAG') {
      //   const recordId = item.goodie_bag_participation_record_ids?.length ? item.goodie_bag_participation_record_ids[0] : null
      //   const hash = recordId ? `goodiebag-${recordId}` : ''
      //   pushRouterWithQuery(this.$router, { path: 'my-goodie-bags', hash })
      // } else {
      //   const couponId = item.coupon_ids?.length ? item.coupon_ids[0] : null
      //   if (couponId) {
      //     pushRouterWithQuery(this.$router, { path: 'coupon-detail', query: { couponId, from: 'stamps-prize' }})
      //   } else {
      //     pushRouterWithQuery(this.$router, { path: 'prize-coupon-detail', query: { couponId: item.coupon_template.id, from: 'stamps-prize' }})
      //   }
      // }
    },
    gotoEarn () {
      replaceRouterWithQuery(this.$router, { name: 'gamification-detail', query: this.$route.query, hash: 'earn-reward' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/common.scss';
.stamp-prize-view {
  padding: 16px;

  .stamps-title-info {
    label {
        margin-right: 4px;
    }
    .question-mark-icon {
      cursor: pointer;
      position: relative;
      z-index: 3;
    }
  }

  .prize-list-view {
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    .title {
      margin-bottom: 12px;
    }
    .title-description {
      font-size: 12px;
      font-weight: 600;
      color: var(--temple-st-dusk-maroon-50, #9D8095);
      margin-bottom: 24px;
    }

    .prize-item {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .process {
        display: flex;
        flex-direction: column;
        .process-cricle {
          // box-shadow: 0px 0px 16px 0px #00000024;
        //   box-shadow: 0px 0px 4px 0px #0000000F;
        box-shadow: 0px 0px 16px 0px #00000024, 0px 0px 4px 0px #0000000F;
          border-radius: 50%;
          width: 52px;
          height: 52px;
          padding: 11px;
          position: relative;

          img {
            width: 30px;
          }
          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: 800;
            font-size: 16px;
            color: #FFF;
          }
        }

        .process-line {
          &.collected {
            background: var(--Junk-Boat-Red);
          }
          background: var(--temple-st-dusk-maroon-50, #9D8095);
          width: 4px;
          height: 100%;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .reward-detail {
        border-radius: 10px;
        // box-shadow: 0px 0px 16px 0px #00000024;
        box-shadow: 0px 0px 16px 0px #00000024, 0px 0px 4px 0px #0000000F;
        margin-bottom: 40px;
        max-width: calc(100% - 68px);
        img {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          object-fit: cover;
        }

        .img-container {
          position: relative;
        }
        .out {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 4px 8px;
          border-radius: 4px;
          background: #05218A;
          z-index: 1;

          // font-family: Manrope;
          font-size: 12px;
          font-weight: 700;
          line-height: 14.4px;
          color: #FFFFFF;
        }
        .out-tips {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -8px;
          margin-top: 0px;

          label {
            // font-family: Manrope;
            font-size: 12px;
            font-weight: 600;
            line-height: 14.4px;
            color: #FF1700;

            margin-right: 8px;
          }
          .info-mark-icon {
            width: 20px;
            height: 20px;
          }
          img {
            border-radius: unset;
            object-fit: unset;
            max-width: unset;
          }
        }

        .reward-info {
          margin: 16px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          .reward-tags {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            .tag {
              font-weight: 700;
              font-size: 12px;
              padding: 4px 8px;
              border-radius: 4px;
              border: 1px solid var(--Junk-Boat-Red, #FF1700);
              background: #FFF;
              color: var(--Junk-Boat-Red, #FF1700);;

              width: fit-content;
              height: fit-content;
              line-height: 1.2;
            //   max-width: calc(100% - 32px - 52px);
              overflow: hidden;
              display: inline-block;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
        }

          .prize-name {
            font-size: 18px;
            font-weight: 800;
            color: var(--Temple-St-Dusk-Maroon, #3B002B);
          }
          .prize-description {
            font-size: 14px;
            font-weight: 400;
            color: var(--Temple-St-Dusk-Maroon, #3B002B) !important;
          }

          button {
            margin-top: 8px;
            width: 100%;
            justify-content: center;
            &.not-collected {
              border: 2px solid var(--temple-st-dusk-maroon-50, #9D8095);
              img {
                width: 20px;
                margin-right: 4px;
              }
              label {
                color: var(--temple-st-dusk-maroon-50, #9D8095);
              }
            }
            // label {
            //   font-size: 14px;
            // font-weight: 400;
            // }
          }

          .tips {
            text-align: center;
            font-size: 12px;
            font-weight: 600;
            color: var(--temple-st-dusk-maroon-50, #9D8095);
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
          }
        }
      }
    }
    .prize-item.is-dimmed {
      .img-container img,
      .reward-info .reward-tags,
      .reward-info .prize-name,
      .reward-info .prize-description,
      .reward-info .common-button,
      .reward-info .tips {
        opacity: 0.5;
      }
    }
  }

  .go-to-earn {
    // height: 178px;
    padding: 32px;
    background: url('/img/atemp/earn.png');
    background-position: center;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    label {
      font-size: 38px;
      font-weight: 700;
      color: #FFF;
      // max-width: 70%;
      margin-bottom: 0px;
    }
    button {
      font-size: 16px;
      font-weight: 600;
      margin-left: unset;
    }
  }
}

.is-vue-desktop .stamp-prize-view {
  padding: 32px;

  .stamps-bg {
    background: #f4f4f4;

    // .stamp-card {
    //   background: greenyellow;
    //   .stamps {
    //     background: rgb(135, 31, 205);
    //     div.stamps-main {
    //       gap: 28.5px;

    //       div.reward-icon {
    //         img {
    //           width: 48px;
    //         }
    //       }
    //       div {
    //         &.next1line-margin {
    //           margin-top: 16px;
    //         }
    //         width: calc((100% - 28.5px * 4)/5);
    //         img {
    //           width: 36px;
    //         }
    //       }
    //     }
    //   }
    // }
  }

  .prize-list-view {
    .title-description {
      font-size: 14px;
    }
    .prize-item {
      padding: 0px 96px;
      // .process {
      //   .process-line {
      //     height: -webkit-fill-available;
      //   }
      // }
      * {
        cursor: pointer;
      }
      .reward-detail {
        display: flex;
        flex-direction: row;
        padding: 16px;

        img.cover {
          height: 188px;
          aspect-ratio: 167 / 94;
          border-radius: 10px;
        }

        .reward-info {
          margin: unset;
          margin-left: 16px;
          max-width: calc(100% - 32px - 334px);
          width: 344px;
          .reward-tags {
            .tag {
              font-size: 14px;
            }
          }

          .prize-name {
            font-size: 20px;
          }
          .prize-description {
            font-size: 16px;
          }

          button {
            font-size: 18px;
            margin-top: auto;
          }

          .tips {
            font-size: 14px;
          }
        }
      }
    }
  }

  .go-to-earn {
    margin: 0px 96px 60px;
    // height: 178px;
    label {
      font-size: 48px;
    }
    button {
      font-size: 18px;
    }
  }
}

.is-vue-mobile .stamp-prize-view {
  .prize-list-view {
    .prize-item {
      .reward-detail img.cover{
        aspect-ratio: 275 / 154.31;
      }
    }
  }
}
</style>
<style lang="scss">
.stamp-prize-view {
  padding: 16px;

  .stamp-card {
    margin: 16px 0px;

    .stamps {
      .reminder {
        margin-top: 8px;
      }

      div.stamps-main {
        gap: 0px;

        div.reward-icon {
          img {
            width: 48px;
          }
        }
        div {
          padding: 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.next1line-margin {
            // margin-top: 16px;
          }
          width: 20%;
          img {
            width: 36px;
          }
        }
      }
    }

    .cover-redeem {
      top: 51px;
      height: calc(100% - 55px);
    }
  }

  // .custom-tooltip-container {
  //   .tooltip-hover-container {
  //     display: flex;
  //     align-items: center;
  //     .tooltip-icon {
  //       top: -10px;
  //       left: 2px;
  //       transform: rotateX(180deg);
  //     }
  //   }
  // }
  .tooltip-content-container.close {
    background: #000000CC;
    // width: calc(100% + 100px);
    left: unset !important;
    right: -16px !important;
    // top: -98px;
    // right: -16px;
  }
  // .tooltip-content-container.close.zhs, .tooltip-content-container.close.zht {
  //   top: -36px;
  // }
}

.is-vue-desktop .stamp-prize-view {
  .stamp-card {
    width: 460px;
  }

  .tooltip-content-container.close {
    // width: calc(100% + 100px);
    left: -68px;
    // top: -76px;
    // right: -16px;
  }
  // .tooltip-content-container.close.zhs, .tooltip-content-container.close.zht {
  //   top: -36px;
  // }
}

</style>
