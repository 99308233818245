<template>
  <div class="stamp-card">
    <div class="header">
      {{ $t('gamificationStampCardTitle') }}
      <div v-if="showSeeMore && startedJourney" class="see-more-button" @click="gotoStampPrizeList()">
        {{ $t('gamificationStampSectionViewCard') }}
        <img src="/img/right-arrow-purple.png">
      </div>
    </div>
    <!-- <img class="header-icon" src="/img/stampImage.png"> -->
    <div class="stamps">
      <div v-show="showToturial" class="stamps-title-info">
        <!-- <div class="progress-earned" v-html="$t('gamificationEarnedDesc', {earned: collectedStampRewardsCount, total: stampCount})"> -->
        <div class="progress-earned">
          {{ $t('gamificationEarnedDesc') }}
          <label class="earned-num">{{collectedStampRewardsCount}}/{{stampCount}}</label>
        </div>
        <!-- <img
            width="20"
            height="20"
            class="question-mark-icon"
            src="/img/question-icon.png"
            @click="gotoTutorial"
          > -->
      </div>

      <label v-if="startedJourney" class="reminder">{{ $t('gamificationStampSectionDesc') }}</label>

      <div class="stamps-main" :class="{ 'guest-default-height': !startedJourney && stampRewards.length < 6 }">
        <div v-for="(item, index) in stampRewards" v-bind:key="index" :class="{ 'reward-icon': item.isReward, 'first-line': index < 5, 'first-line-last': index === 4 || (index < 5 && index == stampRewards.length -1) , 'last-one': (index === stampRewards.length - 1) || ((index + 1) % 5 === 0 && stampRewards.length - index - 1 < 5) }"  @click="gotoStampPrizeList(item)">
          <img v-if="!isJsonImage(item.imgSource)" :src="item.imgSource"/>
          <lottie-animation
              v-if="isJsonImage(item.imgSource)"
              :path="isJsonImage(item.imgSource)"
              :loop="false"
          />
          <label>{{index+1}}</label>
          <span v-if="item.isOutOfStock" class="out">{{ $t('gamificationStampSectionOutOfStockLabel') }}</span>
        </div>
      </div>
      <button v-if="startedJourney && gamificaitonIsValid && showPrizeButton" class="common-button view-prize red-bg-button" @click.prevent="gotoStampPrizeList({ index: 0 })">{{ $t('gamificationStampSectionViewPrizeBtn') }}</button>
    </div>

    <div v-if="!startedJourney || !gamificaitonIsValid" class="cover-redeem">
      <!-- <button class="common-button red-bg-button" @click.prevent="redeemGamification">{{ $t('gamificationStampSectionStartBtn') }}</button>
      <button class="common-button view-prize" @click.prevent="gotoStampPrizeList">{{ $t('gamificationStampSectionViewPrizeBtn') }}</button> -->
    </div>
    <div v-if="!startedJourney || !gamificaitonIsValid" :class="['cover-redeem', 'guest-button']">
      <!-- <button v-if="!startedJourney" class="common-button red-bg-button-height" :class="{'guest-button-horizontal': stampRewards.length < 6}" @click.prevent="startJourney">{{ startButtonText }}</button>
      <button v-if="!startedJourney && showPrizeButton" class="common-button view-prize"  :class="{'guest-button-horizontal': stampRewards.length < 6}" @click.prevent="gotoStampPrizeList({ index: 0 })">{{ $t('gamificationStampSectionViewPrizeBtn') }}</button>
       -->
      <button v-if="!startedJourney || !gamificaitonIsValid" class="common-button red-bg-button-height" :class="{'disabled': disabled || !gamificaitonIsValid}" :disabled="disabled || !gamificaitonIsValid" @click.prevent="startJourney">{{ startButtonText }}</button>
      <button v-if="((!startedJourney && gamificaitonIsValid) || (startedJourney && !gamificaitonIsValid)) && showPrizeButton" class="common-button view-prize" @click.prevent="gotoStampPrizeList({ index: 0 })">{{ $t('gamificationStampSectionViewPrizeBtn') }}</button>
    </div>
  </div>
</template>
<script>
import utilsMixin from '@/mixins/utilsMixin'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import { pushRouterWithQuery } from '@/utils/utils'

export default {
  name: 'StampList',
  components: {
    LottieAnimation
  },
  mixins: [utilsMixin],
  props: {
    // gamificationCampaignId: {
    //   type: String,
    //   default: null
    // },
    // stampRewards: {
    //   type: Array,
    //   default: null
    // },
    // collectedStampRewardsCount: {
    //   type: [Number, String],
    //   default: null
    // },
    // stampCount: {
    //   type: [Number, String],
    //   default: null
    // },
    showAll: {
      type: Boolean,
      default: false
    },
    gamificationData: {
      type: Object,
      default: null
    },
    forceUseStampRewardsData: {
      type: Boolean,
      default: false
    },
    stampRewardsData: {
      type: Array,
      default: () => []
    },
    showToturial: {
      type: Boolean,
      default: false
    },
    hasPrizeButton: {
      type: Boolean,
      default: true
    },
    showSeeMore: {
      type: Boolean,
      default: false
    },
    startedJourney: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    stampCount () {
      return this.gamificationData?.stamp_count || 0
    },
    collectedStampRewardsCount () {
      return this.gamificationData?.collected_stamp_rewards_count || 0
    },
    stampRewards () {
      if (!this.gamificationData?.id) {
        return []
      }

      // let stampRewards = this.$store.getters['gamification/stampRewards'](this.gamificationCampaignId) || []
      let stampRewards = this.stampRewardsData || []
      if (!stampRewards.length && !this.forceUseStampRewardsData) {
        stampRewards = this.gamificationData.stamp_rewards || []
      }

      const stamps = Array.apply(null, Array(this.gamificationData.stamp_count)).map((item, index) => {
        // console.log('@@@280: ', this.detail.stamp_rewards)
        const rewardIndex = stampRewards?.findIndex((v) => parseInt(v.index) === index + 1)
        const stampReward = stampRewards?.[rewardIndex]
        const isReward = stampReward?.quantity && stampReward?.quantity > 0
        const isCollected = index < Math.max(this.gamificationData.collected_stamp_rewards_count)
        if (isReward) {
          const rewardInfo = {
            ...stampReward,
            imgSource: isCollected ? (stampReward.reward_icon_collected_url || this.gamificationData.reward_icon_collected_url || '/img/reward-collected-icon-default.png') : (stampReward.reward_icon_url || this.gamificationData.reward_icon_url || '/img/reward-icon-default.png'),
            isCollected,
            isReward: true,
            isRewardCollected: stampReward.collected_reward,
            // isOutOfStock: !stampReward.collected_reward && (stampReward.coupon_template?.stock < stampReward.quantity || stampReward.goodie_bag?.stock < stampReward.quantity),
            isOutOfStock: !stampReward.collected_stamp && (stampReward.coupon_template?.stock < stampReward.quantity || stampReward.goodie_bag?.stock < stampReward.quantity) || (
              stampReward.collected_stamp && !stampReward.collected_reward
            ),
            prizeImageUrl: this.gamificationData.cover_photo_url,
            prizeTags: (stampReward.prize_categories || []).filter(v => v && v.trim().length > 0)
          }

          if (stampReward.reward_type === 'GOODIE_BAG') {
            rewardInfo.prizeTitle = stampReward.prize_title || stampReward.goodie_bag.name
            rewardInfo.prizeDescription = stampReward.prize_content || stampReward.goodie_bag.short_description
            rewardInfo.prizeImageUrl = this.isMobile ? stampReward.mobile_photo_for_prize_url || stampReward.goodie_bag.mobile_photo_url : stampReward.cover_photo_for_prize_url || stampReward.goodie_bag.cover_photo_url
          } else {
            rewardInfo.prizeTitle = stampReward.prize_title || stampReward.coupon_template.name
            rewardInfo.prizeDescription = stampReward.prize_content || stampReward.coupon_template.short_description
            rewardInfo.prizeImageUrl = (this.isMobile ? stampReward.mobile_photo_for_prize_url : stampReward.cover_photo_for_prize_url) || stampReward.coupon_template.cover_photo_url
          }

          return rewardInfo
        }

        return {
          imgSource: isCollected ? (this.gamificationData.stamp_icon_collected_url || '/img/stamp-collected-icon-default.png') : (this.gamificationData.stamp_icon_url || '/img/stamp-icon-default.png'),
          isCollected,
          isReward: false
        }
      })
      console.log('@@326: ', stamps)
      if (!this.showAll) {
        return stamps.slice(0, 10)
      }
      return stamps
    },
    startButtonText () {
      const now = new Date()
      console.log('@@158: ', new Date(this.gamificationData.start_date), now)

      if (new Date(this.gamificationData.start_date) > now) {
        return this.$t('gamificationStampsAvailableOn', { startDate: this.getDateString(this.gamificationData.start_date, 'D MMM YYYY HH:mm') })
      }
      console.log('@@158: ', new Date(this.gamificationData.end_date), this.gamificationData.end_date && new Date(this.gamificationData.end_date) < now)
      console.log('@@158: ', this.gamificationData)
      if (this.gamificationData.end_date && new Date(this.gamificationData.end_date) < now) {
        // return `Campaign expired`
        return this.$t('gamificationStampSectionExpiredBtn')
      }

      return this.$t('gamificationStampSectionStartBtn')
    },
    gamificaitonIsValid () {
      // return this.startButtonText === this.$t('gamificationStampSectionStartBtn')
      return this.startButtonText !== this.$t('gamificationStampSectionExpiredBtn')
    },
    showPrizeButton () {
      if (!this.hasPrizeButton) {
        return false
      }

      if (this.startButtonText === this.$t('gamificationStampSectionExpiredBtn') && !this.gamificationData.gamification_campaign_participation_record_id) {
        return false
      }

      return true
    }
  },
  mounted () {

  },
  methods: {
    stampMargin (index) {
      return index >= 5
    },
    isJsonImage (url) {
      if (url.indexOf('.json') > 0) {
        return url.replace('https://', '/')
      }

      return null
    },
    showMore () {
      // this.$emit('click')
    },
    gotoTutorial () {
      pushRouterWithQuery(this.$router, { path: 'tutorials', query: { gamificationCampaignId: this.gamificationData?.id || '' }})
    },
    gotoStampPrizeList (item) {
      this.$emit('gotoStampPrizeList', item)
    },
    startJourney () {
      this.$emit('startJourney')
    },
    getRewardList () {
      return this.stampRewards?.filter(item => item.isReward)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/common.scss';

.stamp-card {
  margin: 0px 8px;
  padding: 2px;
  // height: Hug (237px)px;
  border-radius: 10px;
  background: var(--Temple-St-Dusk-Maroon);
  position: relative;
  .header {
    height: 52px;
    font-size: 20px;
    font-weight: 800;
    color: #FFF;

    display: flex;
    align-items: center;
    padding: 0px 12px;

    .see-more-button {
      font-weight: 600;
      font-size: 14px;
      margin-left: auto;
      color: #FFF;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  img.header-icon {
    position: absolute;
    width: 121px;
    top: -35px;
    right: -8px;
    z-index: 2;
  }

  .stamps {
    background: #fff;
    padding: 12px;
    // margin: 2px;
    // width: Fixed (343px)px;
    // height: Hug (196px)px;
    display: flex;
    flex-direction: column;
    // gap: 8px;
    border-radius: 10px;
    // border: 2px solid var(--temple-st-dusk-maroon-80);

    label {
      font-weight: 700;
      color: var(--temple-st-dusk-maroon-50)
    }
    .question-mark-icon {
      cursor: pointer;
    }

    .stamps-title-info {
      display: flex;
      padding: 8px 0px;
      // margin-bottom: 8px;
      align-items: center;

      div.progress-earned {
        color: var(--Temple-St-Dusk-Maroon, #3B002B);
        font-weight: 800;
        font-size: 16px;
        margin-right: 4px;

        label {
          font-weight: 800;
          font-size: 22px;
          color: var(--Temple-St-Dusk-Maroon, #3B002B);
        }
      }
    }

    label.reminder {
      font-size: 12px;
      font-weight: 600;
      color: var(--temple-st-dusk-maroon-50, #9D8095);
      margin-bottom: 8px;
      line-height: 1;
    }

    div.stamps-main {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // padding: 0px 0px 8px;
      margin-bottom: 8px;

      // border-top: 1px solid var(--temple-st-dusk-maroon-80, #D8CCD5);
      border-left: 1px solid var(--temple-st-dusk-maroon-80, #D8CCD5);
      border-radius: 5px;
      overflow: hidden;

      &.guest-default-height {
        min-height: 120px;
      }

      // gap: 9.75px;

      div.reward-icon {
        cursor: pointer;
        img {
          width: 38px;
          // padding: 14px 0px;
        }

        * {
          cursor: pointer;
        }
      }

      div {
        &.next1line-margin {
          // margin-top: 8px;
          // margin-top: 16px;
        }

        &.first-line {
          border-top: 1px solid var(--temple-st-dusk-maroon-80, #D8CCD5);
        }

        &.first-line-last {
          border-top-right-radius: 5px;
        }

        &.last-one {
          border-bottom-right-radius: 5px;
        }
        // width: calc((100% - 9.75px * 4)/5);
        // padding: 7px;
        width: 20%;
        aspect-ratio: 1 / 1;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        // cursor: pointer;

        border-bottom: 1px solid var(--temple-st-dusk-maroon-80, #D8CCD5);
        border-right: 1px solid var(--temple-st-dusk-maroon-80, #D8CCD5);

        label {
          // cursor: pointer;
          // position: absolute;

          position:absolute;
          top: 3px;
          right: 3px;
          // top: 50%;
          // left: 50%;
          // transform: translate(-50%, -50%);
          // width: 100%;

          font-size: 12px;
          font-weight: 700;
          text-align: center;
          // color: #FFF;
        }

        img {
          object-fit: contain;
          width: 36px;
          // padding: 15px 0px;
        }

        .out {
          position: absolute;
          font-size: 9px;
          // white-space: nowrap;
          background-color: var(--temple-st-dusk-maroon-50);
          background-color: #05218A;
          color: #FFF;
          // left: -9px;
          // top: 40%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 48px;
          text-align: center;
          border-radius: 5px;
          padding: 4px 6px;
        }
      }

    }
  }

  // .guest-height {
  //   height: 100px;
  //   overflow: hidden;
  // }

  button.view-prize {
    width: 100%;
    height: 36px;

    margin-top: 8px;
    justify-content: center;
  }

  .cover-redeem {
    background: #FFFFFF;
    position: absolute;
    top: 101px;
    height: calc(100% - 105px);
    width: -webkit-fill-available;
    margin: 2px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    opacity: 0.9;
    gap: 12px;
  }

  .guest-button {
    background: rgba(255,255,255,0.1);

    button {
      &.disabled {
        opacity: 0.5;
      }
      background: var(--Junk-Boat-Red);
      color: #FFF;
      margin-right: auto;
      min-width: 195px;
      height: 36px;
      justify-content: center;
    }

    button.view-prize {
      height: 36px;
      min-width: 195px;
      width: fit-content;
      background: #FFF;
      color: var(--Junk-Boat-Red);
      margin-top: unset;
    }
  }

  // .guest-button-horizontal {
  //   top: 89px;
  //   &.view-prize {
  //     top: 130px;
  //   }
  // }
}

.is-vue-mobile {
  .stamp-card .stamps {
    div {
      .out {
        // font-size: 9px !important;
        // left: -2px !important;
        font-size: 8px !important;
        // top: 34% !important;
        // left: -2px !important;
      }
    }
  }
}

.is-vue-desktop {
  .stamp-card {
    // min-width: 40%;
    // width: 40%;
    min-width: calc((100% - 16px) / 3);
    width: calc((100% - 16px) / 3);
    margin-left: auto;
    margin-right: auto;
    height: fit-content;

    .header {
      font-size: 22px;
      font-weight: 800;

      .see-more-button {
        font-size: 16px;
      }
    }

    .stamps {
      .stamps-title-info {
        div.progress-earned {
          font-size: 18px;

          label {
            font-size: 24px;
            color: var(--Temple-St-Dusk-Maroon, #3B002B);
          }
        }
      }

      .reminder {
        font-size: 14px;
      }

      div.stamps-main {
        div.reward-icon {
          cursor: pointer;
          // img {
          //   padding: 16px 0px;
          // }
        }

        div {
          // img {
          //   img {
          //     padding: 15px 0px;
          //   }
          // }
        }
      }
    }
  }

  .guest-button {
    justify-content: center;
    // width: 214px;
    // left: calc((100% - 214px) / 2);

    button {
      font-size: 16px !important;
    }

    &.view-prize {
      width: 214px;
    }
  }
  // .guest-height {
  //   height: 180px;
  // }
}

.more-stamps_card .stamp-card .stamps {
  // background: #fff;
  padding: unset;
  // // width: Fixed (343px)px;
  // // height: Hug (196px)px;

  // gap: 8px;
  border-radius: unset;
  border: none;

  label {
    font-weight: 800;
    font-size: 20px;
    color: var(--Temple-St-Dusk-Maroon)
  }

  label.tips {
    font-size: 14px;
    font-weight: 500;

    color: var(--temple-st-dusk-maroon-50)
  }

  div.stamps-main {
    max-height: 350px;
    overflow-y: auto;
  }
}

</style>
