<template>
  <div :class="documentClass()" :style="'margin-bottom:'+oneTrustHeight+'px'">
    <a id="top" />
    <div v-if="$store.getters['app/getIsSupportBrowser']" class="columns is-paddingless is-marginless navbar">
      <div class="is-paddingless is-marginless main-container">
        <navbar />
      </div>
    </div>
    <home-menu />
    <goodie-bag-image />
    <goodie-bag-search-bar-background />
    <div class="columns is-paddingless is-marginless main-row">
      <div class="column is-hidden-mobile" />
      <div :class="['main-container', customMainContainerClass]">
        <router-view :class="['container-height', {'no-min-height': $store.getters['app/isShowGoodieBagImage']}]" />

        <manage-cookie-button
          v-show="$store.getters['app/getNeedShowCookieButton']"
        />

        <div v-if="showEarnButton" class="float-buttons">
          <button class="earn-button" @click="scrollTo('earn')">
            <img src="/img/earnIcon.png">
            <label>{{ $t('gamificationEarn') }}</label>
          </button>
        </div>

        <back-top
          v-show="$store.getters['app/getNeedShowBackToTop']"
          class="back-to-top-bottom"
          :visibility-height="300"
          :bottom="100"
          :class="{'back_top_home': $store.getters['app/getNavIsHomePage']}"
        >
          <div class="arrow-placeholder" />
          <img
            class="arrow-inactive"
            srcset="/img/back_top.1x.png, /img/back_top.2x.png 2x, /img/back_top.3x.png 3x"
            src="/img/back_top.2x.png"
          >
          <img
            class="arrow-active"
            srcset="/img/back_top_active.1x.png, /img/back_top_active.2x.png 2x, /img/back_top_active.3x.png 3x"
            src="/img/back_top_active.2x.png"
          >
        </back-top>

      </div>
      <div class="column is-hidden-mobile" />
    </div>
    <div v-show="$store.getters['app/isShowFooter'] && isShowFooter" class="columns is-paddingless is-marginless footer">
      <div class="column is-hidden-mobile" />
      <div class="footer-container">
        <footer-bar />
      </div>
      <div class="column is-hidden-mobile" />
    </div>
    <!--<tutorial />-->
    <!--
    <img v-for="img in imgs" :key="img" style="display:none" :src="img" rel="preload">
    -->
  </div>
</template>

<script>
import {
  Navbar,
  FooterBar
} from '@/components/layout/'
import GoodieBagImage from '@/components/GoodieBagImage'
import HomeMenu from '@/components/HomeMenu'
import BackTop from '@/components/BackTop'
import ManageCookieButton from '@/components/ManageCookieButton'
// import Tutorial from '@/components/Tutorial'
import { sendClickAnalytics, sendPageViewAnalytics } from '@/utils/tracking'
import utilsMixin from '@/mixins/utilsMixin'
import documentMixin from '@/mixins/documentMixin'
import { isWechatMiniProgram } from '@/utils/utils'
import GoodieBagSearchBarBackground from '../GoodieBagSearchBarBackground.vue'
// import Cookies from 'js-cookie'
export default {
  name: 'Document',
  components: {
    Navbar,
    FooterBar,
    GoodieBagImage,
    // Tutorial,
    HomeMenu,
    BackTop,
    ManageCookieButton,
    GoodieBagSearchBarBackground

  },
  mixins: [utilsMixin, documentMixin],
  data () {
    return {
      imgs: [
        '/img/back_top.png',
        '/img/back.png',
        '/img/carousel_left.png',
        '/img/carousel_right.png',
        '/img/chrome.png',
        '/img/close.png',
        '/img/coupon-info.png',
        '/img/email.png',
        '/img/error-1.png',
        '/img/firefox.png',
        '/img/home.x1.png',
        '/img/home.x2.png',
        '/img/home.x3.png',
        '/img/house.png',
        '/img/edge.png',
        '/img/info.png',
        '/img/invisible.png',
        '/img/language.png',
        '/img/loading.gif',
        '/img/location.png',
        '/img/menu.png',
        '/img/message_icon_success.png',
        '/img/my_account.png',
        '/img/my_wallet.1x.png',
        '/img/my_wallet.2x.png',
        '/img/my_wallet.3x.png',
        '/img/nav_logo.png',
        '/img/page_not_find_img.png',
        '/img/phone.png',
        '/img/qrcode_success.png',
        '/img/red_small_right.png',
        '/img/safari.png',
        '/img/scan_close.png',
        '/img/share.png',
        '/img/three.png',
        '/img/time.png',
        '/img/trans1px.png',
        '/img/visible.png'
      ]
    }
  },
  computed: {
    navbarType () {
      console.log(this.$store.getters['app/getNavType'])
      return this.$store.getters['app/getNavType'] || 'logo_language_menu'
    },
    isShowFooter () {
      // always hide footer bar for mini program
      if (this.$router.history.current.path.includes('verify-email-result')) {
        return false
      }
      return true
    },
    customMainContainerClass () {
      if (this.$route.name === 'mission-detail') {
        return 'bg-white'
      }
      return ''
    },
    showEarnButton () {
      if (this.$route.name === 'gamification-detail') {
        const detail = this.$store.getters['gamification/gamificationDetail'](this.$route.query.gamificationCampaignId)
        if (detail && detail.id) {
          return true
        }
      }
      return false
    }
  },
  created () {
    console.log('@104a, created')
    window.addEventListener('resize', this.resizeHandler)
    this.resizeHandler() // trigger for the first time'
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeHandler)
  },
  beforeMount () {
    // initial page tracking
    const _this = this
    // Cookies.set('lang', this.$i18n.locale)
    // localStorage.removeItem('language')
    if (!['gamification-detail', 'landing-gamification', 'mission-detail', 'prize-coupon-detail', 'mission-location-map'].includes(_this.$route.name)) {
      console.log('@woopra document')
      sendPageViewAnalytics(_this.$route, this.$i18n.locale, _this.$store)
    }
  },
  mounted () {
    window.__DOC_MOUNTED = true
    console.log('@104, binding event')
    const _this = this
    window.$('.document').click(function (event) {
      console.log('@106, clicked', event)
      sendClickAnalytics(_this.$route, _this.$i18n.locale, _this.$store, event)
    })

    window.$('body').on('click', 'a', function (event) {
      const href = window.$(this).attr('href')
      if (href && href.startsWith('http') && !href.startsWith(window.location.origin)) {
        event.preventDefault()
        if (!isWechatMiniProgram()) {
          window.open(href)
        } else {
          _this.$clipboard(href)
          _this.showLinkCopySuccess()
        }
      }
    })
  },
  methods: {
    scrollTo (des) {
      if (des === 'earn') {
        const missionSectionRef = document.getElementsByClassName('mission-section-header')[0]
        this.scrollToPosition(missionSectionRef)
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.document {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.document-feedback{
  background-color: #ffffff;
}
.main-row {
  min-height: 100%;
  display: flex;
  flex: 1;
  position: relative;
    //background-color: lightyellow;
}
.document-home .main-container{
  position: relative;
}
.columns.is-paddingless.is-marginless.navbar{
  position: sticky;
  top: 0;
  z-index: 500; // nav bar
}
.no-scroll .columns.is-paddingless.is-marginless.navbar{
  position: fixed;
  width: 100%;
}

.back_top_home{
  box-shadow: none;
}

.document-applicable-store-detail, .document-applicable-store-list{
  background-color: rgba(255, 255, 255, 0.95) !important;
}

.footer{
  z-index: 1;
  background: #393939;
}

.el-backtop{
  user-select: none;
  .arrow-placeholder {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 100px;
    left: 0;
    background-color: white;
    margin: 5px;
  }
  .arrow-inactive {
    transition: opacity 400ms ease-in-out;
    position: absolute;
    opacity: 1;
  }
  .arrow-active {
    transition: opacity 400ms ease-in-out;
    position: absolute;
    opacity: 0;
  }

  &:hover, &:active {
    .arrow-inactive {
      opacity: 0;
    }
    .arrow-active {
      opacity: 1;
    }
  }
}

.is-vue-desktop .el-backtop, .is-vue-mobile .el-backtop{
  width: 70px;
  height: 70px;
  display: block;
  background-color: unset;
  bottom: 20px !important;
  float: right;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-right: -100px;
  position: sticky;
}
.is-vue-desktop .el-backtop {
  bottom: 90px !important;
}

.is-vue-desktop .mini .el-backtop, .is-vue-mobile .mini .el-backtop{
  bottom: calc(75px + var(--sab)) !important;
}

@media screen and (max-width: 1240px) {
 .is-vue-desktop .el-backtop{
    margin-right: unset;
  }
}

.is-vue-desktop {
  .container-height{
    min-height: 500px;
    &.no-data, &.no-min-height {
      min-height: unset;
    }
  }
  .footer-container {
    width: 100%;
    height: 86px;
    padding-left: 0px;
    padding-right: 30px;
  }

  .main-container {
    width: 1024px;
  }

  .navbar .main-container {
    width: 100%;
  }
}

.is-vue-mobile {
  .container-height{
    min-height: 500px;

    &.no-data, &.no-min-height {
      min-height: unset;
    }
  }

  .el-backtop{
    right: 17px !important;
    margin-right: unset;
  }
  .document-coupon-detail .el-backtop,
  .document-prize-coupon-detail .el-backtop {
    bottom: 140px !important;
    z-index: 99;  // back top
  }
  .mini.document-coupon-detail .el-backtop,
  .mini.document-prize-coupon-detail .el-backtop {
    bottom: calc(200px + var(--sab)) !important;
    z-index: 99;  // back top
  }
  .document-campaign-detail .el-backtop,
  .document-prize-campaign-detail .el-backtop {
    bottom: 90px !important;
    z-index: 99;
  }
  .mini.document-campaign-detail .el-backtop,
  .mini.document-prize-campaign-detail .el-backtop {
    bottom: calc(150px + var(--sab)) !important;
    z-index: 99;
  }
  .footer-container {
    width: 100%;
    padding-bottom: 13px;
    // height: 200px;
  }

  .main-container {
    width: 100%;
  }

  .is-hidden-mobile {
    display: none;
  }

  .document-campaign-detail .footer,
  .document-prize-campaign-detail .footer,
  .document-coupon-detail .footer,
  .document-prize-coupon-detail .footer {
    z-index: 9;  // by walty, otherwise footer link in coupon detail page cannot be clicked
  }
}

.document .column {
  padding: 0;
  margin: 0;
}

.document .columns {
  padding: 0;
  margin: 0;
}

.is-vue-mobile .document-policy .main-row{
  display: flex;
}

.document-policy .el-backtop {
  display: none;
}

.mini .navbar{
  min-height: 0px;
}

// walty temp 20230619
.mini.mini-back .navbar {
  min-height: 48px;
}

/*
// hide navigation bar for mini program
.document-coupon-detail.mini .navbar, .document-campaign-detail.mini .navbar{
  min-height: 72px;
}
*/

.document.mini{
  padding-bottom: calc(66px + var(--sab));
}

.mini .footer{
  display: none;
}

.document-home .main-row .main-container{
  background: #F2F3F6;
}
.main-container.bg-white {
  background: #FFFFFF;
}

.float-buttons {
  position: sticky;
  right: 45px;
  bottom: calc(75px + 10px + 5px + var(--sab)) !important;
  z-index: 6;
  float: right;
  margin-bottom: 12px;
  margin-right: -100px;
  margin-top: -155px !important;

  .earn-button {
    background-color: var(--Temple-St-Dusk-Maroon);
    box-shadow: 0px 1px 4px 0px #00000080;
    color: #FFFFFF;

    border-radius: 50%;
    // padding: 14px 20px;
    display: flex;
    flex-direction: column;
    // text-align: center;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    padding: 0px;

    img {
      width: 20px;
    }

    label {
      font-family: Open Sans;
      font-size: 12px;
      font-weight: 600;
      line-height: 16.34px;
    }
  }
}
.is-vue-mobile .float-buttons {
  right: 22px;
  margin-right: unset;
  margin-top: -85px !important;
}
.is-vue-desktop .mini .float-buttons, .is-vue-mobile .mini .float-buttons{
  bottom: calc(75px + 70px + 5px + var(--sab)) !important;
}

</style>

<style lang='scss'>

.no-scroll #app{
  overflow-y: hidden;
  height: 100px;
}

:root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);

    --Temple-St-Dusk-Maroon: #3B002B;
    --Temple-St-Dusk-Maroon: #3B002B;
    --temple-st-dusk-maroon-50: #9D8095;
    --temple-st-dusk-maroon-80: #D8CCD5;
    --temple-st-dusk-maroon-light-1: #EBE5E9;
    --temple-st-dusk-maroon-light-2: #F9E9ED;
    --gray: #F4F4F4;
    --Junk-Boat-Red: #FF1700;

}

.clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}

.clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}

.back-to-top {
  text-align: right;

  img {
    height: 60px;
    width: 60px;
  }

}

.is-vue-desktop {
  .back-to-top {
    display: none;
  }
}

.el-backtop{
  box-shadow: none !important;
}

.document-home .el-backtop{
  z-index: 1;
}

.is-vue-desktop .el-backtop{
  margin-top: -90px !important;
  height: 0px !important;
}

.document-Preview-test{
  .navbar, .footer{
    display: none !important;
  }
}

</style>
