<template>

  <div class="searchbar-container">
    <img
      v-if="!isMission && ((searchKeyword || showSearchButton) && (isMiniProgram() || placeHolder))"
      class="back-icon"
      srcset="/img/back-gray.1x.png, /img/back-gray.2x.png 2x, /img/back-gray.3x.png 3x"
      src="/img/back-gray.2x.png"
      @click="handleClearAll"
    >
    <div
      class="searchbar"
      :class="{'searchbar-keyword': (searchKeyword || showSearchButton), 'searchbar-empty': (!searchKeyword && !showSearchButton)}"
      :style="showSearchButton ? {paddingRight: 0} : {}"
    >
      <!-- <img
        class="search-icon"
        srcset="/img/search-icon.1x.png, /img/search-icon.2x.png 2x, /img/search-icon.3x.png 3x"
        src="/img/search-icon.2x.png"
      > -->
      <img class="search-icon" src="/img/search-icon.svg">

      <div v-if="isMyWallet" class="searchbar-input">
        <input ref="searchInput" v-model="searchKeyword" :placeholder="$t(placeHolder)" @focus="setMyWalletSeachButtonShow(true)">
        <img
          v-if="searchKeyword"
          class="clear-icon"
          srcset="/img/clear-input-icon.1x.png, /img/clear-input-icon.2x.png 2x, /img/clear-input-icon.3x.png 3x"
          src="/img/clear-input-icon.2x.png"
          @click="handleClear"
        >
        <button v-if="showSearchButton" :style="{ background: searchKeyword ? '#E2002C': 'rgba(241, 173,186)' }" :disabled="!searchKeyword" @click="handleSearchCampaigns(searchKeyword)">{{ $t('searchButton') }}</button>
      </div>

      <span v-else @click.stop="handleSearch">
        {{ searchKeyword ? searchKeyword : placeHolder ? placeHolder : $t('goodiebagSearchPlaceholder', {goodiebagName: goodiebagName}) }}
      </span>
      <img
        v-if="isMission && isMobile && searchKeyword"
        class="clear-icon"
        srcset="/img/clear-input-icon.1x.png, /img/clear-input-icon.2x.png 2x, /img/clear-input-icon.3x.png 3x"
        src="/img/clear-input-icon.2x.png"
        @click="handleClearAll"
      >
    </div>

    <div class="searchbar-selector">
      <div class="search-bar-container">
        <!-- <img
          class="search-icon"
          srcset="/img/search-icon.1x.png, /img/search-icon.2x.png 2x, /img/search-icon.3x.png 3x"
          src="/img/search-icon.2x.png"
        > -->
        <img class="search-icon" src="/img/search-icon.svg">
        <el-autocomplete
          v-model="searchKeyword"
          class="search-autocomplete-inline-input"
          :fetch-suggestions="querySearchAsync"
          :placeholder="placeHolder ? $t(placeHolder) : $t('goodiebagSearchPlaceholder', {goodiebagName: goodiebagName})"
          :trigger-on-focus="false"
          :popper-append-to-body="false"
          @change="handleChange"
          @select="handleSelect"
          @focus="desktopSearchIsFocused = true"
          @blur="desktopSearchIsFocused = false"
        >
          <i
            slot="suffix"
            class="el-input__icon"
            @click="handleClear"
          >
            <img
              v-if="searchKeyword"
              class="clear-icon"
              srcset="/img/clear-input-icon.1x.png, /img/clear-input-icon.2x.png 2x, /img/clear-input-icon.3x.png 3x"
              src="/img/clear-input-icon.2x.png"
            >
          </i>
          <template slot-scope="{ item }">
            <div class="autocomplete-search-result">
              <!-- <img
                class="search-icon"
                srcset="/img/search-icon.1x.png, /img/search-icon.2x.png 2x, /img/search-icon.3x.png 3x"
                src="/img/search-icon.2x.png"
              > -->
              <img class="search-icon" src="/img/search-icon.svg">
              <p v-html="item.format" />
            </div>
          </template>
        </el-autocomplete>
      </div>
      <button :style="{ background: searchKeyword || !desktopSearchIsFocused ? '#E2002C': 'rgba(226, 0, 44, 0.30)' }" :disabled="!searchKeyword && desktopSearchIsFocused" @click="handleSearchCampaigns(searchKeyword)">{{ $t('searchButton') }}</button>

    </div>

    <el-dialog
      :visible.sync="showSearchPage"
      :fullscreen="true"
      :modal="false"
      custom-class="search-dialog"
      :lock-scroll="true"
      :show-close="false"
    >
      <div class="search-bar">
        <img
          class="back-icon"
          srcset="/img/back-gray.1x.png, /img/back-gray.2x.png 2x, /img/back-gray.3x.png 3x"
          src="/img/back-gray.2x.png"
          @click.stop="handleBack"
        >
        <div class="search-bar-input">
          <!-- <img
            class="search-icon"
            srcset="/img/search-icon.1x.png, /img/search-icon.2x.png 2x, /img/search-icon.3x.png 3x"
            src="/img/search-icon.2x.png"
          > -->
          <img class="search-icon" src="/img/search-icon.svg">
          <input ref="search" v-model="suggestionKey">
          <img
            v-if="suggestionKey"
            class="clear-icon"
            srcset="/img/clear-input-icon.1x.png, /img/clear-input-icon.2x.png 2x, /img/clear-input-icon.3x.png 3x"
            src="/img/clear-input-icon.2x.png"
            @click="handleClear"
          >
        </div>
        <button :style="{ background: suggestionKey ? '#E2002C': 'rgba(226, 0, 44, 0.30)' }" :disabled="!suggestionKey" @click="handleSearchCampaigns(searchKeyword)">{{ $t('searchButton') }}</button>
      </div>
      <div class="line" />
      <div class="search-result">
        <div v-for="(item, index) in suggestionKeywords" :key="'search-result-key-' + index" class="search-result-item" @click="handleClickSuggestion(item.origin)">
          <div class="search-result-item-inside">
            <!-- <img
              class="search-icon"
              srcset="/img/search-icon.1x.png, /img/search-icon.2x.png 2x, /img/search-icon.3x.png 3x"
              src="/img/search-icon.2x.png"
            > -->
            <img class="search-icon" src="/img/search-icon.svg">
            <p v-html="item.format" />
          </div>
        </div>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import utilsMixin from '@/mixins/utilsMixin'
import { isWechatMiniProgram } from '@/utils/utils'
import { sendManualAnalytics } from '@/utils/tracking'

export default {
  name: 'CampaignListSearchBar',
  components: {
  },
  mixins: [utilsMixin],
  props: {
    goodiebagName: {
      type: String,
      default: null
    },
    goodiebagSlotId: {
      type: String,
      default: null
    },
    placeHolder: {
      type: String,
      default: null
    },
    needAutoSuggestion: {
      type: Boolean,
      default: true
    },
    for: {
      type: String,
      default: null
    },
    missionId: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      searchKeyword: '',
      suggestionKey: '',
      suggestionKeywords: [],
      showSearchPage: false,
      page: 1,
      isLoadingMore: false,
      hasLoadMore: true,
      timeout: null,
      desktopSearchIsFocused: false,
      scrollTop: 0,
      showSearchButton: false
    }
  },
  computed: {
    isMobile () {
      return this.$store.getters['app/isMobile']
    },
    isMyWallet () {
      return this.for === 'COUPON'
    },
    isMission () {
      console.log('@20666666: ', this.for)
      if (this.for) {
        return this.for.indexOf('MISSION') >= 0
      } else {
        console.log('@20666666--: ')
        return false
      }
    }
  },
  watch: {
    suggestionKey: function (newSearchKeyword, oldSearchKeyword) {
      console.log('back search key-searchKeyword: ', newSearchKeyword)
      this.debouncedGetSearchSuggestion()
    },
    '$store.state.app.cleanSearchKey': function (newVal, oldVal) {
      console.log('back search key-search bar: ', newVal, oldVal)
      if (this.isMobile && !isWechatMiniProgram() && newVal) {
        this.searchKeyword = ''
        this.$emit('setsearchkey', this.searchKeyword)
        setTimeout(() => {
          this.$store.commit('app/setCleanSearchKey', false)
        }, 50)
        // this.$store.commit('app/setCleanSearchKey', false)
        // this.$store.commit('campaigns/clearGoodieBagCampaigns')
      }
    }

  },
  created () {
    this.debouncedGetSearchSuggestion = _.debounce(this.searchSuggestion, 500)
  },
  mounted () {
    console.log('???1 search bar', this.for, this.searchKeyword, this.isExplorePage(), this.$store.getters['app/getlastCampaignListState'], this.$store.getters['app/getLastGoodiebagCampaignListState'])

    if (this.isExplorePage()) {
      const { searchKey } = this.$store.getters['app/getlastCampaignListState']
      this.searchKeyword = searchKey
    } else if (this.for === 'COUPON') {
      this.searchKeyword = this.$store.getters['coupons/queryKey']
    } else if (this.isMissionDetailPage()) {
      const { searchKey } = this.$store.getters['app/getLastMissionCampaignListState']
      this.searchKeyword = searchKey
    } else {
      const { searchKey } = this.$store.getters['app/getLastGoodiebagCampaignListState']
      this.searchKeyword = searchKey
    }
    // const { search } = this.$route.query
    // if (search) {
    //   this.searchKeyword = search
    // }
    console.log('???2 search bar', this.searchKeyword)

    window.addEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    isMiniProgram () {
      return isWechatMiniProgram()
    },
    handleClearAll () {
      this.searchKeyword = ''
      if (this.isMission) {
        this.$emit('search', '')
      } else {
        this.$emit('setsearchkey', '')
      }

      this.suggestionKeywords = []

      this.setMyWalletSeachButtonShow(false)
      // need clear search campaign result

      // if (this.isExplorePage()) {
      //   // track generic_offer_campaign_leave_search click
      //   sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'generic_offer_campaign_leave_search', 'onclick')
      // } else {
      //   // track goodie_bag_campaign_leave_search click
      //   sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'goodie_bag_campaign_leave_search', 'onclick')
      // }
    },
    handleBack () {
      console.log('back')
      this.showSearchPage = false
      window.$('html').removeClass('no-scroll')
      window.removeEventListener('scroll', this.handleScroll, true)
      if (this.isMobile) {
        window.$('.search-filter-area').attr('style', '')
      }

      window.scrollBy(0, this.scrollTop)
    },
    handleClear () {
      this.suggestionKey = ''
      if (!this.isMobile) {
        this.searchKeyword = ''
        if (this.isMission) {
          this.$emit('search', '')
        } else {
          this.$emit('setsearchkey', '')
        }
      }

      if (this.isMyWallet) {
        this.searchKeyword = ''
        this.$refs.searchInput.focus()
        return
      }
      this.suggestionKeywords = []
      if (!this.isMobile && !this.isExplorePage()) {
        var toHideElements = document.getElementsByClassName('goodie-bag-header-container')
        if (toHideElements.length) {
          toHideElements[0].style['display'] = 'block'
        }
      }
    },
    handleSearch () {
      console.log('start search', window.$('html').scrollTop(), this.placeHolder)

      this.suggestionKey = this.searchKeyword

      if (!this.needAutoSuggestion) {
        return
      }

      if (this.isMobile) {
        window.$('.search-filter-area').attr('style', 'z-index: 500')
      }

      this.showSearchPage = true

      this.scrollTop = window.$('html').scrollTop()
      console.log('in showing search', window.$('html').scrollTop(), this.placeHolder)
      this.$nextTick(() => {
        this.$refs.search.focus()

        setTimeout(() => {
          window.$('html').addClass('no-scroll')
          window.addEventListener('scroll', this.handleScroll, true)
        }, 500)
      })
      // if (this.isExplorePage()) {
      //   // track generic_offer_campaign_enter_search click
      //   sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'generic_offer_campaign_enter_search', 'onclick')
      // } else {
      //   // track goodie_bag_campaignlist_offer_enter_search click
      //   sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'goodie_bag_campaignlist_offer_enter_search', 'onclick')
      // }
    },
    async searchSuggestion () {
      console.log('@@279-searchSuggestion: ', this.suggestionKey)
      if (!this.suggestionKey) {
        this.suggestionKeywords = []
        return
      }
      let response = null
      if (this.for === 'MISSION_CHECK_IN') {
        response = await axios.get(`/gamification_mission/check_in/search_suggestion?key=${this.suggestionKey.toLowerCase()}&mission=${this.missionId}`)
      } else {
        let query = ''
        if (this.goodiebagSlotId) {
          query = `&goodie_bag_campaign_slot=${this.goodiebagSlotId}`
        } else if (this.missionId) {
          query = `&mission=${this.missionId}&is_gamification=true`
        }
        console.log('@@330: ', query, this.missionId)
        response = await axios.get(`/campaigns/search_suggestion?key=${this.suggestionKey.toLowerCase()}${query}`)
      }

      console.log('search resp:', response)
      if (response && response.data) {
        const suggestion = []
        for (let index = 0; index < response.data.suggestion.length; index++) {
          suggestion[index] = {
            origin: response.data.suggestion[index],
            format: this.handleSearchResultItem(this.suggestionKey, response.data.suggestion[index])
          }
        }
        this.suggestionKeywords = suggestion
      } else {
        this.suggestionKeywords = []
      }

      console.log('@@279 response-1: ', this.suggestionKeywords)
    },
    async loadMoreSuggestion () {
      this.isLoadingMore = true
      if (!this.suggestionKey) {
        return
      }
      this.page += 1

      let response = null
      if (this.for === 'MISSION_CHECK_IN') {
        response = await axios.get(`/gamification_mission/check_in/search_suggestion?key=${this.suggestionKey.toLowerCase()}&mission=${this.missionId}&page=${this.page}`)
      } else {
        let query = ''
        if (this.goodiebagSlotId) {
          query = `&goodie_bag_campaign_slot=${this.goodiebagSlotId}`
        } else if (this.missionId) {
          query = `&mission=${this.missionId}`
        }
        response = await axios.get(`/campaigns/search_suggestion?key=${this.suggestionKey.toLowerCase()}${query}&page=${this.page}`)
      }

      console.log('search resp:', response)
      if (response && response.data) {
        const suggestion = []
        for (let index = 0; index < response.data.suggestion.length; index++) {
          suggestion[index] = {
            origin: response.data.suggestion[index],
            format: this.handleSearchResultItem(this.suggestionKey, response.data.suggestion[index])
          }
        }
        if (suggestion.length) {
          this.suggestionKeywords.concat(suggestion)
        } else {
          this.hasLoadMore = false
        }
      }
      this.isLoadingMore = false
    },
    handleSearchResultItem (keyword, value) {
      const lowerKeyword = keyword.toLowerCase()
      let newValue = ''
      for (let i = 0; i < value.length;) {
        const subValue = value.slice(i, i + keyword.length)
        if (subValue.toLowerCase() === lowerKeyword) {
          newValue += '<span>' + subValue + '</span>'
          i += keyword.length
        } else {
          newValue += value[i]
          i += 1
        }
      }
      return newValue
    },
    handleClickSuggestion (keyword) {
    //   this.searchKeyword = keyword
      this.suggestionKey = keyword
      this.handleSearchCampaigns()
    },
    handleSearchCampaigns () {
      if (this.isMyWallet) {
        this.setMyWalletSeachButtonShow(false)
        this.$emit('setsearchkey', this.searchKeyword)
        return
      }

      if (this.isMobile) {
        if (!this.isMiniProgram()) {
          // const query = this.$route.query
          // // query.search = this.searchKeyword
          // const { search } = this.$route.query
          // query.search = this.searchKeyword
          // console.log('@@279000: ', search)
          // if (search) {
          //   // replace
          //   replaceRouterWithQuery(this.$router, { name: 'search-goodie-bag-campaign-list', query })
          // } else {
          //   // push
          //   pushRouterWithQuery(this.$router, { name: 'search-goodie-bag-campaign-list', query })
          // }
          // this.searchKeyword = ''
          console.log('reset: ', this.searchKeyword, this.suggestionKey)
          if (this.suggestionKey && this.searchKeyword === this.suggestionKey) {
            if (this.$parent.clearFilter) {
              this.$parent.clearFilter()
            }
          }
        }
        this.searchKeyword = this.suggestionKey
      } else {
        if (!this.isExplorePage()) {
          var toHideElements = document.getElementsByClassName('goodie-bag-header-container')
          if (toHideElements.length) {
            toHideElements[0].style['display'] = 'none'
          }
        }
      }
      console.log('@@547x1-------', this.isMission)
      if (this.isMission) {
        this.$emit('search', this.searchKeyword)
      } else {
        this.$emit('setsearchkey', this.searchKeyword)
      }

      this.handleBack()

      if (this.isExplorePage()) {
        // track generic_offer_campaign_submit_search click
        sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'generic_offer_campaign_submit_search', 'onclick', { 'search_term': this.searchKeyword })
      } else if (!this.isMissionDetailPage()) {
        // track goodie_bag_campaign_submit_search click
        sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'goodie_bag_campaign_submit_search', 'onclick', { 'search_term': this.searchKeyword })
      }
    },
    handleScroll () {
      if (!this.suggestionKeywords.length) return
      const totalHeight = this.suggestionKeywords.length * 52
      var bottom = totalHeight - (window.$('.search-result').scrollTop() + window.$('.search-result').height())
      if (bottom < 52 * 1 && this.showSearchPage && !this.isLoadingMore && this.hasLoadMore) {
        this.loadMoreSuggestion()
      }
    },
    async querySearchAsync (queryString, cb) {
      console.log('@@279: ', queryString)
      if (queryString === '') {
        this.handleClear()
        return
      }

      if (!this.needAutoSuggestion) {
        cb([])
        return
      }

      this.suggestionKey = queryString
      //   cb([{ 'value': '123' }])
      await this.searchSuggestion()
      console.log('@@279 response: ', this.suggestionKeywords)

      //   var restaurants = this.restaurants;
      //   var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        cb(this.suggestionKeywords)
      }, 300)
    },
    handleSelect (item) {
      console.log(item)
      this.searchKeyword = item.origin
      this.handleClickSuggestion(item.origin)
    },
    handleChange (value) {
      console.log('339: ', value)
      if (!value) {
        this.handleClear()
      }
    },
    setMyWalletSeachButtonShow (value) {
      this.showSearchButton = value === undefined ? !this.showSearchButton : value
    }
  }
}
</script>

<style lang="scss" scoped>
.is-vue-desktop  .explore-goodie-bag-container {
  padding-left: 10px;
  padding-right: 10px;
}

.searchbar-container {
  img {
    display: none;
  }

  .searchbar {
    display: none;
  }

  .searchbar-selector {
    display: none;
  }

  &.desktop {
    // margin: 14px 0px;
    background: #EEE;
    position: sticky;

    top: 91px;
    z-index: 29;
    margin-top: -79px;

    padding: 16px 60px;

    &.mywallet {
      // position: relative;
      // top: unset;
      margin-top: unset;

      background: #FFF;

      padding: 20px 30px;

      .searchbar-selector .search-bar-container {
        background: hsla(0, 0%, 97%, 1);

      }
    }

    .searchbar-selector{
        display: flex;

        // padding: 16px 0px;
    }

  }

  &.mobile {
    // margin-top: 16px;
    margin-bottom: 16px;

    .searchbar-selector{
      display: none;
    }

    img {
      display: flex;
    }

    .searchbar {
      display: flex;
    }
    display: flex;
    flex-direction: row;
    // padding: 0px 16px;
    // margin-top: 16px;
    // padding: 0px 16px;
    padding: 16px 16px 12px;
    background-color: #FFFFFF;

    // margin-top: 16px;
  }
}

// .is-vue-mobile .searchbar-container {
//   img {
//     display: flex;
//   }

//   .searchbar {
//     display: flex;
//   }

//   .searchbar-selector {
//     display: none;
//   }

//   flex-direction: row;
//   padding: 0px 16px;
//   margin-top: 16px;
// }

.back-icon {
  margin-right: 12px;
  align-self: center;
}

.searchbar {
//   margin-left: 10px;
//   margin-right: 10px;
  padding-left: 12px;
  padding-right: 12px;

  border-radius: 4px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
//   vertical-align: middle;
  height: 48px;
  width: -webkit-fill-available;

  span {
    align-self: center;
    color: #999;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  img {
    align-self: center;
    margin-right: 4px;
  }

  .searchbar-input {
    display: flex;
    height: 48px;
    // padding-left: 12px;
    align-items: center;
    flex: 1 0 0;

    // border-radius: 4px 0px 0px 4px;
    // border: 1px solid #999;
    border-right: 0px;
    // background: #FFF;

    .search-icon {
      margin-right: 4px;
    }

    input {
      width: 100%;

      border: 0;
      color: #000;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background: transparent;
    }
    input::placeholder {
      color: #999;
    }

    .clear-icon {
      width: 24px;
      height: 24px;
      margin-left: 12px;
    }

    button {
      gap: 10px;
      height: 48px;
      padding: 0px 20px;
      border-radius: 0px 4px 4px 0px;
      border: 0;

      color: #FFF;
      text-align: center;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: -3px;
      margin-right: -1px;
      white-space: nowrap;
    }
  }

}

.searchbar-empty {
  background-color: #F7F7F7;
}

.searchbar-keyword {
  background-color: white;
  border: 1px solid #999;

  span {
    color: #000;
  }

}

.search-dialog {
  margin: 0;
  padding: 0;

  .search-icon {
    width: 20px;
    height: 20px;
  }

  .search-bar {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    .search-bar-input {
      display: flex;
      height: 48px;
      padding-left: 12px;
      align-items: center;
      flex: 1 0 0;

      border-radius: 4px 0px 0px 4px;
      border: 1px solid #999;
      border-right: 0px;
      background: #FFF;

      .search-icon {
        margin-right: 4px;
      }

      input {
        width: 100%;

        border: 0;
        color: #000;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      input::placeholder {
        color: #999;
      }

      .clear-icon {
        width: 24px;
        height: 24px;
        margin: 0 12px;
      }
    }
    button {
      gap: 10px;
      height: 48px;
      padding: 0px 20px;
      border-radius: 0px 4px 4px 0px;
      border: 0;

      color: #FFF;
      text-align: center;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .line {
    height: 1px;
    flex-shrink: 0;
    align-self: stretch;
    background: #EEE;
  }

  .search-result {
    display: flex;
    width: 100%;
    height: calc(100vh - 73px);
    flex-direction: column;
    align-items: center;
    overflow: auto;
    .search-result-item {
      display: flex;
      gap: 8px;
      min-height: 52px;
      margin: 0 16px;
      padding: 10px 0;
      align-items: center;
      align-self: stretch;

      color: #000;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      .search-result-item-inside {
        display: flex;
        gap: 8px;
      }
    }
    .search-result-item:not(:last-child) {
      border-bottom: 0.5px solid #EEE;
    }
  }
}

.searchbar-selector {
    display: flex;
    justify-content: center;

    // margin: 16px 0px;

    .search-bar-container {
        display: flex;
        flex-direction: row;

        width: calc(100% - 109px);
        height: 48px;
        background: #FFF;

        padding: 0px 8px 0px 12px;
        // border: ;
        gap: 8px;
        flex-shrink: 0;

        border-radius: 4px;

        img.search-icon {
            display: block;
            align-self: center;
            width: 24px;
            height: 24px;

        }

        .search-autocomplete-inline-input {
            width: 100%;
            // border-left: 1px solid #000;
            padding-left: 8px;
            height: 26px;
            align-self: center;
            display: inline-flex;

            input::placeholder {
                color: #999;
            }

            .el-input {
              height: 100%;
            }

            .el-input__suffix img{
                display: inline-block;
                cursor: pointer;
            }
        }
    }

    button {
      gap: 10px;
      height: 48px;
      padding: 0px 20px;
      border-radius: 0px 4px 4px 0px;
      border: 0;

      align-self: center;

      color: #FFF;
      text-align: center;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      width: 98px;
      cursor: pointer;
    }
}

</style>

<style lang="scss">
.search-dialog {
  .el-dialog__header, .el-dialog__body {
    padding: 0;
  }
  .search-result-item {
    .search-result-item-inside {
      display: flex;
      gap: 8px;
      p {
        span {
          color: #E2002C;
          font-style: normal;
          font-weight: 600;
        }
      }
    }
  }
}

.search-autocomplete-popper {

}

.search-autocomplete-inline-input {
    .el-input input {
        border: none;
        font-family: Open Sans;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        height: 100%;

        padding: 0px;

        background: transparent;
    }
}

.el-autocomplete-suggestion li {
    padding: 0px 16px !important;
    white-space: break-spaces !important;
}

.autocomplete-search-result {
    display: flex;
    flex-direction: row;
    border-bottom: 0.5px solid #EEE;
    min-height: 52px;
    padding: 10px 0px;
    // justify-content: space-between;
    align-items: center;
    align-self: stretch;
    img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        align-self: center;
    }
    p {
        span {
          color: #E2002C;
          font-style: normal;
          font-weight: 600;
        }
    }
}
</style>
