<template>
  <div />
</template>
<script>
import { doLogout, getSsoAuthClient } from '../helpers/http'
import jwt_decode from 'jwt-decode'
import { getLangCode } from '@/utils/lang'
import utilsMixin from '@/mixins/utilsMixin'
import { isWechatMiniProgram, replaceRouterWithQuery } from '@/utils/utils'
import { sendAdobeManualAnalytics } from '@/utils/tracking'
import Cookies from 'js-cookie'
// import axios from 'axios'
export default {
  name: 'VerifyCode',
  mixins: [utilsMixin],
  beforeRouteEnter (to, from, next) {
    console.log('to from', to, from)
    if (from.name === 'campaign-detail') {
      next({ name: 'home' })
    } else {
      next()
    }
  },
  mounted () {
    console.log('1212verify code', this.$route.query)
    if (!this.$route.query.lang) {
      const lang = Cookies.get('lang')
      let ssoLang = localStorage.getItem('language')
      if (ssoLang) {
        ssoLang = getLangCode(ssoLang)
      }
      if (lang && (!ssoLang || lang === ssoLang)) {
        window.location.href = window.location.href + '&lang=' + lang
        return
      }

      if (ssoLang && lang !== ssoLang) {
        window.location.href = window.location.href + '&lang=' + ssoLang
        return
      }
    }

    const ssoAuth = getSsoAuthClient(this.$route.query.state)
    let url = null
    let state = {}
    try {
      state = JSON.parse(this.$route.query.state)
    } catch (error) {
      console.log('error: ', error)
    }
    url = state.url
    console.log('20 url', url)
    if (!url || typeof url !== 'string') {
      const lang = this.$i18n.locale
      url = `/${lang}/home`
    }
    const info = state.info
    console.log('23--- url', url, state, info)

    // const testUrl = 'http://localhost:8080/verifycode' + window.location.search
    ssoAuth.code.getToken(window.location.href).then((response) => {
      console.log('response---', response)
      const { data } = response
      const { access_token: accessToken, refresh_token: refreshToken } = data
      localStorage.setItem('refreshToken', refreshToken)
      localStorage.setItem('accessToken', accessToken)
      localStorage.removeItem('sendVerificationEmailTime')
      this.$store.commit('auth/loginUser', { accessToken })

      if (isWechatMiniProgram()) {
        const wechatTokenURL = window.location.href.split('#')[0]
        this.$store.commit('app/setWechatTokenURL', wechatTokenURL)
      }

      console.log('@43', accessToken)
      console.log('@46', JSON.stringify(jwt_decode(accessToken)))
      const payload = jwt_decode(accessToken)
      let lang = this.$i18n.locale
      if (payload && payload.ext && payload.ext.login_langauge && url.startsWith('/')) {
        console.log('@48, redirect by token')
        lang = getLangCode(payload.ext.login_langauge)

        const urlParts = url.split('/')

        urlParts[1] = lang

        url = urlParts.join('/')
      }

      const membershipId = payload && payload.ext && payload.ext.membership_id || ''
      const countryCode = payload && payload.ext && payload.ext.country_code || ''
      sendAdobeManualAnalytics(this.$route, lang || this.$i18n.locale, this.$store, 'sign up sign in|sign in success', { member: { dhk_id: membershipId, sim_country_code: countryCode }})
      this.$store.commit('auth/setProfile', { membership_id: membershipId, mobile_phone_number_country_code: countryCode })

      if (info.participatingItem) {
        const participatingItem = info.participatingItem
        delete info.participatingItem

        let pathname = ''
        console.log('106-0: ', url)
        if (url.startsWith('http')) {
          const newUrl = new URL(url)
          pathname = newUrl.pathname
        } else {
          pathname = url
        }
        console.log('106: ', pathname)
        console.log('106-2: ', pathname.split('/')[2])
        if (['landing-gamification', 'gamification-detail', 'stamps-prize', 'mission-detail', 'campaign-detail', 'coupon-detail', 'mission-location-detail'].indexOf(pathname.split('/')[2].split('?')[0]) >= 0) {
          const expireDate = new Date()
          expireDate.setTime(expireDate.getTime() + (3 * 60 * 1000))
          Cookies.set('__logintask', participatingItem, { expires: expireDate })
        }
      }

      // this.$store.commit('app/setAfterLoginDoMissionTask', participatingItem)

      // url is full url
      if (!this.$route.query.lang || (lang && this.$route.query.lang !== lang)) {
        if (url.startsWith('http')) {
          window.location.href = url
        } else {
          let newUrl = window.location.origin + url
          if (info && Object.keys(info).length > 0) {
            newUrl = newUrl + '?' + (new URLSearchParams(info)).toString()
          }
          window.location.href = newUrl
        }
      } else {
        if (url.startsWith('http')) {
          window.location.replace(url)
        } else {
          // replaceRouterWithQuery(this.$router, { path: url, query: info, state: { participating_item_id: participatingItem }})
          replaceRouterWithQuery(this.$router, { path: url, query: info })
        }
      }
    }).catch(error => { // otherwise verify code will show blank screen if code is blank
      console.log('cannot get token: ', error)
      doLogout()
    })
  }
}
</script>
