<template>
  <div>
    <el-dialog
      :visible.sync="showGPSDescDialog"
      class="gps-desc"
      width="316px"
      center
      :show-close="false"
      custom-class="gps-desc-dialog"
      @close="closeGPSDescDialog()"
    >
      <div v-if="descTitle" slot="title" class="gps-desc-title" v-html="descTitle"></div>
      <div class="gps-desc-content " v-html="descContent"></div>

      <span slot="footer" class="dialog-footer">
        <el-button
          tag="button"
          type="button"
          class="el-button el-button--primary primary-button"
          @click="closeGPSDescDialog()"
        >
          {{ $t('goodiebagOk') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { isWechatMiniProgram } from '@/utils/utils'

export default {
  name: 'GPSDescDialog',
  props: {
    showGPSDescDialog: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isWechatMiniProgram () {
      return isWechatMiniProgram()
    },
    isChromeBrowser () {
      // https://stackoverflow.com/questions/4565112/how-to-find-out-if-the-user-browser-is-chrome
      var isChromium = window.chrome
      var winNav = window.navigator
      var vendorName = winNav.vendor
      var isOpera = typeof window.opr !== 'undefined'
      // var isFirefox = winNav.userAgent.indexOf('Firefox') > -1
      var isIEedge = winNav.userAgent.indexOf('Edg') > -1
      var isIOSChrome = winNav.userAgent.match('CriOS')
      var isGoogleChrome = (typeof winNav.userAgentData !== 'undefined') ? winNav.userAgentData.brands[2].brand === 'Google Chrome' : vendorName === 'Google Inc.'

      if (isIOSChrome) {
        // is Google Chrome on IOS
        return true
      } else if (
        isChromium !== null &&
        typeof isChromium !== 'undefined' &&
        vendorName === 'Google Inc.' &&
        isOpera === false &&
        isIEedge === false &&
        isGoogleChrome
      ) {
        // is Google Chrome
        return true
      } else {
        // not Google Chrome
        return false
      }
    },
    getBrowserType () {
      let client = 'PC'
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      if (/(phone|pad|pod|iPhone|iPad|iPod|iOS)/i.test(userAgent)) {
        client = 'IOS'
      } else if (/(Android)/i.test(userAgent)) {
        client = 'Android'
      }
      return client
    },
    getDescKeyword () {
      return ''
      // if (this.isWechatMiniProgram) {
      //   return 'Wechat'
      // }
      // if (this.isChromeBrowser && this.getBrowserType === 'IOS') {
      //   return 'IOSChrome'
      // } else if (this.isChromeBrowser && this.getBrowserType === 'Android') {
      //   return 'AndroidChrome'
      // } else {
      //   if (this.isChromeBrowser) {
      //     return 'Chrome'
      //   }
      //   return 'Others'
      // }
    },
    descTitle () {
      return this.$t(`checkGPSLearnMore${this.getDescKeyword}Title`)
    },
    descContent () {
      return this.$t(`checkGPSLearnMore${this.getDescKeyword}Content`)
    }
  },
  methods: {
    closeGPSDescDialog () {
      this.$emit('setShowGPSDescDialog', false)
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/assets/carousel.scss';

.gps-desc{
  .gps-desc-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #000000;
    padding: 24px 24px 0;
  }
  .gps-desc-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    margin-top: 24px;
    padding: 0 24px;
  }
}

.is-vue-mobile {
  .wide-code .el-dialog.gps-desc-dialog .scan-code-success-bar-code{
    padding-bottom: 34px;
  }
}
</style>

<style lang='scss'>
.gps-desc-dialog.el-dialog--center{
  border-radius: 10px;
  .el-dialog__body {
    padding: 0px;
  }
}

.wide-code .gps-desc-dialog{
  width: calc(100% - 6px) !important;
  .scan-code-success-bar-footer{
    width: 100%;
    text-align: center;
  }
}

.el-dialog.desktop-gps-desc-dialog{
  margin-top: auto !important;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.40);
  .el-dialog__body{
    padding-top: 0px;
  }
}

.el-dialog.gps-desc-dialog {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;

  .el-dialog__header{
    padding: 0px;
  }

  .el-dialog__body {
    ul {
      list-style: decimal;
      padding: 0 24px;
    }
  }

  .el-dialog__footer {
    padding: 20px 24px 24px;

    .dialog-footer {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .el-button{
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        background-color: #E2002C;
        color: #ffffff;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px 30px 10px;
        gap:10px;
        border-radius: 50px;
      }
    }
  }
}
</style>

