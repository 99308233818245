<template>
  <div
    :class="['category-option']"
  >
    <div
      :class="['category-option-header', {disabled: isCategoryDisabled}]"
      :data-debug="resultCount ? resultCount.all : 0 "
    >
      <div
        class="header-select-container"
        data-aid="home_filter_{selection}"
        data-atype="campaign_category"
        :data-akey="category.name"
        @click="!isCategoryDisabled && clickCategoryOption()"
      >
        <img
          v-show="categoryTickStatus === 'ticked'"
          class="category-tick"
          srcset="/img/ticked.1x.png, /img/ticked.2x.png 2x, /img/ticked.3x.png 3x"
          src="/img/ticked.2x.png"
        >
        <img
          v-show="categoryTickStatus === 'unticked'"
          class="category-tick"
          srcset="/img/unticked.1x.png, /img/unticked.2x.png 2x, /img/unticked.3x.png 3x"
          src="/img/unticked.2x.png"
        >
        <img
          v-show="categoryTickStatus !== 'unticked' && categoryTickStatus !== 'ticked'"
          class="category-tick"
          srcset="/img/partial-ticked.1x.png, /img/partial-ticked.2x.png 2x, /img/partial-ticked.3x.png 3x"
          src="/img/partial-ticked.2x.png"
        >
        <div class="category-option-label">
          {{ category.name || category.english_name }}
        </div>
      </div>
      <div
        v-if="category.attribute_tags && category.attribute_tags.length > 0"
        class="header-toggle-container"
        @click="resultCount && resultCount.all && resultCount.all > 0 && toggleExpanded()"
      >
        <img
          v-show="!expanded"
          class="category-toggle"
          srcset="/img/expand.1x.png, /img/expand.2x.png 2x, /img/expand.3x.png 3x"
          src="/img/expand.2x.png"
        >
        <img
          v-show="expanded"
          class="category-toggle"
          srcset="/img/shrink.1x.png, /img/shrink.2x.png 2x, /img/shrink.3x.png 3x"
          src="/img/shrink.2x.png"
        >
      </div>
    </div>
    <div
      :class="['attribute-tag-option-container', {expanded}]"
    >
      <div
        v-for="attributeTag in category.attribute_tags"
        :key="attributeTag.id"
        :data-key2="attributeTag.id"
        data-aid="home_filter_{selection}"
        data-atype="campaign_category"
        :data-debug2="attributeTag.id"
        :data-akey="attributeTag.english_name"
        :class="['attribute-tag-option', {disabled: isAttributeTagDisabled(attributeTag) }]"
        @click="!isAttributeTagDisabled(attributeTag) && clickAttributeTag(attributeTag)"
      >
        <img
          v-if="!isAttributeTagDisabled(attributeTag) && selectedTags.includes(attributeTag.id)"
          class="category-tick"
          srcset="/img/ticked.1x.png, /img/ticked.2x.png 2x, /img/ticked.3x.png 3x"
          src="/img/ticked.2x.png"
        >
        <img
          v-else
          class="category-tick"
          srcset="/img/unticked.1x.png, /img/unticked.2x.png 2x, /img/unticked.3x.png 3x"
          src="/img/unticked.2x.png"
        >

        <div class="attribute-tag-label">
          {{ attributeTag.name || attributeTag.english_name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoryOption',
  props: {
    category: {
      type: Object,
      default: null
    },
    value: {
      type: Object,
      default: null
    },
    resultCount: {
      type: Object,
      default: null
    },
    categoryType: {
      type: String,
      default: 'general'
    },
    debug: {
      type: String,
      default: null
    },
    skipResult: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  computed: {
    selectedTags () {
      console.log('@131@124', this.category.id, this.value)
      const { [this.category.id]: selectedTags } = this.value || {}
      return selectedTags || []
    },
    categoryTickStatus () {
      let { [this.category.id]: selectedTags } = this.value || {}
      const { attribute_tags: attributeTags } = this.category || {}

      // console.log('@131@tick status', this.category.id, JSON.stringify(this.value), selectedTags, attributeTags)
      if (!attributeTags || attributeTags.length === 0) {
        if (selectedTags) {
          return 'ticked'
        }

        return 'unticked'
      } else {
        selectedTags = selectedTags || []
        if (selectedTags.length === 0) {
          return 'unticked'
        }

        const options = attributeTags.map(tag => tag.id).slice().sort()
        const selected = selectedTags.slice().sort()
        const allSelected = options.length === selected.length && options.every((value, index) => value === selected[index])
        if (allSelected) {
          return 'ticked'
        }
        return 'partial'
      }
    },
    isCategoryDisabled () {
      if (this.skipResult) {
        return false
      }
      return this.resultCount === null || (this.resultCount && this.resultCount.all <= 0)
    }
  },
  mounted () {
    // console.log('@119@cateogy option', this.debug)
  },
  beforeMount () {
    this.expanded = this.categoryTickStatus === 'partial'
  },
  methods: {
    isAttributeTagDisabled (attributeTag) {
      if (this.skipResult) {
        return false
      }
      return this.resultCount === null || (this.resultCount && !this.resultCount[attributeTag.id])
    },
    toggleExpanded () {
      console.log('@160', this.expanded)
      this.expanded = !this.expanded
    },
    clickAttributeTag (attributeTag) {
      console.log('@131@190aa', this.value, this.category.id, attributeTag.id)
      const newValue = { ...this.value }
      let newSelected
      if (this.selectedTags.includes(attributeTag.id)) {
        newSelected = this.selectedTags.filter(item => item !== attributeTag.id)
      } else {
        newSelected = [...this.selectedTags, attributeTag.id]
      }

      console.log('@176@131', newSelected, JSON.stringify(this.resultCount))
      if (newSelected.length === 0) {
        delete newValue[this.category.id]
      } else {
        newSelected = newSelected.filter(tagId => this.resultCount && this.resultCount[tagId] > 0)
        newValue[this.category.id] = newSelected
      }

      console.log('@190a@131', newValue)
      this.$emit('input', newValue)
    },
    clickCategoryOption () {
      console.log('@188')
      const newValue = { ...this.value }
      if (this.categoryTickStatus === 'ticked') {
        delete newValue[this.category.id]
      } else {
        let { attribute_tags: attributeTags } = this.category || {}
        attributeTags = attributeTags || []
        newValue[this.category.id] = attributeTags.map(tag => tag.id)
      }

      console.log('@190', newValue)
      this.$emit('input', newValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-vue-desktop .category-option{
  .category-option-header {
    height: 65px;

    .header-select-container{
      padding-left: 32px;
    }

    .category-option-label {
      font-size: 18px;
      line-height: 25px;
    }

    .header-toggle-container {
      padding-left: 25px;
      padding-right: 25px;
    }

  }

  .attribute-tag-option-container {
    padding-left: 32px;

    .attribute-tag-option {
      height: 65px;

      .attribute-tag-label {
        font-size: 18px;
        line-height: 25px;
        color: #000000;
      }
    }
  }
}

.category-option {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  &:before{
    content: '';
    height: 1px;
    top: 0;
    position: absolute;
    left: 15px;
    right: 0;
    background: #EEEEEE;
    z-index: 2;
  }

  &:last-child:after {
    content: '';
    height: 1px;
    bottom: 0;
    position: absolute;
    left: 15px;
    right: 0;
    background: #EEEEEE;
    z-index: 2;
  }

  .category-option-header {
    height: 54px;
    position: sticky;
    flex-direction: row;
    display: flex;
    width: 100%;
    background-color: #FFFFFF;
    z-index: 1;
    align-items: center;
    top: 0px;

    .header-select-container{
      padding-left: 16px;
      display: flex;
      flex: 1;
      height: 100%;
      align-items: center;
    }

    &.disabled{
      display: none;
      .category-option-label {
        color: #999999;
      }
      .category-tick, .category-toggle{
        filter: opacity(0.3);
      }
    }

    .header-toggle-container{
      display: flex;
      height: 100%;
      margin-left: 17px;
      padding-left: 16px;
      padding-right: 16px;
      align-items: center;
    }

    .category-option-label {
      flex: 1;
      padding-left: 12px;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

  }

  .attribute-tag-option-container{
    padding-left: 16px;
    width: 100%;
    flex-direction: column;
    // transition: max-height 400ms ease-in-out;
    max-height: 0;
    overflow: hidden;

    &.expanded {
      max-height: 10000px;
    }
    .attribute-tag-option{
      height: 51px;
      flex-direction: row;
      padding-left: 32px;
      display: flex;
      width: 100%;
      align-items: center;
      background: #F3F3F3;

      &.disabled{
        display:none;
        .attribute-tag-label {
          color: #999999;
        }
        .category-tick{
          filter: opacity(0.3);
        }
      }

      .attribute-tag-label {
        flex: 1;
        font-size: 14px;
        line-height: 19px;
        color: #333333;
        padding-left: 8px;
        padding-right: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

}

.custom-options{
  .category-option
    .category-option-header{
      &.disabled{
        display: none;
      }
  }
}
</style>
