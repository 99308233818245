<template>
  <div class="gamification">
    <div v-if="participateDirectId" class="loading-view">
      <img src="/img/loading.gif"/>
    </div>
    <div v-else-if="isNoData" class="no-data">
      <not-find />
    </div>
    <div v-else class="gamification-detail-container" :class="langClass">
      <div class="mission-detail-container">
        <div class="mission-detail-column">
          <!-- mission info -->
          <div class="mission-block">
            <div class="banner-container">
              <!-- <img :src="bannerImage"> -->
              <div class="mission-info-overlay">
                <div class="mission-info-container">
                  <div class="mission-title-icon">
                    <div class="title" :class="langClass">
                      {{ missionData.name }}
                    </div>
                    <img v-if="isCampaign" class="mission-type-icon" src="/img/couponRedeemIcon.png">
                    <img v-else class="mission-type-icon" src="/img/checkInIcon.png">
                  </div>
                  <div v-if="validDate" class="mission-expiration">
                    <label>
                      {{ validDate }}
                      <!-- <el-tooltip
                        class="item"
                        :style="'max-width: 90%'"
                        :content="$t('missionValidPeriodTips')"
                        placement="bottom"
                      >
                        <img
                          width="18"
                          height="18"
                          class="question-mark-icon"
                          src="/img/question-icon.png"
                        >
                      </el-tooltip> -->
                      <img
                        width="18"
                        height="18"
                        class="question-mark-icon"
                        src="/img/question-icon.png"
                        @mouseover="toShowCustomTooltips(true)"
                        @focus="toShowCustomTooltips(true)"
                        @mouseleave="toShowCustomTooltips(false)"
                      >
                    </label>
                    <custom-tooltip
                      :visible="showMissionTips"
                      :show-hover="true"
                      :content="$t('missionValidPeriodTips')"
                      :custom-tooltip-slot-element="customTooltipSlotElement"
                      :custom-tooltip-reference-element="customTooltipReferenceElement"
                      :space-between="0"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="grant-stamp-info-container">
              <div>
                <div :class="['grant-stamp-info', { 'clickable': !isCampaign }]" @click="toggleStampTips">
                  <div class="grant-stamp-title" v-html="$t('missionEarnedDesc', {earned: missionData.collected_stamp_rewards_count, total: missionData.per_head_limit || missionData.per_head_limit === 0 ? `/${missionData.per_head_limit}` : ''})" />
                    <!-- <div class="collpase-arrow">
                      <img v-if="!isShowStampTips" class="stamp-arrow-icon" src="@/assets/images/arrow-down.png">
                      <img v-else class="stamp-arrow-icon" src="@/assets/images/arrow-up.png">
                    </div> -->
                  <div v-if="isShowStampTips && !isCampaign" class="stamp-tips">
                    {{ $t('missionDisclaimerCheckIn') }}
                  </div>
                </div>
              </div>
              <hr />
              <div class="tutorial" @click="gotoEarnTutorial">
                <img src="/img/question-icon.png" />
                <label>{{ $t('missionTutorial') }}</label>
                <img class="right" src="/img/right-arrow-purple.png" />
              </div>
            </div>
          </div>

          <!-- tasks -->
          <div class="search-filter-area" :style="isMiniProgram ? 'top: 52px':''">
            <campaign-list-search-bar
              :class="isMobile ? 'mobile': 'desktop'"
              :place-holder="isCampaign ? $t('missionCampaignSearchPlaceholder') : $t('missionCheckinSearchPlaceholder')"
              :for="isCampaign ? 'MISSION_CAMPAIGN' : 'MISSION_CHECK_IN'"
              :mission-id="missionId"
              :need-auto-suggestion="true"
              @search="search"
            />
            <sort-filter-bar
              ref="filter"
              v-model="filters"
              :is-show-result-count="false"
              :is-show-category-carousel="false"
              :is-show-filter-section="isCampaign"
              :display-sort-inside-filter="true"
              :filter-name="filterName"
              :filter-options="filterOptions"
              :filter-result-count="filterResultCount"
              :campaign-count="resultCount"
              :custom-filter-option-labels="filterCustomOptionLabels"
              :title-custom-refs="filterCustomTitleRefs"
              :campaign-settings="campaignSettings"
              :locations="locations"
              :number-of-visualize-threads="numberOfVisualizeThreads"
              :search-key="searchKey"
              :search-setting-type="searchSettingType"
              :in-filtering="inFiltering"
              :is-combine="true"
              @sub-menu-changed="filterSubMenuChanged"
              @is-show-visualize-filter="isShowVisualizeFilter"
              @visualize-changed="visualizeChanged"
              @input="inputChanged"
            />
          </div>
          <!-- @toggle-sidebar="toggleFilterSideBar" -->
          <div class="mission-block task-container">
            <div>
              <transition-group
                name="fade"
                class="task-list-container"
              >
                <mission-task-loading-item
                  v-for="index in 8"
                  v-show="$store.getters['app/getIsCouponItemLoading']"
                  :key="`mission-task-${index}-loading`"
                />
                <mission-task-item
                  v-for="(item, index) in missionTaskData"
                  ref="missionTaskTarget"
                  :key="`mission-task-${item.id}-${index}`"
                  :index="index"
                  :mission-data="missionData"
                  :mission-is-valid="missionIsValid"
                  :data="item"
                  :is-campaign="isCampaign"
                  :latitude="latitude"
                  :longitude="longitude"
                  :is-in-active-period="isInActivePeriod"
                  @gotoTaskDetail="gotoTaskDetail"
                  @participateMissionTask="participateMissionTask"
                />

                <div
                  v-if="(!missionTaskData || (missionTaskData && missionTaskData.length === 0)) && !$store.getters['app/getIsCouponItemLoading'] && !inFiltering"
                  key="empty-result"
                  class="empty-result"
                >
                  <search-campaign-no-result v-if="searchKey" />
                  <div
                    v-else
                    class="empty-result-title"
                  >
                    {{ $t('goodiebagNoResultsFound') }}
                  </div>
                </div>
              </transition-group>
            </div>
          </div>
        </div>
      </div>

    <!-- <div
      class="sticky-coupon-footer"
    >
      <div class="sticky-coupon-footer-button">
        <div class="sticky-footer-button common-button" @click.prevent="redeemGamification()">view map</div>
      </div>
    </div> -->
    </div>

    <scan-qrcode
      v-show="isScaningQrcode"
      :is-check-in="isCheckIn"
      ref="scanTarget"
      @closeScanQrcode="closeScanQrcode"
      @showError="showErrorMessageDialog"
      @scanSuccess="scanSuccess"
    />

    <!-- error dialog -->
    <el-dialog
      :visible.sync="showErrorDialog"
      :class="[{ 'mission-coupon-use-confirm-dialog': errorDialog.isCopy, 'mission-fail-to-acquire-coupon': !errorDialog.isCopy, 'only-message': !errorDialog.title }]"
      :width="isMobile ? '316px':'600px'"
      :title="errorDialog.title ? '' : errorDialog.text"
      center
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div v-if="errorDialog.title" class="error-dialog-with-title-content">
        <div slot="title" class="goodie-bag-sold-out-title">{{ errorDialog.title || $t(goodieBagFailTitle) }}</div>
        <div class="goodie-bag-sold-out-content">
          {{ errorDialog.text }}
        </div>
        <div v-if="errorDialog.isCopy" class="coupon-detail-use-buttons" :class="errorDialog.buttonsClass || ''">
          <div class="coupon-detail-use-button coupon-detail-cancel-use-button" @click="closeErrorDialog">{{ errorDialog.cancelText || $t('myWalletUseCouponPromptUselater') }}</div>
          <!-- <div v-else class="coupon-detail-use-button coupon-detail-cancel-use-button" @click="closeErrorDialog">{{ errorDialog.cancelText || $t('myWalletUseCouponPromptUselater') }}</div> -->
          <div data-aid="coupon_detail_use_coupon_confirm" class="coupon-detail-use-button coupon-detail-cancel-confirm-button" @click="confirmCopyCode">{{ $t('myWalletUseCouponPromptConfirm') }}</div>
        </div>
        <div v-else class="goodie-bag-sold-out-bottom">
          <div class="button primary-button" @click="closeErrorDialog">{{ $t('goodiebagOk') }}</div>
        </div>
      </div>
      <span v-else slot="footer" class="dialog-footer">
        <el-button
          tag="button"
          type="button"
          class="el-button el-button--primary primary-button"
          @click="closeErrorDialog"
        >
          {{ $t('goodiebagOk') }}
        </el-button>
      </span>
    </el-dialog>
    <!-- <el-dialog
      title=""
      :visible.sync="isShowGoodieBagFail"
      class="goodie-bag-fail"
      :width="$store.getters['app/isMobile']?'316px':'600px'"
      center
      custom-class="coupon-alert-bar-dialog"
      :lock-scroll="true"
      :show-close="$store.getters['app/isMobile']?false:true"
    >
      <div slot="title" class="goodie-bag-sold-out-title">{{ $t(goodieBagFailTitle) }}</div>
      <div class="goodie-bag-sold-out-content">
        {{ goodieBagFailText }}
      </div>
      <div class="goodie-bag-sold-out-bottom">
        <div class="button primary-button" @click="closeGoodieBagError()">{{ $t('goodiebagOk') }}</div>
      </div>
    </el-dialog> -->

    <!-- confirm age -->
    <el-dialog
      title=""
      :visible.sync="isShowAgeRestricted"
      class="age-declaration"
      :width="$store.getters['app/isMobile']?'316px':'600px'"
      center
      custom-class="coupon-alert-bar-dialog"
      :show-close="false"
      :lock-scroll="true"
      :close-on-click-modal="false"
    >
      <div slot="title" class="age-declaration-title" v-html="$t('ageTitle')" />
      <div class="age-declaration-content" v-html="$t('ageContent')" />
      <div class="age-declaration-bottom">
        <div class="button primary-button ok" @click="ageContentOkClick()">
          <div>{{ $t('ageContentOK') }}</div>
          <div class="second">{{ $t('ageContentOKSecond') }}</div>
        </div>
        <div class="button secondary-button cancel" @click="ageContentCancelClick()">
          <div>{{ $t('ageContentCancel') }}</div>
          <div class="second">{{ $t('ageContentCancelSecond') }}</div>
        </div>
      </div>
    </el-dialog>

    <!-- show full screen barcode -->
    <el-dialog
      title=""
      :visible.sync="showFullPartnerBarCodeDialogDesktop"
      :class="{'wide-code': isMissionWideCode(scanCodeSuccessDialog.barcodeFormat)}"
      width="auto"
      custom-class="desktop-coupon-alert-bar-dialog"
      :lock-scroll="true"
    >
      <div class="view-barcode-text">{{ $t('ViewFullBarcode') }}</div>
      <div class="full-barcode-content">
        <barcode :value="scanCodeSuccessDialog.barcode" :format="scanCodeSuccessDialog.barcodeFormat" :options="barcodeOptions(scanCodeSuccessDialog.barcodeFormat)" />
      </div>
    </el-dialog>

    <!-- for scan success -->
    <!-- <el-dialog
      title=""
      :visible.sync="isScanCodeSuccessDialog"
      width="343px"
      center
      custom-class="scan-code-success-dialog full-dialog"
      :lock-scroll="true"
      :class="{'wide-code': isMissionWideCode(scanCodeSuccessDialog.barcodeFormat)}"
    >
      <div class="mission-scan-code-success-dialog-content">

        <coupon-code-display-redeem :coupon-item="scanCodeSuccessDialog" />

        <div
          v-if="scanCodeSuccessDialog.barcode"
          class="scan-code-success-bar-code"
          :class="{'qr-code': participatingItem && participatingItem.coupon_campaign_type_coupon_template.formats==='COUPON_QR_CODE'}"
        >
          <canvas v-show="scanCodeSuccessDialog.couponCodeFormat==='COUPON_QR_CODE'" id="barcode-qr-canvas" />
          <barcode v-if="scanCodeSuccessDialog.couponCodeFormat!=='COUPON_QR_CODE'" :value="scanCodeSuccessDialog.barcode" :options="barcodeOptions(scanCodeSuccessDialog.barcodeFormat)" />
          <div v-if="scanCodeSuccessDialog.couponCodeFormat!=='COUPON_QR_CODE' && isMissionWideCode((scanCodeSuccessDialog.barcodeFormat))" class="rotation-reminder">
            <img src="@/assets/images/rotation-hint.png">
            <p>{{ $t('mycouponfullWindowBarcode') }}</p>
          </div>
          <div v-if="scanCodeSuccessDialog.display_code" class="partner-qr-bar-code-code">{{ scanCodeSuccessDialog.barcode }}</div>
        </div>
        <div v-if="scanCodeSuccessDialog.barcode" class="scan-code-success-bar-footer">
          {{ $t('myWalletMerchantScan') }}
        </div>

        <div :class="{'scan-code-success-buttons':true, 'has-survey':false}">
          <div class="scan-code-success-done-button" @click="acquireSuccessDialogNext">{{ dialogNextButtonName }}</div>
        </div>
      </div>
    </el-dialog> -->

    <!-- for copy success-->
    <el-dialog
      title=""
      class="custom-base-dialog stamps-reward-dialog"
      :visible.sync="showUseSuccessDialog"
      :width="`${isMobile ? '316px' : '600px'}`"
      center
      :custom-class="`display-code-dialog ${isMobile ? 'full-dialog' : ''}`"
      :lock-scroll="true"
      :show-close="false"
      :before-close="showAcquireStampSuccessDialog ? acquireStampDone : acquireSuccessDialogNext"
    >
      <!-- <coupon-code-display
        :data="scanCodeSuccessDialog"
        :barcodeOption="barcodeOptions(scanCodeSuccessDialog.barcodeFormat)"
        :hasNext="collectStampSuccessDialog && collectStampSuccessDialog.count > 0"
        @next="acquireSuccessDialogNext"
        @hide="hideAcquireSuccessDialog"
      /> -->

      <agile
        ref="carousel"
        :slides-to-show="1"
        :initial-slide="0"
        class="carousel detail"
        :autoplay="false"
        :nav-buttons="[isScanCodeSuccessDialog, showAcquireSuccessDialog, showAcquireStampSuccessDialog].filter(Boolean).length > 1"
        :dots="[isScanCodeSuccessDialog, showAcquireSuccessDialog, showAcquireStampSuccessDialog].filter(Boolean).length > 1"
        :unagile="[isScanCodeSuccessDialog, showAcquireSuccessDialog, showAcquireStampSuccessDialog].filter(Boolean).length <= 1"
        @after-change="currentSlide=$refs.carousel.getCurrentSlide()"
      >
        <div
          v-if="isScanCodeSuccessDialog"
          class="slide"
        >
          <div class="mission-scan-code-success-dialog-content">

            <coupon-code-display-redeem :coupon-item="scanCodeSuccessDialog" />

            <div
              v-if="scanCodeSuccessDialog.barcode"
              class="scan-code-success-bar-code"
              :class="{'qr-code': participatingItem && participatingItem.coupon_campaign_type_coupon_template.formats==='COUPON_QR_CODE'}"
            >
              <!-- <canvas v-show="scanCodeSuccessDialog.couponCodeFormat==='COUPON_QR_CODE'" id="barcode-qr-canvas" /> -->
              <img v-show="scanCodeSuccessDialog.couponCodeFormat==='COUPON_QR_CODE'" id="barcode-qr-canvas" :src="scanCodeSuccessDialog.qrcodeUrl"/>
              <barcode v-if="scanCodeSuccessDialog.couponCodeFormat!=='COUPON_QR_CODE'" :value="scanCodeSuccessDialog.barcode" :format="scanCodeSuccessDialog.barcodeFormat" :options="barcodeOptions(scanCodeSuccessDialog.barcodeFormat)" />
              <div v-if="scanCodeSuccessDialog.couponCodeFormat!=='COUPON_QR_CODE' && isMissionWideCode((scanCodeSuccessDialog.barcodeFormat))" class="rotation-reminder">
                <img src="@/assets/images/rotation-hint.png">
                <p>{{ $t('mycouponfullWindowBarcode') }}</p>
              </div>
              <div v-if="scanCodeSuccessDialog.display_code" class="partner-qr-bar-code-code">{{ scanCodeSuccessDialog.barcode }}</div>
            </div>
            <div v-if="scanCodeSuccessDialog.barcode" class="scan-code-success-bar-footer">
              {{ $t('myWalletMerchantScan') }}
            </div>

            <div :class="{'scan-code-success-buttons':true, 'has-survey':false}">
              <!-- <div v-if="canSubmitSurvey" class="scan-code-success-take-survey" data-aid="coupon_detail_prompt_take_survey" @click="takeSurvey">{{ $t('myWalletTakeSurvey') }}</div> -->
              <div class="scan-code-success-done-button" @click="acquireSuccessDialogNext">{{ dialogNextButtonName }}</div>
            </div>
          </div>
        </div>
        <div
          v-if="showAcquireSuccessDialog"
          class="slide"
        >
          <!-- <div class="slide-image" :style="{backgroundImage: 'url('+item+')'}" /> -->
          <coupon-code-display
            :data="scanCodeSuccessDialog"
            :barcodeOption="barcodeOptions(scanCodeSuccessDialog.barcodeFormat)"
            :hasNext="collectStampSuccessDialog && collectStampSuccessDialog.count > 0"
            @next="acquireSuccessDialogNext"
            @hide="hideAcquireSuccessDialog"
          />
        </div>
        <div
          v-if="showAcquireStampSuccessDialog"
          class="slide"
        >
          <acquire-stamp-success
            :data="collectStampSuccessDialog"
            @done="acquireStampDone"
          />
        </div>
        <template slot="prevButton"><div v-show="currentSlide === 1" class="action action-prev"><img :data-aid="'offer_detail_image_view'" src="/img/carousel_left.png"></div></template>
        <template slot="nextButton"><div v-show="currentSlide === 0" class="action action-next"><img :data-aid="'offer_detail_image_view'" src="/img/carousel_right.png"></div></template>
      </agile>

      <!-- <coupon-code-display
        :data="test"
        :barcodeOption="barcodeOptions(test.barcodeFormat)"
        :hasNext="false"
        @next="acquireSuccessDialogNext"
        @hide="hideAcquireSuccessDialog"
      /> -->
    </el-dialog>

    <!-- for earned stamp -->
    <!-- <el-dialog
      title=""
      :visible.sync="showAcquireStampSuccessDialog"
      class="stamps-reward-dialog"
      :width="`${isMobile ? '316px' : '600px'}`"
      center
      :custom-class="`display-code-dialog ${isMobile ? 'full-dialog' : ''}`"
      :lock-scroll="true"
      :show-close="false"
    >
      <acquire-stamp-success
        :check-in="collectStampSuccessDialog && collectStampSuccessDialog.checkIn"
        :count="collectStampSuccessDialog && collectStampSuccessDialog.count"
        :rewards="collectStampSuccessDialog && collectStampSuccessDialog.rewards"
        :earned-stamps="collectStampSuccessDialog && collectStampSuccessDialog.earnedStamps"
        :total-stamps="collectStampSuccessDialog && collectStampSuccessDialog.totalStamps"
        :gamification-campaign-id="collectStampSuccessDialog && collectStampSuccessDialog.gamificationCampaignId"
        @done="acquireStampDone"
      />
    </el-dialog> -->

    <!-- desktop use coupon/check-in to mobile -->
    <el-dialog
      title=""
      :visible.sync="showToMobileDetailQRcode"
      class="to-mobile-dialog"
      :width="`${isMobile ? '316px' : '600px'}`"
      center
      :custom-class="''"
      :lock-scroll="true"
      :show-close="false"
    >
      <canvas id="link-qr-canvas" />
      <label>{{ participatingItem && participatingItem.can_use_coupon_id ? verifyDisplayIn(participatingItem.coupon_campaign_type_coupon_template, 'TICKET_SECTION') ? $t('myWalletUserExperienceTicketMessage') : $t('myWalletUserExperienceMessage') : $t('missionTaskCheckInToUseMobile') }}</label>
      <button class="common-button red-bg-button" @click="showToMobileDetailQRcode = false">{{$t('goodiebagOk')}}</button>
    </el-dialog>

    <div id="robot" />
  </div>
</template>

<script>
import VueBarcode from '@chenfengyuan/vue-barcode'
import axios from 'axios'
import stringify from 'json-stable-stringify'
import NotFind from '@/components/NotFind'
import CampaignListSearchBar from '@/components/CampaignListSearchBar'
import SortFilterBar from '@/components/SortFilterBar'
import MissionTaskLoadingItem from '@/components/MissionTaskLoadingItem'
import MissionTaskItem from '@/components/MissionTaskItem'
import SearchCampaignNoResult from '@/components/SearchCampaignNoResult'
import ScanQrcode from '@/components/ScanQrcode'
import CouponCodeDisplay from '@/components/CouponCodeDisplay'
import AcquireStampSuccess from '@/components/AcquireStampSuccess'
import CouponCodeDisplayRedeem from '@/components/CouponCodeDisplayRedeem'
import CustomTooltip from '@/components/CustomTooltip'
import utilsMixin from '@/mixins/utilsMixin'
import missionMixin from '@/mixins/missionMixin'
import backHandlerMixin from '@/mixins/backHandlerMixin'
import sortFilterMixin from '@/mixins/sortFilterMixin'
import { fetchCampaigns, getCampaignlimit } from '@/helpers/http'
import {
  sendPageViewAnalytics,
  sendManualAnalytics,
  sendAdobeManualAnalytics
} from '@/utils/tracking'
import {
  // isDev,
  isWechatMiniProgram,
  getChannelName,
  convertToQueryString,
  pushRouterWithQuery,
  replaceRouterWithQuery,
  setupDefaultQueryString,
  getCampaignAPIPrefix
} from '@/utils/utils'
import { getLangName } from '@/utils/lang'
import { VueAgile } from 'vue-agile'
// import { loginSSO } from '../helpers/http'

export default {
  name: 'MissionDetail',
  components: {
    agile: VueAgile,
    NotFind,
    CampaignListSearchBar,
    SortFilterBar,
    MissionTaskLoadingItem,
    MissionTaskItem,
    SearchCampaignNoResult,
    ScanQrcode,
    barcode: VueBarcode,
    CouponCodeDisplay,
    AcquireStampSuccess,
    CouponCodeDisplayRedeem,
    CustomTooltip
  },
  mixins: [utilsMixin, backHandlerMixin, sortFilterMixin, missionMixin],
  data () {
    return {
      participateDirectId: null,
      isLoading: true,
      missionId: null,
      missionDetailCollected: false,
      isShowStampTips: true,
      gamificationCampaignRecordId: null,
      gamificationCampaignId: null,
      showMissionTips: false,
      customTooltipSlotElement: null,
      customTooltipReferenceElement: null,

      showErrorDialog: false,
      errorDialog: {},
      displayDialogErrorMessage: '',
      isScaningQrcode: false,

      isShowAgeRestricted: false,
      isScanCodeSuccessDialog: false,
      showFullPartnerBarCodeDialogDesktop: false,

      showAcquireSuccessDialog: false,
      scanCodeSuccessDialog: { storeInfo: {}},

      // showAcquireSuccessDialog: true,
      // scanCodeSuccessDialog: {
      //   name: 'HK$100 Cash Voucher at HKTB Boutique',
      //   redeemedTime: '1 Dec 2021 14:05:10 ',
      //   referenceId: '4168-77-14921867',
      //   couponTypeClassName: 'voucher',
      //   couponTypeText: 'CASH VOUCHER',
      //   storeInfo: {
      //     name: 'D-Store-123',
      //     address: 'Address'
      //   },

      //   isShowQrCanvas: false,
      //   couponCodeFormat: 'PARTNER_QR_CODE', // 'COUPON_CODE', 'PARTNER_QR_CODE'， 'PARTNER_BAR_CODE', 'COUPON_URL'
      //   isShowPartnerQRCode: true,
      //   isShowPartnerBarCode: false,
      //   isPartnerCode: true,
      //   isTicket: false,
      //   barcode: 'ABS-abc-1234-ABC-abc-1234',
      //   barcodeFormat: 'code128',
      //   couponCode: '13234456',
      //   // couponCodeUrl: 'https://google.com',
      //   // couponShopNowName: 'ggo',
      //   displayCouponCode: false
      // },

      showAcquireStampSuccessDialog: false,
      collectStampSuccessDialog: null,
      // showAcquireStampSuccessDialog: true,
      // collectStampSuccessDialog: {
      //   // earned: missionData.collected_stamp_rewards_count,
      //   // total: missionData.per_head_limit,
      //   isCheckIn: false,
      //   checkIn: {

      //   },
      //   count: 2,
      //   rewards: [{
      //     index: 2,
      //     name: 'Free Tai Chung Egg Tart (1 piece)',
      //     imageUrl: '/img/reward-collected-icon-default.png',
      //     isCoupon: false
      //   }]
      // },
      showUseSuccessDialog: false,
      showToMobileDetailQRcode: false,
      participatingItem: null,
      code: null,
      latitude: null,
      longitude: null,
      currentSlide: 0,
      isFirstJoinGame: false,

      // for search + filter
      randomSeed: null,
      searchKey: '',
      filters: {
        sorting: 'featured'
      },
      filterName: 'mission-filter',
      filterValue: null,
      filterSubMenu: null,
      brandOptions: [],
      offerTypeOptions: [],
      // filterSideBarShown: false,
      // customFilterOptionLabels: ['location'],
      // titleCustomRefs: { location: 'Location' },
      customAttributeTags: {},
      campaignSettings: {},
      // hasCategoryCarousel: false,
      numberOfVisualizeThreads: 0,
      // serach + filter result
      filterResultCount: {},
      resultCount: null,
      cachedResponse: {},
      cachedQuery: {},
      // for result
      scrollIndex: 0,
      isEndReached: false,
      pageSize: 10,
      inFiltering: true
    }
  },
  computed: {
    isMiniProgram () {
      return isWechatMiniProgram()
    },
    isMobile () {
      return this.$store.getters['app/isMobile']
    },
    isPreview () {
      return this.$route.query.is_preview
    },
    ssoUid () {
      return this.$store.getters['auth/ssoUid']
    },
    isNoData () {
      if (!this.gamificationCampaignId) {
        return false
      }

      let result = false
      const missionData = this.$store.getters['gamification/missionDetail'](this.gamificationCampaignId, this.missionId)
      if (!(missionData && missionData.name)) {
        result = true
      }

      if (result && this.isLoading) {
        result = false
      }
      return result
    },
    missionData () {
      return this.$store.getters['gamification/missionDetail'](this.gamificationCampaignId, this.missionId) || {}
    },
    gamificationData () {
      return this.$store.getters['gamification/gamificationDetail'](this.gamificationCampaignId) || {}
    },
    gamificationCampaignParticipationRecordId () {
      return this.gamificationCampaignRecordId || this.gamificationData.gamification_campaign_participation_record_id || null
    },
    missionIsValid () {
      // if (this.gamificationData?.isValid === false) {
      //   return false
      // }

      // if (this.$store.getters['auth/ssoUid']) {
      //   const {
      //     mobile_phone_number_country_code: countryCode
      //   } = this.$store.getters['auth/userProfile']
      //   if (this.gamificationData?.country_codes?.length > 0 && this.gamificationData?.country_codes.filter((item) => item.id === countryCode).length <= 0) {
      //     return false
      //   }
      // }

      // const now = new Date()
      // if (new Date(this.missionData.start_date) > now || (this.missionData.end_date && new Date(this.missionData.end_date) < now)) {
      //   return false
      // }

      return true
    },
    isCampaign () {
      return this.missionData.mission_type === 'SPECIAL_CAMPAIGN'
    },
    isCheckIn () {
      return this.missionData.mission_type === 'CHECK_IN'
    },
    bannerImage () {
      if (this.isMobile) {
        return this.missionData.mobile_photo_url || this.missionData.cover_photo_url
      }
      return this.missionData.cover_photo_url
    },
    validDate () {
      let result = ''
      const { end_date: endDate } = this.missionData
      if (endDate) {
        result = this.$t('misisonValidTime', { startDate: this.getDateString(this.missionData.startDate), endDate: this.getDateString(endDate) })
      }
      return result
    },
    missionTaskData () {
      if (this.isCampaign) {
        return this.campaigns
      }
      return this.missionLocations
    },
    // filter
    pageNumber () {
      if (this.missionTaskData) {
        // return parseInt(this.missionTaskData.length / this.pageSize, 10) + 1
        return Math.ceil(this.missionTaskData.length / (this.pageSize || 10)) + 1
      }
      return 1
    },
    categories () {
      console.log('???categories', this.$store.getters['gamification/categories'])
      return this.$store.getters['gamification/categories'](this.missionId)
    },
    brands () {
      return this.$store.getters['gamification/brands'](this.missionId)
    },
    offerTypeLabels () {
      return this.$store.getters['gamification/offerTypeLabels'](this.missionId)
    },
    locations () {
      return this.$store.getters['gamification/locations'](this.missionId)
    },
    filterOptions () {
      let sorting = []
      if (this.isCampaign) {
        sorting = sorting.concat([
          { label: this.$t('goodiebagFeatured'), value: 'featured' },
          { label: this.$t('goodiebagDistance'), value: 'distance' },
          { label: this.$t('goodiebagAlphabetical(aToz)'), value: 'english_name', shortLabel: this.$t('goodiebagAlpha...(aToz)') },
          { label: this.$t('goodiebagAlphabetical(zToa)'), value: '-english_name', shortLabel: this.$t('goodiebagAlpha...(zToa)') }
        ])
      } else {
        sorting = sorting.concat([
          { label: this.$t('goodiebagFeatured'), value: 'featured' },
          { label: this.$t('goodiebagDistance'), value: 'distance' },
          { label: this.$t('goodiebagAlphabetical(aToz)'), value: 'location_name', shortLabel: this.$t('goodiebagAlpha...(aToz)') },
          { label: this.$t('goodiebagAlphabetical(zToa)'), value: '-location_name', shortLabel: this.$t('goodiebagAlpha...(zToa)') }
        ])
      }
      if (this.searchKey) {
        sorting = [{ label: this.$t('goodiebagRecommend'), value: 'recommended' }].concat(sorting)
      }

      if (!this.isCampaign) {
        let locationOptions = this.locations
          .filter(item => !['', null, undefined].includes(item.name))
        locationOptions = this.convertLocationFilterOptions(locationOptions).filter(l => l.name === this.$t('goodiebagLocationFilterDistrict'))

        return {
          missionId: this.missionId,
          sorting: sorting,
          searchKey: this.searchKey,
          category: [],
          brand: [],
          offerType: [],
          location: [...new Set(locationOptions)],
          customOptions: [],
          brandIdMaps: [],
          offerTypeLabelIdMaps: [],
          distance: [{ label: '< 1km', value: '0,1000' }, { label: '< 2km', value: '0,2000' }, { label: '< 5km', value: '0,5000' }]
        }
      }
      console.log('@340b-1', this.brands)
      const categoryHideIds = this.getCategoryHideIds()
      const categoryOptions = this.categories
        .filter(item => !['', null, undefined].includes(item.name) && !categoryHideIds?.includes(item.id))
      const brandOptions = this.brands
        .filter(item => !['', null, undefined].includes(item.name))
        .map(item => item.name)
      const offerTypeOptions = this.offerTypeLabels
        .filter(item => !['', null, undefined].includes(item.name))
        .map(item => item.name)
      let locationOptions = this.locations
        .filter(item => !['', null, undefined].includes(item.name))
      locationOptions = this.convertLocationFilterOptions(locationOptions)

      const brandIdMaps = {}
      this.brands
        .filter(item => !['', null, undefined].includes(item.name))
        .forEach(item => {
          brandIdMaps[item.name] = item.id
        })

      const offerTypeLabelIdMaps = {}
      this.offerTypeLabels
        .filter(item => !['', null, undefined].includes(item.name))
        .forEach(item => {
          offerTypeLabelIdMaps[item.name] = item.id
        })

      const customOptions = this.customAttributeTags

      // console.log('@340', customOptions)
      console.log('@340b', this.brands, brandOptions)
      console.log('??? filterOptionLabels--missionoptions: ', {
        missionId: this.missionId,
        sorting: sorting,
        searchKey: this.searchKey,
        category: [...new Set(categoryOptions)],
        brand: [...new Set(brandOptions)],
        offerType: [...new Set(offerTypeOptions)],
        location: [...new Set(locationOptions)],
        customOptions,
        brandIdMaps,
        offerTypeLabelIdMaps
      })
      return {
        missionId: this.missionId,
        sorting: sorting,
        searchKey: this.searchKey,
        category: [...new Set(categoryOptions)],
        brand: [...new Set(brandOptions)],
        offerType: [...new Set(offerTypeOptions)],
        location: [...new Set(locationOptions)],
        customOptions,
        brandIdMaps,
        offerTypeLabelIdMaps
      }
    },
    filterCustomOptionLabels () {
      // if (!this.isCampaign) {
      //   return ['distance']
      // }
      return []
    },
    filterCustomTitleRefs () {
      if (!this.isCampaign) {
        return { 'distance': 'Distance' }
      }
      return {}
    },
    campaigns () {
      return this.$store.getters['gamification/getMissionCampaignList'](this.missionId)
    },
    missionLocations () {
      return this.$store.getters['campaigns/missionLocations']
      // console.log('@@397: ', this.$store.getters['gamification/missionCheckinList'](this.missionId))
      // return this.$store.getters['gamification/missionCheckinList'](this.missionId)
    },
    isInActivePeriod () {
      const now = new Date()
      if (!this.missionData.end_date) {
        return true
      }

      if (now >= new Date(this.missionData.start_date) && now <= new Date(this.missionData.end_date)) {
        return true
      }

      return false
    },
    dialogNextButtonName () {
      if (this.collectStampSuccessDialog?.count > 0) {
        return this.$t('gamificationNext')
      }

      return this.$t('gamificationDone')
    },
    searchSettingType () {
      return this.isCampaign ? 'MISSION_CAMPAIGN' : 'MISSION_CHECK_IN'
    }
    // showCopySuccessDialog () {
    //   return this.showAcquireSuccessDialog || this.showAcquireStampSuccessDialog
    // },
    // showScanSuccessDialog () {
    //   return this.isScanCodeSuccessDialog || this.showAcquireStampSuccessDialog
    // }
  },
  watch: {
    'isMobile': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.scrollIndex = 0
      }
    },
    '$store.state.app.OneTrustHeight': function () {
      if (!this.oneTrustHeight) {
        // if (window.$('.sticky-coupon-footer')[0]) { window.$('.sticky-coupon-footer')[0].style.marginBottom = '0px' }
        // // window.$('.coupon-alert-bar-dialog').css('margin-top', '15vh')
        // window.$('.custom-base-dialog').css('margin-top', '15vh')
        // // window.$('.coupon-alert-bar-dialog .age-declaration-content').css('height', 'auto')
        // // window.$('.full-dialog .el-dialog__footer').css('margin-bottom', (20 + this.oneTrustHeight) + 'px')
      }

      this.adjustSuccessPopupForOneTrust()
    },
    'filters': function (newVal, oldVal) {
      console.log('@486', this.isBackNavigation)
      if (stringify(newVal) !== stringify(oldVal) && !this.isBackNavigation) {
        console.log('@484 -123', oldVal, '\n', newVal)
        console.log('@484', JSON.stringify(oldVal), '\n', JSON.stringify(newVal))
        this.clearMissionTaskData()
        this.isEndReached = false
        if (this.cancelTokenSource) {
          this.cancelTokenSource.cancel()
        }
        if ((newVal.sorting?.indexOf('distance') >= 0 && oldVal?.sorting?.indexOf('distance') < 0) || (newVal.distance)) {
          this.grantGPS(this.grantGpsSuccess, (error) => {
            console.log('---189---', error)
            this.getMissionTaskData(false, true)
            // this.showErrorMessageDialog(this.$t('grantGPSErrorTitle'), this.$t('grantGPSErrorContent'), { isLoadGrantGPSError: true })
          })
        } else {
          this.getMissionTaskData(false, true)
        }
      }
    },
    'searchKey': function (newVal, oldVal) {
      console.log('??? set to store', newVal)
      this.$store.commit('app/setLastMissionCampaignListSearchKeyState', newVal)
      // if (newVal !== oldVal) {

      // }
    },
    'missionData': async function (newVal, oldVal) {
      if (!oldVal.id && newVal.id) {
        if (!this.isBackNavigation) {
          if (this.isCampaign) {
            this.getCustomAttributeTags(null, this.searchSettingType)

            if (!this.categories || this.categories?.length === 0) {
              this.initCategory()
            }
            console.log('@555b-0--', this.isCampaign, this.brands)
            if (!this.brands || this.brands?.length === 0) {
              console.log('@555b-0')
              this.initBrands()
            }

            if (!this.offerTypeLabels || this.offerTypeLabels?.length === 0) {
              this.initOfferTypeLabels()
            }
          }

          if (!this.locations || this.locations?.length === 0) {
            this.initLocations()
          }

          // if (this.isMiniProgram) {
          //   this.getMissionTaskData()
          // } else {

          // this.grantGPS(this.grantGpsSuccess, (error) => {
          //   console.log('---189---', error)
          //   this.getMissionTaskData()
          //   // this.showErrorMessageDialog(this.$t('grantGPSErrorTitle'), this.$t('grantGPSErrorContent'), { isLoadGrantGPSError: true })
          // }, true)
          try {
            let locationPermissionStatus = null
            if (navigator && navigator.permissions) {
              const permissionStatus = await navigator.permissions.query({ name: 'geolocation' })
              console.log('@@950: ', permissionStatus.state)
              locationPermissionStatus = permissionStatus.state
            }

            if ((this.isMiniProgram && !this.isCampaign) || locationPermissionStatus === 'granted') {
              this.grantGPS(this.grantGpsSuccess, (error) => {
                console.log('---189---', error)
                this.getMissionTaskData()
                // this.showErrorMessageDialog(this.$t('grantGPSErrorTitle'), this.$t('grantGPSErrorContent'), { isLoadGrantGPSError: true })
              }, true)
            } else {
              this.getMissionTaskData()
            }
          } catch (err) {
            console.log('@@950: ', err)
            this.getMissionTaskData()
          }

          // }
        }
      }
      // this.customFilterOptionLabels = ['location']
      // this.titleCustomRefs = { location: 'Location' }
      // if (!this.isCampaign) {
      //   // this.customFilterOptionLabels = ['location']
      //   if (!this.isBackNavigation) {
      //     this.grantGPS(this.grantGpsSuccess, (error) => {
      //       console.log('---189---', error)
      //       this.errorDialog.title = 'grant gps'
      //       this.errorDialog.text = this.$t('grantGPSErrorContent')
      //       this.showErrorDialog = true
      //     })
      //   }
      // } else if (!this.isBackNavigation) {
      //   this.getCustomAttributeTags()
      //   this.getMissionTaskData()
      // }
      // }
    },
    'missionTaskData': function (newVal, oldVal) {
      console.log('@@764: ', this.participateDirectId)
      console.log('??? filterOptionLabels--missionTaskData')
      this.removeCookie('__logintask')
      if (newVal?.length > 0 && this.participateDirectId) {
        this.participatingItem = newVal.filter(v => parseInt(v.id) === this.participateDirectId)[0]

        if (this.participatingItem) {
          this.confirmToParticipate()
        } else {
          this.getSingleMissionTask()
        }
      }
    }
  },
  updated () {
    // this.showQrCodeImg('1234', 'partner-qr-code-img')
    // this.disableOverflow()

    // setTimeout(() => {
    //   this.showAcquireSuccessDialog = true
    //   // this.isScanCodeSuccessDialog = true
    //   // this.showAcquireStampSuccessDialog = true
    //   this.showUseSuccessDialog = true
    // }, 3000)

    this.isUpdated = true
    if (this.$store.getters['app/getIsOneTrustDisplay']) {
      if (this.isMiniProgram) {
        window
          .$('.back-to-top-bottom')
          .attr(
            'style',
            'bottom: calc(' +
            (150 + this.oneTrustHeight) +
            'px + var(--sab)) !important'
          )
      } else if (this.$store.getters['app/isMobile']) {
        window
          .$('.back-to-top-bottom')
          .attr(
            'style',
            'bottom: ' + (90 + this.oneTrustHeight) + 'px !important'
          )
      } else {
        window
          .$('.back-to-top-bottom')
          .attr(
            'style',
            'bottom: ' + (20 + this.oneTrustHeight) + 'px !important'
          )
      }
    }

    if (this.$store.getters['app/getIsOneTrustDisplay']) {
      if (window.$('.sticky-coupon-footer-button')[0] && window.$('.sticky-coupon-footer-button')[0].childElementCount) {
        window.$('.sticky-coupon-footer').attr('style', 'margin-bottom: ' + this.oneTrustHeight + 'px')
      }
      // if (this.isMiniProgram()) {
      //   window.$('.back-to-top-bottom').attr('style', 'bottom: calc(' + (150 + this.oneTrustHeight) + 'px + var(--sab)) !important')
      // } else if (this.$store.getters['app/isMobile']) {
      //   window.$('.back-to-top-bottom').attr('style', 'bottom: ' + (90 + this.oneTrustHeight) + 'px !important')
      // } else {
      //   window.$('.back-to-top-bottom').attr('style', 'bottom: ' + (20 + this.oneTrustHeight) + 'px !important')
      // }
    } else if (!this.isMobile) {
      // const atBottom = (window.innerHeight + Math.ceil(window.pageYOffset)) >= document.body.offsetHeight
      // console.log('@@369: ', atBottom)
      if (window.$('.sticky-coupon-footer-button')[0] && window.$('.sticky-coupon-footer-button')[0].childElementCount) {
        window.$('.sticky-coupon-footer').attr('style', 'margin-bottom: ' + 88 + 'px')
      }
    }

    this.adjustSuccessPopupForOneTrust()
  },
  async mounted () {
    this.participateDirectId = this.getCookie('__logintask')
    this.randomSeed = Math.floor(Math.random() * 100000000)

    const { missionId, gamificationCampaignRecordId, gamificationCampaignId, campaignId, couponId } = this.$route.query
    this.missionId = missionId
    this.gamificationCampaignRecordId = gamificationCampaignRecordId
    this.gamificationCampaignId = gamificationCampaignId
    this.filterName = `mission-filter-${this.missionId}`
    console.log('@@1732a: ', this.missionId, this.gamificationCampaignRecordId)

    const plugin = document.createElement('script')
    plugin.setAttribute(
      'src',
      window.location.origin + '/js/html5-qrcode.min.js'
    )
    plugin.async = true
    document.head.appendChild(plugin)

    if (isWechatMiniProgram()) {
      const wechatTokenURL = window.location.href.split('#')[0]
      this.$store.commit('app/setWechatTokenURLWithoutLimit', wechatTokenURL)
    }

    this.$store.commit('app/setNavType', 'back_language_menu')

    // const ssoUid = this.$store.getters['auth/ssoUid']
    // if (!ssoUid) {
    //   loginSSO()
    //   return
    // }

    console.log('@@252-1: ', this.missionData.id)
    if (!this.missionData.id) {
      this.isLoading = true
    }

    try {
      this.$store.dispatch('app/refreshSystemSettings')
    } catch (error) {
      console.log('error--', error)
    }
    this.setCaptcha()

    this.getMissionDetail()
    window.addEventListener('scroll', this.handleScroll, true)

    if (this.isBackNavigation) {
      const { resultCount, isEndReached, isCampaignUpdating,
        customAttributeTags, campaignSettings, searchKey
      } = this.$store.getters['app/getLastMissionCampaignListState']
      this.resultCount = resultCount
      this.isEndReached = isEndReached
      this.inFiltering = isCampaignUpdating
      // this.titleCustomRefs = titleCustomRefs
      // this.customFilterOptionLabels = customFilterOptionLabels
      this.customAttributeTags = customAttributeTags
      this.campaignSettings = campaignSettings
      this.searchKey = searchKey
      console.log('lst search: ', this.$store.getters['app/getLastMissionCampaignListState'])
      const filterValue = this.$store.getters['app/getFilterValue'](this.filterName)
      console.log('@491b, collected filter value', this.filterName, filterValue, searchKey)
      if (filterValue) {
        this.filters = filterValue
      }
    } else {
      this.clearMissionTaskData()
    }

    console.log('@@581: ', this.isBackNavigation)
    if (!this.isBackNavigation) {
      if (this.isCampaign) {
        this.getCustomAttributeTags(null, this.searchSettingType)
      }
      this.getCampaignsSettings()
      // this.getMissionTaskData()
    }

    if (this.$store.getters['app/getIsOneTrustDisplay']) {
      this.adjustSuccessPopupForOneTrust()
    }

    setTimeout(() => { this.$store.commit('app/couponItemLoadingFinish') }, 3000)

    if (this.$route.query.type === 'combine' && campaignId && couponId) {
      try {
        const data = JSON.parse(localStorage.getItem(`missionParticipatingItem-${campaignId}`))
        console.log('parse missionParticipatingItem', data)
        localStorage.removeItem(`missionParticipatingItem-${campaignId}`)
        if (data && data.id) {
          this.participatingItem = data
          this.participatingItem.can_use_coupon_id = couponId
          this.useCoupon(localStorage.getItem('latitude') || null, localStorage.getItem('longitude') || null)
        }
      } catch (error) {
        console.log('parse missionParticipatingItem error', error)
      }
    } else {
      localStorage.removeItem(`missionParticipatingItem-${campaignId}`)
    }
  },
  beforeDestroy () {
    this.$store.commit('app/setNavType', 'logo_language_menu')
    // this.$store.commit('app/setMiniProgramBackTitle', ' mission')

    this.$message.closeAll()
    window.$('.footer-main.has-sticky-footer').css('padding-bottom', '0px')
    this.enableOverflow()

    window.removeEventListener('scroll', this.handleScroll, true)
    this.$store.commit('app/setLastMissionCampaignListState',
      {
        missionId: this.missionId,
        resultCount: this.resultCount,
        isEndReached: this.isEndReached,
        isCampaignUpdating: this.inFiltering,
        titleCustomRefs: this.filterCustomTitleRefs,
        customFilterOptionLabels: this.filterCustomOptionLabels,
        customAttributeTags: this.customAttributeTags,
        campaignSettings: this.campaignSettings,
        searchKey: this.searchKey
      }
    )
  },
  methods: {
    async getMissionDetail () {
      let query = ''
      if (this.gamificationCampaignRecordId) {
        query = `?gamification_campaign_participation_record=${this.gamificationCampaignRecordId}`
      }
      await axios
        .get(`/gamification_missions/${this.missionId}${query}`)
        .then(response => {
          // console.log('response---', response)
          // this.missionData = response.data
          // this.$store.commit('campaigns/setMissionDetail', this.missionData)
          this.$store.commit('gamification/updateMissionList', {
            gamificationCampaignId: this.gamificationCampaignId,
            missionId: this.missionId,
            detail: response.data
          })
          sendPageViewAnalytics(this.$route, this.$i18n.locale, this.$store)
          sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'game|mission listing list view', { gamificationId: this.gamificationCampaignId, missionId: this.missionId })
          setTimeout(() => {
            this.isLoading = false
          }, 1500)
          // sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'mission|detail', { missionId: this.missionId })
        })
        .catch(error => {
          sendPageViewAnalytics(this.$route, this.$i18n.locale, this.$store)
          console.log('371--- errro', error)
          const { data } = error.response || {}
          const { detail } = data || {}
          console.log('371--- errro', error.response, detail)
          if (error.response.status === 404) {
            replaceRouterWithQuery(this.$router, { name: 'page-not-found' })
          }
          // this.couponNotFound = detail === 'Not found.'
          this.isLoading = false
        })
      this.missionDetailCollected = true
    },
    gotoEarnTutorial () {
      pushRouterWithQuery(this.$router, { path: 'earn-tutorial', query: { missionId: this.missionId, gamificationCampaignId: this.gamificationCampaignId }})
    },
    toggleStampTips () {
      this.isShowStampTips = !this.isShowStampTips
    },
    toShowCustomTooltips (show) {
      this.customTooltipSlotElement = { queryName: '.question-mark-icon', index: 0 }
      this.customTooltipReferenceElement = { queryName: '.mission-expiration', index: 0 }
      if (show) {
        this.showMissionTips = true
      } else {
        this.showMissionTips = false
      }
    },
    goToMap () {
      console.log('goToMap')
    },
    // init filter conditions
    async getCampaignsSettings () {
      const payload = {}
      const response = await axios.get(`/campaigns/settings`, payload)
      if (response && response.data) {
        // this.campaignSettings = { ...response.data }

        this.campaignSettings = {
          'category': response.data.show_campaign_category_filter,
          'brand': response.data.show_brand_filter,
          'offerType': response.data.show_offer_type_label_filter,
          'location': response.data.show_location_filter,
          'outOfStock': response.data.hide_out_of_stock_campaign
        }
      }
    },
    getDefaultInitQueryString () {
      if (!this.isCampaign) {
        const queryString = convertToQueryString({ mission: this.missionId })
        return queryString
      }
      const params = this.getQueryParams(null, this.searchSettingType)
      delete params.is_redirection_campaign

      const queryString = convertToQueryString({ ...params, sort: 'display_priority', mission: this.missionId })
      return queryString
    },
    async initCategory () {
      try {
        // const queryString = convertToQueryString({ ...params, sort: 'display_priority' })
        // const response = await axios.get(`/v2/campaign_categories?${queryString}`)
        const response = await axios.get(`/v2/campaign_categories?${this.getDefaultInitQueryString()}`)
        // console.log('@555', response.data)
        this.$store.commit('gamification/initCategories', { [this.missionId]: response.data })
      } catch (error) {
        console.log('error', error)
      }
    },
    async initBrands () {
      try {
        const response = await axios.get(`/v2/brands?${this.getDefaultInitQueryString()}`)
        this.$store.commit('gamification/initBrands', { [this.missionId]: response.data })
        console.log('@555b', response.data)
      } catch (error) {
        console.log('555b-error', error)
      }
    },
    async initOfferTypeLabels () {
      try {
        const response = await axios.get(`/v2/offer_type_labels?${this.getDefaultInitQueryString()}`)
        this.$store.commit('gamification/initOfferTypeLabels', { [this.missionId]: response.data })
        // console.log('@555c', response.data)
      } catch (error) {
        console.log('error', error)
      }
    },
    async initLocations () {
      try {
        const response = await axios.get(`/v2/locations?${this.getDefaultInitQueryString()}`)
        console.log('@555d', response.data)
        this.$store.commit('gamification/initLocations', { [this.missionId]: response.data })
      } catch (error) {
        console.log('error', error)
      }
    },
    // filter related methods
    inputChanged (filterValue) {
      console.log('@491@478pp', this.filterName, JSON.stringify(filterValue))
      this.$store.commit('app/setFilterValue', { filterName: this.filterName, filterValue })

      this.updateFilteredResultCount({ subMenu: 'location', filterValue: filterValue }, true)
    },
    isShowVisualizeFilter (isShow) {
    },
    // toggleFilterSideBar (shown) {
    //   this.filterSideBarShown = shown
    // },
    // filter result
    handleScroll () {
      const htmlEl = window.$('html')
      const bodyEl = window.$('body')
      if (htmlEl.hasClass('no-scroll')) {
        return
      }

      const scrollTopFromTop = htmlEl.scrollTop() || bodyEl.scrollTop()

      var bottom = htmlEl.height() - (scrollTopFromTop + window.$(window).height())
      if (bottom < 400 && !this.inFiltering) {
        console.log('@@294---0-sc')
        this.getMissionTaskData()
      }

      // const scrollTop = (window.innerHeight + Math.ceil(window.pageYOffset))
      // const pageHeight = document.body.offsetHeight

      // const atBottom = scrollTop >= pageHeight
      // const offsetBottom = pageHeight - scrollTop
      // // console.log('@@369: ', atBottom)
      // if (this.$store.getters['app/getIsOneTrustDisplay']) {
      //   // const atBottom = (scrollTop + 1) === Math.ceil(pageHeight + this.oneTrustHeight)
      //   // const offsetBottom = pageHeight + this.oneTrustHeight - scrollTop

      //   let stickyMarginBottom = this.oneTrustHeight
      //   // console.log('@@431-----: ', parseInt(this.oneTrustHeight))
      //   if (atBottom) {
      //     if (0 - offsetBottom < parseInt(this.oneTrustHeight)) {
      //       stickyMarginBottom = this.oneTrustHeight - offsetBottom
      //     } else {
      //       stickyMarginBottom = 88 + this.oneTrustHeight
      //     }
      //   } else if (offsetBottom < (88) && offsetBottom > this.oneTrustHeight) {
      //     stickyMarginBottom = (88 + this.oneTrustHeight) - offsetBottom
      //   }

      //   if (window.$('.sticky-coupon-footer-button')[0] && window.$('.sticky-coupon-footer-button')[0].childElementCount) {
      //     window.$('.sticky-coupon-footer').attr('style', 'margin-bottom: ' + stickyMarginBottom + 'px')
      //   }
      // // if (this.isMiniProgram()) {
      // //   window.$('.back-to-top-bottom').attr('style', 'bottom: calc(' + (150 + this.oneTrustHeight) + 'px + var(--sab)) !important')
      // // } else if (this.$store.getters['app/isMobile']) {
      // //   window.$('.back-to-top-bottom').attr('style', 'bottom: ' + (90 + this.oneTrustHeight) + 'px !important')
      // // } else {
      // //   window.$('.back-to-top-bottom').attr('style', 'bottom: ' + (20 + this.oneTrustHeight) + 'px !important')
      // // }
      // } else if (!this.isMobile) {
      //   let stickyMarginBottom = 0
      //   if (atBottom) {
      //     stickyMarginBottom = 88
      //   } else if (offsetBottom < 88 && offsetBottom > 0) {
      //     stickyMarginBottom = 88 - offsetBottom
      //   }

      //   if (window.$('.sticky-coupon-footer-button')[0] && window.$('.sticky-coupon-footer-button')[0].childElementCount) {
      //     window.$('.sticky-coupon-footer').attr('style', 'margin-bottom: ' + stickyMarginBottom + 'px')
      //   }
      // } else {
      //   if (isWechatMiniProgram()) {
      //     const stickyMarginBottom = 66
      //     if (window.$('.sticky-coupon-footer-button')[0] && window.$('.sticky-coupon-footer-button')[0].childElementCount) {
      //       window.$('.sticky-coupon-footer').attr('style', 'margin-bottom: ' + stickyMarginBottom + 'px')
      //     }
      //   } else {
      //     const footerHeight = document.getElementsByClassName('footer-container')[0].clientHeight
      //     let stickyMarginBottom = 0
      //     if (atBottom) {
      //       stickyMarginBottom = footerHeight
      //     } else if (offsetBottom < footerHeight && offsetBottom > 0) {
      //       stickyMarginBottom = footerHeight - offsetBottom
      //     }

      //     if (window.$('.sticky-coupon-footer-button')[0] && window.$('.sticky-coupon-footer-button')[0].childElementCount) {
      //       window.$('.sticky-coupon-footer').attr('style', 'margin-bottom: ' + stickyMarginBottom + 'px')
      //     }
      //   }
      // }
    },
    adjustSuccessPopupForOneTrust () {
      console.log('@2694, adjust for onetrust')
      // window.$('.el-dialog.full-dialog .scan-code-success-buttons').css('margin-bottom', this.oneTrustHeight + 'px')
      // window.$('.el-dialog.full-dialog .scan-code-success-dialog-content').css('margin-bottom', this.oneTrustHeight + 'px')
      // window.$('.barcode-full-screen-content canvas').css('margin-bottom', this.oneTrustHeight + 'px')
      // window.$('#qr-code-full-region').css('bottom', (this.oneTrustHeight - 15) + 'px')
      // this.adjustBasePopupForOneTrust()
    },
    async getSingleMissionTask () {
      try {
        let response = null
        if (this.isCampaign) {
          response = await axios.get(`/gamification_mission/campaigns/${this.participateDirectId}`)
        } else {
          response = await axios.get(`/gamification_mission/check_in/${this.participateDirectId}`)
        }

        if (response) {
          this.participatingItem = response.data
          this.confirmToParticipate()
        }
      } catch (e) {
        console.log('getSingleMissionTask error: ', e)
      }
    },
    async fetchMissionLocations (params, sorting = null, cancelToken = null) {
      sorting = sorting || '-id'

      const queryString = new URLSearchParams(params).toString()
      console.log('queryString', queryString, sorting)

      let results = []
      let count = 0
      let status = 200
      const requestConfig = { timeout: 60000 }
      if (cancelToken) {
        requestConfig.cancelToken = cancelToken
      }
      try {
        const url = `/v2/gamification_mission/check_in?${queryString}&check_link=true`;

        ({ data: { count, results }} = await axios.get(url, requestConfig))
      } catch (error) {
        console.log('error', error)
        status = error.response.status
      }

      return { count, results, status }
    },
    getAdobeFilterValue () {
      let gameFilterLevel1 = ''
      const filterValue = this.filters
      const { brandIdMaps, offerTypeLabelIdMaps } = this.filterOptions
      console.log('@@1381: ', filterValue)
      if (filterValue.subMenu === 'location') {
        let goodie_filter_level1 = ''
        let level1Count = 0
        if (filterValue.location.L_AREA?.length) {
          level1Count = level1Count + filterValue.location.L_AREA?.length
          filterValue.location.L_AREA.sort(function (a, b) { return a - b }).forEach((item, index) => {
            if (index === 0) {
              goodie_filter_level1 += `A:${item}`
            } else {
              goodie_filter_level1 += `+A:${item}`
            }
          })
        }
        if (filterValue.location.L_DISTRICT?.length) {
          level1Count = level1Count + filterValue.location.L_DISTRICT?.length
          if (goodie_filter_level1) {
            goodie_filter_level1 += ','
          }
          filterValue.location.L_DISTRICT.sort(function (a, b) { return a - b }).forEach((item, index) => {
            if (index === 0) {
              goodie_filter_level1 += `D:${item}`
            } else {
              goodie_filter_level1 += `+D:${item}`
            }
          })
        }
        console.log('location-level1Count: ', level1Count)
        gameFilterLevel1 = goodie_filter_level1
        // sendAdobeManualAnalytics(
        //   this.$route,
        //   this.$i18n.locale,
        //   this.$store,
        //   level1Count === 1 ? 'deals|filter location|single' : level1Count === 0 ? 'deals|filter location|clear' : 'deals|filter location|multi',
        //   { 'filter': { 'deals_filter_level1': goodie_filter_level1, 'deals_result': `${count}` }}
        // )
      } else if (filterValue.subMenu === 'category' && filterValue.selectedCategory) {
        // if (filterValue.selectedCategory.id !== 0) {
        //   sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'deals|filter category|single', { 'filter': { 'deals_filter_level1': `C:${filterValue.selectedCategory.id}`, 'deals_result': `${count}` }})
        // } else {
        //   sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'deals|filter category|all', { 'filter': { 'goodie_filter_level1': '', 'goodie_result': `${count}` }})
        // }
      } else {
        let goodie_filter_level1 = ''
        let brandIds = []
        const categoryIds = []
        const tagIds = []
        let offerTypeLabelIds = []
        for (const [key, value] of Object.entries(filterValue)) {
          if (key === 'brand') {
            if (filterValue.brand?.length) {
              if (goodie_filter_level1) {
                goodie_filter_level1 += ','
              }
              brandIds = filterValue.brand.map((item) => brandIdMaps[item] || '')
            }
            continue
          } else if (key === 'category') {
            if (filterValue.category) {
              for (const [categoryId, itemTagIds] of Object.entries(filterValue.category)) {
                categoryIds.push(categoryId)
                if (itemTagIds && itemTagIds.length) {
                  tagIds.push(...itemTagIds)
                }
              }
            }
            continue
          } else if (key === 'offerType') {
            if (value && value.length) {
              offerTypeLabelIds = value.map((item) => offerTypeLabelIdMaps[item] || '')
            }
            continue
          } else {
            if (['creditRange', 'categorySelectedIndex', 'location', 'noExpiryDate', 'offerType', 'onlySortChange', 'searchKey', 'selectedCategory', 'subMenu', 'visualize'].includes(key)) {
              continue
            }
            if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
              tagIds.push(...Object.keys(value).map((item) => parseInt(item)))
            }
          }
        }
        let level1Count = 0
        if (brandIds && brandIds.length) {
          level1Count = level1Count + brandIds.length
          goodie_filter_level1 += brandIds.sort(function (a, b) { return a - b }).map((item) => `B:${item}`).join('+')
        }
        if (categoryIds && categoryIds.length) {
          level1Count = level1Count + categoryIds.length
          if (goodie_filter_level1) {
            goodie_filter_level1 += ','
          }
          goodie_filter_level1 += categoryIds.sort(function (a, b) { return a - b }).map((item) => `C:${item}`).join('+')
        }
        if (offerTypeLabelIds && offerTypeLabelIds.length) {
          level1Count = level1Count + offerTypeLabelIds.length
          if (goodie_filter_level1) {
            goodie_filter_level1 += ','
          }
          goodie_filter_level1 += offerTypeLabelIds.sort(function (a, b) { return a - b }).map((item) => `O:${item}`).join('+')
        }
        if (tagIds && tagIds.length) {
          level1Count = level1Count + tagIds.length
          if (goodie_filter_level1) {
            goodie_filter_level1 += ','
          }
          goodie_filter_level1 += tagIds.sort(function (a, b) { return a - b }).map((item) => `T:${item}`).join('+')
        }
        console.log('filter-all-level1Count: ', level1Count)
        // sendAdobeManualAnalytics(
        //   this.$route,
        //   this.$i18n.locale,
        //   this.$store,
        //   level1Count === 1 ? 'deals|filters|single' : level1Count === 0 ? 'deals|filters|clear' : 'deals|filters|multi',
        //   { 'filter': { 'deals_filter_level1': goodie_filter_level1, 'deals_result': `${count}` }}
        // )
        gameFilterLevel1 = goodie_filter_level1
      }
      return gameFilterLevel1
    },
    async getMissionTaskData (changeKeyword = null, isFilter = null) {
      if (this.isEndReached) {
        console.log('@547x111---end reached')
        return
      }
      this.inFiltering = true

      if (changeKeyword) {
        sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'gamification_campaign_mission_search', 'event', { mission_task_searchkey: this.searchKey })
      }
      let gameFilterLevel1 = ''
      if (isFilter) {
        gameFilterLevel1 = this.getAdobeFilterValue()
        sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'gamification_campaign_mission_filter', 'event', { mission_task_filter: gameFilterLevel1 })
      }

      let { sorting } = this.filters
      sorting = sorting || 'featured'

      let sort = sorting
      if (sorting === 'recommended') {
        sort = ['search_order', '-id']
      } else if (sorting === 'featured') {
        if (this.isCampaign) {
          sort = ['-is_coupon_active', 'new_is_expired', 'is_completed', 'new_is_out_of_stock', '-new_is_active', 'new_is_not_reach_active', '-is_featured', 'random', '-id']
        } else {
          sort = ['is_completed', '-is_featured', 'random', '-id']
        }
      } else if (sorting === 'distance') {
        if (this.isCampaign) {
          if (this.latitude && this.longitude) {
            sort = ['-is_coupon_active', 'new_is_expired', 'is_completed', 'new_is_out_of_stock', '-new_is_active', 'new_is_not_reach_active', '-is_featured', 'distance', '-id']
          } else {
            sort = ['-is_coupon_active', 'new_is_expired', 'is_completed', 'new_is_out_of_stock', '-new_is_active', 'new_is_not_reach_active', '-is_featured', '-id']
          }
        } else {
          sort = ['is_completed', 'distance', '-id']
        }
      } else if (sorting === 'english_name' || sorting === 'location_name') {
        if (this.isCampaign) {
          sort = ['-is_coupon_active', 'new_is_expired', 'is_completed', 'new_is_out_of_stock', '-new_is_active', 'new_is_not_reach_active', '-is_featured', 'english_name', '-id']
        } else {
          sort = ['is_completed', 'location_name', '-id']
        }
      } else if (sorting === '-english_name' || sorting === '-location_name') {
        if (this.isCampaign) {
          sort = ['-is_coupon_active', 'new_is_expired', 'is_completed', 'new_is_out_of_stock', '-new_is_active', 'new_is_not_reach_active', '-is_featured', '-english_name', '-id']
        } else {
          sort = ['is_completed', '-location_name', '-id']
        }
      }

      console.log('@654', this.filters)
      const params = {
        ...this.getQueryParams(this.filters, this.searchSettingType),
        mission: this.missionId,
        lang: this.$i18n.locale,
        'page[number]': this.pageNumber,
        'page[size]': this.pageSize,
        // search_campaign: this.searchKey ? this.searchKey.toLowerCase() : '',
        sort: sort
      }

      if (this.searchKey) {
        if (this.isCampaign) {
          params.search_campaign = this.searchKey.toLowerCase()
          params.search_setting_type = 'MISSION_CAMPAIGN'
        } else {
          params.search_location = this.searchKey.toLowerCase()
          params.search_setting_type = 'MISSION_CHECK_IN'
        }
      }

      let paramsSorting = params.sort
      console.log('@@1568: ', paramsSorting)
      if (Array.isArray(paramsSorting)) {
        paramsSorting = paramsSorting.join(',')
      }

      if (['featured'].includes(sorting)) {
        params.seed = this.randomSeed || 10
      }
      if (this.latitude && this.longitude && (!this.isCampaign || sorting === 'distance')) {
        params.latitude = this.latitude
        params.longitude = this.longitude
      }

      const cancelTokenSource = axios.CancelToken.source()

      let results = []
      let count = 0
      let status = 200
      let loadMoreNoResult = false
      try {
        if (this.isCampaign) {
          ({ count, results, status } = await fetchCampaigns(params, 'sortbyapi', cancelTokenSource.token))
          // const response = await fetchCampaigns(params, 'sortbyapi', cancelTokenSource.token)
          // count = response.data.count
          // results = response.data.results
          // console.log('1114-----', response?.status)
        } else {
          ({ count, results, status } = await this.fetchMissionLocations(params, sorting, cancelTokenSource.token))
        }
        loadMoreNoResult = status === 404
      } catch (error) {
        console.log('@@294---', error)
        // loadMoreNoResult = this.pageNumber > 1
      }

      console.log('@478p, campaigns results', results)

      this.cancelTokenSource = null
      console.log('@@294---2', count, loadMoreNoResult, results?.length < this.pageSize)
      if (count) {
        this.resultCount = count
        this.isEndReached = results?.length < this.pageSize

        console.log('@@294---1', this.isCampaign)
        if (this.isCampaign) {
          // const storeCampaigns = this.$store.getters['campaigns/campaigns'] || []
          // // console.log('@@294---0', [...storeCampaigns, ...results].length)
          // this.$store.commit('campaigns/initCampaigns', [...storeCampaigns, ...results])
          let list = results
          if (this.pageNumber > 1) {
            const storeCampaigns = this.$store.getters['gamification/getMissionCampaignList'](this.missionId) || []
            list = [...storeCampaigns, ...results]
          }
          console.log('@@294---0', this.pageNumber, list.length, results?.length, results)
          this.$store.commit('gamification/initMissionCampaignList', { missionId: this.missionId, list })
        } else {
          let list = results
          if (this.pageNumber > 1) {
            const storeMissionLocations = this.$store.getters['campaigns/missionLocations'] || []
            list = [...storeMissionLocations, ...results]
          }

          this.$store.commit(
            'campaigns/initMissionLocations',
            list
            // [...storeMissionLocations, ...results.map((item) => { return { ...item.location, mission_location_id: item.id, distance: item.distance } })]
          )
        }
      } else {
        if (this.pageNumber <= 1) {
          if (this.isCampaign) {
            // const storeCampaigns = this.$store.getters['campaigns/campaigns'] || []
            // // console.log('@@294---0', [...storeCampaigns, ...results].length)
            // this.$store.commit('campaigns/initCampaigns', [...storeCampaigns, ...results])
            this.$store.commit('gamification/initMissionCampaignList', { missionId: this.missionId, list: [] })
          } else {
            this.$store.commit('campaigns/initMissionLocations', [])
          }

          if (this.searchKey) {
            this.resultCount = 0
          }
        }

        if (this.pageNumber <= 1 || loadMoreNoResult) {
          this.isEndReached = true
        }
      }
      this.inFiltering = false
      let aaEventName = ''
      if (changeKeyword) {
        aaEventName = 'game|search'
        sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'gamification_campaign_mission_search_result', 'event', { mission_task_search_result: count })
      }
      if (isFilter) {
        aaEventName = 'game|filter'
      }

      if (aaEventName) {
        const aaEventExtra = {
          gamificationId: this.gamificationCampaignId,
          missionId: this.missionId
        }
        if (this.searchKey || changeKeyword) {
          aaEventExtra.search = {
            'game_search_term': this.searchKey,
            'game_search_result': count?.toString() || '0'
          }
        }
        if (gameFilterLevel1 || isFilter) {
          aaEventExtra.filter = {
            'game_filter_level1': gameFilterLevel1,
            'game_filter_result': count?.toString() || '0'
          }
        }
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, aaEventName, aaEventExtra)
      }
    },
    async updateFilteredResultCount (params) {
      const { filterValue, subMenu } = params
      console.log('@1073@478j', subMenu, filterValue)

      if (!['category', 'brand', 'offerType', 'location', 'searchKey'].includes(subMenu)) {
        // this.filterResultCount = {}
        return
      }
      const { [subMenu]: options } = this.filterOptions
      const resultCount = {}
      // console.log('@704a@478', JSON.stringify(options))
      /*
      if (subMenu === 'location') {
        resultCount[LOCATION_AREA_ID] = { 'all': 1 }
        resultCount[LOCATION_DISTRICT_ID] = { 'all': 1 }
      }
      */

      if (subMenu === 'category' || subMenu === 'location') {
        options.forEach(category => {
          const result = { 'all': 1 }
          let { attribute_tags: attributeTags } = category
          attributeTags = attributeTags || []

          attributeTags.map(tag => { result[tag.id] = 1 })
          console.log('@478xx', attributeTags, result)
          resultCount[category.id] = result
        })
      } else {
        options.forEach(option => {
          resultCount[option] = 1
        })
      }

      console.log('@478pp, filterResultCount', JSON.stringify(resultCount))
      this.filterResultCount = { ...this.filterResultCount, [subMenu]: resultCount }
      console.log('@1073@478j filterResultCount', this.filterResultCount)

      if (this.filterCancelTokenSource) {
        this.filterCancelTokenSource.cancel()
      }
      const filters = { ...filterValue }
      delete filters[subMenu]
      console.log('@1073@478j filters', filters)

      const queryParams = this.getQueryParams(filters, this.searchSettingType)
      console.log('@673', queryParams)
      /*
      if (subMenu !== 'location' && (!queryParams || Object.keys(queryParams).length === 0)) {
        return
      }
      */

      const fetchedResultCount = {}

      if (subMenu !== 'location' && !queryParams) {
        return
      }

      let data = []

      if (this.cachedResponse[subMenu] &&
        this.cachedQuery[subMenu] === stringify(queryParams)) {
        console.log('@861, cache hit')
        data = this.cachedResponse[subMenu]
      } else {
        this.cachedQuery[subMenu] = stringify(queryParams)
        const params = {
          sort: 'display_priority',
          'page[number]': 1,
          'channel': getChannelName(),
          ...queryParams
        }

        if (subMenu === 'category') {
          delete params.is_redirection_campaign
        }

        const queryString = new URLSearchParams(params).toString()

        // let data = []
        const urlRefs = {
          category: '/v2/campaign_categories',
          brand: '/v2/brands',
          offerType: '/v2/offer_type_labels',
          location: '/v2/locations'
        }
        const { [subMenu]: urlPath } = urlRefs
        console.log('@478@urlRefs', urlRefs, urlPath, queryString)
        const filterCancelTokenSource = axios.CancelToken.source()
        try {
          const url = `${urlPath}?${queryString}`;
          ({ data } = await axios.get(url, { timeout: 5000, cancelToken: filterCancelTokenSource.token }))
        } catch (error) {
          console.log('@478, error', urlPath, error)
        }

        console.log('@478@790', urlPath, queryString, data, this.filterResultCount)
      }

      if (subMenu === 'category') {
        data.forEach(category => {
          const tagsResultCount = { 'all': category.number_of_campaigns || category.number_of_mission_locations || 0 }
          let { attribute_tags: attributeTags } = category
          attributeTags = attributeTags || []
          attributeTags.forEach(({ id: tagId, number_of_campaigns: count }) => { tagsResultCount[tagId] = count })
          fetchedResultCount[category.id] = tagsResultCount
        })
      }

      if (data && data.length) {
        this.cachedResponse[subMenu] = [...data]
      }

      if (subMenu === 'location') {
        Object.assign(fetchedResultCount,
          this.convertLocationResultCount(data, filterValue))

        this.removeHiddenLocationDistricts(filterValue)
        console.log('@815', filterValue)
      } else {
        data.forEach(option => {
          // console.log('@758', option.id, option.name, option.number_of_campaigns)
          fetchedResultCount[option.name] = (option.number_of_campaigns || 0) + (option.number_of_mission_locations || 0)
        })
      }

      this.filterCancelTokenSource = null
      this.filterResultCount = { ...this.filterResultCount, [subMenu]: fetchedResultCount }

      console.log('@478y, filterResultCount@b', JSON.stringify(this.filterResultCount))
    },
    clearMissionTaskData () {
      // if (!this.isBackNavigation) {
      // }

      if (this.isCampaign) {
        this.$store.commit('gamification/clearMisisonCampaignList', this.missionId)
      } else {
        this.$store.commit('campaigns/clearMissionLocations')
        // this.$store.commit('gamification/clearMisisonCheckinList')
      }
    },
    gotoTaskDetail (item) {
      let query = { missionId: this.missionId }
      if (this.gamificationCampaignRecordId) {
        query.gamificationCampaignRecordId = this.gamificationCampaignRecordId
      }
      if (this.gamificationCampaignId) {
        query.gamificationId = this.gamificationCampaignId
      }

      const routeQuery = this.$route.query
      if (routeQuery.sign) {
        query = {
          ...query,
          ...(routeQuery)
        }
        delete query.gamificationCampaignId
      }
      if (this.isCampaign) {
        if (item.can_use_coupon_id) {
          pushRouterWithQuery(this.$router, { path: 'coupon-detail', query: { couponId: item.can_use_coupon_id, ...query }})
        } else {
          // this.$store.commit('app/setAfterLoginDoMissionTask', item.id)
          pushRouterWithQuery(this.$router, { path: 'campaign-detail', query: { campaignId: item.id, ...query }})
        }
      } else {
        query = { ...query, locationId: item.id }
        pushRouterWithQuery(this.$router, { path: 'mission-location-detail', query })
      }
    },
    closeScanQrcode () {
      this.isScaningQrcode = false
      this.participateDirectId = null
      this.$refs.missionTaskTarget[this.participatingItem.targetIndex].earnDone()
    },
    hideQrCodeImg () {
      this.isShowQrCanvas = false
    },
    acquireSuccessDialogNext () {
      if (this.collectStampSuccessDialog?.count) {
        this.$refs.carousel.goToNext()
        console.log('@@1487: ', this.collectStampSuccessDialog)
        this.showAcquireStampSuccessDialog = true
      } else {
        this.showUseSuccessDialog = false
        this.showAcquireSuccessDialog = false
        this.isScanCodeSuccessDialog = false

        this.$refs.missionTaskTarget[this.participatingItem.targetIndex].earnDone()

        this.removeBodyScrollingWhenModalOpen(false)

        this.enableOverflow()
      }
    },
    updateMissionCollectedStamps () {
      this.participatingItem.can_use_coupon_id = null // need response coupon id
      // if (vm.isGoodieBagVoucher || this.isGoodieBagGift) {
      //   vm.showGoodieBagSuccess(vm)
      // } else {
      this.$store.commit('gamification/updateMissionCampaignList', {
        missionId: this.missionId,
        campaign: this.participatingItem
      })

      this.missionData.collected_stamp_rewards_count = this.missionData.collected_stamp_rewards_count + (this.collectStampSuccessDialog?.count || 0)
      this.$store.commit('gamification/updateMissionList', {
        gamificationCampaignId: this.gamificationCampaignId,
        detail: this.missionData
      })
    },
    updateMissionTaskErrorMessage (error) {
      if (this.isCampaign) {
        if (error.couponData?.effective_date) {
          this.participatingItem.coupon.effective_date = error.couponData?.effective_date
        } else {
          this.participatingItem.error_message = error.displayErrorMessage || error.display_error_content.detail
        }
        if (this.participatingItem.error_message) {
          this.participatingItem.is_valid = false
        }
        // this.participatingItem.can_use_coupon_id = null // need response coupon id
        // if (vm.isGoodieBagVoucher || this.isGoodieBagGift) {
        //   vm.showGoodieBagSuccess(vm)
        // } else {
        console.log('@@updateMissionTaskErrorMessage: ', this.participatingItem)
        this.$store.commit('gamification/updateMissionCampaignList', {
          missionId: this.missionId,
          campaign: this.participatingItem
        })
      }
    },
    showErrorMessageDialog (title, text, others) {
      this.errorDialog = {
        title,
        text,
        ...(others || {})
      }
      // this.updateMissionTaskErrorMessage(error.response.data)

      this.showErrorDialog = true

      this.participateDirectId = null
    },
    hideAcquireSuccessDialog () {
      this.showAcquireSuccessDialog = false
      this.showUseSuccessDialog = false
      this.$refs.missionTaskTarget[this.participatingItem.targetIndex].earnDone()
      this.enableOverflow()
    },
    acquireStampDone () {
      this.showAcquireSuccessDialog = false
      this.isScanCodeSuccessDialog = false
      this.showAcquireStampSuccessDialog = false
      this.showUseSuccessDialog = false

      if (this.currentSlide === 1) {
        this.$refs.carousel.goToNext()
      }
      this.collectStampSuccessDialog = null
      this.$refs.missionTaskTarget[this.participatingItem.targetIndex].earnDone()

      this.removeBodyScrollingWhenModalOpen(false)

      this.enableOverflow()
    },
    async ajaxUseCoupon (code = null) {
      // this.setCaptcha()
      const {
        recaptchaString,
        errorData
      } = await this.getRecaptchaString(this.isVerifyRecaptcha, true)
      if (errorData.errorContent) {
        this.errorDialog.title = errorData.errorTitle
        this.errorDialog.text = errorData.errorContent
        this.showErrorDialog = true

        sendManualAnalytics(
          this.$route,
          this.$i18n.locale,
          this.$store,
          'gamification_campaign_mission_task_fail',
          'event',
          {
            task_id: this.participatingItem.can_use_coupon_id,
            selected_campaign_id: this.participatingItem.id,
            selected_campaign_name: this.participatingItem.name,
            mission_task_fail_reason: errorData.errorContent
          }
        )
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon|use coupon fail', {
          couponId: this.participatingItem.can_use_coupon_id,
          missionId: this.missionId,
          gamificationId: this.gamificationCampaignId,
          campaignId: this.participatingItem.id,
          stamps: 0,
          useSpecialStore: true,
          errorMessage: errorData.errorContent
        })
        return
      }
      const payload = {
        recaptcha_verify_credential: recaptchaString,
        check_in_latitude: this.latitude,
        check_in_longitude: this.longitude
      }
      if (code) {
        payload.store_qr_code = code
      }
      const query = this.isGamificationPrize ? `?is_gamification=true` : ''
      // alert(JSON.stringify(payload))
      try {
        const response = await axios.post(`/coupons/${this.participatingItem.can_use_coupon_id}/usage_record${query}`, payload)
        const { data } = response
        const { coupon } = data

        console.log('response', response, data, coupon)
        this.$store.commit('coupons/updateCoupon', coupon)
        // alert('earn stamp success from check in')
        this.$refs.scanTarget.close()

        this.setCollectStampSuccessDialog(false, data)

        let couponCampaignLabel = ''
        if (coupon.campaign.is_starred) {
          couponCampaignLabel = this.$t('campaignSTARRED')
        }

        let couponTypeClassName = ''
        let couponTypeText = this.$t('myWalletCoupons')
        if (this.verifyDisplayIn(coupon.template, 'LIMITED_OFFER_SECTION')) {
          couponTypeClassName = 'limited-offer'
          couponTypeText = this.$t('goodiebagLimitedOffers')
        } else if (this.verifyDisplayIn(coupon.template, 'VOUCHER_SECTION')) {
          couponTypeClassName = 'voucher'
          couponTypeText = this.$t('goodiebagCashVouchers')
        } else if (this.verifyDisplayIn(coupon.template, 'TICKET_SECTION')) {
          couponTypeClassName = 'ticket'
          couponTypeText = this.$t('myWalletTickets')
        }

        if (this.participatingItem.coupon_campaign_type_coupon_template.redemption_method === 'MERCHANT_APP_SCAN_COUPON_QR_CODE') {
        // show qrcode prompt
        } else if (this.participatingItem.coupon_campaign_type_coupon_template.redemption_method === 'USERS_SCAN_STORE_QR_CODE') {
          // scan
          this.scanCodeSuccessDialog = {
            name: coupon.template.name || coupon.template.english_name,
            showColorIndicator: coupon.template.show_color_indicator,
            indicatorColor: coupon.template.indicator_color,
            indicatorText: coupon.template.indicator_text,
            redeemedTime: this.getDateString(coupon.used_at, 'D MMM YYYY HH:mm:ss'),
            storeInfo: {
              name: coupon.coupon_used_transaction?.store?.name || coupon.coupon_used_transaction?.store?.english_name || '',
              address: coupon.coupon_used_transaction?.store?.address || ''
            },
            referenceId: coupon.reference_id,
            couponCampaignLabel,
            couponTypeClassName,
            couponTypeText,
            couponCodeFormat: coupon.template.formats,
            displayBarCode: coupon.coupon_used_transaction.code,
            barcode: coupon.coupon_used_transaction.code,
            barcodeFormat: coupon.template.barcode_format
          }
          // if (!this.isMobile) {
          //   this.scanCodeSuccessDialog.couponItem = {
          //     name: coupon.template.name || coupon.template.english_name,
          //     redeemedTime: this.getDateString(coupon.used_at, 'D MMM YYYY HH:mm:ss'),
          //     referenceId: coupon.reference_id,
          //     couponTypeClassName,
          //     couponTypeText
          //   }
          // }

          this.disableOverflow()

          console.log('113: ', this.scanCodeSuccessDialog, coupon.template.formats === 'COUPON_QR_CODE')
          if (this.scanCodeSuccessDialog.couponCodeFormat === 'COUPON_QR_CODE') {
            // this.renderShowQrcode('123456789', 'barcode-qr-canvas')
            const QRCode = require('qrcode')
            const vm = this
            await QRCode.toDataURL(coupon.coupon_used_transaction.code, { width: 262 }, function (err, url) {
              if (err) console.error(err)
              console.log(url)
              vm.scanCodeSuccessDialog.qrcodeUrl = url || ''
            })
          }
          this.isScanCodeSuccessDialog = true
        } else {
          // copy
          const couponCodeFormat = coupon.template.discount_code_format
          const isShowPartnerQRCode = couponCodeFormat === 'PARTNER_QR_CODE'
          const isShowPartnerBarCode = couponCodeFormat === 'PARTNER_BARCODE'
          this.scanCodeSuccessDialog = {
            storeInfo: {},
            isShowQrCanvas: false,
            couponCodeFormat,
            isShowPartnerQRCode,
            isShowPartnerBarCode,
            isPartnerCode: isShowPartnerQRCode || isShowPartnerBarCode,
            isTicket: coupon.template.display_in === 'TICKET_SECTION',
            barcode: coupon.coupon_used_transaction.code,
            barcodeFormat: coupon.template.barcode_format,
            couponCode: coupon.coupon_used_transaction.code,
            displayCouponCode: coupon.template.display_code,
            referenceId: coupon.reference_id,
            couponCodeUrl: coupon.template.landing_page_url,
            couponShopNowName: coupon.template.landing_page_button_name
          }
          console.log('@@1853: ', this.scanCodeSuccessDialog)
          // if (isShowPartnerQRCode) {
          //   this.showQrCodeImg(coupon.code, 'partner-qr-code-img')
          // }
          this.disableOverflow()
          this.showAcquireSuccessDialog = true
        }

        if (this.showAcquireStampSuccessDialog || this.isScanCodeSuccessDialog || this.showAcquireSuccessDialog) {
          this.removeBodyScrollingWhenModalOpen(true, window.scrollY)
          this.showUseSuccessDialog = true
        }
        sendManualAnalytics(
          this.$route,
          this.$i18n.locale,
          this.$store,
          'gamification_campaign_mission_task_success',
          'event',
          {
            task_id: this.participatingItem.can_use_coupon_id,
            selected_campaign_id: this.participatingItem.id,
            selected_campaign_name: this.participatingItem.name
          }
        )
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon|use coupon success', {
          couponId: this.participatingItem.can_use_coupon_id,
          missionId: this.missionId,
          gamificationId: this.gamificationCampaignId,
          campaignId: this.participatingItem.id,
          useSpecialStore: true,
          store: response.data.store || {},
          stamps: data.collected_stamps?.acquired_stamp_count || '0',
          rewards: data.collected_stamps?.stamp_rewards?.filter((item) => item.collected_reward === true).map(r => r.id),
          out_of_stock: data.collected_stamps?.stamp_rewards_error_messages?.length > 0 ? '1' : '0'
        })
        if (data.collected_stamps?.acquired_stamp_count) {
          sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'gamification_campaign_earn_success', 'event', data.collected_stamps)
        } else if (data.collected_stamps?.error_type) {
          sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'gamification_campaign_earn_fail', 'event', data.collected_stamps)
        }

        if (this.participatingItem.coupon_campaign_type_coupon_template.redemption_method !== 'MERCHANT_APP_SCAN_COUPON_QR_CODE') {
          this.updateMissionCollectedStamps()
        }
      } catch (error) {
        console.log(error.response)
        if (this.participatingItem.coupon_campaign_type_coupon_template.redemption_method === 'USERS_SCAN_STORE_QR_CODE') {
          if (isWechatMiniProgram()) {
            this.isScaningQrcode = true
            this.participateDirectId = null
          }

          this.$refs.scanTarget.showQrcodeError()
        } else {
          this.$refs.scanTarget.close()

          const errorDict = this.checkUseCouponFailHandle(error)
          let errorTitle = 'Use coupon fail'
          let errorText = ''
          if (errorDict.displayErrorMessage) {
            errorTitle = errorDict.displayErrorMessageTitle
            errorText = errorDict.displayErrorMessage
            this.updateMissionTaskErrorMessage(errorDict)
          } else {
            errorTitle = this.$t('couponUseFailPromptTitle')
            errorText = error.response.data.display_error_content
            this.updateMissionTaskErrorMessage(error.response.data)
          }
          this.showErrorMessageDialog(errorTitle, errorText)
          // if (['error_use_coupon_unreach_special_time_coupon', 'Coupon is expired.', 'Coupon is used.', 'Coupon is inactive', 'Coupon is unavailable.', 'error_use_coupon_gps_limit'].indexOf(error.response.data.detail) >= 0) {
          // }
        }

        sendManualAnalytics(
          this.$route,
          this.$i18n.locale,
          this.$store,
          'gamification_campaign_mission_task_fail',
          'event',
          {
            task_id: this.participatingItem.can_use_coupon_id,
            selected_campaign_id: this.participatingItem.id,
            selected_campaign_name: this.participatingItem.name,
            mission_task_fail_reason: error.response.data.display_error_content
          }
        )
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon|use coupon fail', {
          couponId: this.participatingItem.can_use_coupon_id,
          missionId: this.missionId,
          gamificationId: this.gamificationCampaignId,
          campaignId: this.participatingItem.id,
          stamps: 0,
          useSpecialStore: true,
          errorMessage: error.response.data.display_error_content
        })
      }
    },
    confirmCopyCode () {
      this.showErrorDialog = false
      this.errorDialog = {}
      if (this.participatingItem.can_use_coupon_id) {
        this.ajaxUseCoupon()
      } else {
        this.confirmToParticipate()
      }
    },
    async useCoupon (latitude = null, longitude = null) {
      sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon|use coupon', {
        campaignId: this.participatingItem.id,
        missionId: this.missionId,
        couponId: this.participatingItem.can_use_coupon_id,
        gamificationId: this.gamificationCampaignId
      })

      this.formatLatitudeLongitude(latitude, longitude)
      // let checkResult = true
      console.log('@@1500: ', this.participatingItem)
      try {
        await this.ajaxCheckCoupon({
          check_in_latitude: this.latitude,
          check_in_longitude: this.longitude,
          gamification_campaign_participation_record: this.gamificationCampaignParticipationRecordId,
          mission: this.missionId
        }, this.participatingItem.can_use_coupon_id)
      } catch (error) {
        const errorDict = this.checkUseCouponFailHandle(error)
        let errorTitle = ''
        let errorText = ''
        if (errorDict.displayErrorMessage) {
          // console.log('@@1757: ', errorDict)
          errorTitle = errorDict.displayErrorMessageTitle
          errorText = errorDict.displayErrorMessage

          this.updateMissionTaskErrorMessage(errorDict)
        } else {
          errorText = error.response.data.display_error_content.detail
          this.updateMissionTaskErrorMessage(error.response.data)
        }
        this.showErrorMessageDialog(errorTitle, errorText)

        sendManualAnalytics(
          this.$route,
          this.$i18n.locale,
          this.$store,
          'gamification_campaign_mission_task_fail',
          'event',
          {
            task_id: this.participatingItem.can_use_coupon_id,
            selected_campaign_id: this.participatingItem.id,
            selected_campaign_name: this.participatingItem.name,
            mission_task_fail_reason: errorText
          }
        )
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon|use coupon fail', {
          couponId: this.participatingItem.can_use_coupon_id,
          missionId: this.missionId,
          gamificationId: this.gamificationCampaignId,
          campaignId: this.participatingItem.id,
          stamps: 0,
          useSpecialStore: true,
          errorMessage: errorText
        })

        return
      }

      if (this.participatingItem.coupon_campaign_type_coupon_template.redemption_method === 'MERCHANT_APP_SCAN_COUPON_QR_CODE') {
        // show qrcode prompt
        // const isShowPartnerQRCode = couponCodeFormat === 'PARTNER_QR_CODE'
        // const isShowPartnerBarCode = couponCodeFormat === 'PARTNER_BARCODE'
        console.log('1954: ', this.participatingItem, this.participatingItem.coupon)
        this.scanCodeSuccessDialog = {
          isMerchant: true,
          storeInfo: {},
          isShowQrCanvas: true,
          isTicket: this.participatingItem.coupon_campaign_type_coupon_template.display_in === 'TICKET_SECTION',
          barcode: this.participatingItem.coupon.serial_number,
          srk: this.participatingItem.coupon.srk
          // couponCodeFormat,
          // isShowPartnerQRCode,
          // isShowPartnerBarCode,
          // isPartnerCode: isShowPartnerQRCode || isShowPartnerBarCode,
          // isTicket: coupon.template.display_in === 'TICKET_SECTION',
          // barcode: coupon.coupon_used_transaction.code,
          // couponCode: coupon.coupon_used_transaction.code,
          // displayCouponCode: coupon.template.display_code,
          // referenceId: coupon.reference_id,
          // couponCodeUrl: coupon.template.landing_page_url,
          // couponShopNowName: coupon.template.landing_page_button_name
        }

        this.disableOverflow()
        this.showAcquireSuccessDialog = true
        this.showUseSuccessDialog = true
        // this.showQrCodeImg(this.participatingItem.coupon?.serial_number)
      } else if (this.participatingItem.coupon_campaign_type_coupon_template.redemption_method === 'USERS_SCAN_STORE_QR_CODE') {
        // scan
        this.isScaningQrcode = true
        this.$refs.scanTarget.initQrcode(latitude, longitude)
        this.participateDirectId = null

        // this.scanSuccess('95d69796-7efa-11ef-8942-0a58a9feac02')
      } else {
        // copy
        const isTicket = this.verifyDisplayIn(this.participatingItem.coupon_campaign_type_coupon_template, 'TICKET_SECTION')

        const errorTitle = isTicket ? this.$t('myWalletUseTicketPromptTitle') : this.$t('myWalletUseCouponPromptTitle')
        const errorText = isTicket ? this.$t('myWalletUseTicketPromptDescription') : this.$t('myWalletUseCouponPromptDescription')

        this.showErrorMessageDialog(errorTitle, errorText, { isCopy: true })
      }

      // if (this.participatingItem.coupon_campaign_type_coupon_template.is_check_gps) {
      //   checkResult = await this.ajaxGetCoupon(true)
      //   console.log('695-  1404: ', checkResult)
      // }
      // console.log('695-  1407: ', checkResult)
      // if (checkResult) {
      //   this.ajaxGetCoupon()
      // }
    },
    gotoWalletURL () {
      const lang = getLangName(this.$i18n.locale)

      const qty = 1

      let verifyUrl = `${process.env.VUE_APP_WALLET_URL}/verify/?campaign=${this.participatingItem.id}&lang=${lang}&gamificationCampaignRecordId=${this.gamificationCampaignRecordId}&gamificationCampaignId=${this.gamificationCampaignId}&missionId=${this.missionId}&qty=${qty}&from=mission-detail`

      const { slotId } = this.$route.query
      if (slotId) { // for goodiebag multiple slot
        verifyUrl += '&slotid=' + slotId
      }

      if (this.eventName) {
        verifyUrl += '&event=' + this.eventName
      }

      // const isLogin = localStorage.getItem('refreshToken')

      // if (isWechatMiniProgram() && !isLogin) {
      //   loginSSO(verifyUrl)
      //   return
      // }

      if (isWechatMiniProgram()) {
        verifyUrl += `&aaid=${this.$store.getters['app/getWechatAdobeAid']}&platform=wechat`
      }

      verifyUrl = setupDefaultQueryString(this.$route, verifyUrl)

      window.location.href = verifyUrl
    },
    async ajaxGetCoupon () {
      const {
        recaptchaString,
        errorData
      } = await this.getRecaptchaString(this.isVerifyRecaptcha, true)
      if (errorData.errorContent) {
        this.errorDialog.title = errorData.errorTitle
        this.errorDialog.text = errorData.errorContent
        this.showErrorDialog = true
        sendManualAnalytics(
          this.$route,
          this.$i18n.locale,
          this.$store,
          'gamification_campaign_mission_task_fail',
          'event',
          {
            task_id: this.participatingItem.id,
            selected_campaign_id: this.participatingItem.id,
            selected_campaign_name: this.participatingItem.name,
            mission_task_fail_reason: errorData.errorContent
          }
        )
        return false
      }
      const profile = this.$store.getters['auth/userProfile']
      // if (this.isGoodieBagVoucher || this.isGoodieBagGift) {
      //   postData = {
      //     campaign: parseInt(this.campaignId),
      //     customer: profile.id,
      //     desired_coupon_quantity: this.requireAmount,
      //     desired_coupon_goodie_bag_participation_record: parseInt(this.recordId),
      //     desired_coupon_publish_channel: getChannelName(),
      //     desired_coupon_goodie_bag_campaign_slot: parseInt(this.slotId)
      //   }
      // } else {
      const postData = {
        recaptcha_verify_credential: recaptchaString,
        campaign: parseInt(this.participatingItem.id),
        customer: profile.id,
        desired_coupon_quantity: this.participatingItem.coupon_campaign_type_per_head_download_for_each_time || 1,
        desired_coupon_publish_channel: getChannelName(),
        gamification_campaign_participation_record: this.gamificationCampaignParticipationRecordId,
        mission: this.missionId
      }
      // }

      // if (recaptchaString || recaptchaString === '') {
      //   Object.assign(postData, { recaptcha_verify_credential: recaptchaString })
      // }

      if (this.participatingItem.is_check_gps) {
        Object.assign(postData, {
          check_in_latitude: this.latitude,
          check_in_longitude: this.longitude
        })
      }

      const query = []

      if (this.eventName) {
        query.push(`event=${this.eventName}`)
      }
      if (this.isGamificationPrize) {
        query.push(`is_gamificaiton=true`)
      }
      const vm = this
      axios.post(getCampaignAPIPrefix() + `/customers/${this.ssoUid}/campaign_participation_records?${query.join('&')}`, postData).then(response => {
        // const collectedQty = 0

        console.log('@2142', response)
        this.participatingItem.can_use_coupon_id = response.data.coupon_ids[0] // need response coupon id
        this.participatingItem.coupon = response.data.coupon || {}
        // if (vm.isGoodieBagVoucher || this.isGoodieBagGift) {
        //   vm.showGoodieBagSuccess(vm)
        // } else {
        this.$store.commit('gamification/updateMissionCampaignList', {
          missionId: this.missionId,
          campaign: this.participatingItem
        })
        // const displayIn = this.participatingItem && this.participatingItem.coupon_campaign_type_coupon_template
        //   ? this.participatingItem.coupon_campaign_type_coupon_template.display_in : ''
        // vm.showGetSuccessfullMessage(true, displayIn)
        vm.participateDirectId = null
        // this.$refs.missionTaskTarget[this.participatingItem.targetIndex].earnDone()
        // this.hasGotCoupon = true
        // setTimeout(() => { this.hasGotCoupon = false }, 3000)
        // }
        // collectedQty = response.data.desired_coupon_quantity

        sendManualAnalytics(
          this.$route,
          this.$i18n.locale,
          this.$store,
          'gamification_campaign_mission_task_success',
          'event',
          {
            task_id: this.participatingItem.id,
            selected_campaign_id: this.participatingItem.id,
            selected_campaign_name: this.participatingItem.name
          }
        )
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon|download coupon', {
          campaignId: this.participatingItem.id,
          missionId: this.missionId,
          gamificationId: this.gamificationCampaignId,
          join: this.isFirstJoinGame ? 1 : 0
        })

        this.$store.dispatch('coupons/refreshCoupons')
        // sendManualAnalytics(this.$route, this.$i18n.locale, this.$store,
        // 'acquire_coupon_success', 'event', { coupon_qty: collectedQty })
        if (this.participatingItem.is_starred) {
          getCampaignlimit(this.$store)
        }
        // sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'campaign|add to wallet', { campaignId: this.campaignId })
        // this.useCoupon(this.latitude, this.longitude)
        this.participateMissionTask(this.participatingItem)
      }).catch(error => {
        console.log('@695--- detail', error)
        const { data } = error.response
        let display_error_content = data.display_error_content
        // const detail = data.detail
        // if (vm.isGoodieBagVoucher || this.isGoodieBagGift) {
        //   if (display_error_content === 'Not enough credit') {
        //     vm.goodieBagFailText = 'Desired ticket(s) are sold out.'
        //     vm.isShowGoodieBagFail = true
        //   } else {
        //     vm.goodieBagFailText = display_error_content
        //     vm.isShowGoodieBagFail = true
        //     if (data && data.code === 'error_grant_coupon_campaign_gps_limit') {
        //       vm.goodieBagFailTitle = 'couponAcquireFailPromptTitle'
        //     }
        //   }
        //   sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'campaign|add to wallet fail', { campaignId: this.campaignId, errorMessage: vm.goodieBagFailText })
        // } else {

        if (data && data.detail && data.detail.includes('required hkid')) { // high value coupon but not detected
          localStorage.setItem(`missionParticipatingItem-${this.participatingItem.id}`, JSON.stringify(this.participatingItem))
          this.gotoWalletURL()
        } else {
          let errorTitle = ''
          if (data && data.code === 'error_grant_coupon_campaign_gps_limit') {
            errorTitle = this.$t('couponAcquireFailPromptTitle')
          } else {
            errorTitle = this.$t('goodiebagFailedAcquire1')
            if (data && data.detail === 'Expired') {
              if (data.end_date || this.participatingItem.end_date) {
                display_error_content = this.$t('gamificationMissionExpiredOn') + this.getDateString(data.end_date || this.participatingItem.end_date, 'D MMM YYYY')
              }
            } else if (data && data.detail === 'Offer out of stock') {
              display_error_content = `This reward is out of quota.`
            } else if (data && data.code === 'error_grant_normal_coupon_campaign_not_reach_start_date') {
              display_error_content = this.$t('availableOn', { startDate: this.getDateString(data.start_date || this.participatingItem.start_date, 'D MMM YYYY') })
            }
          }
          const errorText = display_error_content
          vm.showErrorMessageDialog(errorTitle, errorText)
          sendManualAnalytics(
            this.$route,
            this.$i18n.locale,
            this.$store,
            'gamification_campaign_mission_task_fail',
            'event',
            {
              task_id: this.participatingItem.id,
              selected_campaign_id: this.participatingItem.id,
              selected_campaign_name: this.participatingItem.name,
              mission_task_fail_reason: errorText
            }
          )
          // sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'campaign|add to wallet fail', { campaignId: this.campaignId, errorMessage: vm.goodieBagFailText })

          if (display_error_content) {
            this.updateMissionTaskErrorMessage({ displayErrorMessage: display_error_content })
          }
        }

        return false
        // }
        // sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'acquire_coupon_fail', 'onclick', { 'detail': detail, coupon_qty: this.requireAmount })
      })

      return true
    },
    getCoupon (latitude = null, longitude = null) {
      this.formatLatitudeLongitude(latitude, longitude)
      this.ajaxGetCoupon()
      // let checkResult = true
      // if (this.participatingItem.is_check_gps) {
      //   checkResult = await this.ajaxGetCoupon(true)
      //   console.log('695-  1404: ', checkResult)
      // }
      // console.log('695-  1407: ', checkResult)
      // if (checkResult) {
      //   this.ajaxGetCoupon()
      // }
    },
    scanSuccess (code) {
      this.code = code
      if (this.isCampaign) {
        this.ajaxUseCoupon(code)
      } else {
        // this.setCaptcha()
        this.ajaxCheckin(false, code)
      }
    },
    async ajaxCheckin (isCheck = false, code = null) {
      const vm = this
      const {
        recaptchaString,
        errorData
      } = await this.getRecaptchaString(!isCheck && this.isVerifyRecaptcha, true)
      if (errorData.errorContent) {
        this.errorDialog.title = errorData.errorTitle
        this.errorDialog.text = errorData.errorContent
        this.showErrorDialog = true
        sendManualAnalytics(
          this.$route,
          this.$i18n.locale,
          this.$store,
          'gamification_campaign_mission_task_fail',
          'event',
          {
            task_id: this.participatingItem.id,
            selected_location_id: this.participatingItem.id,
            selected_location_name: this.participatingItem.name,
            mission_task_fail_reason: errorData.errorContent
          }
        )
        return false
      }
      try {
        const payload = {
          recaptcha_verify_credential: recaptchaString,
          mission_location: this.participatingItem.id,
          check_in_latitude: this.latitude,
          check_in_longitude: this.longitude,
          gamification_campaign_participation_record: this.gamificationCampaignParticipationRecordId,
          code: code,
          // recaptcha_verify_credential: 'aaaa',
          mission: this.missionId
        }

        const query = isCheck ? 'check_link=true' : ''

        const response = await axios.post(`/customers/${this.ssoUid}/gamification_check_in?${query}`, payload)
        console.log(response)
        // alert('earn stamp success from check in')
        if (!isCheck) {
          this.$refs.scanTarget.close()

          const { data } = response
          this.setCollectStampSuccessDialog(true, data, this.participatingItem.location.name)

          this.disableOverflow()
          this.removeBodyScrollingWhenModalOpen(true, window.scrollY)

          this.showAcquireStampSuccessDialog = true
          this.showUseSuccessDialog = true
          this.participateDirectId = null
          this.updateMissionCollectedStamps()
          this.participatingItem.check_in_count = (this.participatingItem.check_in_count || 0) + 1
          this.participatingItem.is_completed = true
          // this.$store.commit('gamification/updateMissionCheckinList', {
          //   missionId: this.missionId,
          //   checkInData: this.participatingItem
          // })
          this.$store.commit('campaigns/updateMissionLocations', this.participatingItem)

          sendManualAnalytics(
            this.$route,
            this.$i18n.locale,
            this.$store,
            'gamification_campaign_mission_task_success',
            'event',
            {
              task_id: this.participatingItem.id,
              selected_location_id: this.participatingItem.location?.id,
              selected_location_name: this.participatingItem.location?.name
            }
          )
          sendAdobeManualAnalytics(
            this.$route,
            this.$i18n.locale,
            this.$store,
            'game|check-in success',
            {
              gamificationId: this.gamificationCampaignId,
              missionId: this.missionId,
              misisonLocationId: this.participatingItem.id,
              stamps: data.collected_stamps?.acquired_stamp_count,
              rewards: data.collected_stamps?.stamp_rewards?.filter((item) => item.collected_reward === true).map(r => r.id),
              out_of_stock: data.collected_stamps?.stamp_rewards_error_messages?.length > 0 ? '1' : '0',
              join: this.isFirstJoinGame ? '1' : '0'
            }
          )
          if (data.collected_stamps?.acquired_stamp_count) {
            sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'gamification_campaign_earn_success', 'event', data.collected_stamps)
          } else if (data.collected_stamps?.error_type) {
            sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'gamification_campaign_earn_fail', 'event', data.collected_stamps)
          }
        }
      } catch (error) {
        console.log(error.response)

        if (isCheck) {
          vm.showErrorMessageDialog(this.$t('couponCampaginReminderTitle'), error.response.data.display_error_content)
          // alert(error.response.data.display_error_content)
          // this.showAcquireCouponSuccessDialog = true
        } else {
          if (error.response.data.display_error_content.toLowerCase() === 'invalid code') {
            if (isWechatMiniProgram()) {
              this.isScaningQrcode = true
              this.participateDirectId = null
            }
            this.$refs.scanTarget.showQrcodeError()
          } else {
            this.$refs.scanTarget.close()
            vm.showErrorMessageDialog(this.$t('couponCampaginReminderTitle'), error.response.data.display_error_content)
          }
        }

        sendManualAnalytics(
          this.$route,
          this.$i18n.locale,
          this.$store,
          'gamification_campaign_mission_task_fail',
          'event',
          {
            task_id: this.participatingItem.id,
            selected_location_id: this.participatingItem.id,
            selected_location_name: this.participatingItem.name,
            mission_task_fail_reason: error.response.data.display_error_content
          }
        )
        sendAdobeManualAnalytics(
          this.$route,
          this.$i18n.locale,
          this.$store,
          'game|check-in fail',
          {
            gamificationId: this.gamificationCampaignId,
            missionId: this.missionId,
            misisonLocationId: this.participatingItem.id,
            stamps: '0',
            errorMessage: error.response.data.display_error_content
          }
        )

        return false
      }

      return true
    },
    async participateCheckIn (latitude = null, longitude = null) {
      this.formatLatitudeLongitude(latitude, longitude)
      // alert(`${this.latitude}, ${this.longitude}`)

      const checkResult = await this.ajaxCheckin(true)
      if (!checkResult) {
        return
      }

      if (this.missionData.check_in_mode === 'BOTH') {
        // check location
        this.isScaningQrcode = true
        this.$refs.scanTarget.initQrcode(latitude, longitude)
        this.participateDirectId = null
      } else {
        this.ajaxCheckin()
      }
    },
    earnStamp () {
      let needGPS = false
      let next = null
      if (this.isCampaign) {
        needGPS = this.participatingItem.is_check_gps
        if (!this.participateDirectId && this.participatingItem.can_use_coupon_id) {
          next = this.useCoupon
        } else {
          // show age popup
          next = this.getCoupon
        }
      } else {
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'game|check-in start', { gamificationId: this.gamificationCampaignId, missionId: this.missionId, misisonLocationId: this.participatingItem.id })

        if (this.missionData.check_in_mode === 'QR_CODE') {
          this.isScaningQrcode = true
          this.$refs.scanTarget.initQrcode(null, null)
          this.participateDirectId = null
        } else {
          needGPS = true
          next = this.participateCheckIn
        }
      }

      if (needGPS) {
        this.grantGPS(next, (error) => {
          console.log('---189---', error)
          this.showErrorMessageDialog(this.$t('grantGPSErrorTitle'), this.$t('grantGPSErrorContent'))
        })
      } else if (this.isCampaign) {
        next()
      }
    },
    async confirmToParticipate () {
      // this.setCaptcha()
      if (!this.gamificationCampaignParticipationRecordId) {
        // const {
        //   recaptchaString,
        //   errorData
        // } = await this.getRecaptchaString(this.isVerifyRecaptcha, true)
        // if (errorData.errorContent) {
        //   this.showErrorMessageDialog(errorData.errorTitle, errorData.errorContent)
        //   return
        // }

        const { sign, t } = this.$route.query

        this.redeemGamification({
          gamification_campaign: this.gamificationCampaignId,
          mission: this.missionId || null,
          sign: sign || null,
          tracking_code: t || null,
          // recaptcha_verify_credential: recaptchaString,
          participatingItem: this.participatingItem.id
        }, (created) => {
          if (created) {
            this.isFirstJoinGame = true
          } else {
            this.isFirstJoinGame = false
          }
          this.earnStamp()
        }, (error) => {
          const display_error_content = this.handleRedeemGamificationError(error)
          const errorTitle = error.isRecaptchaError ? error.errorTitle : ''
          const errorOthers = {}
          if (error.code === 'error_grant_gamification_campaign_invalid_country_code') {
            errorOthers.errorNext = 'my-gamifications'
          } else if (error.display_error_content !== display_error_content) {
            errorOthers.errorNext = 'home'
          }
          if (display_error_content) {
            this.showErrorMessageDialog(errorTitle, display_error_content, errorOthers)
          }

          if (!error.isRecaptchaError) {
            this.$store.commit('gamification/updateGamificationList', {
              id: this.gamificationCampaignId,
              detail: { isValid: false }
            })
          }
        })
      } else {
        this.isFirstJoinGame = false
        this.earnStamp()
      }
    },
    ageContentOkClick () {
      this.isShowAgeRestricted = false

      if (this.isCampaign && !this.participatingItem.can_use_coupon_id && this.participatingItem.show_coupon_download_confirmation) {
        this.showErrorMessageDialog(
          this.$t('couponCampaginReminderTitle'),
          this.$t('couponCampaginReminderContent'),
          {
            isCopy: true,
            cancelText: this.$t('couponCampaginReminderCancel'),
            buttonsClass: 'campaign-comfirm-buttons'
          }
        )
        return
      }

      this.confirmToParticipate()
    },
    async testSuccessDialogUI () {
      this.adjustSuccessPopupForOneTrust()
      // this.showAcquireSuccessDialog = true

      this.scanCodeSuccessDialog = {
        name: 'HK$100 Cash Voucher at HKTB Boutique',
        redeemedTime: '1 Dec 2021 14:05:10 ',
        referenceId: '4168-77-14921867',
        couponTypeClassName: 'voucher',
        couponTypeText: 'CASH VOUCHER',
        storeInfo: {
          name: 'D-Store-123',
          address: 'Address'
        },

        isShowQrCanvas: false,
        couponCodeFormat: 'COUPON_CODE', // 'COUPON_CODE', 'PARTNER_QR_CODE'， 'PARTNER_BAR_CODE', 'COUPON_URL'
        isShowPartnerQRCode: true,
        isShowPartnerBarCode: false,
        isPartnerCode: true,
        isTicket: false,
        barcode: 'ABS-abc-1234-ABC-abc-1234',
        barcodeFormat: 'code128',
        couponCode: '13234456',
        // couponCodeUrl: 'https://google.com',
        // couponShopNowName: 'ggo',
        displayCouponCode: false
      }

      this.scanCodeSuccessDialog = {
        'name': 'nancy - test scan store',
        'showColorIndicator': false,
        'indicatorColor': null,
        'indicatorText': null,
        'redeemedTime': '10 Feb 2025 14:59:14',
        'storeInfo': {
          'name': 'Fulum Restaurant - Kwai Chung',
          'address': 'Shop A, G/F, 26-30 Wo Yi Hop Road, Kwai Chung, N.T.'
        },
        'referenceId': '20405105-14234-2959',
        'couponCampaignLabel': '',
        'couponTypeClassName': '',
        'couponTypeText': 'Coupons',
        'couponCodeFormat': 'COUPON_QR_CODE',
        'displayBarCode': '123456',
        'barcode': '123456',
        'barcodeFormat': null
      }

      if (this.scanCodeSuccessDialog.couponCodeFormat === 'COUPON_QR_CODE') {
        // this.renderShowQrcode('123456789', 'barcode-qr-canvas')
        const QRCode = require('qrcode')
        const vm = this
        await QRCode.toDataURL('1111111', { width: 262 }, function (err, url) {
          if (err) console.error(err)
          console.log(url)
          vm.scanCodeSuccessDialog.qrcodeUrl = url || ''
        })
      }
      this.isScanCodeSuccessDialog = true

      this.showAcquireStampSuccessDialog = true
      // this.collectStampSuccessDialog = {
      //   // earned: missionData.collected_stamp_rewards_count,
      //   // total: missionData.per_head_limit,
      //   isCheckIn: false,
      //   checkIn: {

      //   },
      //   count: 2,
      //   rewards: [{
      //     index: 2,
      //     name: 'Free Tai Chung Egg Tart (1 piece)',
      //     imageUrl: '/img/reward-collected-icon-default.png',
      //     isCoupon: false
      //   }]
      // }
      this.collectStampSuccessDialog = {
        // checkIn: {
        //   on: this.getDateString('2025-01-24 08:00:00+0800', 'D MMM YYYY HH:mm:ss'),
        //   at: checkInLocation || ''
        // },
        gamificationCampaignId: 102,
        missionId: 1,
        count: 2,
        earnedStamps: 1,
        totalStamps: 10,
        rewards: [
          {
            name: '国泰国际新春汇演之夜奖赏活动',
            // imageUrl: '/img/reward-collected-icon-default.png',
            imageUrl: 'https://assets-es-uat.discoverhongkong.com/cms/media/93c1f933-013d-4177-a123-315e68531a2c.jpg',
            type: 'COUPON',
            isCoupon: true,
            goodieBagParticipationRecords: [1, 2],
            fortuneBagCouponIds: [1, 2],
            quantity: 1,
            index: 1
          },
          {
            name: 'collected goodie/couponset name collected goodie/couponset name collected goodie/couponset name',
            // imageUrl: '/img/reward-collected-icon-default.png',
            imageUrl: 'https://assets-es-uat.discoverhongkong.com/cms/media/93c1f933-013d-4177-a123-315e68531a2c.jpg',
            type: 'GOODIE_BAG',
            isCoupon: false,
            goodieBagParticipationRecords: [1, 2],
            fortuneBagCouponIds: [1, 2],
            quantity: 2,
            index: 2
          }
        ]
      }

      this.showUseSuccessDialog = true
    },
    renderShowQrcode (code, element) {
      setTimeout(() => {
        // const dom = document.getElementById('alert-mobile-url-qr-code')
        // console.log('807---- dom: ', dom)
        // this.generateMobileUrlCodeImg(dom, { width: 258 })
        const QRCode = require('qrcode')

        // QRCode.toCanvas(dom, window.location.href, config, function (error) {
        //   console.log('generate url code error: ', error)
        // })

        const canvas = document.getElementById(element)
        console.log('@@canvas', canvas)
        QRCode.toCanvas(canvas, code, { width: '142' }, function (error) {
          console.log('generate url code error: ', error)
        })
      }, 1000)
    },
    participateMissionTask (item) {
      this.participatingItem = item

      const testDialogUI = false
      if (testDialogUI) {
        this.removeBodyScrollingWhenModalOpen(true, window.scrollY)
        this.testSuccessDialogUI()

        return
      }

      if (!this.isMobile && (!this.isCampaign || (item.can_use_coupon_id && item.coupon_campaign_type_coupon_template.redemption_method === 'USERS_SCAN_STORE_QR_CODE'))) {
        let link = window.location.origin
        if (!this.isCampaign) {
          link += `/${this.$i18n.locale}/mission-location-detail?locationId=${item.id}&missionId=${this.missionId}&gamificationId=${this.gamificationCampaignId}&gamificationCampaignRecordId=${this.gamificationCampaignRecordId || ''}`
        } else {
          link += `/${this.$i18n.locale}/coupon-detail?couponId=${item.can_use_coupon_id}&missionId=${this.missionId}&gamificationId=${this.gamificationCampaignId}&gamificationCampaignRecordId=${this.gamificationCampaignRecordId || ''}`
        }
        this.showToMobileDetailQRcode = true
        // setTimeout(() => {
        //   // const dom = document.getElementById('alert-mobile-url-qr-code')
        //   // console.log('807---- dom: ', dom)
        //   // this.generateMobileUrlCodeImg(dom, { width: 258 })
        //   const QRCode = require('qrcode')

        //   // QRCode.toCanvas(dom, window.location.href, config, function (error) {
        //   //   console.log('generate url code error: ', error)
        //   // })

        //   const canvas = document.getElementById('link-qr-canvas')
        //   console.log('@@canvas', canvas)
        //   QRCode.toCanvas(canvas, link, { width: '142' }, function (error) {
        //     console.log('generate url code error: ', error)
        //   })
        // }, 100)
        this.renderShowQrcode(link, 'link-qr-canvas')

        console.log('link: ', link)

        this.$refs.missionTaskTarget[item.targetIndex].earnDone()
        return
      }

      if (this.isCampaign && !item.can_use_coupon_id && item.is_age_restricted) {
        this.isShowAgeRestricted = true
        return
      }

      this.ageContentOkClick()
    },
    grantGpsSuccess (latitude, longitude) {
      this.formatLatitudeLongitude(latitude, longitude)
      console.log('@@294---0-s')
      this.getMissionTaskData()
    },
    closeErrorDialog () {
      if (this.errorDialog.errorNext) {
        pushRouterWithQuery(this.$router, { path: this.errorDialog.errorNext })
      }

      this.showErrorDialog = false
      if (this.errorDialog.isLoadGrantGPSError) {
        console.log('@@294---0-f')
        this.getMissionTaskData()
      } else if (this.participatingItem) {
        this.$refs.missionTaskTarget[this.participatingItem.targetIndex].earnDone()
      }

      this.errorDialog = {}
    },
    search (key) {
      this.searchKey = key
      if (key) {
        this.filters.sorting = 'recommended'
      } else {
        this.filters.sorting = 'featured'
      }

      this.filters.searchKey = key
      this.clearMissionTaskData()
      this.isEndReached = false
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel()
      }
      this.getMissionTaskData(true)
      let filterValue = this.$store.getters['app/getFilterValueAll']
      filterValue = { ...this.filterValue, searchKey: key }
      this.updateAllSubmenus(filterValue)
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/assets/common.scss';
@import "@/assets/coupon-item.scss";

.gamification-detail-container {
  padding: 16px;
  // margin-top: 72px;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
}
.mission-detail-container {
  background-color: #FFFFFF;
  padding-left: 37px;
  padding-right: 34px;
  padding-top: 40px;
  padding-bottom: 30px;
  display:flex;

  .mission-detail-column {
    width: 0px;
    flex: 1;
  }

  .mission-block {
    background-color: #FFFFFF;
  }

  div.search-filter-area {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: 72px;
    z-index: 2;
    background: #FFF;
    padding: 16px 0px;
  }

  .section-container {
    margin: 12px 16px;
    &.padding {
      padding: 12px;
    }
    &.border {
      border-radius: 10px;
      border: 2px solid var(--temple-st-dusk-maroon-80);
    }
  }

  .slide-image {
    padding-top: calc(3/4 * 100%);
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .banner-container {
    position: relative;
    .mission-info-overlay {
      // position: absolute;
      bottom: 6px;
    }
  }

  .mission-info-overlay {
    // position: absolute;
    // top: 0px;
    // width: 100%;

    .mission-info-container {
      .mission-title-icon {
        display: flex;
        align-items: center;
        gap: 16px;
        .title {
          margin: 0px;
          font-size: 36px;
          font-weight: 700;
          text-align: left;
          align-content: center;
          // color: #FFFFFF;

          min-height: 58px;
          // max-height: 87px;
        }
        .mission-type-icon {
          width: 56px;
          height: 56px;
          background: #FFFFFF99;
          // border: 2px solid var(--Temple-St-Dusk-Maroon);
          border-radius: 100px;
        }
      }
      .mission-expiration {
        margin-top: 8px;
        label {
          display: flex;
          flex-wrap: wrap;
          font-size: 14px;
          font-weight: 600;
          line-height: 16.8px;
          text-align: left;
          // color: var(--temple-st-dusk-maroon-80);
          color: var(--temple-st-dusk-maroon-50);
          span {
            text-decoration: underline;
          }
        }
      }
      .question-mark-icon {
        width: 18px;
        height: 18px;
      }
    }
    .mission-icon-container {
      // .mission-type-icon {
      // }
      .earn-tutorial {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .grant-stamp-info-container {
    margin: 16px 0px 0px;
    padding: 12px;
    border-radius: 10px;
    // border: 2px solid var(--temple-st-dusk-maroon-80);

    background: var(--gray);

    .grant-stamp-info.clickable {
      cursor: pointer;
    }

    .grant-stamp-title {
      font-size: 16px;
      font-weight: 800;
      // line-height: 19.2px;
      text-align: left;
      color: var(--Temple-St-Dusk-Maroon);
    }
    .stamp-tips {
      margin: 8px 0px 0px;

      font-size: 12px;
      font-weight: 400;
      // line-height: 14.4px;
      text-align: left;
      color: var(--Temple-St-Dusk-Maroon);
    }

    hr {
      background-color: var(--temple-st-dusk-maroon-80);
      height: 1px;
      margin: 12px 0px;
    }

    div.tutorial {
      cursor: pointer;
      display: flex;
      gap: 4px;

      align-items: center;

      img {
        aspect-ratio: 1/1;
        height: fit-content;
      }
      img:first-of-type {
        width: 16px;
      }

      img:last-child {
        width: 12px;
      }
      label {
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        color: var(--Temple-St-Dusk-Maroon);
      }

    }
  }

  .collpase-arrow{
    cursor: pointer;
  }

  .task-container {
    margin: 0px 0px 16px;
    div:first-of-type {
      display: flex;
      width: 100%;
    }
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      font-size: 28px;
      font-weight: 700;
      text-align: left;
      color: var(--Temple-St-Dusk-Maroon);

      .view-map-button {
        display: flex;
        padding: 10px 24px;
        gap: 4px;
        border-radius: 10px;
        border: 2px solid var(--Junk-Boat-Red);
        background-color: #FFFFFF;

        font-size: 16px;
        font-weight: 600;
        line-height: 19.2px;
        text-align: left;
        color: var(--Junk-Boat-Red);

        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .task-list-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
    // position: relative;
    margin-top: 16px;
    z-index: 1;
    width: 100%
    //justify-content: space-evenly;
  }

  .empty-result {
    margin-top: 24px;
    margin-bottom: 59px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .empty-result-title {
      font-size: 14px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 16px;
    }
    .no-result-button {
      border-radius: 50px;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 8px;
      padding-bottom: 10px;
    }
  }
}

.sticky-coupon-footer {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 11px;
  // border-top:  1px solid #D1D1D1;
  // background-color: #fff;
  cursor: pointer;
  flex-direction: column;
  padding-left: 14px;
  padding-right: 14px;
  z-index: 2;
}

.is-vue-mobile .mission-detail-container {
  padding: 0px;

  .search-filter-area {
    padding: 16px;
    margin: 0px -16px;
  }
}

.is-vue-desktop .gamification-detail-container {
  padding-bottom: 60px;
  // margin-top: 91px;
  .mission-detail-container {
    padding: 16px;

    .mission-expiration {
      margin-top: 16px;
      label {
        font-size: 16px;
      }
    }

    div.mission-title-icon {
      div.title {
        font-size: 56px !important;
      }
      img{
        margin-left: auto;
      }
    }

    .grant-stamp-info-container {
      display: flex;
      hr {
        display: none;
      }

      .grant-stamp-title {
        font-size: 18px !important;
      }

      .stamp-tips {
        font-size: 14px !important;
      }

      .tutorial {
        margin: 1px 0px 1px auto;
        padding-left: 16px;
        border-left: 1px solid var(--temple-st-dusk-maroon-80);

        label {
          font-size: 18px !important;
        }
      }
    }

    div.search-filter-area {
      top: 91px;
    }

    span.task-list-container {
      flex-direction: row;

      div.mission-task-item-container {
        width: calc((100% - 48px) / 4);

        div.item-container {
          flex-direction: column-reverse;

          div.item-content {
            width: 100%;
          }

          img {
            width: 100%;
            height: fit-content;
            aspect-ratio: 4 / 3;
          }
        }

        .acquire-area {
          flex-direction: column;
        }
      }
    }
  }
}
</style>

<style lang='scss'>
@import '@/assets/gamification-font.scss';

.gamification {
  .goodie-bag-sold-out-title{
    font-weight: 700;
    font-size: 18px;
    padding-top: 43px;
    padding-left: 33px;
    padding-right: 33px;
    color: #000000;
  }
  .goodie-bag-sold-out-content{
    font-size: 14px;
    color: #000000;
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 16px;
    text-align: center;
    word-break: break-word;
    font-weight: 400;
  }
  .goodie-bag-sold-out-bottom{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 18px;
    margin-bottom: 28px;
    .button{
      border-radius: 50px;
      border:none;
      font-weight: 700;
      font-size: 18px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .coupon-detail-use-button {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #E2002C;
    padding: 12px 15px;
    border: 2px solid #E2002C;
    border-radius: 100px;
    box-sizing: border-box;
    min-width: 100px;
  }

  .coupon-detail-cancel-confirm-button {
    color: #fff !important;
    background: #E2002C;
    margin-left: 20.5px;
  }

  .wide-code .el-dialog.coupon-alert-bar-dialog .scan-code-success-bar-code{
    padding-bottom: 34px;
  }
  .wide-code .scan-code-success-bar-code{
    padding-bottom: 30px;
  }
  .scan-code-success-dialog.el-dialog--center, .coupon-alert-bar-dialog.el-dialog--center{
    border-radius: 10px;
    .el-dialog__body {
      padding: 0px;
    }
  }

  .wide-code .scan-code-success-dialog, .wide-code .coupon-alert-bar-dialog{
    width: calc(100% - 6px) !important;
    .scan-code-success-bar-footer{
      width: 100%;
      text-align: center;
    }
  }

  .el-dialog.coupon-alert-bar-dialog .el-dialog__header{
    padding: 0px;
  }

  .el-dialog.mission-coupon-use-confirm-dialog, .el-dialog.coupon-alert-bar-dialog{
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    overflow: hidden;
  }

  .mission-scan-code-success-dialog-content{
    display: flex;
    align-items: center;
    flex-flow: column;

    max-height: calc(100vh - 78px);
    overflow-y: auto;

    // .coupon-indicator {
    //   display: flex;
    //   padding: 20px 16px;
    //   align-items: center;
    //   flex-shrink: 0;

    //   color: #FFF;
    //   font-family: Open Sans;
    //   font-size: 15px;
    //   font-style: normal;
    //   font-weight: 700;
    //   line-height: 130%; /* 19.5px */
    //   /*text-transform: uppercase;*/
    //   min-height: 59.5px
    // }

    // .scan-code-success-check{
    //   margin-bottom: 20px;  /* 44px - 16px */
    //   margin-top: 0;
    //   color: #04BD81;
    //   font-size: 80px;
    //   font-weight: 300;
    //   line-height: 108.95px;
    // }

    // .scan-code-success-name{
    //   text-align: center;
    //   font-weight: bold;
    //   font-size: 21px;
    //   color: #000000;
    //   word-wrap: break-word;
    //   padding-left: 24px;
    //   padding-right: 24px;
    //   margin-bottom: 8px;
    // }

    // .scan-code-success-description{
    //   font-weight: 600;
    //   font-size: 16px;
    //   color: #999999;
    //   margin-bottom: 11px;
    // }

    // .scan-code-success-message{
    //   text-align: center;
    //   font-weight: 600;
    //   font-size: 16px;
    //   line-height: 150%;
    //   color: #000000;
    //   // word-break: keep-all;
    //   word-wrap: break-word;
    //   padding-left: 24px;
    //   padding-right: 24px;
    // }

    // .scan-code-success-message.scan-code-store{
    //   margin-bottom: 8px;
    // }

    // .dialog-footer .scan-code-success-done{
    //   background: #E2002C;
    //   border-radius: 41px;
    //   width: 150px;
    // }

    // .coupon-content .coupon-type{
    //   font-size: 16px;
    //   padding: 4px 8px;
    //   // margin: 0;
    // }

    .scan-code-success-buttons {
      width: 100%;
      padding-left: 14px;
      padding-right: 14px;
      padding-top: 16px;
      padding-bottom: 6px;
      position: fixed;
      bottom: 0;
      z-index: 50;
      background-color: #fff;
      box-shadow: 0px 1px 0px 0px #D1D1D1 inset;

      .scan-code-success-done-button, .scan-code-success-take-survey {
        border-radius: 50px;
        background: #FFF;
        text-align: center;
        cursor: pointer;
        display: flex;
        width: 100%;
        height: 48px;
        padding: 8px 40px;
        margin-bottom: 8px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        color: #E2002C;
        text-align: center;

        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 116%; /* 20.88px */
      }

    }

    .scan-code-success-buttons.has-survey .scan-code-success-done-button{
      color: #E2002C;
      background: #fff;
    }

    .scan-code-success-take-survey {
      width: 190px;
      height: 48px;
      margin-bottom: 16px;
      background: #E2002C;
      border-radius: 41px;
      color: #fff;
      text-align: center;
      line-height: 48px;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
    }

    .scan-code-success-bar-code {
      // padding: 27px 46px;
      width: 100%;
      padding-bottom: 8px;
      margin-top: 0px;
      padding-top: 20px;
      text-align: center;
      border-top: 1px dashed var(--temple-st-dusk-maroon-80);

      .partner-qr-bar-code-code {
        color: #000000;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        word-break: break-all;
        padding: 0 20px 10px;
      }
    }

    .scan-code-success-bar-footer {
      word-break: normal;
      font-weight: 500;
      font-size: 14px;
      color: var(--Temple-St-Dusk-Maroon, #3B002B);
      background: #f4f4f4;
      padding: 12px 16px;
      text-align: left;
    }

    .rotation-reminder{
      padding-left: 16px;
      padding-right: 16px;
      justify-content: space-evenly;
      display: flex;
      flex-flow: row;
      color: var(--temple-st-dusk-maroon-50, #9D8095);
      font-size: 14px;
      font-weight: 500;
      line-height: 1.2;
      text-align: left;
      margin-top: 8px;
      align-items: center;
      img {
        height: 30px;
        margin-right: 5px;
      }
    }
  }

  .el-dialog.full-dialog{
    width: 100% !important;
    height: 100% !important;
    margin-top: 0 !important;
    border-radius: 0 !important;

    .el-dialog__footer {
      width:100%;
      text-align: center;
      position:absolute;
      bottom:0;
    }

    .el-dialog__header{
      height: 0;
      padding-top:0;
      padding-bottom:0;
    }
    .el-dialog__close {
      display: none;
    }
  }

  .el-dialog__body .mission-scan-code-success-dialog-content{
    .dialog-footer .scan-code-success-done{
      background: #E2002C;
      border-radius: 41px;
      width: 150px;
    }

    .coupon-content .coupon-type{
      font-size: 16px;
      padding: 4px 8px;
      // margin: 0;
    }

    .scan-code-success-buttons {
      width: 100%;
      padding-left: 14px;
      padding-right: 14px;
      padding-top: 16px;
      padding-bottom: 6px;
      position: fixed;
      bottom: 0;
      z-index: 50;
      background-color: #fff;
      box-shadow: 0px 1px 0px 0px #D1D1D1 inset;

      .scan-code-success-done-button, .scan-code-success-take-survey {
        border-radius: 50px;
        background: #FFF;
        text-align: center;
        cursor: pointer;
        display: flex;
        width: 100%;
        height: 48px;
        padding: 8px 40px;
        margin-bottom: 8px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        color: #E2002C;
        text-align: center;
        border: 2px solid #E2002C;

        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 116%; /* 20.88px */
      }

    }

    .scan-code-success-buttons.has-survey .scan-code-success-done-button{
      color: #E2002C;
      background: #fff;
    }

    .scan-code-success-take-survey {
      width: 190px;
      height: 48px;
      margin-bottom: 16px;
      background: #E2002C;
      border-radius: 41px;
      color: #fff;
      text-align: center;
      line-height: 48px;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
    }

    .scan-code-success-bar-code {
      // padding: 27px 46px;
      width: 100%;
      padding-bottom: 8px;
      margin-top: 0px;
      padding-top: 20px;
      text-align: center;
      background: #FFF;

      .partner-qr-bar-code-code {
        color: #000000;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        word-break: break-all;
        padding: 0 20px 10px;
      }
    }

    .scan-code-success-bar-footer {
      word-break: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000;
      background: #EEE;
      padding: 12px 16px;
      text-align: left;
    }

  }

  .scan-code-success-dialog .el-dialog__body .scan-code-success-dialog-content {
    .scan-code-success-bar-code {
      .partner-qr-bar-code-code {
        padding: 0 40px 10px;
      }
    }
  }

  .el-dialog.full-dialog .el-dialog__body .scan-code-success-dialog-content{
  .scan-code-success-bar-footer {
      margin-bottom: 80px;
    }

    .scan-code-success-bar-code {
        border-top: 1px dashed #979797;
    }
  }

  .scan-code-success-dialog .el-dialog__headerbtn i{
    font-size: 28px;
  }

  .scan-code-success-dialog .el-dialog__body{
    padding-bottom: 22px;
  }
}

.is-vue-mobile .gamification {
  .el-dialog__body {
    // font-family: Manrope;
  }

  .scan-code-success-dialog .coupon-indicator {
    width: 100%;
    display: flex;
    padding: 20px 16px;
    align-items: center;
    flex-shrink: 0;

    color: #FFF;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 19.5px */
    /*text-transform: uppercase;*/
    min-height: 55.5px
  }

  // .partner-qr-bar-code{
  //   .coupon-detail-mobile-button-tip{
  //     color: #000;
  //     font-size: 14px;
  //     font-weight: 600;
  //   }
  //   .coupon-detail-mobile-button-text .coupon-detail-mobile-copy-button-text {
  //     padding-bottom: 16px;
  //   }
  //   .coupon-detail-mobile-copy-button-text .coupon-detail-mobile-code{
  //     padding: 0;
  //     margin: 0;
  //   }
  // }
}

.mission-detail-container {
  .searchbar-container {
    &.desktop {
      // background: #EEE;
      background: #FFF;
      padding: 0px;
      margin: 0px;
      align-items: center;
      justify-items: baseline;
      justify-content: flex-end;

      .searchbar-selector {
        width: 362px;
        .search-bar-container {
          border: 2px solid var(--temple-st-dusk-maroon-80);
          border-radius: 10px 0 0 10px;
          gap: 4px;
          .search-icon {
            width: 20px;
            height: 20px;
          }
          .search-autocomplete-inline-input {
            padding-left: 0px;
            .el-input input::placeholder {
              color: #9D8095;
            }
          }
        }
        button {
          margin-left: -2px;
          padding: 0px 24px;
          width: 109px;
          background-color: #FF1700 !important;
          border-radius: 0 10px 10px 0;
          font-size: 18px;
        }
      }
    }
    &.mobile {
      background: #FFFFFF;
      display: flex;
      flex-direction: row;
      margin: 0px;
      padding: 0px;
      width: 100%;
    }
    .searchbar {
      border-radius: 10px;
      border: 2px solid var(--temple-st-dusk-maroon-80);
      span {
        width: 100%;

        font-size: 14px;
        font-weight: 600;
        line-height: 19.12px;
        text-align: left;
        color: var(--temple-st-dusk-maroon-50);
      }
    }
    .searchbar-empty {
      background: #FFFFFF;
    }
  }

  .search-filter-area {
    .sort-filter-line {
      padding: 0px;
      margin-left: 12px;

      .sort-filters-container {
        margin: 0px;
        width: fit-content;
      }
    }
  }

  .filter-container {
    .sort-filter-line {
      padding: 0px;

      .sort-filters-container {
        margin: 0px;
        width: 100%;
        border-radius: 10px;
        border: 2px solid var(--temple-st-dusk-maroon-80);
        .sort-divider {
          background-color: var(--temple-st-dusk-maroon-50);
        }
        .filters {
          min-width: 80px;
          .filter-text {
            font-size: 14px;
            font-weight: 600;
            line-height: 19.12px;
            text-align: left;
            color: var(--Temple-St-Dusk-Maroon);
          }

        }
      }
    }
  }
}

.is-vue-desktop {
  .gamification {
    [role="dialog"] {
      border-radius: 10px;

      .el-dialog__body {
        // font-family: Manrope;
      }
    }

    .goodie-bag-sold-out-title{
      padding-top: 30px;
      padding-left: 30px;
      padding-right: 30px;
      text-align: left;
      color: #4A4A4A;
      font-weight: 700;
      font-size: 30px;
      line-height: 1.3;
    }
    .goodie-bag-sold-out-content{
      color: #404040;
      text-align: left;
      margin-left: 30px;
      margin-right: 30px;
      font-weight: 400;
      font-size: 15px;
    }
    .goodie-bag-sold-out-bottom{
      justify-content: right;
      padding-right: 37px;
      .button{
        font-weight: 700;
        font-size: 20px;
        padding-left: 36px;
        padding-right: 36px;
      }
    }
    .coupon-detail-use-button {
      cursor: pointer;
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      color: #E2002C;
      padding: 12px 15px;
      border: 2px solid #E2002C;
      border-radius: 100px;
      box-sizing: border-box;
      min-width: 100px;
    }

    .scan-code-success-dialog .coupon-indicator {
      width: 100%;
      display: flex;
      padding: 18px 27px;
      align-items: center;
      flex-shrink: 0;

      color: #FFF;
      // font-family: Open Sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 19.5px */
      /*text-transform: uppercase;*/
      min-height: 59.5px
    }

    .scan-code-success-dialog-content .coupon-content .coupon-type-container  {
      width: calc(100vw);
      padding-left: 24px;
      padding-right: 24px !important;

      .coupon-type-tags {
        display: flex;
        align-items: center;
        padding-right: 0px;

        .coupon-type.starred {
          margin-left: 0px;
        }
      }
    }

    .mission-coupon-use-confirm-dialog {
      .el-dialog__body {
        padding-left: 0px;
        padding-right: 30px;
        padding-top: 0px;
        padding-bottom: 30px;
      }

      .el-dialog__header {
        padding-left: 30px;
        padding-top: 0px;
      }

    }

    .display-code-dialog {
      height: fit-content !important;
      .el-dialog__header {
        display: none;
      }
    }

    .to-mobile-dialog {
      border-radius: 10px;
      .el-dialog__header {
        display: none;
      }
      .el-dialog__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;

        label {
          font-size: 16px;
          font-weight: 400;
          line-height: 1.2;
          text-align: center;
          color: #000000;
        }
        button {
          cursor: pointer;
          justify-content: center;
          margin-right: auto;
          width: 240px;
        }
      }
    }

    .agile__dots {
      bottom: 3px;
    }

    .stamps-reward-dialog {
      overflow: hidden;

      .el-dialog__body {
        height: 100%;

        .agile.carousel.detail {
          height: 100%;
        }

        .agile__list {
          height: 100%;

          .agile__track {
            height: 100%;

            .agile__slides {
              height:100%;

              .slide {
                height: 100%;
                width: 600px;
              }
            }
          }
        }
      }
    }

    .el-autocomplete-suggestion {
      width: 288px !important;
    }
  }
  .mission-detail-container {
    .sort-filter-line {
      padding: 0px !important;
      margin-left: 16px;

      .sort-filters-container {
        margin: 0px;
        width: fit-content;
      }
    }
    .combine-filters {
      padding: 0px !important;
    }

    span.task-list-container {
      flex-direction: row;
      width: 100%;
    }
  }
}

.coupon-detail-use-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.mission-coupon-use-confirm-dialog {
  .el-dialog__body {
    padding-left: 0px !important;
    padding-right: 15px;
    padding-top: 0 !important;
    padding-bottom: 0;
    // display: flex;
    // justify-content: center;
    // font-size: 14px;
    // line-height: 22px;
    // text-align: center;
    // color: #000000;

    .goodie-bag-sold-out-title {
      padding-top: 20px
    }
  }
}
.mission-fail-to-acquire-coupon {
  &.only-message {
    [role="dialog"] {
      .el-dialog__header {
        padding-top: 20px;
      }
    }
  }
  [role="dialog"] {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    .el-dialog__header {
      padding-top: 0px;
      padding-bottom: 0;
      .el-dialog__title {
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #000000;
      }
    }
    .el-dialog__body {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      justify-content: center;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #000000;

      div.error-dialog-with-title-content {
        width: 100%;
      }
    }
    .el-dialog__footer {
      padding-top: 24px;
      padding-bottom: 24px;
      .el-button--primary {
        padding-top: 8px;
        padding-bottom: 10px;
        padding-left: 39.5px;
        padding-right: 39.5px;
        border-radius: 50px;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
      }
    }
  }
}
// .slide {
//   height: 100vh;
// }
.is-vue-mobile .gamification {
  .el-dialog.mission-coupon-use-confirm-dialog .coupon-detail-use-buttons {
    justify-content: center;
  }

  .mission-coupon-use-confirm-dialog {
    .el-dialog__body {
      padding-left: 25px !important;
      .goodie-bag-sold-out-title {
        text-align: center;
      }
      .coupon-detail-use-buttons {
        justify-content: center;
        align-items: center;
        gap: 20.5px;

        &.campaign-comfirm-buttons {
          flex-direction: column-reverse;
          // gap: 10px;

          .coupon-detail-use-button {
            padding: 5px 15px;
            width: 220px;
          }
        }

        .coupon-detail-cancel-confirm-button {
          margin-left: unset;
        }
      }
    }
  }

  .el-dialog__wrapper {
    overflow: hidden;
  }
  .stamps-reward-dialog {
    overflow: hidden;

    .el-dialog__body {
      height: 100%;

      .agile.carousel.detail {
        height: 100%;
      }

      .agile__list {
        height: 100%;

        .agile__track {
          height: 100%;

          .agile__slides {
            height:100%;

            .slide {
              height: 100%;
              width: 100vw;
            }
          }
        }
      }
    }
  }

  // .carousel.detail {
  //   height: 100vh;
  // }
  // .agile__list {
  //   height: 100vh;
  // }
  // .slide {
  //   height: 100vh;
  // }

  .agile__dots {
    bottom: 3px;
  }
}

.el-dialog__wrapper.recaptcha-fail {
  .goodie-bag-sold-out-title {
    text-align: center;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
}

.display-code-dialog {
  height: calc(100vh - 81px) !important;
  .el-dialog__body {
    padding: 0px !important
  }
}
</style>
