<template>

  <div :class="{'footer-main':true, 'has-sticky-footer': $store.getters['app/isSticky']}" :style="footerStyle">
    <div class="footer-left">
      <div :class="{'footer-links': true, }">
        <div class="footer-links" :class="{'mobile': $store.getters['app/isMobile']}">
          <router-link data-aid="home_footer_terms" :to="$i18nRoute($route, 'policy', null, {type:'tc'})">{{ $t('footerTermsConditions') }}</router-link>
          <div class="line" />
          <router-link data-aid="home_footer_privacy" :to="$i18nRoute($route, 'policy', null, {type:'privacy'})">{{ $t('footerPrivacyPolicy') }}</router-link>
          <div class="line" />
          <router-link :to="$i18nRoute($route, 'policy', null, {type:'cookie'})">{{ $t('cookiepolicyCookiePolicy') }}</router-link>
        </div>
        <div v-if="!$store.getters['app/isMobile']" class="line" />
        <router-link data-aid="home_footer_privacy" :to="$i18nRoute($route, 'policy', null, {type:'faq'})">{{ $t('footerFAQ') }}</router-link>
        <div class="line" />
        <a data-aid="home_footer_hktb" :href="footerContactHref">{{ $t('navigationContact') }}</a>
      </div>
      <div v-if="!$store.getters['app/getIsGrecaptcha']" class="footer-recerved">{{ $t('footer2021HKTBAllRightsRecerved', {'year': new Date().getFullYear()}) }}</div>
      <div v-else :class="{'footer-links': true, }" class="getGrecaptcha">
        <div class="footer-recerved">{{ $t('footer2021HKTBAllRightsRecerved', {'year': new Date().getFullYear()}) }}</div>
        <div class="footer-links">
          <div class="footer-recaptcha">{{ $t('footerReCAPTCHA') }}</div>
          <div class="footer-recaptcha">
            <a href="https://policies.google.com/privacy">{{ $t('footerRePrivacyPolicy') }}</a>
            <div class="line" :class="{'repeatline': !$store.getters['app/isMobile']}" />
            <a href="https://policies.google.com/terms">{{ $t('footerTermsOfService') }}</a>
          </div>
        </div>
      </div>

    </div>
    <img :src="footerLogo" width="108px" height="35px">
  </div>

</template>

<style scoped lang='scss'>

.footer-links a, .footer-links span {
  color: #FFFFFF;
  font-size: 11px;
  transition: color 400ms ease-in-out;

  &:hover {
    color: #E2002C;
  }
}

.footer-links{
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  .line{
    width: 1px;
    height: 11px;
    background-color: #FFFFFF;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.footer-left{
  display: flex;
  flex-flow: column;
}

.footer-recerved{
  font-size: 11px;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  align-items: center;
}
.footer-recaptcha {
  font-size: 11px;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  align-items: center;
}

.footer-links.mobile{
  width: 100%;
}

.is-vue-desktop {
  .footer-recerved{
    margin-top: 8px;
    font-size: 13px;
    color: #EEEEEE;
    height: 18px;
    line-height: 18px;
    margin-right:24px;
  }

  .footer-main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding-left: 30px;
  }
  .line{
    margin-left: 24px;
    margin-right: 24px;
  }
  .repeatline{
    margin-left: 12px;
    margin-right: 12px;
  }
  .footer-links a, .footer-links span {
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    height: 24px;
  }
  .footer-recaptcha{
    margin-top: 8px;
    font-size: 13px;
    color: #EEEEEE;
    height: 18px;
    line-height: 18px;
    margin-right:12px;
  }
  .footer-recaptcha a{
    height: 18px;
    line-height: 18px;
  }
}

.is-vue-mobile {
  .footer-recerved{
    margin-bottom: 8px;
    margin-top: 2px;
    font-size: 11px;
    height: 28px;
    line-height: 18px;
    margin-right:0px;
    width: 100%;
  }
  .footer-links {
    text-align: left;
  }

  img{
    height: 31px;
    width: 93.9px;
  }

  .footer-main{
    display: flex;
    flex-flow: column;
    height: 100%;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .footer-links a, .footer-links span {
    display: flex;
    align-items: center;
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 11px;
    height: 28px;
    line-height: 18px;
  }

  .footer-recaptcha{
    margin-top: 8px;
    font-size: 11px;
    color: #FFFFFF;
    line-height: 18px;
  }

  .footer-recaptcha a{
    font-size: 11px;
  }
  .getGrecaptcha{
    margin-bottom: 8.15px;
  }

}

</style>
<script>
import { isWechatMiniProgram } from '@/utils/utils'
export default {
  computed: {
    footerLogo () {
      const lang = this.$i18n.locale
      if (lang === 'zh-s') {
        return '/img/hktb-logo-footer-sc.svg'
      }

      if (lang === 'zh-t') {
        return '/img/hktb-logo-footer-tc.svg'
      }

      return '/img/hktb-logo-footer.svg'
    },
    footerStyle () {
      let paddingBottom = 0
      if (this.$store.getters['app/isSticky'] && window.$('.sticky-coupon-footer').length) {
        console.log('@70, get bottom padding', window.$('.sticky-coupon-footer').outerHeight())
        paddingBottom = window.$('.sticky-coupon-footer').outerHeight() + 'px'
      }
      const style = { paddingBottom }
      return style
    },
    footerContactHref () {
      const lang = this.$i18n.locale
      if (lang === 'en') {
        return 'https://www.discoverhongkong.com/eng/contact-us.html'
      } else if (lang === 'zh-s') {
        return 'https://www.discoverhongkong.cn/china/contact-us.html'
      } else {
        return 'https://www.discoverhongkong.com/tc/contact-us.html'
      }
    }
  },
  mounted () {
    window.$('body').on('DOMNodeInserted', '.sticky-coupon-footer', function () {
      console.log('@114', window.$('.sticky-coupon-footer').outerHeight())
      if (isWechatMiniProgram()) {
        window.$('.document-campaign-detail .main-container, .document-coupon-detail .main-container').css('padding-bottom', window.$('.sticky-coupon-footer').outerHeight() + 'px')
        window.$('.document-prize-campaign-detail .main-container, .document-prize-coupon-detail .main-container').css('padding-bottom', window.$('.sticky-coupon-footer').outerHeight() + 'px')
      } else {
        window.$('.footer-main.has-sticky-footer').css('padding-bottom', window.$('.sticky-coupon-footer').outerHeight() + 'px')
      }
    })
  }
}
</script>
