<template>
  <div :class="['gamification-item-detail', { 'vertical-mode': isVerticalCard }]" @click.prevent="gotoDetail">
    <div class="gamification-item-detail-container" :class="langClass">
      <div class="gamification-banner-container">
        <empty-image :class-name="{'gamification-banner-image': true}" :source="coverPhotoUrl || item.cover_photo_url" />
      </div>
      <div class="gamification-info-container">
        <div v-if="gamificationLabels && gamificationLabels.length" class="gamification-labels">
          <label v-for="(gamificationLabel, labelIndex) in gamificationLabels" :key="`gamification-label-${labelIndex}`">
            {{ gamificationLabel }}
          </label>
        </div>
        <div class="gamification-title-container">
          <div>
            <div :class="['title gamification-title', langClass]">
              {{ item.name }}
            </div>
            <div class="description gamification-description is-collapse-2lines-html" v-html="item.short_description" />
          </div>

          <div class="bottom-container">
            <button class="common-button red-bg-button-height">{{ $t('gamificationListCardViewDetail') }}</button>
            <div class="right">
              <label>{{ collectedStampRewardsCount }}/{{ item.stamp_count }} {{ $t('gamificationStamps') }}</label>
              <!-- <div class="stamp-icon">
                <img v-if="!isJsonImage(stampIconCollected)" class="icon-bg" :src="stampIconCollected">
                <lottie-animation
                  v-if="isJsonImage(stampIconCollected)"
                  :path="isJsonImage(stampIconCollected)"
                  :loop="false"
                />
                <label>{{ collectedStampRewardsCount }}</label>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilsMixin from '@/mixins/utilsMixin'
import { pushRouterWithQuery } from '@/utils/utils'
import EmptyImage from '@/components/EmptyImage'
// import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import { sendManualAnalytics } from '@/utils/tracking'

export default {
  name: 'GamificationListItem',
  components: {
    EmptyImage
    // LottieAnimation
  },
  mixins: [utilsMixin],
  props: {
    item: {
      type: Object,
      default: null
    },
    cardOrientation: {
      type: String,
      default: 'HORIZONTAL'
    }
  },
  computed: {
    isVerticalCard () {
      if (this.isMobile) {
        return true
      }
      return this.cardOrientation === 'VERTICAL'
    },
    coverPhotoUrl () {
      let imageField = 'cover_photo_url'
      if (this.$store.getters['app/isMobile']) {
        imageField = 'mobile_photo_url'
      }
      const { [imageField]: coverPhotoUrl } = this.item || {}
      return coverPhotoUrl
    },
    collectedStampRewardsCount () {
      const collectedStampRewardsCount = this.item.collected_stamp_rewards_count || 0
      if (collectedStampRewardsCount % this.item.stamp_count === 0) {
        return collectedStampRewardsCount
      }
      return collectedStampRewardsCount % this.item.stamp_count
    },
    validTo () {
      return this.getCampaignVaidTil(null, this.item.endDate, false)
    },
    gamificationLabels () {
      let missionLabels = []
      if (this.item.show_mission_labels && this.item.mission_labels && this.item.mission_labels.length) {
        missionLabels = this.item.mission_labels.filter((missionLabel) => missionLabel)
      }
      return missionLabels
    },
    stampIconCollected () {
      return this.item.stamp_icon_collected_url || '/img/stamp-collected-icon-default.png'
    }
  },
  mounted () {
    console.log('@@39: ', this.item)
  },
  methods: {
    gotoDetail () {
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'gamification_campaign_myreward_click', 'onclick', { gamificationCampaignId: this.item.id })
      pushRouterWithQuery(this.$router, { path: 'gamification-detail', query: { gamificationCampaignId: this.item.id }})
    },
    isJsonImage (url) {
      if (url.indexOf('.json') > 0) {
        return url.replace('https://', '/')
      }
      return null
    }
  }
}
</script>

<style lang='scss'>

</style>

<style lang='scss' scoped>
@import '@/assets/common.scss';
.gamification-item-detail {
  cursor: pointer;
  padding: 12px;
  background-color: #FFFFFF;
  border-radius: 8px;
  button, label {
    cursor: pointer;
  }
  .gamification-item-detail-container {
    display: flex;
    flex-direction: row;

    .gamification-banner-container {
      width: 50%;
      line-height: 0;
      .gamification-banner-image {
        // max-height: 366px;
        border-radius: 10px;
        aspect-ratio: 16/9;
        width: 100%;
        height: 100%;
      }
    }
    .gamification-info-container {
      width: 50%;
      display: flex;
      flex-direction: column;
      padding-left: 20px;

      .gamification-labels {
        // margin-bottom: 8px;
        display: flex;
        flex-wrap: wrap;
        // gap: 4px 8px;
        label {
          margin-right: 8px;
          margin-bottom: 8px;
          width: fit-content;
          padding: 4px 8px;
          border-radius: 4px;
          border: 1px solid var(--Junk-Boat-Red);

          // font-family: Manrope;
          font-size: 12px;
          font-weight: 700;
          // line-height: 14.4px;
          color: var(--Junk-Boat-Red);
        }
      }
      .gamification-title-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .gamification-title {
          margin-bottom: 8px;
          // font-family: HK Remix;
          font-size: 28px;
          font-weight: 700;
          line-height: 1.3;
          color: #3B002B;

          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 2; /* number of lines to show */
                  line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .gamification-title.en {
          // font-family: HKRemixSmall-Bold;
        }
        .gamification-title.zht {
          // font-family: 'Noto Sans HK', sans-serif;
        }
        .gamification-title.zhs {
          // font-family: 'Noto Sans SC', sans-serif;
        }
        .gamification-description {
          margin-bottom: 8px;
          // font-family: Manrope;
          font-size: 14px;
          font-weight: 500;
          // line-height: 19.12px;
          color: #9D8095;

          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 2; /* number of lines to show */
                  line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .bottom-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          button {
            // cursor: pointer;
            margin: 0px;
            padding: 9px 30px;

            // font-family: Manrope;
            font-size: 16px;
            font-weight: 600;
            // line-height: 19.2px;
          }
          .right {
            display: flex;
            align-items: center;
            // gap: 8px;
            label {
              // font-family: Manrope;
              font-size: 14px;
              font-weight: 600;
              // line-height: 16.8px;
              color: #9D8095;
            }
            .stamp-icon {
              margin-left: 8px;
              width: 24px;
              height: 24px;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              label {
                position: absolute;
                // font-family: Manrope;
                font-size: 10.29px;
                font-weight: 800;
                // line-height: 14.05px;
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
  }
}
.gamification-item-detail-container.en {
  font-family: "Manrope", serif;
}
.gamification-item-detail-container.zht {
  font-family: 'Noto Sans HK', sans-serif;
}
.gamification-item-detail-container.zhs {
  font-family: 'Noto Sans SC', sans-serif;
}

.gamification-item-detail.vertical-mode .gamification-item-detail-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .gamification-banner-container {
    width: 100%;
    .gamification-banner-image {
    }
  }
  .gamification-info-container {
    width: 100%;
    padding-left: 0px;
  }
}

.is-vue-desktop .gamification-item-detail {
  padding: 16px;
  border-radius: 10px;
}

.is-vue-desktop .gamification-item-detail .gamification-item-detail-container {
  .gamification-info-container {
    .gamification-labels {
      label {
        font-size: 14px;
        font-weight: 700;
        // line-height: 16.8px;
      }
    }
    .gamification-title-container {
      .gamification-title {
        font-size: 38px;
        font-weight: 700;
        // line-height: 30.4px;
      }
      .gamification-description {
        font-size: 16px;
        font-weight: 500;
        // line-height: 21.86px;
      }
      .bottom-container {
        button {
          padding: 8px 20px;

          // font-family: Manrope;
          font-size: 18px;
          font-weight: 600;
          // line-height: 21.6px;
        }
        .right {
          label {
            font-size: 16px;
            font-weight: 600;
            // line-height: 19.2px;
          }
          .stamp-icon {
            width: 32px;
            height: 32px;
            label {
              font-size: 14px;
              font-weight: 800;
              // line-height: 19.12px;
            }
          }
        }
      }
    }
  }
}
</style>
