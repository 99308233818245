<template>
  <div
    id="app"
    :class="[{'is-vue-page': true, 'is-vue-mobile': $store.getters['app/isMobile'],'is-vue-desktop': !$store.getters['app/isMobile']}]"
  >
    <router-view />
  </div>
</template>

<script>
import store from './store'
import { i18n } from './i18n'
const Bowser = require('bowser')
import { sendPageViewAnalytics } from '@/utils/tracking'

export default {
  name: 'App',
  created () {
    window.addEventListener('resize', this.resizeHandler)
    this.resizeHandler() // trigger for the first time
    this.prepareCollapsible()

    const demoElem = document.getElementsByTagName('body')[0]
    const observer = new MutationObserver(mutations => {
      mutations.forEach((mutation) => {
        console.log('@@27: ', mutation.target, mutation.target.getAttribute('class'))
        if (mutation.target.getAttribute('class')?.indexOf('el-popup-parent--hidden') >= 0 && mutation.target.getAttribute('class')?.indexOf('el-popup--full-screen') < 0) {
          this.disableBodyScroll()
        } else {
          this.enableBodyScroll()
        }
      })
    })

    observer.observe(demoElem, { attributes: true })
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeHandler)
  },
  mounted () {
    this.$router.afterEach((to, from) => {
      console.log('@36, route changed')
      if (!(from.name === 'campaign-detail' && to.name === 'home')) {
        window.scrollTo(0, 0)
      }

      console.log('@router@106@36b', to)

      if (!['gamification-detail', 'landing-gamification', 'mission-detail', 'prize-coupon-detail', 'mission-location-map'].includes(to.name)) {
        console.log('@woopra app')
        sendPageViewAnalytics(to, i18n.locale, store)
      }
    })
    console.log('this support brower', this.isSupportBrowser())

    const wechatTokenURL = window.location.href.split('#')[0]
    this.$store.commit('app/setFirstLoadWechatTokenURL', wechatTokenURL)
  },
  methods: {
    isSupportBrowser () {
      return ['chrome', 'firefox', 'msie', 'safari'].indexOf(Bowser.name.toLowerCase()) > -1
    },
    resizeHandler (e) {
      this.$store.commit('app/setWindowWidth', window.innerWidth)
      this.$store.commit('app/setWindowHeight', window.innerHeight)
      if (window.$('#onetrust-banner-sdk:visible').height()) {
        this.$store.commit('app/setOneTrustHeight', window.$('#onetrust-banner-sdk').height())
      }
    // your code for handling resize...
    },
    prepareCollapsible () {
      window.$('document').find('.collapse-button').click(function () {
        window.$(this).parents('.is-collapsible').toggleClass('is-collapsed')
      })
    },
    preventDefault (e) {
      console.log('@@27-76: ', e)
      e.preventDefault()
    },
    preventDefaultForScrollKeys (e) {
      const keys = { 37: 1, 38: 1, 39: 1, 40: 1 }
      if (keys[e.keyCode]) {
        this.preventDefault(e)
        return false
      }
    },
    disableBodyScroll () {
      console.log('@@27-87: ')
      const supportsPassive = true
      var wheelOpt = supportsPassive ? { passive: false } : false
      const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'

      window.addEventListener('DOMMouseScroll', this.preventDefault, false) // older FF
      window.addEventListener(wheelEvent, this.preventDefault, wheelOpt) // modern desktop
      window.addEventListener('touchmove', this.preventDefault, wheelOpt) // mobile
      window.addEventListener('keydown', this.preventDefaultForScrollKeys, false)
    },
    // call this to Enable
    enableBodyScroll () {
      const supportsPassive = true
      var wheelOpt = supportsPassive ? { passive: false } : false
      const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'

      window.removeEventListener('DOMMouseScroll', this.preventDefault, false)
      window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt)
      window.removeEventListener('touchmove', this.preventDefault, wheelOpt)
      window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false)
    }
  }
}
</script>

<style lang='scss'>
#app {
 background-color: #F7F7F7;
 min-height: 100vh;
 font-family: Open Sans, Microsoft JHei TC Regular, arial, sans-serif;
}
.el-scrollbar {
    > .el-scrollbar__bar {
        opacity: 1;
    }
}
.el-popup-parent--hidden::-webkit-scrollbar {
  display: none;
  width: 0px;
}
.el-popup-parent--hidden {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  // max-height: calc(100vh);
  overflow: visible !important;
}
</style>
